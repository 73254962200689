import { useEffect, useState } from 'react';

let deferredPrompt: any;
let isSupported: boolean = false;
let wasTriggeredVisible: boolean = false;

/** Based on 
 https://web.dev/customize-install/
  */

export const initAppInstall = () => {
  window.addEventListener('beforeinstallprompt', (event) => {
    const button = document.querySelector('.install-app-btn');
    // @ts-ignore
    if (button?.style?.visibility && !isAppInstalled()) {
      // @ts-ignore
      button.style.visibility = 'visible';
      wasTriggeredVisible = true;
    }
    deferredPrompt = event;
    isSupported = !!event;
  });

  window.addEventListener('appinstalled', () => {
    const button = document.querySelector('.install-app-btn');
    // @ts-ignore
    button.style.visibility = 'hidden';
    deferredPrompt = null;
    wasTriggeredVisible = false;
  });
};

export const handleAppInstall = async () => {
  if (deferredPrompt) {
    deferredPrompt.prompt();
    const { outcome } = await deferredPrompt.userChoice;
    if (outcome === 'accepted') {
      deferredPrompt = null;
    }
  }
};

export const isAppInstallSupported = () => isSupported;

export const isAppInstalled = (): boolean => {
  return window.matchMedia('(display-mode: standalone)').matches;
};

export const shouldButtonVisibleOnSecondInit = () => {
  return isAppInstallSupported() && !isAppInstalled() && wasTriggeredVisible;
};

export const useIsAppInstallSupported: any = () => {
  const [isSupported, setIsSupported] = useState<boolean>(isAppInstalled());

  window.addEventListener('beforeinstallprompt', (event) => {
    // debugger;
    setIsSupported(true);
  });

  const checkIfCanInstall = () => {
    // @ts-ignore
    if (deferredPrompt) {
      setIsSupported(true);
    } else
      setTimeout(() => {
        console.warn('.....');
        checkIfCanInstall();
      }, 3 * 1000);
  };

  useEffect(() => {
    // @ts-ignore
    if (deferredPrompt) setIsSupported(true);
    window.addEventListener('beforeinstallprompt', (event) => {
      // debugger;
      setIsSupported(true);
    });
    checkIfCanInstall();
  }, []);

  return isSupported;
};
