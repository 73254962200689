import React from 'react';
import styled from 'styled-components';
import CheckIcon from '@material-ui/icons/Check';
import { colors } from '../../theme';

const IconContainer = styled.div`
  position: absolute;
  right: 0;
  top: 21px;
  color: ${colors.white};
  margin-right: 10%;

  .MuiSvgIcon-root {
    font-size: 37px;
    font-weight: bold;
  }
`;

export const FinalInputIcon = (props) => {
  return (
    <IconContainer>
      <CheckIcon />
    </IconContainer>
  );
};

export default FinalInputIcon;
