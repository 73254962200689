import React, { FC } from 'react';
import { PrimarySmallButton } from '../Buttons/buttons';
import Dialog from '@material-ui/core/Dialog';
import styled, { css } from 'styled-components';
import * as theme from '../../theme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';

export const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  line-height: normal;
  margin-bottom: 10px;
  &:first-letter {
    text-transform: capitalize;
  }
`;

export const ModalText = styled.p`
  margin-top: 5px;
  font-size: 16px;
  line-height: normal;
  color: ${theme.colors.grayDescription};
`;

export const ModalButton = styled(PrimarySmallButton)`
  margin-left: ${(props) => (props.disableMargin ? 0 : 'auto')};
  margin-right: ${(props) => (props.disableMargin ? 0 : 'auto')};
  font-weight: bold !important;
  margin-top: 20px !important;
  text-transform: none !important;
  font-size: 16px;
  width: 200px;
  color: ${({ cancel }) => cancel && theme.colors.darkBlue};
  background-color: ${({ cancel }) => cancel && theme.colors.white};

  ${(props) =>
    props.disabled &&
    `
    background-color: ${theme.colors.gray} !important;
    pointer-events: none;
    `}

  ${({ full }) =>
    full &&
    css`
      max-width: 100% !important;
      width: 100% !important;
    `};
`;

export const GreyModalButton = styled(ModalButton)`
  background-color: ${theme.colors.brightGray} !important;
  color: ${theme.colors.yellow} !important;
`;

export const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.6rem;
  width: 100%;
  margin: auto;

  @media (min-width: ${theme.size.mobileL}) {
    width: 80%;
  }
`;

export const ModalTitleIconWrapper = styled.div`
  margin: 0 auto 8px;
  width: 48px;
  * {
    fill: ${theme.colors.black};
  }
`;

export const ModalLink = styled(Link)`
  color: ${theme.colors.yellow};
  font-size: 14px;
  font-weight: bold;
`;

const DialogContentContainer = styled.div`
  padding: 30px 10px;
  position: relative;
`;

const useStyles = makeStyles({
  root: {
    maxWidth: 'unset',
  },
  paper: {
    borderRadius: '16px !important',
    width: '100vw',
    maxWidth: (props: any) =>
      props.modalSize
        ? props.modalSize === 'xl'
          ? theme.sizes.maxSimpleModalWidthPxValLg
          : theme.sizes.maxSimpleModalWidthPxVal
        : theme.sizes.maxSimpleModalWidthPxVal,
    marginLeft: theme.sizes.cardPaddingPxVal,
    marginRight: theme.sizes.cardPaddingPxVal,
    textAlign: 'center',
    paddingBottom: 0,
  },
  stretchHeight: {
    maxHeight: '99vh',
  },
});

export const ModalContainer = (props: any) => {
  const { open, setOpen, children, disableClose, modalSize, handleClose } = props;

  const classes = useStyles({ modalSize });

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (disableClose && reason === 'backdropClick') {
          return false;
        }
        if (disableClose && reason === 'escapeKeyDown') {
          return false;
        }
        if (typeof handleClose === 'function') {
          handleClose();
        }
      }}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      classes={{ paper: classes.paper, root: classes.root }}
    >
      <DialogContentContainer>{children}</DialogContentContainer>
    </Dialog>
  );
};

export const FullWidthButtonContainer = styled.div`
  width: 100%;
  margin: auto;

  @media ${theme.device.mobileL} {
    width: 40%;
  }

  button {
    display: block;
    max-width: 100%;
    width: 100%;
  }
`;

export const FullWidthButton = (props: any) => {
  const { buttonText, children, ...otherProps } = props;
  return <ModalButton {...otherProps}>{buttonText || children}</ModalButton>;
};
