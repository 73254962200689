import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import SurveyResults from './SurveyResults';
import { getSurveyResults, resetSurveyDetail } from '../../../reducers/Surveys/surveys.actions';

type OtherProps = RouteComponentProps<{
  uuid: string;
}>;

function mapStateToProps(state: any, otherProps: OtherProps) {
  return {
    uuid: otherProps.match.params.uuid,
    survey: state.surveys.detail,
    resultFetchError: state.surveys.result.fetchError,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getSurveyResults,
    resetSurveyDetail,
  })(SurveyResults)
);
