import styled from 'styled-components';

export const LabelValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 1rem;
  margin-bottom: 2rem;

  max-width: 90%;

  text-align: center;
`;

export const Label = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
`;

export const InputValue = styled.div`
  display: flex;
  gap: 0.5rem;
  font-size: 1rem;
  word-break: break-word;
`;

export const Image: any = styled.div`
  margin-top: 0.625rem;
  height: 100px;
  width: 100px;
  border: 2px solid #373737;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  background: ${(props: any) => (props.bgImage ? `url(${props.bgImage})` : 'transparent')};
  background-size: 100% 100%;
  background-repeat: no-repeat;
`;
