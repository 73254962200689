import React from 'react';
import styled from 'styled-components';
import PinInput from 'react-pin-input';
import * as theme from '../../theme';
import { Controller } from 'react-hook-form';
import ErrorMessage from './ErrorMessage';

export const pinInputStyles = {
  borderColor: theme.colors.grayBorder,
  color: theme.colors.black,
  borderWidth: '2px',
  borderStyle: 'solid',
  borderRadius: '5px',
  padding: '10px',
  margin: '6px',
  width: '40px',
};

export const pinInputRegex = /^[ A-Za-z0-9_@./#&+-]*$/;

const InputPin = (props) => {
  const { name = 'pin', control, defaultValue = null, secret, customErrorMessage } = props;

  return (
    <>
      <Controller
        render={({ field }) => (
          <PinInput
            length={6}
            inputMode="numeric"
            secret={secret}
            inputStyle={pinInputStyles}
            inputFocusStyle={{ borderColor: theme.colors.darkGrey }}
            autoSelect={true}
            regexCriteria={pinInputRegex}
            onChange={(val, x) => field.onChange(val)}
            onBlur={field.onBlur}
            value={field.value}
            name={field.name}
            ref={field.ref}
          />
        )}
        name={name}
        control={control}
        defaultValue={defaultValue}
      />
      <ErrorMessage customErrorMessage={customErrorMessage} fieldError={customErrorMessage} />
    </>
  );
};

export default InputPin;
