import React, { useEffect } from 'react';
import { Button, LogoSvg } from '../../../utils/uiComponents';
import { useForm } from 'react-hook-form';
import InputText from '../../../components/Forms/InputText';
import Checkbox from '../../../components/Forms/Checkbox';
import { Trans } from 'react-i18next';
import { history } from '../../../utils/history';
import { getLoginPath } from '../../../constants/paths.constants';
import { Center, LoginBgContainer, Form } from '../../../components/Containers/containers';
import { PrimarySmallButton } from '../../../components/Buttons/buttons';
import LinkToTermsAndConditions from '../../../components/LinkToTermsAndConditions';

interface Props {}

type Inputs = {
  nickname: string;
  regulation: boolean;
};

const Nickname: React.FC<Props> = ({}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    formState,
  } = useForm<Inputs>();

  useEffect(() => {
    if (!history.location.state?.isVoting || !history.location.state?.redirect) {
      history.push(getLoginPath());
    }
  }, []);

  const onSubmit = (data: Inputs) => {
    if (history.location.state.isVoting && history.location.state.redirect) {
      history.push(`${history.location.state.redirect}/${history.location.state.uuid}/vote`, {
        nickname: data.nickname,
      });
    }
  };

  return (
    <>
      <LoginBgContainer>
        <LogoSvg src={'/images/ivoting-logo.svg'} />
        <Center>
          <h2 style={{ textTransform: 'uppercase' }}>Głosowanie bez rejestracji</h2>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <InputText
              label={'Nickname'}
              name={'nickname'}
              formRef={register}
              formState={formState}
              validate={{ required: true }}
              fieldError={errors.nickname}
            />
            <Checkbox
              labelComponent={<LinkToTermsAndConditions type={'login'} />}
              name={'regulation'}
              formRef={register}
              formState={formState}
              validate={{ required: true }}
              uppercase={false}
              fieldError={errors.regulation}
            />
            <PrimarySmallButton type={'submit'}>
              <Trans>Further</Trans>
            </PrimarySmallButton>
          </Form>
        </Center>
      </LoginBgContainer>
    </>
  );
};

export default Nickname;
