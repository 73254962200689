import React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getTermsAndConditionsPath } from '../constants/paths.constants';

const StyledRegisterMemberContent = styled.div`
  a {
    color: unset;
  }
`;

const LinkToTermsAndConditions: any = (props: any) => {
  const { type = 'register' } = props;
  return (
    <StyledRegisterMemberContent>
      {type === 'login' ? (
        <Trans>LoginRegulationTextRegisterBefore</Trans>
      ) : (
        <Trans>RegisterRegulationTextRegisterBefore</Trans>
      )}
      <a target="_blank" rel="noopener noreferrer" href={getTermsAndConditionsPath()}>
        <Trans>RegulationTextRegisterOnly</Trans>
      </a>
      <Trans>RegulationTextRegisterAfter</Trans>
    </StyledRegisterMemberContent>
  );
};

export default LinkToTermsAndConditions;
