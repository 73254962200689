import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { SurveyAnswer, SurveyData, SurveyType } from '../../../types/surveys.types';
import SurveyTypeHeader from '../../../components/SurveyTypeHeader';
import SelectedGroups from '../../../components/SelectedGroups';
import moment, { Moment } from 'moment';
import { postMedia } from '../../../reducers/Media/media.actions';
import { asyncForEach } from '../../../utils/asyncForEach';
import { createNewSurvey } from '../../../reducers/Surveys/surveys.actions';
import {
  getCoordinatorGroups,
  getCoordinatorGroups as _getCoordinatorGroups,
  getGroups,
  inviteGroups,
} from '../../../reducers/Groups/groups.actions';
import { Trans } from 'react-i18next';
import { colors } from '../../../theme';
import QuestionPreview from '../../../components/SurveyComponents/QuestionPreview';
import { history } from '../../../utils/history';
import { getSurveysPath } from '../../../constants/paths.constants';
import { FormLabel } from '../../../components/Forms/forms';
import { PrimarySmallButton } from '../../../components/Buttons/buttons';
import { Center } from '../../../components/Containers/containers';
import { PrimarySmallSpinnerButton } from '../../../components/SpinnerButton';
import { useForm, useWatch } from 'react-hook-form';
import DateTimePicker from '../../../components/Forms/DateTimePicker';
import SurveyShareModal from '../../../components/SurveyShareModal/SurveyShareModal';
import SummarySettingsPreview from './SummarySettingsPreview';

interface Props {
  survey: SurveyData;
  groups: Array<any>;
  coordinatorGroups: Array<any>;

  setStep(stepNumber: number): void;

  setIsBackVisible(isBackVisible: boolean): void;

  inviteGroups(data: { survey: number; groups: Array<number> }): void;
}

type Inputs = {
  startDate: string;
  endDate: string;
};

const Preview: React.FC<Props> = ({
  survey,
  setStep,
  inviteGroups,
  setIsBackVisible,
  groups,
  coordinatorGroups,
}) => {
  const [create, setCreate] = useState<any>(true);
  const [uuid, setUuid] = useState<any>();
  const [surveyIsCreating, setSurveyIsCreating] = useState<any>(false);
  const [createdSurvey, setCreatedSurvey] = useState<SurveyType>();
  const [shareModalOpen, setShareModalOpen] = useState<any>(false);
  const [validDates, setValidDates] = useState<any>();
  // move it to redux
  const [newConfirmedSurvey, setNewConfirmedSurvey] = useState<any>({ is_closed_vote: false });

  const {
    control,
    register,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
  } = useForm<Inputs>({
    defaultValues: {
      startDate: survey.time?.startDate,
      endDate: survey.time?.endDate,
    },
  });
  const watchStartDate = useWatch({
    name: 'startDate',
    defaultValue: survey.time?.startDate,
    control,
  });
  const watchEndDate = useWatch({
    name: 'endDate',
    defaultValue: survey.time?.endDate,
    control,
  });

  useEffect(() => {
    if (survey.time.value === 'custom') {
      const now = moment();
      if (moment(survey.time.startDate) < now)
        setError('startDate', { type: 'dateCantBeInThePast' });
      if (moment(survey.time.endDate) < now) setError('endDate', { type: 'dateCantBeInThePast' });
    }
  }, []);

  const getEndDate = (startDate: Moment): string | null => {
    switch (survey.time.value) {
      case '15':
      case '30':
      case '60':
        return moment(startDate)
          .add(+survey.time.value, 'minutes')
          .format();
      case 'custom':
        return moment(watchEndDate).format();
      default:
        return null;
    }
  };

  const validateDates = (now?: Moment): boolean => {
    let isValid = true;
    if (!now) now = moment();
    let startDate, endDate;
    if (moment(watchStartDate).isValid()) startDate = moment(watchStartDate);
    if (moment(watchEndDate).isValid()) endDate = moment(watchEndDate);
    if (startDate && endDate) {
      if (startDate > endDate) {
        isValid = false;
        setError('startDate', { type: 'startDateGreaterThanEndDate' });
        setError('endDate', { type: 'endDateLessThanStartDate' });
      }
      if (startDate.toString() === endDate.toString()) {
        isValid = false;
        setError('endDate', { type: 'datesCantBeTheSame' });
      }
    }
    if (endDate) {
      if (now > endDate) {
        isValid = false;
        setError('endDate', { type: 'dateCantBeInThePast' });
      }
    }
    if (!startDate) {
      isValid = false;
      setError('startDate', { type: 'required' });
    }
    if (!endDate) {
      isValid = false;
      setError('endDate', { type: 'required' });
    }
    return isValid;
  };

  useEffect(() => {
    clearErrors(['startDate', 'endDate']);
    validateDates();
  }, [watchStartDate, watchEndDate]);

  const handleAddSurvey = async () => {
    const now = moment(new Date());
    let isValid = true;
    if (survey.time.value === 'custom') {
      isValid = validateDates(now);
    }
    if (isValid) {
      const pictureSurveyAnswers: Array<SurveyAnswer> = [];
      setSurveyIsCreating(true);
      if (survey.survey_type === 'image') {
        await asyncForEach(
          survey.questions[0].answers,
          async (answer: { text: string; image: any }) => {
            await postMedia(answer.image[0]).then((response) =>
              pictureSurveyAnswers.push({
                text: answer.text,
                image: response.id,
              })
            );
          }
        );
      }
      const newSurvey = {
        name: survey.name,
        is_overt: survey.is_overt,
        show_on_page: survey.show_on_page,
        start_date: (watchStartDate && moment(watchStartDate).format()) || moment(now).format(),
        end_date: getEndDate(now),
        questions: survey.questions.map((question) => {
          switch (survey.survey_type) {
            case 'simple':
            case 'extended':
              return {
                question: question.question,
                answers: question.answers.map((answer) => ({
                  text: answer.text,
                  is_default: answer.is_default,
                })),
                is_free_answers: question.is_free_answers,
                min_required_answers: +question.min_required_answers || 1,
                max_required_answers: +question.max_required_answers || 1,
              };
            case 'image':
              return {
                question: question.question,
                answers: pictureSurveyAnswers,
              };
          }
        }),
        close_after_submitted_votes: survey.close_after_submitted_votes,
        show_results_after_voted: survey.show_results_after_voted,
        description: survey.survey_type,
        survey_type: survey.survey_type,
        require_to_vote_email: survey.require_to_vote_email,
        require_to_vote_phone: survey.require_to_vote_phone,
        require_to_vote_qr: survey.require_to_vote_qr,
        is_closed_vote: survey.is_closed_vote,
        calculate_votes_based_on_default: !!survey.default_answer,
        send_vote_confirmations: survey.send_vote_confirmations,
      };
      createNewSurvey(newSurvey)
        .then((response) => {
          if (survey.invited_groups.length > 0)
            inviteGroups({
              survey: response.data.id,
              groups: survey.invited_groups,
            });
          setCreatedSurvey(response.data);
          setUuid(response.data.uuid);
          setCreate(false);
          setSurveyIsCreating(false);
          setNewConfirmedSurvey(response.data);
          setIsBackVisible(false);
        })
        .catch((error) => {
          setSurveyIsCreating(false);
        });
    }
  };

  const handleShare = () => {
    setShareModalOpen(true);
  };

  return (
    <>
      {survey && (
        <>
          {shareModalOpen && createdSurvey && (
            <SurveyShareModal
              uuid={uuid}
              survey={createdSurvey}
              isOpen={shareModalOpen}
              setIsOpen={setShareModalOpen}
            />
          )}
          <SurveyTypeHeader type={survey.survey_type} />
          <FormLabel formType={'survey'}>
            <Trans>{survey.survey_type === 'extended' ? 'SurveyTitle' : 'PollTitle'}</Trans>
          </FormLabel>
          {survey.name}
          {survey.questions?.map((question, key) => {
            return (
              <QuestionPreview
                question={question}
                key={key}
                defaultAnswer={survey.default_answer}
                showFreeAnswers={false}
                surveyType={survey.survey_type}
              />
            );
          })}
          <FormLabel formType={'survey'}>
            <Trans>Settings</Trans>
          </FormLabel>

          <SummarySettingsPreview
            survey={survey}
            watchStartDate={watchStartDate}
            watchEndDate={watchEndDate}
          />

          {survey.invited_groups.length > 0 && (
            <>
              <FormLabel formType={'survey'}>
                <Trans>InvitedGroups</Trans>
              </FormLabel>
              <SelectedGroups
                selectedGroups={survey.invited_groups}
                getGroups={getGroups}
                getCoordinatorGroups={getCoordinatorGroups}
                groups={groups}
                coordinatorGroups={coordinatorGroups}
              />
            </>
          )}
          {survey.time.value === 'custom' && (
            <>
              <Center>
                <FormLabel formType={'time'}>
                  <Trans>StartDate</Trans>
                </FormLabel>
                <DateTimePicker
                  control={control}
                  onChange={(e: any) => {
                    setValue('startDate', moment(e).format());
                  }}
                  name={'startDate'}
                  formRef={register}
                  fieldError={errors.startDate}
                />
              </Center>
              <Center>
                <FormLabel formType={'time'}>
                  <Trans>EndDate</Trans>
                </FormLabel>
                <DateTimePicker
                  control={control}
                  onChange={(e: any) => {
                    setValue('endDate', moment(e).format());
                  }}
                  disablePast={true}
                  name={'endDate'}
                  formRef={register}
                  fieldError={errors.endDate}
                />
              </Center>
            </>
          )}
          {create && (
            <>
              <PrimarySmallButton
                type={'button'}
                onClick={() => {
                  setStep(1);
                }}
              >
                <Trans>Edit</Trans>
              </PrimarySmallButton>
              <PrimarySmallSpinnerButton
                isSpinning={surveyIsCreating}
                onClick={handleAddSurvey}
                borderColor={colors.darkGreen}
                bgColor={colors.darkGreen}
                color={'#ffffff'}
                style={{ marginTop: '24px' }}
              >
                <Trans>Add</Trans>
              </PrimarySmallSpinnerButton>
            </>
          )}
          {!create && (
            <>
              {!newConfirmedSurvey?.is_closed_vote && (
                <PrimarySmallButton onClick={handleShare}>
                  <Trans>ShareVoting</Trans>
                </PrimarySmallButton>
              )}
              <PrimarySmallButton
                onClick={() => history.push(`${getSurveysPath()}`)}
                style={{ marginTop: '24px' }}
              >
                <Trans>Further</Trans>
              </PrimarySmallButton>
            </>
          )}
        </>
      )}
    </>
  );
};

function mapStateToProps(state: any, otherProps: RouteComponentProps) {
  return {
    survey: state.surveys.newSurvey,
    groups: state.groups.list,
    coordinatorGroups: state.groups.coordinators.list.items,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    inviteGroups,
    getGroups,
    getCoordinatorGroups,
  })(Preview)
);
