import { useEffect, useRef, useState } from 'react';
import { theme } from '../theme';

/**
 * 'useState' wrapper. Use it instead of useState to always get current object value in functions.
 * Can cause problems in some async cases!
 * Based on: https://dev.to/scastiel/react-hooks-get-the-current-state-back-to-the-future-3op2
 * @param initialValue
 * @returns {(any | React.MutableRefObject<any> | React.Dispatch<React.SetStateAction<any>>)[]}
 */
export const useRefState = (initialValue) => {
  const [state, setState] = useState(initialValue);
  const stateRef = useRef(state);
  useEffect(() => {
    stateRef.current = state;
  }, [state]);
  return [state, stateRef, setState];
};

export const useScript = (url, callBefore) => {
  useEffect(() => {
    callBefore && callBefore();
    const script = document.createElement('script');
    script.src = url;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
};

// const useMediaToDetectMobile = {
//   queries: [
//     `(max-width: ${theme.responsive.maxMobileWidthPxVal}px)`,
//     `(min-width: ${theme.responsive.minDesktopWidthPxVal}px)`,
//   ],
//   values: [true, false],
//   defaultValue: false,
// };

// export const useIsMobile = () =>
//   useMedia(
//     useMediaToDetectMobile.queries,
//     useMediaToDetectMobile.values,
//     useMediaToDetectMobile.defaultValue
//   );

// according to docs: https://usehooks.com/useMedia/
export const useMedia = (queries, values, defaultValue) => {
  // Array containing a media query list for each query
  const mediaQueryLists = queries.map((q) => window.matchMedia(q));

  // Function that gets value based on matching media query
  const getValue = () => {
    // Get index of first media query that matches
    const index = mediaQueryLists.findIndex((mql) => mql.matches);
    // Return related value or defaultValue if none
    return typeof values[index] !== 'undefined' ? values[index] : defaultValue;
  };

  // State and setter for matched value
  const [value, setValue] = useState(getValue);

  useEffect(
    () => {
      // Event listener callback
      // Note: By defining getValue outside of useEffect we ensure that it has ...
      // ... current values of hook args (as this hook callback is created once on mount).
      const handler = () => setValue(getValue);
      // Set a listener for each media query with above handler as callback.
      mediaQueryLists.forEach((mql) => mql.addListener(handler));
      // Remove listeners on cleanup
      return () => mediaQueryLists.forEach((mql) => mql.removeListener(handler));
    },
    [] // Empty array ensures effect is only run on mount and unmount
  );

  return value;
};

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
