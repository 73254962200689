import React, { useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import styled from 'styled-components';
import { colors, device } from '../../theme';
import { makeStyles } from '@material-ui/styles';
import { CloseIconStyle } from '../../utils/uiComponents';
import { menuLinks } from './customDrawer.config';
import { Trans } from 'react-i18next';
import { history } from '../../utils/history';

const DrawerContainer = styled.div`
  width: 100vw;
  max-width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3rem 0;
  min-height: 100vh;

  background-image: url(/images/mobile-bg-dark.svg);
  background-size: 100% auto;
  background-repeat: repeat-y;
`;

const MenuHeader = styled.span`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 40px;
  text-align: center;
  color: #fff;

  @media ${device.laptop} {
    font-size: 50px;
  }
`;

const MenuButton = styled.button`
  width: 90%;
  height: 53px;
  border: 3px solid #fff;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: bold;
  background-color: transparent;
  border-radius: 100px;
  color: #fff;
  cursor: pointer;
  margin-top: 14px;

  @media ${device.laptop} {
    margin-top: 28px;
    font-size: 30px;
    max-width: 600px;
  }
`;

const MenuListContainer = styled.div`
  align-items: center;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const MenuItemContainer = styled.div`
  width: 100%;
`;

const useStyles = makeStyles({
  paper: {
    background: colors.lightBlue,
  },
});

interface Props {
  showMenu: boolean;

  toggleMenu(): void;

  logout(): void;

  setIsQrCodeModalOpen(flag: boolean): void;
}

const CustomDrawer: React.FC<Props> = ({ showMenu, toggleMenu, logout, setIsQrCodeModalOpen }) => {
  const [desireLogout, setDesireLogout] = useState<any>(false);
  const classes = useStyles({});

  const handleAction = (action: string) => {
    switch (action) {
      case 'logout':
        setDesireLogout(true);
        break;
      case 'qrCode':
        setIsQrCodeModalOpen(true);
        break;
      default:
        break;
    }
  };

  return (
    <Drawer
      anchor={'right'}
      open={showMenu}
      style={{ background: colors.lightBlue }}
      classes={{
        paper: classes.paper,
      }}
    >
      <DrawerContainer>
        <CloseIconStyle
          style={{ fontSize: 40 }}
          onClick={() => {
            toggleMenu();
            setDesireLogout(false);
          }}
        />
        <MenuListContainer>
          <MenuHeader>
            <Trans>Menu</Trans>
          </MenuHeader>
          {!desireLogout &&
            menuLinks.map((link, key) => (
              <MenuItemContainer>
                <MenuButton
                  onClick={() => {
                    if (!!link.path) history.push(link.path);
                    if (!!link.action) handleAction(link.action);
                    if (link.action !== 'logout') toggleMenu();
                  }}
                  key={key}
                >
                  <Trans>{link.text}</Trans>
                </MenuButton>
              </MenuItemContainer>
            ))}
          {desireLogout && (
            <>
              <h2 style={{ color: '#ffffff' }}>Czy na pewno chcesz się wylogować</h2>
              <MenuButton
                onClick={() => {
                  logout();
                  toggleMenu();
                }}
              >
                Tak
              </MenuButton>
              <MenuButton onClick={() => setDesireLogout(false)}>Nie</MenuButton>
            </>
          )}
        </MenuListContainer>
      </DrawerContainer>
    </Drawer>
  );
};

export default CustomDrawer;
