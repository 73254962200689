import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { history } from '../../../utils/history';
import { useForm } from 'react-hook-form';
import { ContactPerson, PostUpdateContactPersonType } from '../../../types/contactPersons.types';
import InputText from '../../../components/Forms/InputText';
import InputEmail from '../../../components/Forms/InputEmail';
import { Trans } from 'react-i18next';
import SelectGroup from '../../../components/SelectGroup';
import { postNewGroup } from '../../../reducers/Groups/groups.actions';
import { colors } from '../../../theme';
import { Group } from '../../../types/groups.types';
import { getContactPersonsPath, getGroupsPath } from '../../../constants/paths.constants';
import { Center, FlexContainer, Form } from '../../../components/Containers/containers';
import { SurveyListH1 } from '../../../components/Typography/typography';
import { PrimarySmallButton } from '../../../components/Buttons/buttons';

interface Props {
  id: string;

  groups: Array<Group>;

  contactPerson: ContactPerson;

  getContactPerson(data: { id: number }): void;

  postContactPerson(
    data: {
      phone: string;
      name: string;
      groups: Array<number>;
      newGroup: string;
      id: number;
      email: string;
    },
    redirect?: { path: string; add?: string }
  ): void;

  updateContactPerson(id: number, data: PostUpdateContactPersonType, redirect?: string): void;

  resetContactPerson(): void;

  deleteContactPerson(id: number): void;

  getGroups(): void;
}

interface Inputs extends ContactPerson {
  newGroup: string;
}

const ContactPersonsDetail: React.FC<Props> = ({
  id,
  getContactPerson,
  postContactPerson,
  updateContactPerson,
  contactPerson,
  resetContactPerson,
  deleteContactPerson,
  getGroups,
  groups,
}) => {
  let searchParams = new URLSearchParams(history.location.search);
  let groupFromSearch = searchParams.get('group');
  const [isNewContact, setIsNewContact] = useState<boolean>();
  const [selectedGroups, setSelectedGroups] = useState<Array<number>>(
    groupFromSearch ? [+groupFromSearch] : []
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
    formState,
    setValue,
  } = useForm<Inputs>({
    defaultValues: contactPerson,
  });

  useEffect(() => {
    if (contactPerson.groups) setSelectedGroups(contactPerson.groups.map((group) => group.id));
  }, [contactPerson.groups]);

  useEffect(() => {
    getGroups();
  }, []);

  useEffect(() => {
    if (id === 'new-contact') {
      setIsNewContact(true);
      resetContactPerson();
    } else if (_.isNumber(+id) && +id >= 0) {
      setIsNewContact(false);
      getContactPerson({ id: +id });
    } else {
      history.push(getContactPersonsPath());
    }
    return () => {
      resetContactPerson();
    };
  }, [history.location]);

  const getRedirect = (): { path: string } => {
    if (!!groupFromSearch) {
      return { path: getGroupsPath() };
    } else {
      return { path: getContactPersonsPath() };
    }
  };

  const onSubmit = async (data: Inputs) => {
    let groups = selectedGroups;
    if (data.newGroup) {
      await postNewGroup({ name: data.newGroup }).then((response) => {
        getGroups();
        setValue('newGroup', '');
        groups = [...selectedGroups, response.id];
      });
    }
    if (isNewContact) {
      postContactPerson(
        {
          ...data,
          groups: groups,
        },
        getRedirect()
      );
    } else {
      updateContactPerson(
        +id,
        {
          ...data,
          groups: groups,
        },
        getContactPersonsPath()
      );
    }
  };

  return (
    <FlexContainer>
      <Center>
        <SurveyListH1>
          <Trans>{isNewContact ? 'CreateContact' : 'EditContact'}</Trans>
        </SurveyListH1>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <InputText
            label={'FullName'}
            name={'name'}
            formRef={register}
            formState={formState}
            fieldError={errors.name}
            defaultValue={contactPerson.name}
            formType={'survey'}
          />
          <InputText
            label={'Phone'}
            name={'phone'}
            formRef={register}
            formState={formState}
            fieldError={errors.phone}
            defaultValue={contactPerson.phone}
            formType={'survey'}
          />
          <InputEmail
            label={'Email'}
            name={'email'}
            formRef={register}
            formState={formState}
            fieldError={errors.email}
            validate={{ required: true }}
            defaultValue={contactPerson.email}
            formType={'survey'}
          />
          <SelectGroup
            register={register}
            formState={formState}
            selectedGroups={selectedGroups}
            setSelectedGroups={setSelectedGroups}
            getGroups={getGroups}
            groups={groups}
            newGroup={true}
          />

          <PrimarySmallButton type={'submit'}>
            <Trans>Save</Trans>
          </PrimarySmallButton>
          {!isNewContact && (
            <PrimarySmallButton
              borderColor={colors.red}
              bgColor={colors.red}
              color={'#ffffff'}
              type={'button'}
              onClick={() => deleteContactPerson(contactPerson.id)}
              style={{ marginTop: '24px' }}
            >
              <Trans>Delete</Trans>
            </PrimarySmallButton>
          )}
        </Form>
      </Center>
    </FlexContainer>
  );
};

export default ContactPersonsDetail;
