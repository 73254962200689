import styled from 'styled-components';
import { colors } from '../../../theme';
import { IconContainer } from '../../../components/ActionButton';
import RequiredAnswersCounters from '../../../components/Forms/RequiredAnswersCounters';

export const ActionButton = styled.button<{ success?: boolean; error?: boolean; inline?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
  background-color: ${(props) =>
    props.error ? colors.red : props.success ? colors.darkGreen : colors.darkBlue};
  border: none;
  color: white;
  height: 1.5rem;
  width: 1.5rem;
  font-size: 0.875rem;
  margin-left: 0.375rem;

  &:hover {
    cursor: pointer;
  }
`;
export const StyledIconContainerWithInput = styled(IconContainer)`
  position: absolute;
  right: 2.5%;
  transform: translateX(-100%);
`;
export const InputTextContainer = styled.div<{ withIcon?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
  ${(props) => props.withIcon && 'padding-right: 3rem'}
`;
export const Flex = styled.div`
  width: 95%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;
export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledRequiredAnswersCounters = styled(RequiredAnswersCounters)`
  margin-top: 1rem;
`;
