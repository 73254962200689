import React, { FC, useEffect } from 'react';
import { history } from '../../utils/history';
import {
  getAuthorizedVotingLoginPath,
  getAuthorizedVotingRegisterPath,
  getLoginPath,
  getRegisterPath,
  getSurveyPath,
} from '../../constants/paths.constants';
import { getPublicSurveyUid, getVoterId } from '../../utils/localStorage';
import { REQUEST_STATUSES } from '../../constants/requestStatuses.constants';
import { showNotification } from '../../utils/taost.config';
import { deletePublicSurveyUid } from '../../utils/localStorage';
import { SurveyTypeEnum } from '../../types/surveys.types';

interface AuthorizedVotingLandingProps {
  accountForSurveyVotingData: any;
  accountForSurveyVotingFetchStatus: string | null;
  checkIfUserCanVote: any;
  clearUserRequirementsForSurvey: any;
}

const AuthorizedVotingLanding: FC<AuthorizedVotingLandingProps> = (props) => {
  const {
    accountForSurveyVotingData,
    accountForSurveyVotingFetchStatus,
    checkIfUserCanVote,
    clearUserRequirementsForSurvey,
  } = props;

  const isProjectType = history.location.state?.surveyType === SurveyTypeEnum.project;

  const onSubmit = () => {};

  const getVidAndSurveyId = (): { survey_uuid: string | null; vid: string | null } => {
    const vid = getVoterId();
    const survey_uuid = getPublicSurveyUid();
    return { survey_uuid, vid };
  };

  useEffect(() => {
    if (accountForSurveyVotingData?.is_registered) {
      history.push(getAuthorizedVotingLoginPath());
    } else if (accountForSurveyVotingData && accountForSurveyVotingData.is_registered === false) {
      showNotification({
        message: 'Brak użytkownika o podanym adresie email. Aby przejść dalej dokonaj rejestracji.',
        type: 'error',
        autoClose: 5000,
      });
      history.push(getAuthorizedVotingRegisterPath());
    }
  }, [accountForSurveyVotingData]);

  useEffect(() => {
    if (accountForSurveyVotingFetchStatus === REQUEST_STATUSES.ERROR) {
      deletePublicSurveyUid();
      history.push(getLoginPath());
    }
  }, [accountForSurveyVotingFetchStatus]);

  useEffect(() => {
    clearUserRequirementsForSurvey({});
    const data = getVidAndSurveyId();
    if (data.vid && data.survey_uuid && !isProjectType) {
      checkIfUserCanVote(data);
    } else if (!data.vid || isProjectType) {
      const redirectAfterLoginOptions = data.survey_uuid
        ? {
            redirectToSurvey: `${getSurveyPath()}/${data.survey_uuid}/vote`,
          }
        : undefined;
      deletePublicSurveyUid();
      history.push(getLoginPath(), redirectAfterLoginOptions);
    }
  }, []);

  return <></>;
};

export default AuthorizedVotingLanding;
