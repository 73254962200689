import React, { useEffect, useState } from 'react';
import { ListSurveyType } from '../types/surveys.types';
import { dynamicSortMultiple } from '../utils/sort';
import SurveyButton from './SurveyButton';
import { v1 } from 'uuid';
import { colors } from '../theme';
import { SurveyListButton } from './Buttons/buttons';

interface Props {
  allSurveys: Array<ListSurveyType>;
  lists: Array<{
    filters: Array<{
      key:
        | 'end_date'
        | 'has_ended'
        | 'has_started'
        | 'is_viewed_results'
        | 'is_voted'
        | 'start_date';
      value: any;
    }>;
    sort: Array<string>;
  }>;

  getSurveysList(): void;
}

const SurveysButtonsList: React.FC<Props> = ({ allSurveys, lists, getSurveysList }) => {
  const [limit, setLimit] = useState<any>(10);
  const [surveys, setSurveys] = useState<Array<ListSurveyType>>([]);

  useEffect(() => {
    allSurveys &&
      lists.forEach(({ filters, sort }) => {
        setSurveys((prevState) => {
          return [
            ...prevState,
            ...allSurveys
              .filter((survey) => {
                let good = true;
                filters.forEach((filter) => {
                  if (survey[filter.key] !== filter.value) good = false;
                });
                return good;
              })
              .sort(dynamicSortMultiple(sort)),
          ];
        });
      });
  }, [allSurveys]);

  const handleAddLimit = () => {
    setLimit((prevState: any) => prevState + 10);
  };

  return (
    <>
      {surveys &&
        surveys.slice(0, limit).map((survey, key) => {
          return <SurveyButton survey={survey} key={survey.uuid} getSurveysList={getSurveysList} />;
        })}
      {surveys && limit < surveys.length && (
        <SurveyListButton
          type={'button'}
          onClick={handleAddLimit}
          bgColor={colors.darkGreen}
          small={true}
        >
          <img
            height={'20px'}
            src={'left-arrow.svg'}
            style={{ transform: 'rotate(-90deg)' }}
            alt={'v'}
          />
        </SurveyListButton>
      )}
    </>
  );
};

export default SurveysButtonsList;
