import React from 'react';
import { Trans } from 'react-i18next';
import ErrorMessage from './ErrorMessage';
import { FormTypes } from '../../types/form.types';
import i18next from 'i18next';
import { Input, PrimaryInput, PrimaryLabel } from '../../utils/uiComponents';
import { FormLabel, getInputStyles } from './forms';
import { Controller } from 'react-hook-form';
import InputReadOnly from './InputReadOnly';
import MaskedInput from 'react-text-mask';
import styled from 'styled-components';
import { device } from '../../theme';

interface InputTypes extends FormTypes {
  label: string;
  name: string;
  disabled?: boolean;
  final?: boolean;
  control: any;
}

export const MaskedFormInput: any = styled(MaskedInput)`
  border-radius: 30px;
  width: 90%;

  @media ${device.laptop} {
    max-width: 600px;
  }

  ${(props) => getInputStyles(props)}
`;

const InputPostalCode: React.FC<InputTypes> = ({
  label,
  name,
  type = '',
  placeholder = '',
  formRef,
  fieldError,
  validate = {},
  formState,
  defaultValue = '',
  borderColor,
  color,
  caretColor,
  formType,
  showRequiredStar,
  disabled = false,
  final,
  control,
}) => {
  return disabled || final ? (
    <InputReadOnly
      label={label}
      showRequiredStar
      name={name}
      formRef={formRef}
      formState={formState}
      defaultValue={defaultValue}
      color={color}
      final={final}
    />
  ) : (
    <div style={{ width: '100%', textAlign: 'center' }}>
      <FormLabel color={color} formType={formType}>
        <Trans>{label}</Trans>
        {(validate.required || showRequiredStar) && '*'}
      </FormLabel>

      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <MaskedFormInput
            onChange={field.onChange}
            value={field.value}
            placeholder={i18next.t(placeholder)}
            isDirty={formState.dirtyFields[name]}
            borderColor={borderColor}
            color={color}
            caretColor={caretColor}
            formType={formType}
            mask={[/[0-9]/, /\d/, '-', /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
          />
        )}
      />
      <ErrorMessage fieldError={fieldError} validate={validate} />
    </div>
  );
};

export default InputPostalCode;
