import { connect } from 'react-redux';
import VerifyEmail from './VerifyEmail';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { verifyEmail } from '../../reducers/Auth/auth.actions';

type OtherProps = RouteComponentProps<{
  uuid: string;
}>;

function mapStateToProps(state: any, otherProps: OtherProps) {
  return {
    uuid: otherProps.match.params.uuid,
    verifyEmailFetchStatus: state.auth.verifyEmailFetchStatus,
  };
}

export default withRouter(connect(mapStateToProps, { verifyEmail })(VerifyEmail));
