import { useEffect, useRef } from 'react';
import { Location } from 'history';
import { history, MyLocationState } from './history';
import { getCreateProjectPath } from '../constants/paths.constants';

interface Props {
  resetNewProjectData(): void;
}

const useResetNewProjectDataOnLocationChange = (props: Props) => {
  const { resetNewProjectData } = props;

  const prevLocation = useRef<Location<MyLocationState>>();

  useEffect(() => {
    return history.listen((location, ...props) => {
      const createProjectPaths = [1, 2, 3, 4].map((index: number) =>
        getCreateProjectPath({ step: index })
      );
      if (
        prevLocation.current &&
        createProjectPaths.includes(prevLocation.current?.pathname) &&
        !createProjectPaths.includes(location.pathname)
      ) {
        resetNewProjectData();
      }
      prevLocation.current = location;
    });
  }, [history]);
};

export default useResetNewProjectDataOnLocationChange;
