import React from 'react';
import { Trans } from 'react-i18next';
import { ReadOnlyInputTypes } from '../../types/form.types';
import { Input, PrimaryLabel } from '../../utils/uiComponents';
import FinalInputIcon from './FinalInputIcon';
import styled from 'styled-components';
import InputDescription from './InputDescription';

const InputContainer = styled.div`
  position: relative;
  width: 100%;
  text-align: center;

  input {
    margin-left: auto;
    margin-right: auto;
  }
`;

const InputReadOnly: React.FC<ReadOnlyInputTypes> = ({
  labelColor = '#000000',
  label = 'Email',
  name = 'email',
  type = 'text',
  formRef,
  defaultValue,
  color,
  showRequiredStar,
  formState,
  final,
  description,
  tooltipText,
}) => {
  return (
    <>
      <PrimaryLabel color={color}>
        <Trans>{label}</Trans>
        {showRequiredStar && '*'}
      </PrimaryLabel>
      {description && <InputDescription text={description} />}

      <InputContainer>
        <Input disabled type={type} {...formRef(name)} value={defaultValue || ''} />
        {final && <FinalInputIcon />}
      </InputContainer>
    </>
  );
};

export default InputReadOnly;
