export const UPDATE_GROUPS_LIST = 'UPDATE_GROUPS_LIST';
export const UPDATE_GROUPS_DETAIL = 'UPDATE_GROUPS_DETAIL';
export const UPDATE_COORDINATORS_GROUPS_LIST = 'UPDATE_COORDINATORS_GROUPS_LIST';
export const UPDATE_COORDINATORS_GROUPS_DETAIL = 'UPDATE_COORDINATORS_GROUPS_DETAIL';
export const UPDATE_COORDINATORS_GROUPS_DETAIL_FETCH_STATUS =
  'UPDATE_COORDINATORS_GROUPS_DETAIL_FETCH_STATUS';
export const UPDATE_COORDINATORS_GROUPS_LIST_FETCH_STATUS =
  'UPDATE_COORDINATORS_GROUPS_LIST_FETCH_STATUS';
export const INVITE_MORE_FETCH_STATUS = 'INVITE_MORE_FETCH_STATUS';
export const UPDATE_GROUP_FETCH_STATUS = 'UPDATE_GROUP_FETCH_STATUS';
export const PROCESS_CSV_FETCH_STATUS = 'PROCESS_CSV_FETCH_STATUS';
export const SET_NEW_GROUP_WITH_CONTACTS = 'SET_NEW_GROUP_WITH_CONTACTS';
export const SET_NEW_GROUP_WITH_CONTACTS_FETCH_STATUS = 'SET_NEW_GROUP_WITH_CONTACTS_FETCH_STATUS';
export const UPDATE_I_BELONG_TO_GROUPS_LIST_FETCH_STATUS =
  'UPDATE_I_BELONG_TO_GROUPS_LIST_FETCH_STATUS';
export const UPDATE_I_BELONG_TO_GROUPS_LIST = 'UPDATE_I_BELONG_TO_GROUPS_LIST';
export const UPDATE_LEAVE_GROUP_FETCH_STATUS = 'UPDATE_LEAVE_GROUP_FETCH_STATUS';
