import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import auth from './reducers/Auth/auth.reducer';
import { composeWithDevTools } from 'redux-devtools-extension/index';
import utils from './reducers/Utils/utils.reducer';
import surveys from './reducers/Surveys/surveys.reducer';
import contactPersons from './reducers/ContactPersons/contactPersons.reducer';
import groups from './reducers/Groups/groups.reducer';
import governmentLocation from './reducers/GovernmentLocation/governmentLocation.reducer';
import constants from './reducers/Constants/constants.reducer';
import notifications from './reducers/Notifications/notifications.reducer';

const reducer = combineReducers({
  auth,
  constants,
  contactPersons,
  groups,
  utils,
  surveys,
  governmentLocation,
  notifications,
});
const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk, logger)));

export default store;
