import React, { FC } from 'react';
import { ActionButton } from './GroupButton';
import styled from 'styled-components';

const DummyIcon = styled.div`
  font-size: 18px;
  padding-left: 5px;
  padding-right: 5px;
`;

interface NumberBadgeProps {
  number: number;
  onClick?(): void;
  withBorder?: boolean;
}

const NumberBadge: FC<NumberBadgeProps> = ({ number = 0, onClick, withBorder }) => {
  return (
    <ActionButton
      type={'button'}
      onClick={onClick}
      autoWidth={true}
      withBorder={withBorder}
      noCursor={true}
    >
      <DummyIcon>{number}</DummyIcon>
    </ActionButton>
  );
};

export default NumberBadge;
