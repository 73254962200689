import React from 'react'

const AppBarInfoSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="588" height="133">
      <g id="Group_1541"  transform="translate(-665.844 0)">
        <path id="Path_1278"  d="M1083.985,0H668.438L700.6,133h523.345L1256.115,0Z" transform="translate(-2.594)" fill="#1a1818"/>
        <g id="Component_27_1"  transform="translate(754.685 10.402)">
          <g id="Group_858"  transform="translate(140.917 35.371)">
            <path id="Path_1119"  d="M365.521,139.12a3.706,3.706,0,0,1-1.094,2.865A4.412,4.412,0,0,1,361.32,143h-.841v3.672h-2.99V135.6h3.831a4.681,4.681,0,0,1,3.149.916A3.267,3.267,0,0,1,365.521,139.12Zm-5.042,1.438h.545a1.49,1.49,0,0,0,1.071-.378,1.375,1.375,0,0,0,.4-1.045q0-1.12-1.242-1.121h-.772Z" transform="translate(-356.977 -135.426)" fill="#fff"/>
            <path id="Path_1120"  d="M380.975,146.668V135.6h3.006v11.068Z" transform="translate(-369.241 -135.426)" fill="#fff"/>
            <path id="Path_1121"  d="M401.828,146.668h-6.571V135.6h6.571V138h-3.581v1.741h3.316v2.4h-3.316v2.09h3.581Z" transform="translate(-376.698 -135.426)" fill="#fff"/>
            <path id="Path_1122"  d="M419.039,142.64v4.027h-2.99V135.6h3.626q4.512,0,4.512,3.27a3.268,3.268,0,0,1-1.877,2.975l3.225,4.822h-3.392L419.8,142.64Zm0-2.249h.561q1.568,0,1.567-1.385,0-1.143-1.537-1.143h-.591Z" transform="translate(-387.555 -135.426)" fill="#fff"/>
            <path id="Path_1123"  d="M451.9,146.668H448.36l-1.06-4.822q-.075-.3-.238-1.2t-.238-1.506q-.06.492-.2,1.222t-.268,1.344q-.133.614-1.1,4.966h-3.535L438.98,135.6h2.884l1.2,5.549q.409,1.84.56,2.93.1-.772.352-2.1t.473-2.2l.977-4.186H448.2l.947,4.186q.242,1.007.492,2.324t.333,1.968q.1-.841.537-2.915l1.227-5.564h2.884Z" transform="translate(-399.529 -135.426)" fill="#fff"/>
            <path id="Path_1124"  d="M484.366,143.133a3.263,3.263,0,0,1-.523,1.828,3.421,3.421,0,0,1-1.506,1.241,5.587,5.587,0,0,1-2.309.443,9.333,9.333,0,0,1-1.855-.155,6.889,6.889,0,0,1-1.56-.541v-2.665a8.933,8.933,0,0,0,1.78.685,6.629,6.629,0,0,0,1.7.246,1.642,1.642,0,0,0,.976-.231.709.709,0,0,0,.311-.594.655.655,0,0,0-.125-.4,1.456,1.456,0,0,0-.4-.345q-.276-.174-1.472-.711a7.064,7.064,0,0,1-1.624-.954,2.925,2.925,0,0,1-.8-1.06,3.518,3.518,0,0,1-.261-1.415,2.839,2.839,0,0,1,1.113-2.385,4.908,4.908,0,0,1,3.059-.855,8.58,8.58,0,0,1,3.505.8l-.916,2.309a6.641,6.641,0,0,0-2.68-.712,1.4,1.4,0,0,0-.848.2.618.618,0,0,0-.265.507.731.731,0,0,0,.337.582,12.742,12.742,0,0,0,1.828.939,5.067,5.067,0,0,1,1.987,1.381A3,3,0,0,1,484.366,143.133Z" transform="translate(-419.179 -135.252)" fill="#fff"/>
            <path id="Path_1125"  d="M506.49,146.668h-8.623V144.82l4.792-6.805H497.98V135.6h8.388v1.84l-4.792,6.814h4.913Z" transform="translate(-430.277 -135.426)" fill="#fff"/>
            <path id="Path_1126"  d="M527.692,146.618l-.546-2.074h-3.6l-.56,2.074H519.7l3.611-11.113h3.99l3.656,11.113Zm-1.167-4.527-.477-1.817q-.166-.606-.4-1.567t-.314-1.378c-.045.257-.135.681-.269,1.272s-.431,1.754-.89,3.49Z" transform="translate(-441.68 -135.376)" fill="#fff"/>
            <path id="Path_1127"  d="M568.2,139.12a3.7,3.7,0,0,1-1.094,2.865A4.41,4.41,0,0,1,564,143h-.84v3.672h-2.99V135.6H564a4.683,4.683,0,0,1,3.149.916A3.267,3.267,0,0,1,568.2,139.12Zm-5.042,1.438h.545a1.489,1.489,0,0,0,1.071-.378,1.373,1.373,0,0,0,.4-1.045q0-1.12-1.241-1.121h-.772Z" transform="translate(-462.806 -135.426)" fill="#fff"/>
            <path id="Path_1128"  d="M593.561,140.928a5.969,5.969,0,0,1-1.37,4.247,6.226,6.226,0,0,1-8.006-.008,7.174,7.174,0,0,1-.008-8.475,6.266,6.266,0,0,1,8.021-.012A5.975,5.975,0,0,1,593.561,140.928Zm-7.616,0q0,3.21,2.234,3.21a1.922,1.922,0,0,0,1.685-.78,5.661,5.661,0,0,0-.008-4.879,1.9,1.9,0,0,0-1.661-.791Q585.945,137.688,585.945,140.928Z" transform="translate(-474.623 -135.235)" fill="#fff"/>
            <path id="Path_1129"  d="M612.492,146.668V135.6h2.99v8.653h4.262v2.415Z" transform="translate(-490.129 -135.426)" fill="#fff"/>
            <path id="Path_1130"  d="M641.037,143.133a3.265,3.265,0,0,1-.523,1.828,3.422,3.422,0,0,1-1.507,1.241,5.585,5.585,0,0,1-2.309.443,9.324,9.324,0,0,1-1.854-.155,6.888,6.888,0,0,1-1.56-.541v-2.665a8.926,8.926,0,0,0,1.779.685,6.631,6.631,0,0,0,1.7.246,1.642,1.642,0,0,0,.976-.231.709.709,0,0,0,.311-.594.655.655,0,0,0-.125-.4,1.458,1.458,0,0,0-.4-.345q-.277-.174-1.472-.711a7.069,7.069,0,0,1-1.624-.954,2.922,2.922,0,0,1-.8-1.06,3.519,3.519,0,0,1-.262-1.415,2.838,2.838,0,0,1,1.113-2.385,4.909,4.909,0,0,1,3.059-.855,8.579,8.579,0,0,1,3.505.8l-.916,2.309a6.644,6.644,0,0,0-2.681-.712,1.4,1.4,0,0,0-.848.2.619.619,0,0,0-.265.507.73.73,0,0,0,.337.582,12.732,12.732,0,0,0,1.828.939,5.058,5.058,0,0,1,1.987,1.381A3,3,0,0,1,641.037,143.133Z" transform="translate(-500.987 -135.252)" fill="#fff"/>
            <path id="Path_1131"  d="M665.528,146.668h-3.376l-2.188-4.232-.886.53v3.7h-3.006V135.6h3.006v4.807a13.078,13.078,0,0,1,.916-1.416l2.324-3.391h3.271l-3.49,4.959Z" transform="translate(-512.886 -135.426)" fill="#fff"/>
            <path id="Path_1132"  d="M686.833,146.618l-.545-2.074h-3.6l-.56,2.074h-3.286l3.611-11.113h3.99l3.656,11.113Zm-1.166-4.527-.477-1.817q-.167-.606-.405-1.567t-.314-1.378q-.068.386-.269,1.272t-.889,3.49Z" transform="translate(-524.777 -135.376)" fill="#fff"/>
            <path id="Path_1133"  d="M724.79,146.618l-.545-2.074h-3.6l-.56,2.074H716.8l3.611-11.113h3.99l3.656,11.113Zm-1.166-4.527-.477-1.817q-.167-.606-.406-1.567t-.314-1.378q-.068.386-.269,1.272t-.889,3.49Z" transform="translate(-544.596 -135.376)" fill="#fff"/>
            <path id="Path_1134"  d="M753.882,139.12a3.706,3.706,0,0,1-1.094,2.865A4.411,4.411,0,0,1,749.681,143h-.841v3.672h-2.99V135.6h3.831a4.682,4.682,0,0,1,3.149.916A3.267,3.267,0,0,1,753.882,139.12Zm-5.042,1.438h.546a1.489,1.489,0,0,0,1.071-.378,1.372,1.372,0,0,0,.4-1.045q0-1.12-1.242-1.121h-.772Z" transform="translate(-559.764 -135.426)" fill="#fff"/>
            <path id="Path_1135"  d="M769.336,146.668V135.6h2.99v8.653h4.262v2.415Z" transform="translate(-572.027 -135.426)" fill="#fff"/>
            <path id="Path_1136"  d="M791.206,146.668V135.6h3.005v11.068Z" transform="translate(-583.447 -135.426)" fill="#fff"/>
            <path id="Path_1137"  d="M814.943,146.668h-3.376l-2.188-4.232-.886.53v3.7h-3.006V135.6h3.006v4.807a13.065,13.065,0,0,1,.916-1.416l2.324-3.391H815l-3.49,4.959Z" transform="translate(-590.904 -135.426)" fill="#fff"/>
            <path id="Path_1138"  d="M836.247,146.618l-.545-2.074h-3.6l-.56,2.074H828.26l3.611-11.113h3.99l3.656,11.113Zm-1.166-4.527-.477-1.817q-.167-.606-.405-1.567t-.314-1.378q-.068.386-.269,1.272t-.889,3.49Z" transform="translate(-602.795 -135.376)" fill="#fff"/>
            <path id="Path_1139"  d="M861.79,137.72a1.909,1.909,0,0,0-1.666.874,4.226,4.226,0,0,0-.6,2.411q0,3.195,2.43,3.195a4.978,4.978,0,0,0,1.423-.2,14.058,14.058,0,0,0,1.385-.492v2.529a7.671,7.671,0,0,1-3.134.613,4.957,4.957,0,0,1-3.842-1.454,6,6,0,0,1-1.336-4.2,6.712,6.712,0,0,1,.647-3.02,4.636,4.636,0,0,1,1.863-2,5.628,5.628,0,0,1,2.858-.7,7.874,7.874,0,0,1,3.43.78l-.916,2.354a10.343,10.343,0,0,0-1.226-.485A4.3,4.3,0,0,0,861.79,137.72Z" transform="translate(-617.516 -135.252)" fill="#fff"/>
            <path id="Path_1140"  d="M877.83,150.143a7.345,7.345,0,0,1-1.552-.159v-2.325q.242.046.515.1a2.908,2.908,0,0,0,.591.056,1.211,1.211,0,0,0,1.06-.446,2.737,2.737,0,0,0,.318-1.53V135.6h3.006v9.985a5.121,5.121,0,0,1-.984,3.4A3.664,3.664,0,0,1,877.83,150.143Z" transform="translate(-627.868 -135.426)" fill="#fff"/>
            <path id="Path_1141"  d="M901.24,146.618l-.546-2.074h-3.6l-.56,2.074h-3.286l3.61-11.113h3.99l3.657,11.113Zm-1.167-4.527-.477-1.817q-.167-.606-.406-1.567t-.314-1.378q-.068.386-.269,1.272t-.89,3.49Z" transform="translate(-636.732 -135.376)" fill="#fff"/>
            <path id="Path_1142"  d="M365.252,178.06a5.589,5.589,0,0,1-1.487,4.19,5.887,5.887,0,0,1-4.281,1.45h-3.066V172.632h3.391a4.969,4.969,0,0,1,5.443,5.428Zm-1.363.045a4.454,4.454,0,0,0-1.086-3.263,4.353,4.353,0,0,0-3.229-1.1H357.7v8.842h1.567a4.766,4.766,0,0,0,3.46-1.132A4.48,4.48,0,0,0,363.889,178.106Z" transform="translate(-356.418 -154.762)" fill="#fff"/>
            <path id="Path_1143"  d="M388.076,177.961a6.1,6.1,0,0,1-1.344,4.179A4.724,4.724,0,0,1,383,183.662a4.783,4.783,0,0,1-3.774-1.5,6.142,6.142,0,0,1-1.329-4.221,6.069,6.069,0,0,1,1.332-4.19,4.82,4.82,0,0,1,3.786-1.488,4.715,4.715,0,0,1,3.725,1.514A6.087,6.087,0,0,1,388.076,177.961Zm-8.82,0a5.253,5.253,0,0,0,.958,3.41,3.911,3.911,0,0,0,5.56,0,6.641,6.641,0,0,0,0-6.8,3.35,3.35,0,0,0-2.767-1.154,3.417,3.417,0,0,0-2.8,1.162A5.2,5.2,0,0,0,379.257,177.961Z" transform="translate(-367.632 -154.572)" fill="#fff"/>
            <path id="Path_1144"  d="M415.088,177.728h3.763v5.382a12.111,12.111,0,0,1-1.786.424,13.653,13.653,0,0,1-2.1.144,5.1,5.1,0,0,1-3.914-1.5,5.893,5.893,0,0,1-1.4-4.19,6.341,6.341,0,0,1,.692-3.025,4.69,4.69,0,0,1,1.995-1.984,6.469,6.469,0,0,1,3.051-.685,8.336,8.336,0,0,1,3.3.651l-.5,1.136a7.366,7.366,0,0,0-2.884-.636,4.129,4.129,0,0,0-3.157,1.2,4.67,4.67,0,0,0-1.136,3.339,4.775,4.775,0,0,0,1.094,3.4,4.212,4.212,0,0,0,3.214,1.158,9.57,9.57,0,0,0,2.249-.265v-3.407h-2.476Z" transform="translate(-384.211 -154.589)" fill="#fff"/>
            <path id="Path_1145"  d="M432.075,183.7v-3.846l-.8.447-.508-.863,1.3-.765v-6.041h1.287v5.284l2.248-1.317.507.916-2.756,1.6v3.422h4.883V183.7Z" transform="translate(-395.243 -154.762)" fill="#fff"/>
            <path id="Path_1146"  d="M458.47,177.961a6.094,6.094,0,0,1-1.343,4.179,4.725,4.725,0,0,1-3.736,1.522,4.784,4.784,0,0,1-3.774-1.5,6.145,6.145,0,0,1-1.328-4.221,6.068,6.068,0,0,1,1.332-4.19,4.819,4.819,0,0,1,3.785-1.488,4.715,4.715,0,0,1,3.725,1.514A6.088,6.088,0,0,1,458.47,177.961Zm-8.82,0a5.252,5.252,0,0,0,.958,3.41,3.912,3.912,0,0,0,5.561,0,6.641,6.641,0,0,0,0-6.8,3.351,3.351,0,0,0-2.767-1.154,3.417,3.417,0,0,0-2.8,1.162A5.2,5.2,0,0,0,449.65,177.961Z" transform="translate(-404.389 -154.572)" fill="#fff"/>
            <path id="Path_1147"  d="M479.249,180.582a2.715,2.715,0,0,1-1.06,2.279,4.594,4.594,0,0,1-2.877.818,7.2,7.2,0,0,1-3.028-.507v-1.241a7.971,7.971,0,0,0,1.483.454,7.827,7.827,0,0,0,1.59.166,3.208,3.208,0,0,0,1.938-.488,1.6,1.6,0,0,0,.652-1.359,1.745,1.745,0,0,0-.231-.942,2.146,2.146,0,0,0-.772-.678,10.821,10.821,0,0,0-1.646-.7,5.255,5.255,0,0,1-2.208-1.309,2.893,2.893,0,0,1-.662-1.976,2.459,2.459,0,0,1,.961-2.037,4,4,0,0,1,2.544-.757,7.5,7.5,0,0,1,3.036.605l-.4,1.121a6.859,6.859,0,0,0-2.665-.575,2.578,2.578,0,0,0-1.6.439,1.448,1.448,0,0,0-.575,1.219,1.866,1.866,0,0,0,.213.943,1.988,1.988,0,0,0,.715.674,8.882,8.882,0,0,0,1.54.678,6.1,6.1,0,0,1,2.4,1.332A2.623,2.623,0,0,1,479.249,180.582Z" transform="translate(-416.918 -154.589)" fill="#fff"/>
            <path id="Path_1148"  d="M499.6,177.961a6.1,6.1,0,0,1-1.343,4.179,4.726,4.726,0,0,1-3.737,1.522,4.783,4.783,0,0,1-3.774-1.5,6.142,6.142,0,0,1-1.328-4.221,6.066,6.066,0,0,1,1.332-4.19,4.819,4.819,0,0,1,3.785-1.488,4.716,4.716,0,0,1,3.725,1.514A6.087,6.087,0,0,1,499.6,177.961Zm-8.82,0a5.252,5.252,0,0,0,.958,3.41,3.911,3.911,0,0,0,5.56,0,6.64,6.64,0,0,0,0-6.8,3.351,3.351,0,0,0-2.767-1.154,3.417,3.417,0,0,0-2.8,1.162A5.2,5.2,0,0,0,490.781,177.961Z" transform="translate(-425.866 -154.572)" fill="#fff"/>
            <path id="Path_1149"  d="M523.14,183.7h-1.273l-2.233-7.411q-.159-.492-.356-1.241a7.221,7.221,0,0,1-.2-.9,17.329,17.329,0,0,1-.53,2.188l-2.165,7.366h-1.272l-2.945-11.068h1.362l1.749,6.836a25.435,25.435,0,0,1,.53,2.6,18.787,18.787,0,0,1,.606-2.71l1.984-6.73h1.362l2.082,6.791a20.576,20.576,0,0,1,.613,2.65,22.634,22.634,0,0,1,.546-2.619l1.741-6.821H526.1Z" transform="translate(-437.741 -154.762)" fill="#fff"/>
            <path id="Path_1150"  d="M549.279,183.651l-1.378-3.52h-4.436l-1.363,3.52h-1.3l4.375-11.113h1.082l4.353,11.113Zm-1.779-4.678-1.287-3.429q-.25-.651-.515-1.6a14.335,14.335,0,0,1-.477,1.6l-1.3,3.429Z" transform="translate(-452.695 -154.713)" fill="#fff"/>
            <path id="Path_1151"  d="M572.188,183.7H570.72l-6.049-9.289h-.061q.121,1.636.121,3V183.7h-1.188V172.632H565l6.034,9.251h.06q-.015-.2-.068-1.314t-.038-1.586v-6.352h1.2Z" transform="translate(-464.57 -154.762)" fill="#fff"/>
            <path id="Path_1152"  d="M587.031,183.7V172.632h1.287V183.7Z" transform="translate(-476.835 -154.762)" fill="#fff"/>
            <path id="Path_1153"  d="M600.4,183.651l-1.378-3.52h-4.436l-1.363,3.52h-1.3l4.376-11.113h1.083l4.353,11.113Zm-1.779-4.678-1.287-3.429q-.25-.651-.515-1.6a14.346,14.346,0,0,1-.477,1.6l-1.3,3.429Z" transform="translate(-479.388 -154.713)" fill="#fff"/>
            <path id="Path_1154"  d="M627.842,183.7h-7.609v-1.007l5.875-8.9h-5.693v-1.159h7.26v1.007l-5.874,8.895h6.041Z" transform="translate(-494.171 -154.762)" fill="#fff"/>
            <path id="Path_1155"  d="M655.341,183.7h-1.272l-2.233-7.411q-.159-.492-.355-1.241a7.228,7.228,0,0,1-.2-.9,17.31,17.31,0,0,1-.53,2.188L648.58,183.7h-1.271l-2.945-11.068h1.363l1.749,6.836a25.348,25.348,0,0,1,.529,2.6,18.787,18.787,0,0,1,.606-2.71l1.984-6.73h1.363l2.081,6.791a20.516,20.516,0,0,1,.613,2.65,22.588,22.588,0,0,1,.546-2.619l1.741-6.821H658.3Z" transform="translate(-506.772 -154.762)" fill="#fff"/>
            <path id="Path_1156"  d="M677.339,178.166l2.953-5.534h1.393l-3.695,6.776V183.7h-1.3v-4.232L673,172.632h1.408Z" transform="translate(-521.725 -154.762)" fill="#fff"/>
            <path id="Path_1157"  d="M701.379,183.7h-1.514l-4.035-5.368-1.158,1.03V183.7h-1.287V172.632h1.287v5.489l5.019-5.489h1.521l-4.451,4.808Z" transform="translate(-532.369 -154.762)" fill="#fff"/>
            <path id="Path_1158"  d="M721.3,177.961a6.1,6.1,0,0,1-1.343,4.179,4.726,4.726,0,0,1-3.736,1.522,4.784,4.784,0,0,1-3.774-1.5,6.143,6.143,0,0,1-1.328-4.221,6.069,6.069,0,0,1,1.332-4.19,4.819,4.819,0,0,1,3.785-1.488,4.715,4.715,0,0,1,3.725,1.514A6.088,6.088,0,0,1,721.3,177.961Zm-8.819,0a5.252,5.252,0,0,0,.958,3.41,3.911,3.911,0,0,0,5.561,0,6.642,6.642,0,0,0,0-6.8,3.352,3.352,0,0,0-2.767-1.154,3.417,3.417,0,0,0-2.8,1.162A5.2,5.2,0,0,0,712.484,177.961Z" transform="translate(-541.63 -154.572)" fill="#fff"/>
            <path id="Path_1159"  d="M737.909,179.1v4.6h-1.287V172.632h3.035a4.779,4.779,0,0,1,3.01.78,2.827,2.827,0,0,1,.973,2.347,2.939,2.939,0,0,1-2.226,2.967l3.006,4.974H742.9l-2.68-4.6Zm0-1.106h1.764a3.043,3.043,0,0,0,2-.541,2.013,2.013,0,0,0,.636-1.624,1.848,1.848,0,0,0-.647-1.582,3.506,3.506,0,0,0-2.078-.485h-1.673Z" transform="translate(-554.945 -154.762)" fill="#fff"/>
            <path id="Path_1160"  d="M761.429,183.7H753.82v-1.007l5.875-8.9H754v-1.159h7.26v1.007l-5.875,8.895h6.041Z" transform="translate(-563.925 -154.762)" fill="#fff"/>
            <path id="Path_1161"  d="M774.406,178.166l2.953-5.534h1.393l-3.694,6.776V183.7h-1.3v-4.232l-3.687-6.836h1.408Z" transform="translate(-572.409 -154.762)" fill="#fff"/>
            <path id="Path_1162"  d="M795.91,180.582a2.715,2.715,0,0,1-1.06,2.279,4.6,4.6,0,0,1-2.877.818,7.2,7.2,0,0,1-3.028-.507v-1.241a7.963,7.963,0,0,0,1.484.454,7.818,7.818,0,0,0,1.59.166,3.207,3.207,0,0,0,1.938-.488,1.6,1.6,0,0,0,.652-1.359,1.747,1.747,0,0,0-.231-.942,2.146,2.146,0,0,0-.772-.678,10.826,10.826,0,0,0-1.646-.7,5.249,5.249,0,0,1-2.207-1.309,2.891,2.891,0,0,1-.662-1.976,2.458,2.458,0,0,1,.961-2.037,4,4,0,0,1,2.544-.757,7.493,7.493,0,0,1,3.036.605l-.4,1.121a6.857,6.857,0,0,0-2.665-.575,2.577,2.577,0,0,0-1.6.439,1.446,1.446,0,0,0-.576,1.219,1.869,1.869,0,0,0,.212.943,1.988,1.988,0,0,0,.716.674,8.873,8.873,0,0,0,1.54.678,6.1,6.1,0,0,1,2.4,1.332A2.622,2.622,0,0,1,795.91,180.582Z" transform="translate(-582.267 -154.589)" fill="#fff"/>
            <path id="Path_1163"  d="M809.178,183.7h-1.287v-9.925h-3.505v-1.143h8.3v1.143h-3.505Z" transform="translate(-590.329 -154.762)" fill="#fff"/>
            <path id="Path_1164"  d="M829.557,183.651l-1.378-3.52h-4.436l-1.363,3.52h-1.3l4.376-11.113h1.083l4.352,11.113Zm-1.779-4.678-1.287-3.429q-.25-.651-.515-1.6a14.418,14.418,0,0,1-.477,1.6l-1.3,3.429Z" transform="translate(-599.045 -154.713)" fill="#fff"/>
            <path id="Path_1165"  d="M852.467,183.7H851l-6.049-9.289h-.061q.121,1.636.121,3V183.7h-1.188V172.632h1.454l6.034,9.251h.06q-.015-.2-.068-1.314t-.038-1.586v-6.352h1.2Z" transform="translate(-610.92 -154.762)" fill="#fff"/>
            <path id="Path_1166"  d="M867.309,183.7V172.632H868.6V183.7Z" transform="translate(-623.184 -154.762)" fill="#fff"/>
            <path id="Path_1167"  d="M881.553,183.7h-6.17V172.632h6.17v1.143H876.67v3.566h4.588v1.135H876.67v4.073h4.883Z" transform="translate(-627.401 -154.762)" fill="#fff"/>
            <path id="Path_1168"  d="M897.352,183.7l-3.755-9.812h-.061q.106,1.166.106,2.771v7.04h-1.189V172.632h1.938l3.5,9.13h.061l3.536-9.13h1.923V183.7h-1.287v-7.131q0-1.226.106-2.665h-.06l-3.786,9.8Z" transform="translate(-636.314 -154.762)" fill="#fff"/>
            <path id="Path_1169"  d="M538.1,209.665h3.127a5.9,5.9,0,0,1,3.187.658,2.325,2.325,0,0,1,.984,2.082,2.4,2.4,0,0,1-.549,1.624,2.639,2.639,0,0,1-1.6.829v.075q2.52.432,2.521,2.65a2.845,2.845,0,0,1-1,2.317,4.267,4.267,0,0,1-2.8.833H538.1Zm1.287,4.739h2.119a3.448,3.448,0,0,0,1.961-.428,1.651,1.651,0,0,0,.6-1.442,1.459,1.459,0,0,0-.666-1.344,4.16,4.16,0,0,0-2.119-.412h-1.893Zm0,1.09v4.141H541.7a3.289,3.289,0,0,0,2.017-.518,1.915,1.915,0,0,0,.678-1.624,1.718,1.718,0,0,0-.693-1.514,3.7,3.7,0,0,0-2.108-.485Z" transform="translate(-451.284 -174.099)" fill="#fff"/>
            <path id="Path_1170"  d="M559.123,220.733V209.665h1.287v9.9h4.883v1.166Z" transform="translate(-462.262 -174.099)" fill="#fff"/>
            <path id="Path_1171"  d="M584.942,214.993a6.094,6.094,0,0,1-1.343,4.179,4.724,4.724,0,0,1-3.737,1.522,4.783,4.783,0,0,1-3.774-1.5,6.142,6.142,0,0,1-1.329-4.22,6.064,6.064,0,0,1,1.333-4.19,4.817,4.817,0,0,1,3.785-1.488,4.714,4.714,0,0,1,3.725,1.514A6.086,6.086,0,0,1,584.942,214.993Zm-8.82,0a5.255,5.255,0,0,0,.958,3.411,3.4,3.4,0,0,0,2.782,1.162,3.363,3.363,0,0,0,2.779-1.158,6.637,6.637,0,0,0,0-6.8,3.348,3.348,0,0,0-2.767-1.154,3.416,3.416,0,0,0-2.8,1.162A5.2,5.2,0,0,0,576.123,214.993Z" transform="translate(-470.427 -173.909)" fill="#fff"/>
            <path id="Path_1172"  d="M605.344,210.483a3.618,3.618,0,0,0-2.88,1.215,4.9,4.9,0,0,0-1.056,3.327,5,5,0,0,0,1.018,3.357,3.618,3.618,0,0,0,2.9,1.185,9.91,9.91,0,0,0,2.642-.417v1.128a8.137,8.137,0,0,1-2.839.431,4.8,4.8,0,0,1-3.774-1.484,6.113,6.113,0,0,1-1.328-4.217,6.651,6.651,0,0,1,.64-3,4.59,4.59,0,0,1,1.847-1.983,5.59,5.59,0,0,1,2.843-.7,6.853,6.853,0,0,1,3.043.636l-.545,1.105A5.866,5.866,0,0,0,605.344,210.483Z" transform="translate(-483.622 -173.926)" fill="#fff"/>
            <path id="Path_1173"  d="M629.7,220.733h-1.514l-4.035-5.368-1.158,1.03v4.338H621.7V209.665h1.287v5.488l5.019-5.488h1.522l-4.452,4.807Z" transform="translate(-494.939 -174.099)" fill="#fff"/>
            <path id="Path_1174"  d="M645.729,210.483a3.618,3.618,0,0,0-2.88,1.215,4.9,4.9,0,0,0-1.056,3.327,5,5,0,0,0,1.018,3.357,3.62,3.62,0,0,0,2.9,1.185,9.913,9.913,0,0,0,2.642-.417v1.128a8.138,8.138,0,0,1-2.839.431,4.8,4.8,0,0,1-3.774-1.484,6.112,6.112,0,0,1-1.328-4.217,6.653,6.653,0,0,1,.64-3,4.588,4.588,0,0,1,1.847-1.983,5.589,5.589,0,0,1,2.843-.7,6.853,6.853,0,0,1,3.043.636l-.545,1.105A5.868,5.868,0,0,0,645.729,210.483Z" transform="translate(-504.709 -173.926)" fill="#fff"/>
            <path id="Path_1175"  d="M670.49,220.733H669.2v-5.208h-5.829v5.208h-1.287V209.665h1.287v4.709H669.2v-4.709h1.287Z" transform="translate(-516.026 -174.099)" fill="#fff"/>
            <path id="Path_1176"  d="M691.321,220.683l-1.378-3.52h-4.436l-1.363,3.52h-1.3l4.376-11.113H688.3l4.353,11.113ZM689.542,216l-1.287-3.429q-.25-.651-.515-1.6a14.352,14.352,0,0,1-.477,1.6l-1.3,3.429Z" transform="translate(-526.863 -174.05)" fill="#fff"/>
            <path id="Path_1177"  d="M706.558,220.733V209.665h1.287v11.068Z" transform="translate(-539.247 -174.099)" fill="#fff"/>
            <path id="Path_1178"  d="M724.249,220.733h-1.468l-6.049-9.289h-.061q.121,1.635.121,3v6.291H715.6V209.665h1.453l6.034,9.251h.06q-.014-.2-.068-1.314t-.038-1.586v-6.351h1.2Z" transform="translate(-543.97 -174.099)" fill="#fff"/>
          </g>
          <g id="Group_903"  transform="translate(0 0)">
            <g id="Group_895"  transform="translate(0 0)">
              <g id="Group_894" >
                <g id="Group_891" >
                  <g id="Group_859" >
                    <ellipse id="Ellipse_44"  cx="44.889" cy="44.889" rx="44.889" ry="44.889" transform="translate(0 82.782) rotate(-67.232)" fill="#1a1818"/>
                    <path id="Path_1179"  d="M133.959,179.9A46.244,46.244,0,1,1,180.2,133.657,46.3,46.3,0,0,1,133.959,179.9Zm0-89.778a43.534,43.534,0,1,0,43.535,43.535A43.584,43.584,0,0,0,133.959,90.122Z" transform="translate(-75.196 -74.894)" fill="#fff"/>
                  </g>
                  <g id="Group_866"  transform="translate(11.978 24.549)">
                    <g id="Group_862" >
                      <g id="Group_860"  transform="translate(0.824 0.645)">
                        <path id="Path_1180"  d="M89.937,120.517l-1.63-6.58H97.16l1.63,6.58Z" transform="translate(-88.307 -113.938)" fill="#14b3c5"/>
                      </g>
                      <g id="Group_861" >
                        <path id="Path_1181"  d="M95.755,113.878l1.121,5.29H89.4l-1.168-5.29h7.524m1.01-1.29H86.582l1.693,7.869h10.17l-1.681-7.869Z" transform="translate(-86.582 -112.588)" fill="#1a1818"/>
                      </g>
                    </g>
                    <g id="Group_865"  transform="translate(2.233 10.45)">
                      <g id="Group_863"  transform="translate(0.824 0.644)">
                        <path id="Path_1182"  d="M106.51,159.691,101.834,135.8H92.981l5.408,23.886Z" transform="translate(-92.981 -135.805)" fill="#14b3c5"/>
                      </g>
                      <g id="Group_864" >
                        <path id="Path_1183"  d="M97.915,158.988,92.9,135.747h7.524l4.971,23.241h1.293l-5.254-24.53H91.256l.4,1.6,4.869,22.93Z" transform="translate(-91.256 -134.457)" fill="#1a1818"/>
                      </g>
                    </g>
                  </g>
                  <g id="Group_867"  transform="translate(23.716 22.24)">
                    <path id="Path_1184"  d="M111.147,107.756h9.176l10.936,36.312.706.274,10.549-33.571h8.861L139.7,157.8l-19.374-11.98Z" transform="translate(-111.147 -107.756)" fill="#fff"/>
                  </g>
                  <g id="Group_872"  transform="translate(79.189 24.729)">
                    <g id="Group_869"  transform="translate(0.622 0.807)">
                      <g id="Group_868" >
                        <path id="Path_1185"  d="M228.539,149.108l6.844-27.667h-3.789l1.656-6.786H250.21l-1.656,6.786h-3.792l-6.844,27.667Z" transform="translate(-228.539 -114.655)" fill="#fff"/>
                      </g>
                    </g>
                    <g id="Group_871" >
                      <g id="Group_870" >
                        <path id="Path_1186"  d="M248.91,114.1l-1.379,5.651h-3.79L236.9,147.418h-8.025l6.844-27.667h-3.791L233.3,114.1H248.91m1.629-1.135H232.226l-.22.9-1.379,5.652-.333,1.367h3.786l-6.5,26.3-.34,1.369h10.734l.222-.9,6.622-26.768h3.794l.219-.9,1.379-5.652.333-1.366Z" transform="translate(-227.237 -112.965)" fill="#1a1818"/>
                      </g>
                    </g>
                  </g>
                  <g id="Group_873"  transform="translate(48.798 61.378)">
                    <path id="Path_1187"  d="M171.592,222.894l8.2-33.231h-7.955l-8.2,33.231Z" transform="translate(-163.637 -189.662)" fill="#fff"/>
                  </g>
                  <g id="Group_874"  transform="translate(59.105 61.378)">
                    <path id="Path_1188"  d="M203.867,222.894l8.2-33.231H205.44l-4.175,16.826h-.2l.884-16.826h-8.545l-8.2,33.231h6.581l3.928-16.026h.2v16.026Z" transform="translate(-185.207 -189.662)" fill="#fff"/>
                  </g>
                  <g id="Group_879"  transform="translate(79.848 60.198)">
                    <g id="Group_876"  transform="translate(0.66 0.554)">
                      <g id="Group_875" >
                        <path id="Path_1189"  d="M238.318,222.854c-5.517,0-8.32-.641-8.32-4.142a14.183,14.183,0,0,1,.555-3.416l4.959-19.662c1.626-6.426,3.743-7.283,11.3-7.283,4.7,0,8.028.282,8.028,3.936a15.6,15.6,0,0,1-.555,3.575l-.846,3.411h-9.005l.934-3.874h-.077a1.12,1.12,0,0,0-.393.037.98.98,0,0,0-.135.41l-5,19.92h.075c.226,0,.419-.008.578-.02l3.355-13.337h8.864l-5.023,19.956-.436.066A69.763,69.763,0,0,1,238.318,222.854Z" transform="translate(-229.998 -188.352)" fill="#fff"/>
                      </g>
                    </g>
                    <g id="Group_878" >
                      <g id="Group_877" >
                        <path id="Path_1190"  d="M246.092,188.3c5.3,0,7.367.495,7.367,3.381a15.4,15.4,0,0,1-.54,3.462l-.736,2.969h-7.661l.834-3.463c0-.288-.2-.411-.785-.411-.835,0-1.031.205-1.18.906l-5.057,20.16c0,.329.2.412.786.412a6.221,6.221,0,0,0,1.129-.083l3.34-13.275H251.1l-4.763,18.923a68.121,68.121,0,0,1-8.742.413c-5.646,0-7.66-.7-7.66-3.588a13.888,13.888,0,0,1,.54-3.3l4.96-19.665c1.522-6.019,3.29-6.844,10.655-6.844m0-1.109c-3.841,0-6.431.226-8.3,1.38-2.015,1.243-2.889,3.349-3.646,6.342l-4.96,19.665a14.539,14.539,0,0,0-.569,3.528c0,4.337,4.248,4.7,8.981,4.7a69.695,69.695,0,0,0,8.979-.43l.872-.134.184-.729,4.763-18.921.337-1.339h-9.521l.508-2.028h9.537l.219-.883.737-2.969a16.066,16.066,0,0,0,.567-3.688,3.7,3.7,0,0,0-2.435-3.77,17.73,17.73,0,0,0-6.253-.72Z" transform="translate(-228.616 -187.192)" fill="#1a1818"/>
                      </g>
                    </g>
                  </g>
                  <g id="Group_884"  transform="translate(14.575 51.767)">
                    <g id="Group_880"  transform="translate(2.288 8.579)">
                      <path id="Path_1191"  d="M132.426,196.817,115.439,187.5l-.843,1.251.389-1.2-13.474,2.7,2.461,4.523.105.044a22.324,22.324,0,0,0,7.6,1.786,7.256,7.256,0,0,0,2.462-.379l.409-.147.067-.057-.123.183,2.354,4.517-1.011-.179.822.822.1-.094-.011.089.017-.1.246-.222.516.992-3.241,3.41,1,8.442-3.292-6.854-3.5-4.032-1.4,2.343-4.921-3.6-5.362-4.265,1.5,7.673,1.039,7.191,5.384,4.462,1.693,4.962h20.808l2.7-9.821,2.876-14.725-1.889.585Z" transform="translate(-96.805 -187.502)" fill="#fff"/>
                    </g>
                    <g id="Group_881"  transform="translate(11.456 17.122)">
                      <path id="Path_1192"  d="M125.786,206.957l-9.8-1.576,7.291,8.107Z" transform="translate(-115.991 -205.381)" fill="#fff"/>
                    </g>
                    <g id="Group_882"  transform="translate(4.936)">
                      <path id="Path_1193"  d="M110.776,169.549l-8.43,4.962,2.46,5.788,8.481-1.852-.4-.938c-.27-1.093-.531-2.177-.712-2.932l2.186,2.756Z" transform="translate(-102.346 -169.549)" fill="#fff"/>
                    </g>
                    <g id="Group_883"  transform="translate(0 5.664)">
                      <path id="Path_1194"  d="M100.008,198.708l4.4,2.741,2.513-3.42-5.327-7.645-3.057-5.1-.185-.343.041-.008L96.956,181.4l-4.94,7.261,7.569,9.7Z" transform="translate(-92.016 -181.403)" fill="#fff"/>
                    </g>
                  </g>
                  <g id="Group_887"  transform="translate(58.632 25.698)">
                    <g id="Group_885" >
                      <path id="Path_1195"  d="M200.192,114.992c-7.26,0-9,.83-10.5,6.884l-4.889,19.78a14.292,14.292,0,0,0-.581,3.525c0,2.944,2.082,3.4,7.6,3.4,7.26,0,9.052-.829,10.552-6.925l4.89-19.78a15.782,15.782,0,0,0,.533-3.483C207.792,115.49,205.711,114.992,200.192,114.992Z" transform="translate(-184.218 -114.992)" fill="#fff"/>
                    </g>
                    <g id="Group_886"  transform="translate(8.648 7.281)">
                      <path id="Path_1196"  d="M209.038,130.754c0-.367-.246-.525-.982-.525-1.044,0-1.29.262-1.474,1.157l-4.266,17.345c0,.421.245.527.982.527,1.044,0,1.228-.263,1.473-1.157Z" transform="translate(-202.317 -130.229)" fill="#1a1818"/>
                    </g>
                  </g>
                  <g id="Group_888"  transform="translate(14.41 21.668)">
                    <path id="Path_1197"  d="M131.944,109.632l-10.415,33.182-10.982-36.254h-10.4l.329,1.334,7.186,32.23-1.933-5.22-9.686,5.85.5,1.226-4.866,7.268L96.5,170.169l4.487,4.949,1.665,5.63h21.908l1.276-4.251,5.461-22.128.006,0,.173-.7,10.547-42.708.329-1.333ZM104.988,136.96l3.025,6.343-2.1-2.662s.738,2.275,1.23,4.275l-7.461,1.758-2.036-5.294Zm-12.814,12.5,4.468-5.8,4.809,9.089,4.847,6.035-2.269,2.751L98.406,157.6l.331-.561.68-1.155-1.09.779-.507.363Zm12.114,4.961s4.016,2.637,7.327,2.462l-2.688,3.054ZM123.723,179.5l-6.731,0,.117-3.894-1.022,3.894h-4.774l-.756-4-.138,4-6.678,0-1.5-5.259-4.426-4.9-3.571-13.8,3.165,3.17,7.333,4.66,1.695-2,2.529,3.482,3.644,6.769-.743-8.433,2.844-3.481.886.846-3.393-6.837,1.207-1.776-1.785,1.486-.356.13c-3.284,1.186-8.632-.9-9.83-1.4l-2.2-4.038,12.5-2.5-.926,2.857,1.96-2.907,16.258,8.915-2.977,1.949,3.717-1.214Zm6.522-26.2h0l-17.417-9.554-3.23.64-2.89-13.5,3.6-1.166-3.845.031-2.145-10.019,3.648-1.292-3.919.014-2.314-10.793H109.5l10.58,35.989,1.678.409,3.692-10.957,3.567.539-3.2-1.641,3.292-9.768,3.386.5-3.031-1.558,3.52-10.445h7.768Z" transform="translate(-91.672 -106.559)" fill="#1a1818"/>
                  </g>
                  <g id="Group_889"  transform="translate(21.15 61.784)">
                    <path id="Path_1198"  d="M108.1,197.049l-2.328-6.215,1.022-.322s3.215,7.686,3.779,7.816A17.769,17.769,0,0,0,108.1,197.049Z" transform="translate(-105.777 -190.512)" fill="#1a1818"/>
                  </g>
                  <g id="Group_890"  transform="translate(23.107 65.877)">
                    <path id="Path_1199"  d="M112.121,201.254a7.3,7.3,0,0,1-2.154-2.056c-.031-.036-.063-.078-.1-.119l.565,2.472s5.585,3.83,10.683,3.641c-.671-.745-5.828-2.194-6.718-3.24A6.177,6.177,0,0,1,112.121,201.254Z" transform="translate(-109.872 -199.078)" fill="#1a1818"/>
                  </g>
                </g>
                <g id="Group_893"  transform="translate(124.761 33.027)">
                  <g id="Group_892" >
                    <rect id="Rectangle_300"  width="3.147" height="51.471" fill="#14b3c5"/>
                  </g>
                </g>
              </g>
            </g>
            <g id="Group_902"  transform="translate(105.138 25.051)">
              <g id="Group_898" >
                <g id="Group_896"  transform="translate(0.418 0.418)">
                  <path id="Path_1200"  d="M292,119.3a4.792,4.792,0,1,1-4.792-4.792A4.792,4.792,0,0,1,292,119.3Z" transform="translate(-282.417 -114.513)" fill="#fff"/>
                </g>
                <g id="Group_897" >
                  <path id="Path_1201"  d="M286.752,124.058a5.21,5.21,0,1,1,5.21-5.21A5.216,5.216,0,0,1,286.752,124.058Zm0-9.584a4.374,4.374,0,1,0,4.374,4.374A4.379,4.379,0,0,0,286.752,114.474Z" transform="translate(-281.542 -113.638)" fill="#1a1818"/>
                </g>
              </g>
              <g id="Group_901"  transform="translate(2.064 3.544)">
                <g id="Group_899" >
                  <path id="Path_1202"  d="M286.851,124.387v-2.768h-.989v-.564h2.648v.564h-.987v2.768Z" transform="translate(-285.862 -121.055)" fill="#1a1818"/>
                </g>
                <g id="Group_900"  transform="translate(3.073)">
                  <path id="Path_1203"  d="M292.293,124.387v-3.332H293.3l.6,2.273.6-2.273h1.009v3.332h-.625v-2.623l-.662,2.623h-.647l-.659-2.623v2.623Z" transform="translate(-292.293 -121.055)" fill="#1a1818"/>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default AppBarInfoSvg;
