import React from 'react';
import { Popover } from '@material-ui/core';
import { Trans } from 'react-i18next';
import styled from 'styled-components';
import { colors } from '../theme';
import { Help, Info } from '@material-ui/icons';
import './MyPopover.css';

const ButtonWithPopoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: auto;
`;

const HelpButton = styled.button`
  background-color: ${colors.lightBlue};
  border: none;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    fill: white;
  }
`;

interface Props {
  text: string;
  containerRef?: any;
  buttonType?: 'help' | 'info';
}

const MyPopover: React.FC<Props> = ({ text, containerRef, buttonType = 'help' }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <ButtonWithPopoverContainer className={'my-popover'}>
      <HelpButton aria-describedby={id} onClick={handleClick} type={'button'}>
        {buttonType === 'help' ? (
          <Help width={'1rem'} height={'1rem'} />
        ) : buttonType === 'info' ? (
          <Info width={'1rem'} height={'1rem'} />
        ) : (
          <></>
        )}
      </HelpButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{ paper: 'my-paper' }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        container={containerRef.current}
      >
        <Trans>{text}</Trans>
      </Popover>
    </ButtonWithPopoverContainer>
  );
};

export default MyPopover;
