import React from 'react';
import { FormTypes } from '../../types/form.types';
import i18next from 'i18next';
import styled from 'styled-components';
import { colors, device } from '../../theme';
import { Trans } from 'react-i18next';
import { useWatch } from 'react-hook-form';

const CheckboxSwitchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

  width: 90%;

  @media ${device.laptop} {
    max-width: 600px;
  }
`;

const SwitchLabelContainer = styled.div<{ theme: 'light' | 'dark' }>`
  display: inline;
  color: ${(props) => (props.theme === 'light' ? colors.white : colors.black)};
  //width: 70%;
  //max-width: 250px;
`;

const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 56px;
  height: 18px;
  margin-left: 1rem;
`;

const SwitchInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

const SwitchSlider: any = styled.span<{ theme: 'light' | 'dark' }>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 2px solid ${(props) => (props.theme === 'light' ? colors.white : colors.darkBlue)};
  background-color: ${(props: any) =>
    props.isChecked ? (props.theme === 'light' ? colors.white : colors.darkBlue) : 'transparent'};
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    content: ' ${(props: any) => (props.isChecked ? '1' : '0')}';
    color: ${(props) => (props.theme === 'light' ? colors.darkBlue : colors.white)};
    font-size: 15px;
    line-height: 22px;
    height: 22px;
    width: 22px;
    bottom: -4px;
    left: -2px;
    background-color: ${(props) => (props.theme === 'light' ? colors.white : colors.darkBlue)};
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
    transform: ${(props: any) => (props.isChecked ? 'translateX(34px)' : 'none')};
  }

  ${SwitchInput}:focus {
    box-shadow: 0 0 1px #2196f3;
  }
`;

interface InputTypes extends FormTypes {
  label: string;
  name: string;
  control: any;
  theme?: 'light' | 'dark';
}

const CheckboxSwitch: React.FC<InputTypes> = ({
  control,
  label,
  name,
  type = 'checkbox',
  placeholder = '',
  formRef,
  validate = {},
  theme = 'light',
}) => {
  const value = useWatch({ name, control });

  return (
    <>
      <CheckboxSwitchContainer>
        <SwitchLabelContainer theme={theme}>
          <Trans>{label}</Trans>
        </SwitchLabelContainer>
        <Switch>
          <SwitchInput
            type={type}
            {...formRef(name, validate)}
            placeholder={i18next.t(placeholder)}
          />
          <SwitchSlider isChecked={value} theme={theme} />
        </Switch>
      </CheckboxSwitchContainer>
    </>
  );
};

export default CheckboxSwitch;
