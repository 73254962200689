import React from 'react';
import { Trans } from 'react-i18next';
import ErrorMessage from './ErrorMessage';
import { FormTypes } from '../../types/form.types';
import i18next from 'i18next';
import { FormLabel, getInputStyles } from './forms';
import PhoneInput from 'react-phone-input-2';
import './InputPhone.css';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';
import { device } from '../../theme';
import InputReadOnly from './InputReadOnly';

const PhoneInputContainer: any = styled.div`
  width: 100%;

  margin-left: 0;
  margin-right: 0;
  > div {
    width: 100%;
    margin-top: 10px;
    div {
      display: flex;
      justify-content: flex-start;
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      .flag-dropdown {
        left: 10px;
        .selected-flag {
          margin-left: 0px;
        }
      }

      input {
        border-radius: 30px;
        width: 100%;
        margin-left: 0;
        margin-right: 0;

        @media ${device.laptop} {
          max-width: 600px;
          margin-left: auto;
          margin-right: auto;
        }

        ${(props) => getInputStyles(props)}
      }
    }
  }

  @media ${device.laptop} {
    margin-left: auto;
    margin-right: auto;
    max-width: 600px;
    > div {
      div {
        .flag-dropdown {
          left: 40px;
        }
        width: 100%;
        input {
          width: 90%;
        }
      }
    }
  }
`;

interface InputTypes extends FormTypes {
  label: string;
  name: string;
  control: any;
  disabled?: any;
  showRequiredStar?: boolean;
  final?: boolean;
  setValue: any;
}

const InputPhone: React.FC<InputTypes> = ({
  label,
  name,
  type = 'text',
  placeholder = '',
  formRef,
  fieldError,
  validate = {},
  formState,
  defaultValue = '',
  borderColor,
  color,
  caretColor,
  formType,
  showRequiredStar = false,
  control,
  disabled,
  final,
  setValue,
}) => {
  return !disabled && !final ? (
    <div style={{ width: '100%', textAlign: 'center' }}>
      <FormLabel color={color} formType={formType}>
        <Trans>{label}</Trans>
        {(validate.required || showRequiredStar) && '*'}
      </FormLabel>
      <PhoneInputContainer
        isDirty={formState.dirtyFields[name]}
        borderColor={borderColor}
        color={color}
        caretColor={caretColor}
      >
        <div>
          <Controller
            name={name}
            control={control}
            rules={{ required: true }}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
            }) => (
              <PhoneInput
                inputProps={{
                  name,
                  ref,
                }}
                country={'pl'}
                value={value}
                placeholder={i18next.t(placeholder)}
                onChange={(value) => {
                  setValue(name, value);
                }}
                onBlur={onBlur}
              />
            )}
          />
        </div>
      </PhoneInputContainer>
      <ErrorMessage fieldError={fieldError} validate={validate} />
    </div>
  ) : (
    <InputReadOnly
      formRef={formRef}
      defaultValue={defaultValue}
      color={'#ffffff'}
      label={label}
      name={name}
      type={type}
      showRequiredStar={showRequiredStar}
      final={final}
    />
  );
};

export default InputPhone;
