import React, { useEffect, useRef, useState } from 'react';
import SurveyTypeHeader from '../../../components/SurveyTypeHeader';
import { Trans, useTranslation } from 'react-i18next';
import {
  SurveyAnswer,
  SurveyQuestion,
  SurveyType,
  SurveyTypeEnum,
} from '../../../types/surveys.types';
import BaseTemplate from '../../BaseTemplate';
import { UserData } from '../../../types/auth.types';
import { history } from '../../../utils/history';
import {
  getAuthorizedVotingLandingPath,
  getLoginPath,
  getMyProfilePath,
  getSurveyPath,
  getSurveysPath,
  getSurveyThanksPath,
} from '../../../constants/paths.constants';
import { Center, FlexContainer } from '../../../components/Containers/containers';
import {
  deletePublicSurveyUid,
  deleteVoterId,
  getSessionToken,
  savePublicSurveyUid,
  saveVoterId,
} from '../../../utils/localStorage';
import { PrimarySmallButton } from '../../../components/Buttons/buttons';
import Checkbox from '../../../components/Forms/Checkbox';
import { useForm, useWatch } from 'react-hook-form';
import styled from 'styled-components';
import {
  ClockIcon,
  InvalidAuthorizationTitleIcon,
  UserTitleIcon,
} from '../../../components/Modal/ModalTitleIcons';
import SimpleModal from '../../../components/Modal/SimpleModal';
import QrCodeModal from '../../../components/QrCodeModal';
import NicknameModal from './NicknameModal';
import { useLocation } from 'react-router-dom';
import NetworkService from '../../../utils/network.service';
import InputText from '../../../components/Forms/InputText';
import { REQUEST_STATUSES } from '../../../constants/requestStatuses.constants';
import { showNotification } from '../../../utils/taost.config';
import { usePrevious } from '../../../utils/customHooks';
import ErrorMessage from '../../../components/Forms/ErrorMessage';
import SurveyPreview from '../../../components/SurveyComponents/SurveyPreview';
import SurveyProjectVote, {
  getFormattedDateToVotingStart,
  isVotingInFuture,
} from './ProjectVote/ProjectVote';

const ApprovalCheckboxWrapper = styled.div`
  margin-top: 50px;
  font-size: 12px !important;
`;

interface Props {
  uuid: string;
  survey: SurveyType;
  user: UserData;
  token: string;
  isQrCodeModalOpen: boolean;
  surveyFetchStatus: null | string;
  surveyFetchError?: any;
  accountForSurveyVotingData?: any;
  postVoteFreeAnswersFetchError?: any;
  postVoteFetchError?: any;
  userRequirementsForSurvey?: object;
  postVoteFetchStatus: null | string;

  getSurveyVotingDetail(uuid: string, isLogged?: boolean): void;
  resetSurveyDetail(): void;
  setIsQrCodeModalOpen(flag: boolean): void;
  setUserNicknameFetchStatus(status: any): void;
  setUserNickname(nickname: any): void;
  postVote(data: any): void;
  setSurveyDetailFetchStatus(data: any): void;
  setPostVoteFreeAnswersFetchError(data: any): void;
  setPostVoteFetchError(data: any): void;
  getUserRequirementsForSurvey(data: any): void;
  userRequirementsForSurveyFetchStatus: any;
}

const SurveyVote: React.FC<Props> = ({
  uuid,
  getSurveyVotingDetail,
  survey,
  resetSurveyDetail,
  user,
  token,
  setIsQrCodeModalOpen,
  isQrCodeModalOpen,
  setUserNicknameFetchStatus,
  setUserNickname,
  surveyFetchStatus,
  surveyFetchError,
  accountForSurveyVotingData,
  postVote,
  setSurveyDetailFetchStatus,
  postVoteFreeAnswersFetchError,
  postVoteFetchError,
  setPostVoteFreeAnswersFetchError,
  setPostVoteFetchError,
  getUserRequirementsForSurvey,
  userRequirementsForSurvey,
  userRequirementsForSurveyFetchStatus,
  postVoteFetchStatus,
}) => {
  const nickname = history.location.state?.nickname || user?.voting_nick;

  const [selectedAnswers, setSelectedAnswers] = useState<Array<any>>([]);
  const [errors, setErrors] = useState<{ [key: number]: string }>({});
  const [freeAnswerErrors, setFreeAnswerErrors] = useState<{ [key: number]: string }>({});
  const [agreementError, setAgreementError] = useState<{ type: string } | null>(null);
  const [modalConfig, setModalConfig] = useState<any>();
  const [isModalOpen, setIsModalOpen] = useState<any>(false);
  const [isNicknameModalOpen, setIsNicknameModalOpen] = useState<any>(false);
  const { register, formState, getValues, control, setValue } = useForm();
  const location = useLocation();
  const { t } = useTranslation();

  const is_anonymous = useWatch({
    name: 'is_anonymous',
    control,
  });
  const is_anonymousPrev = usePrevious(is_anonymous);
  const revealNameApproval = useWatch({
    name: 'revealNameApproval',
    control,
  });

  const setDefaultAnswers = () => {
    const answers: Array<SurveyAnswer> = [];
    survey?.questions?.forEach((question, index) => {
      const defaultAnswer = question.answers?.find((answer) => answer.is_default);
      if (defaultAnswer) answers.push(defaultAnswer);
    });
    setSelectedAnswers(answers);
  };

  const getVoterIdFromQueryParams = () => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('vid');
  };

  useEffect(() => {
    if (is_anonymous || revealNameApproval) {
      setAgreementError(null);
    }
    if (is_anonymous && revealNameApproval) {
      if (is_anonymousPrev) setValue('is_anonymous', false);
      else setValue('revealNameApproval', false);
    }
  }, [is_anonymous, revealNameApproval]);

  useEffect(() => {
    if (is_anonymous && !user.voting_nick) {
      setIsNicknameModalOpen(true);
    }
  }, [is_anonymous]);

  useEffect(() => {
    const voterId = getVoterIdFromQueryParams();
    voterId ? saveVoterId(voterId) : deleteVoterId();
    voterId && NetworkService.setupDefaultHeaders({ voterId });
    if (!token && !nickname) {
      // it should open generic nickname modal here
    }
    if (uuid) {
      getSurveyVotingDetail(uuid, !!user);
    }
    if (voterId && uuid && !user) {
      savePublicSurveyUid(uuid);
    } else if (!uuid) {
      deletePublicSurveyUid();
    }
  }, [uuid]);

  useEffect(() => {
    return () => {
      if (!isVoted) resetSurveyDetail();
    };
  }, [uuid]);

  useEffect(() => {
    if (survey) {
      if (survey.has_ended && (survey.is_own || !survey.is_results_only_for_owner)) {
        showNotification({
          message: 'Ankieta zakończyła się',
          type: 'error',
          autoClose: 5000,
        });
        history.push(`${getSurveyPath()}/${uuid}/results`);
      } else if (survey.has_ended) {
        showNotification({
          message: 'Ankieta zakończyła się',
          type: 'error',
          autoClose: 5000,
        });
        history.push(`${getSurveyPath()}`);
      } else if (survey.is_voted) {
        showNotification({
          message: 'Głos w ankiecie już został oddany',
          type: 'error',
          autoClose: 5000,
        });
        history.push(getSurveyPath());
      } else if (survey.is_own) {
        showNotification({
          message: 'Nie możesz głosować w swojej ankiecie.',
          type: 'error',
          autoClose: 5000,
        });
        history.push(`${getSurveyPath()}/${uuid}`);
      }
      // only for projects rn
      if (survey.survey_type === SurveyTypeEnum.project)
        getUserRequirementsForSurvey({ surveyUuid: uuid });
    }
    // before starting time
    if (survey.start_date && !survey.has_started) {
      recalcAndShowClockModal(survey);
    }
  }, [survey]);

  // todo: move future calculation logic to separate file
  let timeModal: React.MutableRefObject<any> = useRef();

  const recalcAndShowClockModal = (survey: any, updateTimeOnly?: boolean) => {
    if (isVotingInFuture(survey)) {
      const formattedTextToVotingStart = getFormattedDateToVotingStart(survey);
      setIsModalOpen(true);
      setModalConfig((prevAddressAgeModalConfig: any) => ({
        iconComponent: ClockIcon,
        title: t('VotingDidntStart'),
        text: `${t('YouWillStartVoting')} ${formattedTextToVotingStart}`,
        onButtonClick: () => {
          if (timeModal.current) clearTimeout(timeModal.current);
          timeModal.current = undefined;
          history.push(getSurveysPath());
        },
        disableClose: true,
      }));

      timeModal.current = setTimeout(() => {
        recalcAndShowClockModal(survey, true);
      }, 60 * 1000);
    } else {
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    if (survey && user) {
      if (survey.require_to_vote_qr && !user.is_blockchain_verified) {
        setIsQrCodeModalOpen(true);
      } else if (
        (survey.require_to_vote_email && !user.is_email_verified) ||
        (survey.require_to_vote_phone &&
          !user.is_phone_verified &&
          survey?.survey_type !== SurveyTypeEnum.project)
      ) {
        setModalConfig({
          title: 'Brak autoryzacji',
          textChildren: (
            <>
              Aby oddać głos w tym głosowaniu musisz jeszcze zautoryzować się przy użyciu:
              {survey.require_to_vote_email && !user.is_email_verified && ' adresu email'}
              {survey.require_to_vote_email &&
                !user.is_email_verified &&
                survey.require_to_vote_phone &&
                !user.is_phone_verified &&
                survey.require_to_vote_qr &&
                ' i'}
              {/* Email + Phone */}
              {survey.require_to_vote_phone && !user.is_phone_verified && ' numeru telefonu'}
            </>
          ),
        });
        setIsModalOpen(true);
      }
    }
  }, [survey]);

  useEffect(() => {
    if (survey) {
      if ((user && token) || nickname) {
      } else if (!getSessionToken() && !getVoterIdFromQueryParams()) {
        // history.push(getLoginPath(), {
        //   isVoting: true,
        //   canVoteWithoutLogin: !(
        //     survey?.require_to_vote_email ||
        //     survey?.require_to_vote_phone ||
        //     survey?.require_to_vote_qr
        //   ),
        //   redirect: getSurveyPath(),
        //   uuid: uuid,
        // });
      }
    }
  }, [user, token, survey]);

  useEffect(() => {
    if (survey) setDefaultAnswers();
  }, [survey]);

  useEffect(
    function redirectToAuthorizedLogin() {
      if (
        ((!user && survey?.require_to_vote_email) ||
          (!user && surveyFetchStatus === REQUEST_STATUSES.ERROR) ||
          (survey?.require_to_vote_email &&
            accountForSurveyVotingData?.is_email_verified === false) ||
          (survey?.require_to_vote_phone &&
            accountForSurveyVotingData?.is_phone_verified === false)) &&
        userRequirementsForSurveyFetchStatus === REQUEST_STATUSES.DONE
      ) {
        setSurveyDetailFetchStatus(REQUEST_STATUSES.NULL);
        savePublicSurveyUid(uuid);
        history.push(getAuthorizedVotingLandingPath(), { surveyType: survey.survey_type });
      }
      // other old flows, not tested
      if (surveyFetchStatus === REQUEST_STATUSES.ERROR && surveyFetchError?.status === 404) {
        showNotification({
          message: t('VotingNotExists'),
          type: 'error',
          autoClose: 5000,
        });
      } else if (
        surveyFetchStatus === REQUEST_STATUSES.ERROR &&
        surveyFetchError?.status === 403 &&
        !user &&
        userRequirementsForSurveyFetchStatus === REQUEST_STATUSES.DONE
      ) {
        setSurveyDetailFetchStatus(REQUEST_STATUSES.NULL);
        history.push(getAuthorizedVotingLandingPath(), { surveyType: survey.survey_type });
      } else if (
        surveyFetchStatus === REQUEST_STATUSES.ERROR &&
        surveyFetchError?.status === 403 &&
        !!user
      ) {
        showNotification({
          message: t('CantVoteBecauseOfEmail'),
          type: 'error',
          autoClose: 10000,
        });
        history.push(getSurveysPath());
      } else if (surveyFetchStatus === REQUEST_STATUSES.ERROR && surveyFetchError?.status !== 401) {
        showNotification({
          response: surveyFetchError,
          type: 'error',
        });
        //  case for not logged in user, who clicked link in email to vote in extended survey
      } else if (
        surveyFetchStatus === REQUEST_STATUSES.ERROR &&
        surveyFetchError?.status === 401 &&
        !user
      ) {
        history.push(getAuthorizedVotingLandingPath(), { surveyType: SurveyTypeEnum.extended });
      }
      if (
        surveyFetchError?.status &&
        surveyFetchError?.status !== 403 &&
        surveyFetchError?.status !== 401 &&
        surveyFetchError?.status !== 404
      ) {
        setTimeout(() => {
          history.push(getSurveysPath());
        }, 300);
      }
    },
    [surveyFetchStatus, user, survey, userRequirementsForSurveyFetchStatus]
  );

  useEffect(() => {
    // probably not needed any more
    if (postVoteFetchError?.status === 200) {
    } else if (postVoteFetchError?.status === 403) {
    } else if (postVoteFetchError?.status === 401) {
      showNotification({
        message: 'RequiredAuthorization',
        type: 'error',
        autoClose: 5000,
      });
      history.push(getLoginPath());
    }
  }, [postVoteFetchError]);

  const getSelectedAnswersCountForQuestion = (
    selectedAnswers: Array<SurveyAnswer>,
    questionId: any
  ) => {
    return selectedAnswers.filter(
      (selectedAnswer) =>
        selectedAnswer.question_id === +questionId && !selectedAnswer.is_free_answer
    ).length;
  };

  useEffect(() => {
    Object.entries(errors).forEach(([questionId, errorValue]) => {
      const question = survey.questions.find((question) => +question.id === +questionId);
      if (
        errorValue &&
        question &&
        getSelectedAnswersCountForQuestion(selectedAnswers, questionId) <
          question.min_required_answers
      ) {
        setErrors((prev: any) => {
          return {
            ...prev,
            [questionId]: t('NotEnoughAnswersChecked'),
          };
        });
      }
      if (
        errorValue &&
        question &&
        !(
          getSelectedAnswersCountForQuestion(selectedAnswers, questionId) <
          question.min_required_answers
        )
      )
        setErrors((prev: any) => {
          return {
            ...prev,
            [questionId]: null,
          };
        });
    });
  }, [selectedAnswers]);

  const handleSelectAnswer = (question: SurveyQuestion, answer: SurveyAnswer) => {
    const selectedAnswerIndex = selectedAnswers.findIndex(
      (selectedAnswer) => selectedAnswer.id === answer.id
    );
    if (selectedAnswerIndex === -1) {
      if (
        getSelectedAnswersCountForQuestion(selectedAnswers, question.id) <
          question.max_required_answers ||
        answer.is_free_answer
      ) {
        setSelectedAnswers((prev: any) => [...prev, answer]);
      } else {
        setErrors((prev: any) => ({
          ...prev,
          [question.id]: t('YouCantSelectMore'),
        }));
      }
    }
    if (selectedAnswerIndex !== -1) {
      setSelectedAnswers((prev: any) => {
        let newArray: Array<SurveyAnswer> = [...prev];
        newArray.splice(selectedAnswerIndex, 1);
        return newArray;
      });
    }
  };

  const validateFreeAnswers = (selectedAnswers: Array<SurveyAnswer>) => {
    let newFreeAnswerErrors: { [key: number]: string } = {};
    selectedAnswers.forEach((selectedAnswer) => {
      if (selectedAnswer.is_free_answer && selectedAnswer.question_id) {
        if (selectedAnswer.text.length > 300) {
          newFreeAnswerErrors[selectedAnswer.question_id] = t('Max300Chars');
        }
      }
    });
    setFreeAnswerErrors(newFreeAnswerErrors);
  };

  useEffect(() => {
    validateFreeAnswers(selectedAnswers);
  }, [selectedAnswers]);

  const changeFreeAnswerValue = (answer: SurveyAnswer, value: string) => {
    setSelectedAnswers((prev) =>
      prev.map((selectedAnswer) => {
        if (selectedAnswer.id === answer.id) {
          return { ...selectedAnswer, text: value };
        }
        return selectedAnswer;
      })
    );
  };

  const submitVote = () => {
    // value from form if not, value from route state
    const _nickname = getValues().nickname || nickname;
    // validation
    const revealNameApprovalChecked = getValues('revealNameApproval');
    const is_anonymous = getValues('is_anonymous');

    let isValid = true;

    if (!(is_anonymous || revealNameApprovalChecked)) {
      if (survey.is_overt && user) {
        setAgreementError({ type: 'chooseOption' });
        isValid = false;
      }
    }

    survey.questions.forEach((question) => {
      const getSelectedAnswersForQuestion = selectedAnswers.filter(
        (selectedAnswer) =>
          selectedAnswer.question_id === question.id && !selectedAnswer.is_free_answer
      );
      if (getSelectedAnswersForQuestion.length < question.min_required_answers) {
        setErrors((prev: any) => ({
          ...prev,
          [question.id]: t('NotEnoughAnswersChecked'),
        }));
        isValid = false;
      }
    });

    Object.values(freeAnswerErrors).forEach((value) => {
      if (value) isValid = false;
    });

    if (isValid) {
      postVote({
        selectedAnswers,
        uuid,
        survey,
        revealNameApprovalChecked,
        is_anonymous,
        nickname: _nickname,
      });
    }
  };

  const routeToProfile = () => history.push(getMyProfilePath());

  const isVoted = postVoteFetchStatus === REQUEST_STATUSES.DONE;

  useEffect(() => {
    if (isVoted && uuid) history.push(getSurveyThanksPath({ uuid }));
  }, [isVoted]);

  return (
    <>
      {!isVoted && (
        <BaseTemplate>
          {isQrCodeModalOpen && <QrCodeModal />}
          {isModalOpen && (
            <SimpleModal
              open={isModalOpen}
              iconComponent={InvalidAuthorizationTitleIcon}
              onButtonClick={routeToProfile}
              {...modalConfig}
            />
          )}
          <SimpleModal
            open={postVoteFetchError?.status === 403}
            setOpen={setIsNicknameModalOpen}
            title={t('NoAuthorization')}
            text={t('GoToLoginScreenToAuthorize')}
            customButtonComponent={
              <Center>
                <PrimarySmallButton
                  type={'button'}
                  onClick={() => {
                    setPostVoteFetchError(null);
                    history.push(getLoginPath(), {
                      redirectToSurvey: `location.pathname$?vid=${getVoterIdFromQueryParams()}`,
                    });
                  }}
                >
                  <Trans>Przejdź do logowania</Trans>
                </PrimarySmallButton>
              </Center>
            }
            iconComponent={UserTitleIcon}
            disableClose={true}
          />
          {isNicknameModalOpen && (
            <SimpleModal
              open={isNicknameModalOpen}
              setOpen={setIsNicknameModalOpen}
              title={t('SetNick')}
              text={t('SetNickForAccount')}
              children={
                <NicknameModal
                  setOpen={setIsNicknameModalOpen}
                  failCallback={() => setValue('vote_through_nickname', false)}
                  setUserNickname={setUserNickname}
                  setUserNicknameFetchStatus={setUserNicknameFetchStatus}
                />
              }
              customButtonComponent={<></>}
              iconComponent={UserTitleIcon}
              disableClose={true}
            />
          )}
          <FlexContainer>
            <Center>
              {survey && survey.survey_type && (
                <>
                  <SurveyTypeHeader
                    type={survey.survey_type}
                    headerPopover={
                      survey?.created_by?.organization ||
                      (survey?.created_by?.first_name &&
                        survey?.created_by?.last_name &&
                        `${survey?.created_by?.first_name || ''} ${
                          survey?.created_by?.last_name || ''
                        }`)
                    }
                  />
                  {survey?.survey_type === SurveyTypeEnum.project ? (
                    <SurveyProjectVote
                      survey={survey}
                      userRequirementsForSurvey={userRequirementsForSurvey}
                      getUserRequirementsForSurvey={getUserRequirementsForSurvey}
                    />
                  ) : (
                    <>
                      <SurveyPreview
                        survey={survey}
                        errors={errors}
                        freeAnswerErrors={freeAnswerErrors}
                        changeFreeAnswerValue={changeFreeAnswerValue}
                        selectedAnswers={selectedAnswers}
                        getSelectedAnswersCountForQuestion={getSelectedAnswersCountForQuestion}
                        handleSelectAnswer={handleSelectAnswer}
                      />

                      {!token && !nickname && !survey.require_to_vote_email && (
                        <InputText
                          label={'VotingNick'}
                          showRequiredStar
                          name={'nickname'}
                          formRef={register}
                          formState={formState}
                          defaultValue={''}
                          borderColor={'#000'}
                          color={'#000'}
                          caretColor={'#000'}
                        />
                      )}

                      {(survey.is_overt || user) && (
                        <ApprovalCheckboxWrapper>
                          {survey.is_overt && (
                            <Checkbox
                              formState={formState}
                              label={t('IAgreeToPublishName')}
                              name={`revealNameApproval`}
                              formRef={register}
                              defaultChecked={false}
                            />
                          )}

                          {user && (
                            <Checkbox
                              formState={formState}
                              label={`${t('VoteAnonymously')} ${
                                user.voting_nick ? `: ${user.voting_nick}` : ''
                              }`}
                              name={`is_anonymous`}
                              formRef={register}
                              defaultChecked={false}
                            />
                          )}
                          {agreementError && <ErrorMessage fieldError={agreementError} />}
                        </ApprovalCheckboxWrapper>
                      )}

                      {!isVoted && (
                        <PrimarySmallButton type={'button'} onClick={submitVote}>
                          <Trans>Vote</Trans>
                        </PrimarySmallButton>
                      )}
                    </>
                  )}
                </>
              )}
            </Center>
          </FlexContainer>
        </BaseTemplate>
      )}
    </>
  );
};

export default SurveyVote;
