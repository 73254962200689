import React from 'react';
import styled from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';
import { Trans } from 'react-i18next';
import { colors } from '../../theme';
import { FormControlLabel } from '@material-ui/core';
import { alignInputParentStyles } from '../../components/Forms/forms';
import ErrorMessage from '../../components/Forms/ErrorMessage';
import { Controller } from 'react-hook-form';

const SwitchContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 20px;
  width: 100%;

  label {
    ${alignInputParentStyles}
    margin-left: auto;
    margin-right: auto;

    .MuiCheckbox-root {
      color: ${colors.white};
      margin-left: 10px;
      margin-right: 10px;
      padding-left: 0px;
      padding-right: 0px;
    }
    .MuiCheckbox-colorSecondary.Mui-checked {
      color: ${colors.white};
    }
    .MuiFormControlLabel-label {
      font-size: 16px;
      //line-height: 45px;
      font-weight: bold;
      color: ${colors.white};
    }
    
    &.Mui-disabled {
      .MuiCheckbox-root, .MuiCheckbox-colorSecondary.Mui-checked {
        color: ${colors.gray};
    }
  }
`;

const ExtendedProfileSwitch = (props) => {
  const {
    checked,
    setChecked = () => {},
    disabled = false,
    label,
    formRef,
    name,
    validate,
    defaultValue,
    fieldError,
    control,
    final,
  } = props;

  const formRefObj = (formRef || control) && name;

  return (
    <>
      <SwitchContainer>
        <FormControlLabel
          disabled={disabled}
          control={
            formRefObj ? (
              <>
                <Controller
                  defaultValue={final ? true : defaultValue}
                  name={name}
                  control={control}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                  }) => (
                    <Checkbox
                      name={name}
                      inputRef={ref}
                      checked={value}
                      onChange={(e) => !disabled && onChange(e.target.checked)}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                      onBlur={onBlur}
                    />
                  )}
                />
              </>
            ) : (
              <Checkbox
                checked={checked}
                onChange={(event) => !disabled && setChecked(event.target?.checked)}
                inputProps={{ 'aria-label': 'primary checkbox' }}
                disabled={disabled}
              />
            )
          }
          label={<Trans>{label || 'CreateExtendedProfile'}</Trans>}
        />
        <ErrorMessage fieldError={fieldError} validate={validate} />
      </SwitchContainer>
    </>
  );
};

export default ExtendedProfileSwitch;
