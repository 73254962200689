export const SET_REGISTER_FETCH_STATUS = 'SET_REGISTER_FETCH_STATUS';
export const SET_LOGIN_FETCH_STATUS = 'SET_LOGIN_FETCH_STATUS';
export const SET_LOGOUT_FETCH_STATUS = 'SET_LOGOUT_FETCH_STATUS';
export const SET_RESET_PASSWORD_FETCH_STATUS = 'SET_RESET_PASSWORD_FETCH_STATUS';
export const SET_CHANGE_PASSWORD_FETCH_STATUS = 'SET_CHANGE_PASSWORD_FETCH_STATUS';
export const SET_CHANGE_PASSWORD_FETCH_ERROR = 'SET_CHANGE_PASSWORD_FETCH_ERROR';
export const TOKEN_UPDATE = 'TOKEN_UPDATE';
export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_IS_LOGGED = 'SET_IS_LOGGED';
export const SET_USER_FETCH_STATUS = 'SET_USER_FETCH_STATUS';
export const RESET_USER_PATCH_FETCH_STATUS = 'RESET_USER_PATCH_FETCH_STATUS';
export const SET_UPDATE_USER_FETCH_STATUS = 'SET_UPDATE_USER_FETCH_STATUS';
export const SET_UPDATE_USER_FETCH_ERROR = 'SET_UPDATE_USER_FETCH_ERROR';
export const SET_SMS_CODE_ID = 'SET_SMS_CODE_ID';
export const UNAUTH_USER = 'UNAUTH_USER';
export const SET_VERIFY_EMAIL_FETCH_STATUS = 'SET_VERIFY_EMAIL_FETCH_STATUS';
export const SET_POST_PHONE_CODE_FETCH_STATUS = 'SET_POST_PHONE_CODE_FETCH_STATUS';
export const SET_BLOCKCHAIN_FETCH_STATUS = 'SET_BLOCKCHAIN_FETCH_STATUS';
export const SET_BLOCKCHAIN_KEYS = 'SET_BLOCKCHAIN_KEYS';
export const SET_BLOCKCHAIN_QR_CODE = 'SET_BLOCKCHAIN_QR_CODE';
export const SET_CLERK_VERIFICATION_KEYS_CHECK_FETCH_STATUS =
  'SET_CLERK_VERIFICATION_KEYS_CHECK_FETCH_STATUS';
export const SET_CLERK_VERIFICATION_VERIFY_FETCH_STATUS =
  'SET_CLERK_VERIFICATION_VERIFY_FETCH_STATUS';
export const SET_USER_NICKNAME_FETCH_STATUS = 'SET_USER_NICKNAME_FETCH_STATUS';
export const SET_DELETE_MY_ACCOUNT_FETCH_STATUS = 'SET_DELETE_MY_ACCOUNT_FETCH_STATUS';
export const SET_ERROR_TYPES = 'SET_ERROR_TYPES';
export const CHECK_IF_USER_CAN_VOTE = 'CHECK_IF_USER_CAN_VOTE';
export const CHECK_IF_USER_CAN_VOTE_FETCH_STATUS = 'CHECK_IF_USER_CAN_VOTE_FETCH_STATUS';
export const VERIFY_EMAIL_BY_CODE = 'VERIFY_EMAIL_BY_CODE';
export const VERIFY_PHONE_BY_CODE = 'VERIFY_PHONE_BY_CODE';
export const VERIFY_EMAIL_BY_CODE_FETCH_STATUS = 'VERIFY_EMAIL_BY_CODE_FETCH_STATUS';
export const VERIFY_PHONE_BY_CODE_FETCH_STATUS = 'VERIFY_PHONE_BY_CODE_FETCH_STATUS';
export const CHECK_VERIFICATION = 'CHECK_VERIFICATION';
export const CHECK_VERIFICATION_FETCH_STATUS = 'CHECK_VERIFICATION_FETCH_STATUS';
