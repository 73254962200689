import React, { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import NotificationsIcon from '@material-ui/icons/Notifications';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import { colors, size, sizes } from '../../../theme';
import { ListItemIcon, ListItemText, Menu, MenuItem, withStyles } from '@material-ui/core';
import { REQUEST_STATUSES } from '../../../constants/requestStatuses.constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from 'react-router-dom';
import { getParamsFromCursor } from '../../../utils/cursorHelpers';
import { useTranslation } from 'react-i18next';
import NothingIcon from '@material-ui/icons/CancelOutlined';

const spinnerSize = 23;
const bottomThreshold = 60;

const Logo = styled.img`
  width: 35px;
  height: 35px;
`;

const ButtonContainer = styled.div`
  margin-left: auto;
  cursor: pointer;
  //position: relative;
  .MuiBadge-badge {
    margin-bottom: 15px;
    margin-right: 10px;
  }
  * {
    min-width: 50px;
  }
`;

const CircularProgressContainer = styled.div`
  width: ${spinnerSize}px;
  margin-left: auto;
  margin-right: auto;
`;

const StyledMenu = withStyles({
  paper: {
    width: '85vw',
    maxWidth: size.mobileL,
    marginTop: '5px',
    border: `1px solid ${colors.gray}`,
  },
})((props: any) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledListItemText = withStyles({
  root: {
    height: '100%',
    margin: 0,
    whiteSpace: 'break-spaces',
    lineHeight: '1.2',
    minWidth: '200px',
  },
})((props: any) => <ListItemText {...props} />);

const UnreadDot: FC<any> = styled.div`
  margin-top: 25%;
  margin-left: -10px;
  margin-right: 5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${(props: any) => (props.isRead ? 'none' : colors.lightBlue)};
`;

const StyledMenuItem: FC<any> = styled(MenuItem)`
  img {
    object-fit: scale-down;
  }
  .MuiList-root {
    .MuiTypography-root {
      font-weight: ${(props: any) => (props.isRead ? 'regular' : 'bold')};
    }
  }
`;

interface NotificationsProps {
  count: number | null;
  notificationsListData: any;
  getNotificationsSummary: any;
  getNotifications: any;
  postMarkAllNotificationsAsRead: any;
}

const Notifications: FC<NotificationsProps> = (props) => {
  const {
    count,
    notificationsListData,
    getNotifications,
    getNotificationsSummary,
    postMarkAllNotificationsAsRead,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  const { t } = useTranslation();

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    if (!event) return;
    getNotifications({});
    postMarkAllNotificationsAsRead();
  };

  const handleClose = () => {
    setAnchorEl(null);
    getNotificationsSummary();
  };

  const handleItemClick = (item: any) => {
    if (item?.deep_link) {
      history.push(item.deep_link);
      setAnchorEl(null);
    } else {
      console.warn('Notification without deeplink!');
    }
  };

  // to define if scroll was up to fetch more
  const lastScrollPosition: Event | any = useRef({});
  // to define when load more messages
  const lastReqCursor = useRef();
  const threadRef = useRef(notificationsListData);
  useEffect(() => {
    threadRef.current = notificationsListData;
  }, [notificationsListData]);

  const checkLoadMore = (event: Event | any) => {
    if (event.target?.scrollTop > lastScrollPosition.current?.scrollTop) {
      const _thread = threadRef.current;
      const { cursor: nextCursor } = getParamsFromCursor(_thread?.next);

      if (
        event.target.scrollTop + event.target.clientHeight + bottomThreshold >
          event.target.scrollHeight &&
        _thread?.next &&
        lastReqCursor.current !== nextCursor &&
        _thread?.fetchStatus !== REQUEST_STATUSES.FETCHING
      ) {
        const { cursor, page_size } = getParamsFromCursor(_thread?.next);
        getNotifications({ cursor, page_size: page_size || 20 });
      }
    }
    lastScrollPosition.current = {
      scrollHeight: event.target.scrollHeight,
      scrollTop: event.target?.scrollTop,
    };
  };

  useEffect(() => {
    if (notificationsListData.fetchStatus !== REQUEST_STATUSES.DONE) return;
    const scrollElement = document.querySelector('#notifications-dropdown .MuiPaper-root');
    scrollElement?.addEventListener('scroll', checkLoadMore);
    return () => {
      scrollElement?.removeEventListener('scroll', checkLoadMore);
    };
  }, [notificationsListData.fetchStatus]);

  return (
    <>
      <ButtonContainer onClick={handleClick}>
        <Badge
          badgeContent={count}
          color="secondary"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="bell"
            onClick={() => {}}
            size={'medium'}
          >
            <NotificationsIcon style={{ fontSize: sizes.navbarIcons }} />
          </IconButton>
        </Badge>
      </ButtonContainer>

      <StyledMenu
        id="notifications-dropdown"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {(notificationsListData.fetchStatus === REQUEST_STATUSES.FETCHING ||
          notificationsListData.fetchStatus === REQUEST_STATUSES.NULL) &&
        !notificationsListData.results?.length ? (
          <StyledMenuItem>
            <CircularProgressContainer className={'container'}>
              <CircularProgress color="inherit" size={spinnerSize} />
            </CircularProgressContainer>
          </StyledMenuItem>
        ) : notificationsListData.fetchStatus === REQUEST_STATUSES.DONE &&
          !notificationsListData.results?.length ? (
          <StyledMenuItem isRead={true} onClick={() => {}}>
            <ListItemIcon>
              <NothingIcon style={{ fontSize: 34 }} />
            </ListItemIcon>
            <ListItemText primary={t('NoNotifications')} />
          </StyledMenuItem>
        ) : (
          <>
            {notificationsListData.results?.map((item: any, idx: number) => (
              <StyledMenuItem
                key={item.timestamp}
                isRead={item?.is_read}
                onClick={() => handleItemClick(item)}
              >
                <ListItemIcon>
                  <UnreadDot isRead={item?.is_read} />
                  <Logo src={'/logo192.png'}></Logo>
                </ListItemIcon>
                <StyledListItemText
                  primary={<div style={{ lineHeight: 1.3 }}>{item.description}</div>}
                />
              </StyledMenuItem>
            ))}

            {notificationsListData.fetchStatus === REQUEST_STATUSES.FETCHING && (
              <StyledMenuItem>
                <CircularProgressContainer>
                  <CircularProgress color="inherit" size={spinnerSize} />
                </CircularProgressContainer>
              </StyledMenuItem>
            )}
          </>
        )}
      </StyledMenu>
    </>
  );
};

export default Notifications;
