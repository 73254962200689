import axios from 'axios';
import {
  GET_GOVERNMENT_COUNTY_FETCH_STATUS,
  GET_GOVERNMENT_LOCALITY_FETCH_STATUS,
  GET_GOVERNMENT_MUNICIPALITY_FETCH_STATUS,
  GET_GOVERNMENT_PROVINCE_FETCH_STATUS,
  GET_GOVERNMENT_STREET_FETCH_STATUS,
  UPDATE_GOVERNMENT_COUNTIES,
  UPDATE_GOVERNMENT_LOCALITIES,
  UPDATE_GOVERNMENT_MUNICIPALITIES,
  UPDATE_GOVERNMENT_PROVINCES,
  UPDATE_GOVERNMENT_STREETS,
} from './governmentLocation.actionTypes';
import { Dispatch } from 'redux';
import { REQUEST_STATUSES } from '../../constants/requestStatuses.constants';
import { showNotification } from '../../utils/taost.config';

export const getGovernmentProvinces = () => {
  let url = process.env.REACT_APP_API_URL + `/teryt/government_province/`;
  return function (dispatch: Dispatch) {
    dispatch(updateGovernmentProvincesFetchStatus(REQUEST_STATUSES.NULL));
    axios
      .get(url)
      .then((response) => {
        dispatch(updateGovernmentProvincesFetchStatus(REQUEST_STATUSES.DONE));
        dispatch(updateGovernmentProvinces(response.data));
      })
      .catch((error) => {
        dispatch(updateGovernmentProvincesFetchStatus(REQUEST_STATUSES.ERROR));
        showNotification({
          response: error.response,
          type: 'error',
        });
      });
  };
};

export const getGovernmentCounties = (queryData: { q?: string; province: any }) => {
  let searchParams = new URLSearchParams(queryData).toString()
    ? `?${new URLSearchParams(queryData).toString()}`
    : '';
  let url = process.env.REACT_APP_API_URL + `/teryt/government_county/${searchParams}`;
  return function (dispatch: Dispatch) {
    dispatch(updateGovernmentCountiesFetchStatus(REQUEST_STATUSES.NULL));
    axios
      .get(url)
      .then((response) => {
        dispatch(updateGovernmentCountiesFetchStatus(REQUEST_STATUSES.DONE));
        dispatch(updateGovernmentCounties(response.data));
      })
      .catch((error) => {
        dispatch(updateGovernmentCountiesFetchStatus(REQUEST_STATUSES.ERROR));
        showNotification({
          response: error.response,
          type: 'error',
        });
      });
  };
};

export const getGovernmentMunicipalities = (queryData: {
  q?: string;
  province?: any;
  county: any;
}) => {
  let searchParams = new URLSearchParams(queryData).toString()
    ? `?${new URLSearchParams(queryData).toString()}`
    : '';
  let url = process.env.REACT_APP_API_URL + `/teryt/government_municipality/${searchParams}`;
  return function (dispatch: Dispatch) {
    dispatch(updateGovernmentMunicipalitiesFetchStatus(REQUEST_STATUSES.NULL));
    axios
      .get(url)
      .then((response) => {
        dispatch(updateGovernmentMunicipalitiesFetchStatus(REQUEST_STATUSES.DONE));
        dispatch(updateGovernmentMunicipalities(response.data));
      })
      .catch((error) => {
        dispatch(updateGovernmentMunicipalitiesFetchStatus(REQUEST_STATUSES.ERROR));
        showNotification({
          response: error.response,
          type: 'error',
        });
      });
  };
};

export const getGovernmentLocalities = (queryData: {
  q?: string;
  province?: any;
  county?: any;
  municipality?: any;
}) => {
  let searchParams = new URLSearchParams(queryData).toString()
    ? `?${new URLSearchParams(queryData).toString()}`
    : '';
  let url = process.env.REACT_APP_API_URL + `/teryt/government_locality/${searchParams}`;
  return function (dispatch: Dispatch) {
    dispatch(updateGovernmentLocalitiesFetchStatus(REQUEST_STATUSES.NULL));
    axios
      .get(url)
      .then((response) => {
        dispatch(updateGovernmentLocalitiesFetchStatus(REQUEST_STATUSES.DONE));
        dispatch(updateGovernmentLocalities(response.data));
      })
      .catch((error) => {
        dispatch(updateGovernmentLocalitiesFetchStatus(REQUEST_STATUSES.ERROR));
        showNotification({
          response: error.response,
          type: 'error',
        });
      });
  };
};

export const getGovernmentStreets = (queryData: {
  q?: string;
  province?: any;
  county?: any;
  locality: any;
}) => {
  let searchParams = new URLSearchParams(queryData).toString()
    ? `?${new URLSearchParams(queryData).toString()}`
    : '';
  let url = process.env.REACT_APP_API_URL + `/teryt/government_street/${searchParams}`;
  return function (dispatch: Dispatch) {
    dispatch(updateGovernmentStreetsFetchStatus(REQUEST_STATUSES.NULL));
    axios
      .get(url)
      .then((response) => {
        dispatch(updateGovernmentStreetsFetchStatus(REQUEST_STATUSES.DONE));
        dispatch(updateGovernmentStreets(response.data));
      })
      .catch((error) => {
        dispatch(updateGovernmentStreetsFetchStatus(REQUEST_STATUSES.ERROR));
        showNotification({
          response: error.response,
          type: 'error',
        });
      });
  };
};

const action = (type: any) => (payload: any) => ({ type, payload });
export const updateGovernmentProvinces = action(UPDATE_GOVERNMENT_PROVINCES);
export const updateGovernmentProvincesFetchStatus = action(GET_GOVERNMENT_PROVINCE_FETCH_STATUS);
export const updateGovernmentCounties = action(UPDATE_GOVERNMENT_COUNTIES);
export const updateGovernmentCountiesFetchStatus = action(GET_GOVERNMENT_COUNTY_FETCH_STATUS);
export const updateGovernmentMunicipalities = action(UPDATE_GOVERNMENT_MUNICIPALITIES);
export const updateGovernmentMunicipalitiesFetchStatus = action(
  GET_GOVERNMENT_MUNICIPALITY_FETCH_STATUS
);
export const updateGovernmentLocalities = action(UPDATE_GOVERNMENT_LOCALITIES);
export const updateGovernmentLocalitiesFetchStatus = action(GET_GOVERNMENT_LOCALITY_FETCH_STATUS);
export const updateGovernmentStreets = action(UPDATE_GOVERNMENT_STREETS);
export const updateGovernmentStreetsFetchStatus = action(GET_GOVERNMENT_STREET_FETCH_STATUS);
