import React from 'react';
import styled from 'styled-components'
import {useHistory} from "react-router-dom";

interface Props {

}

const Container = styled.div`
  width:100vw;
  max-width: 100%;
  height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-image: url('/images/bg.jpg');
  background-position: center center;
  background-size: cover;
`;

const LogoImg = styled.img`
  width:50%;
  max-width: 300px;
  object-fit: contain;
`;

const BadgeImg = styled.img`
  width: 125px;
`;

const DataContainer = styled.div`
`;

const CompanyInfo = styled.div`
`;

const IconRow = styled.div`
`;

const BottomLabel = styled.div`
  margin-bottom:30px;
  font-size:14px;
  &:hover {
    cursor: pointer;
  }
`;

const Info: React.FC<Props> = (props) => {
  let history = useHistory();

  return (
    <Container>
      <div>
        <LogoImg
          src="/images/ivoting-logo.png"
          alt=""
        />
      </div>
      <DataContainer>
        <CompanyInfo>
          <div><b>Carbonet.pl Sp. z o.o.</b></div>
          <div>ul. Cieszyńska 365,
            43-300 Bielsko-Biała
          </div>
          <div>REGON: 368637979 / NIP: 5472186055 <br/> KRS: 0000695975</div>
          <div>www.isurvey.pl / biuro@isurvey.pl</div>
          <div>Tel: 606 648 115</div>
        </CompanyInfo>
        <IconRow>
          <div><BadgeImg
            src="/images/google-play-icon.png"
            alt=""
          /></div>
          <div><BadgeImg
            src="/images/app-store-icon.png"
            alt=""
          /></div>
        </IconRow>
        <BottomLabel
          onClick={() => {
            history.push('/your-voice/contact-messages/add?type=2');
          }}
        ><b>Zgłoś uwagi do aplikacji</b></BottomLabel>
      </DataContainer>
    </Container>
  )
}

export default Info;

