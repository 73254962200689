import { connect } from 'react-redux';
import AuthorizedVotingRegistration from './AuthorizedVotingRegistration';
import { RouteComponentProps } from 'react-router';
import { loginUser, registerUser, getAuthInfo } from '../../reducers/Auth/auth.actions';
import { getSurveyDetail, resetSurveyState } from '../../reducers/Surveys/surveys.actions';
import { setEmailModalOpen, setPhoneModalOpen } from '../../reducers/Utils/utils.actions';

function mapStateToProps(state: any, otherProps: RouteComponentProps) {
  return {
    registerFetchStatus: state.auth.registerFetchStatus,
    errorTypes: state.auth.errorTypes,
    accountForSurveyVotingData: state.auth.accountForSurveyVoting.data,
    user: state.auth.user,
    surveyDetails: state.auth.accountForSurveyVoting.data,
    surveyDetailsFetchStatus: state.auth.accountForSurveyVoting.fetchStatus,
    token: state.auth.token,
    verifyEmailFetchStatus: state.auth.verifyEmailCode.fetchStatus,
    verifyPhoneFetchStatus: state.auth.verifyPhoneCode.fetchStatus,
    emailModalOpen: state.utils.emailModal,
    phoneModalOpen: state.utils.phoneModal,
  };
}

export default connect(mapStateToProps, {
  registerUser,
  getSurveyDetail,
  getAuthInfo,
  resetSurveyState,
  setPhoneModalOpen,
  setEmailModalOpen,
})(AuthorizedVotingRegistration);
