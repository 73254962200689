import React from 'react';
import { Trans } from 'react-i18next';
import ErrorMessage from './ErrorMessage';
import { FormTypes } from '../../types/form.types';
import i18next from 'i18next';
import styled from 'styled-components';
import { FormLabel, FormTextarea } from './forms';
import { useWatch } from 'react-hook-form';
import { useValidationParamValue } from '../../utils/yup/yupHelpers';
import useLettersCount from '../../utils/useLettersCount';

const MessageContainer = styled.div`
  display: flex;
  width: 90%;
  position: relative;
  align-items: center;
  justify-content: center;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  padding: 0 3rem;
`;

const LettersCounter = styled.div`
  margin-left: auto;
  position: absolute;
  right: 0;
  top: 0;
`;

interface InputTypes extends FormTypes {
  label: string;
  name: string;
  titleButton?: any;
  maxLength?: number;
  control: any;
  schema?: any;
  setValue?(name: string, value: any): void;
}

// Be careful with 'validate' !
const InputTextarea: React.FC<InputTypes> = (props) => {
  const {
    label,
    name,
    type = 'text',
    placeholder = '',
    formRef,
    fieldError,
    validate = {},
    formState,
    defaultValue,
    formType,
    showRequiredStar = true,
    titleButton,
    control,
    schema,
    setValue,
  } = props;
  const [lettersCount, maxLengthFromSchema] = useLettersCount({ name, control, schema });
  const maxLength = props.maxLength || maxLengthFromSchema;

  return (
    <>
      <FormLabel formType={formType}>
        <Trans>{label}</Trans>
        {validate.required || showRequiredStar ? '*' : ''}
        {titleButton ? titleButton : <></>}
      </FormLabel>
      <FormTextarea
        type={type}
        {...formRef(name, {
          ...validate,
          onChange: (e: any) => {
            if (typeof setValue === 'function' && maxLength) {
              const value = e.target.value;
              if (value.length > maxLength) setValue(name, value.slice(0, maxLength));
            }
          },
        })}
        placeholder={i18next.t(placeholder)}
        isDirty={formState.dirtyFields[name]}
        defaultValue={defaultValue}
        rows={3}
        formType={formType}
      />
      <MessageContainer>
        <StyledErrorMessage fieldError={fieldError} validate={validate} />
        {lettersCount != undefined && maxLength && (
          <LettersCounter>
            {lettersCount} / {maxLength}
          </LettersCounter>
        )}
      </MessageContainer>
    </>
  );
};

export default InputTextarea;
