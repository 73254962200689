export const surveysTypes: any = {
  simple: {
    type: 'simple',
    label: 'Poll',
    description: 'PollDescription',
  },
  extended_survey: {
    type: 'extended',
    label: 'ExtendedSurvey',
    description: 'ExtendedSurveyDescription',
  },
  picture_survey: {
    type: 'image',
    label: 'PictureSurvey',
    description: 'PictureSurveyDescription',
  },
  projects: {
    type: 'projects',
    label: 'Projects',
    description: 'ProjectsDescription',
  },
};
