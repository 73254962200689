import React, { ComponentType } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { getLoginPath } from '../../constants/paths.constants';

interface Props {
  component: ComponentType<any>;
  token: string | null;
  path: string;
}

const PrivateRoute: React.FC<Props> = ({ component: Component, token, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        token ? <Component {...props} /> : <Redirect to={{ pathname: getLoginPath() }} />
      }
    />
  );
};

export default PrivateRoute;
