import React from 'react';
import styled from 'styled-components';

interface Props {
    privacyPolicyTemplates: any;
}

const TextContainer = styled.div`
    margin: 10px 0;
    max-width: 600px;
    text-align: center;
    color: #bbb;
    font-size: 15px;
`;
  
const PrivacyPolicyNote: React.FC<Props> = (props) => {
    const { privacyPolicyTemplates } = props;
    return (
        <TextContainer>
            W ankiecie projektowej istnieje możliwość dodania w kolejnym kroku własnego dokumentu z polityką prywatności.&nbsp;
            {privacyPolicyTemplates && privacyPolicyTemplates.length > 0 && (
                <>
                    Możesz skorzystać z jednego z naszych szablonów:
                    {privacyPolicyTemplates.map((template: any, i: number) => (
                        <div key={`project-template-${i}`}>
                            <a href={template.file} target={'_blank'} style={{color: 'inherit'}}>{template.name}</a>
                        </div>
                    ))}
                </>
            )}
        </TextContainer>
    );
}

export default PrivacyPolicyNote;
