import React, { FC, ReactElement, useEffect } from 'react';
import {
  ModalContainer,
  ButtonsWrapper,
  ModalTitleIconWrapper,
  ModalButton,
  ModalText,
  ModalTitle,
} from './ModalPartials';
import { EmailTitleIcon } from './ModalTitleIcons';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Trans } from 'react-i18next';
import LinkButton from '../LinkButton';

export interface ModalProps {
  icon?: any | IconDefinition | string | undefined;
  iconComponent?: any;
  title?: string | ReactElement;
  text?: string | ReactElement;
  children?: any;
  textChildren?: any;
  onButtonClick?: () => void;
  buttonText?: string;
  open?: any;
  setOpen?: (arg1: boolean) => any;
  buttonConfirmText?: any;
  buttonCancelText?: any;
  buttonConfirmDisabled?: any;
  onButtonConfirmClick?: any;
  onButtonCancelClick?: any;
  customButtonComponent?: any;
  disableClose?: any;
  modalSize?: string;
  mainButtonForm?: string;
  onLinkButtonClick?: any;
  linkButtonText?: string;
  handleClose?: any;
}

const SimpleModal = (props: ModalProps) => {
  const {
    icon,
    iconComponent: IconComponent = EmailTitleIcon,
    title = '',
    text = '',
    children = <div></div>,
    onButtonClick,
    buttonText = 'Ok',
    open,
    setOpen = () => {},
    buttonConfirmText,
    buttonCancelText,
    buttonConfirmDisabled,
    onButtonConfirmClick,
    onButtonCancelClick,
    customButtonComponent,
    disableClose,
    textChildren,
    modalSize,
    mainButtonForm,
    onLinkButtonClick,
    linkButtonText,
    handleClose: customHandleClose,
  } = props;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (customHandleClose && typeof customHandleClose === 'function') customHandleClose();
    else setOpen(false);
  };

  useEffect(() => {});

  return (
    <ModalContainer
      open={open}
      setOpen={setOpen}
      disableClose={disableClose}
      modalSize={modalSize}
      handleClose={handleClose}
    >
      <ModalTitleIconWrapper>
        {icon ? <img src={icon} alt="Title" /> : <IconComponent />}
      </ModalTitleIconWrapper>

      {title && (
        <ModalTitle>
          <Trans>{title}</Trans>
        </ModalTitle>
      )}

      {(text || textChildren) && (
        <ModalText>{textChildren ? textChildren : <Trans>{text}</Trans>}</ModalText>
      )}

      {children}

      {buttonConfirmText && buttonCancelText && !customButtonComponent ? (
        <ButtonsWrapper>
          <ModalButton
            full
            disabled={buttonConfirmDisabled}
            onClick={onButtonConfirmClick || onButtonClick}
          >
            <Trans>{buttonConfirmText}</Trans>
          </ModalButton>
          <ModalButton full cancel onClick={onButtonCancelClick || handleClose}>
            <Trans>{buttonCancelText}</Trans>
          </ModalButton>
        </ButtonsWrapper>
      ) : customButtonComponent ? (
        <>{customButtonComponent}</>
      ) : (
        <ModalButton onClick={onButtonClick || handleClose} form={mainButtonForm}>
          <Trans>{buttonText}</Trans>
        </ModalButton>
      )}
      {onLinkButtonClick && <LinkButton onClick={onLinkButtonClick} text={linkButtonText} />}
    </ModalContainer>
  );
};

export default SimpleModal;
