import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import SurveyVote from './SurveyVote';
import {
  getSurveyDetail,
  getSurveyVotingDetail,
  getUserRequirementsForSurvey,
  postVote,
  resetSurveyDetail,
  resetVoteState,
  setPostVoteFetchError,
  setPostVoteFreeAnswersFetchError,
  setSurveyDetailFetchStatus,
} from '../../../reducers/Surveys/surveys.actions';
import { setIsQrCodeModalOpen } from '../../../reducers/Utils/utils.actions';
import { setUserNickname } from '../../../reducers/Auth/auth.actions';

type OtherProps = RouteComponentProps<{
  uuid: string;
}>;

function mapStateToProps(state: any, otherProps: OtherProps) {
  return {
    uuid: otherProps.match.params.uuid,
    survey: state.surveys.vote.detail,
    surveyFetchStatus: state.surveys.vote.detailFetchStatus,
    surveyFetchError: state.surveys.vote.detailFetchError,
    user: state.auth.user,
    token: state.auth.token,
    isQrCodeModalOpen: state.utils.isQrCodeModalOpen,
    setUserNicknameFetchStatus: state.auth.setUserNicknameFetchStatus,
    accountForSurveyVotingData: state.auth.accountForSurveyVoting?.data,
    postVoteFetchStatus: state.surveys.vote.fetchStatus,
    postVoteFreeAnswersFetchError: state.surveys.vote.postFreeAnswersFetchError,
    postVoteFetchError: state.surveys.vote.postFetchError,
    userRequirementsForSurvey: state.surveys.userRequirementsForSurvey.details,
    userRequirementsForSurveyFetchStatus: state.surveys.userRequirementsForSurvey.fetchStatus,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getSurveyVotingDetail,
    resetSurveyDetail,
    setIsQrCodeModalOpen,
    setUserNickname,
    setSurveyDetailFetchStatus,
    postVote,
    setPostVoteFreeAnswersFetchError,
    setPostVoteFetchError,
    getUserRequirementsForSurvey,
    resetVoteState,
  })(SurveyVote)
);
