export const filterAddressBeforeSave = (data: any) => {
  if (data?.extended_profile?.country && data?.extended_profile?.country?.toUpperCase() !== 'PL') {
    data.extended_profile.province_id = null;
    data.extended_profile.county_id = null;
    data.extended_profile.municipality_id = null;
    data.extended_profile.locality_id = null;
    data.extended_profile.street_id = null;
  } else if (data?.extended_profile?.country) {
    data.extended_profile.province = null;
    data.extended_profile.county = null;
    data.extended_profile.municipality = null;
    data.extended_profile.locality = null;
  }
  return data;
};

export const addCountryIfExtended = (data: any) => {
  if (data.extended_profile && data.extended_profile) {
    data.extended_profile.country = 'PL';
    return data;
  } else return data;
};

export const cropDataForBlockchain = (data: any) => {
  let dataToBlockchain = { ...data };
  delete dataToBlockchain.enable_email_notifications;
  delete dataToBlockchain.enable_push_notifications;
  delete dataToBlockchain.enable_sms_notifications;
  delete dataToBlockchain.picture;
  return dataToBlockchain;
};
