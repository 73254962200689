import { UPDATE_CONSTANTS, GET_CONSTANTS_FETCH_STATUS } from './constants.actionTypes';
import { Dispatch } from 'redux';
import { REQUEST_STATUSES } from '../../constants/requestStatuses.constants';
import axios from 'axios';

export const getConstants = () => {
  let url = process.env.REACT_APP_API_URL + `/constants/`;
  return function (dispatch: Dispatch) {
    dispatch(updateConstantsFetchStatus(REQUEST_STATUSES.NULL));
    axios
      .get(url)
      .then((response) => {
        dispatch(updateConstantsFetchStatus(REQUEST_STATUSES.DONE));
        dispatch(updateConstants(response.data));
      })
      .catch((error) => {
        dispatch(updateConstantsFetchStatus(REQUEST_STATUSES.ERROR));
      });
  };
};

const action = (type: any) => (payload: any) => ({ type, payload });
export const updateConstants = action(UPDATE_CONSTANTS);
export const updateConstantsFetchStatus = action(GET_CONSTANTS_FETCH_STATUS);
