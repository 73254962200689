export const colors = {
  lightBlue: '#1BD0E2',
  darkBlue: '#19A0AE',
  white: '#FFFFFF',
  black: '#000000',
  gray: '#BBBBBB',
  red: '#FF6868',
  lightGreen: '#9BBE72',
  darkGreen: '#5A8825',
  yellow: '#FFEF0A',
  brightGray: '#EBEBEB',
  lightGrayRBG: 'rgba(255,255,255, 0.7)',
  lightSeaGreen: '#1EA0AE',
  grayDescription: '#595959',
  grayBorder: '#CACACA',
  instruction: 'orange',
  bottomNavigationBg: '#F7F7F7',
};

export const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
  heightXS: '600px',
};

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
};

export const fontWeight = {
  light: 300,
  regular: 400,
  semiBold: 600,
  bold: 700,
  extraBold: 800,
};

export const fontSizes = {
  mobile: {
    h1: '1.875rem',
    h2: '1.25rem',
    h3: '1rem',
    h4: '0.8rem',
    h5: '0.625rem',
    p: '1.25rem',
  },
  desktop: {
    h1: '3.125rem',
    h2: '2rem',
    h3: '1.5rem',
    h4: '0.9375rem',
    h5: '0.9375rem',
    p: '1.875rem',
  },
};

export const sizes = {
  navbarHeightPxVal: 56,
  navbarHeightDesktopPxVal: 64,
  bottomNavHeightPxVal: 56,
  navbarHeight: 'calc(56px + env(safe-area-inset-top))',
  navbarHeightDesktop: 'calc(64px + env(safe-area-inset-top))',
  bottomNavHeight: 'calc(56px + env(safe-area-inset-bottom))',
  contentWidthVwVal: 80,
  maxContentWidthPxVal: 1000,
  // vh for mobile Safari
  getSafariWindowHeight: () =>
    document && document.documentElement
      ? document.documentElement.clientHeight
      : window.innerHeight,
  expansionPanelElementHeight: 53,
  appPaddingPxValSmall: 16,
  appPaddingPxVal: 32,
  cardPaddingPxVal: 15,
  routeContentPxVal: 30,
  maxSimpleModalWidthPxVal: 580,
  maxSimpleModalWidthPxValLg: '90vw',
  navbarIcons: 31
};

// used in build script
export const splash = {
  isSplashWithBgImg: false,
};
