import { Action } from '../../types/reducerAction.types';
import {
  SET_EMAIL_MODAL_OPEN,
  SET_IS_QR_CODE_MODAL_OPEN,
  SET_PHONE_MODAL_OPEN,
  TOGGLE_MENU,
} from './utils.actionTypes';

const INITIAL_STATE = {
  showMenu: false,
  isQrCodeModalOpen: false,
  phoneModal: { open: false, codeModalOpen: false, triggerRequest: false },
  emailModal: { open: false, triggerRequest: false },
};

export default function utils(state = INITIAL_STATE, action: Action) {
  switch (action.type) {
    case TOGGLE_MENU:
      return {
        ...state,
        showMenu: !state.showMenu,
      };
    case SET_IS_QR_CODE_MODAL_OPEN:
      return {
        ...state,
        isQrCodeModalOpen: action.payload,
      };
    case SET_PHONE_MODAL_OPEN:
      return {
        ...state,
        phoneModal: action.payload,
      };
    case SET_EMAIL_MODAL_OPEN:
      return {
        ...state,
        emailModal: action.payload,
      };
    default:
      return state;
  }
}
