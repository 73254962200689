import { connect } from 'react-redux';
import Login from './Login';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { loginUser } from '../../reducers/Auth/auth.actions';

function mapStateToProps(state: any, otherProps: RouteComponentProps) {
  return {
    loginFetchStatus: state.auth.loginFetchStatus,
    errorTypes: state.auth.errorTypes,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    loginUser,
  })(Login)
);
