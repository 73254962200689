import { REQUEST_STATUSES } from '../../constants/requestStatuses.constants';
import { Action } from '../../types/reducerAction.types';
import {
  GET_GOVERNMENT_COUNTY_FETCH_STATUS,
  GET_GOVERNMENT_LOCALITY_FETCH_STATUS,
  GET_GOVERNMENT_MUNICIPALITY_FETCH_STATUS,
  GET_GOVERNMENT_PROVINCE_FETCH_STATUS,
  GET_GOVERNMENT_STREET_FETCH_STATUS,
  UPDATE_GOVERNMENT_COUNTIES,
  UPDATE_GOVERNMENT_LOCALITIES,
  UPDATE_GOVERNMENT_MUNICIPALITIES,
  UPDATE_GOVERNMENT_PROVINCES,
  UPDATE_GOVERNMENT_STREETS,
} from './governmentLocation.actionTypes';

const INITIAL_STATE = {
  province: {
    list: [],
    fetchStatus: REQUEST_STATUSES.NULL,
    requested: null,
  },
  county: {
    list: [],
    fetchStatus: REQUEST_STATUSES.NULL,
    requested: null,
  },
  municipality: {
    list: [],
    fetchStatus: REQUEST_STATUSES.NULL,
    requested: null,
  },
  locality: {
    list: [],
    fetchStatus: REQUEST_STATUSES.NULL,
    requested: null,
  },
  street: {
    list: [],
    fetchStatus: REQUEST_STATUSES.NULL,
    requested: null,
  },
};

export default function location(state = INITIAL_STATE, action: Action) {
  switch (action.type) {
    case UPDATE_GOVERNMENT_PROVINCES:
      return {
        ...state,
        province: { ...state.province, list: action.payload },
        county: { ...INITIAL_STATE.county },
        municipality: { ...INITIAL_STATE.municipality },
        locality: { ...INITIAL_STATE.locality },
        street: { ...INITIAL_STATE.street },
      };
    case GET_GOVERNMENT_PROVINCE_FETCH_STATUS:
      return {
        ...state,
        province: { ...state.province, fetchStatus: action.payload },
      };
    case UPDATE_GOVERNMENT_COUNTIES:
      return {
        ...state,
        county: { ...state.county, list: action.payload },
        municipality: { ...INITIAL_STATE.municipality },
        locality: { ...INITIAL_STATE.locality },
        street: { ...INITIAL_STATE.street },
      };
    case GET_GOVERNMENT_COUNTY_FETCH_STATUS:
      return {
        ...state,
        county: { ...state.county, fetchStatus: action.payload },
      };
    case UPDATE_GOVERNMENT_MUNICIPALITIES:
      return {
        ...state,
        municipality: { ...state.municipality, list: action.payload },
        locality: { ...INITIAL_STATE.locality },
        street: { ...INITIAL_STATE.street },
      };
    case GET_GOVERNMENT_MUNICIPALITY_FETCH_STATUS:
      return {
        ...state,
        municipality: { ...state.municipality, fetchStatus: action.payload },
      };
    case UPDATE_GOVERNMENT_LOCALITIES:
      return {
        ...state,
        locality: { ...state.locality, list: action.payload },
        street: { ...INITIAL_STATE.street },
      };
    case GET_GOVERNMENT_LOCALITY_FETCH_STATUS:
      return {
        ...state,
        locality: { ...state.locality, fetchStatus: action.payload },
      };
    case UPDATE_GOVERNMENT_STREETS:
      return {
        ...state,
        street: { ...state.street, list: action.payload },
      };
    case GET_GOVERNMENT_STREET_FETCH_STATUS:
      return {
        ...state,
        street: { ...state.street, fetchStatus: action.payload },
      };
    default:
      return state;
  }
}
