import React from 'react';
import { SurveyListH1, SurveyTypeDescription } from '../../../components/Typography/typography';
import { Trans } from 'react-i18next';
import { SurveyTypeButton } from '../../../components/Buttons/buttons';
import {
  getCreateExtendedSurveyPath,
  getCreateImageSurveyPath,
  getCreateProjectPath,
  getCreateSimpleSurveyPath,
} from '../../../constants/paths.constants';
import { useHistory } from 'react-router-dom';
import { surveysTypes } from '../surveys.config';

interface Props {
  setStep(step: any): any;
}

const NewSurveyTypeSelect: React.FC<Props> = ({ setStep }) => {
  const history = useHistory();

  const handleChooseTypeClick = (survey: any) => {
    // todo: refactor, delete steps
    setStep(1);
    if (survey.type === 'simple') {
      history.push(getCreateSimpleSurveyPath());
    }
    if (survey.type === 'extended') {
      history.push(getCreateExtendedSurveyPath());
    }
    if (survey.type === 'image') {
      history.push(getCreateImageSurveyPath());
    }
    if (survey.type === 'projects') {
      history.push(getCreateProjectPath({ step: 1 }));
    }
  };

  return (
    <>
      <SurveyListH1>
        <Trans>Choose</Trans>
      </SurveyListH1>
      {surveysTypes &&
        Object.values(surveysTypes).map((survey: any, key) => (
          <React.Fragment key={key}>
            <SurveyTypeButton onClick={() => handleChooseTypeClick(survey)}>
              <Trans>{survey.label}</Trans>
            </SurveyTypeButton>
            <SurveyTypeDescription>
              <Trans>{survey.description}</Trans>
            </SurveyTypeDescription>
          </React.Fragment>
        ))}
    </>
  );
};

export default NewSurveyTypeSelect;
