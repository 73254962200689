import React from 'react';
import styled from 'styled-components';
import {
  faMobile,
  faEnvelopeOpenText,
  faTimesCircle,
  faLock,
  faCheckCircle,
  faQrcode,
  faUser,
  faTrash,
  faFileExcel,
  faHandPaper,
  faUsers,
  faExclamationTriangle,
  faInfoCircle,
  faCheckSquare,
  faEllipsisH,
  faClock,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import * as theme from '../../theme';

const TitleIconContainer = styled.div`
  svg {
    min-height: 50px;
    min-width: 50px;
    .white {
      fill: ${theme.colors.white} !important;
    }
  }
`;

interface Props {
  icon: any;
}

const TitleIcon: React.FC<Props> = (props) => {
  const { icon } = props;
  return (
    <TitleIconContainer>
      <FontAwesomeIcon icon={icon} />
    </TitleIconContainer>
  );
};

export const PhoneTitleIcon = () => TitleIcon({ icon: faMobile });
export const EmailTitleIcon = () => TitleIcon({ icon: faEnvelopeOpenText });
export const LockTitleIcon = () => TitleIcon({ icon: faLock });
export const CheckTitleIcon = () => TitleIcon({ icon: faCheckCircle });
export const InvalidAuthorizationTitleIcon = () => TitleIcon({ icon: faTimesCircle });
export const QrCodeTitleIcon = () => TitleIcon({ icon: faQrcode });
export const UserTitleIcon = () => TitleIcon({ icon: faUser });
export const DeleteTitleIcon = () => TitleIcon({ icon: faTrash });
export const ExcelTitleIcon = () => TitleIcon({ icon: faFileExcel });
export const HandTitleIcon = () => TitleIcon({ icon: faHandPaper });
export const UsersTitleIcon = () => TitleIcon({ icon: faUsers });
export const AlertTitleIcon = () => TitleIcon({ icon: faExclamationTriangle });
export const InfoIcon = () => TitleIcon({ icon: faInfoCircle });
export const SquareCheck = () => TitleIcon({ icon: faCheckSquare });
export const EllipsisIcon = () => TitleIcon({ icon: faEllipsisH });
export const ClockIcon = () => TitleIcon({ icon: faClock });
