import { Action } from '../../types/reducerAction.types';
import {
  INVITE_MORE_FETCH_STATUS,
  PROCESS_CSV_FETCH_STATUS,
  SET_NEW_GROUP_WITH_CONTACTS,
  SET_NEW_GROUP_WITH_CONTACTS_FETCH_STATUS,
  UPDATE_COORDINATORS_GROUPS_DETAIL,
  UPDATE_COORDINATORS_GROUPS_DETAIL_FETCH_STATUS,
  UPDATE_COORDINATORS_GROUPS_LIST,
  UPDATE_COORDINATORS_GROUPS_LIST_FETCH_STATUS,
  UPDATE_GROUP_FETCH_STATUS,
  UPDATE_GROUPS_DETAIL,
  UPDATE_GROUPS_LIST,
  UPDATE_I_BELONG_TO_GROUPS_LIST,
  UPDATE_I_BELONG_TO_GROUPS_LIST_FETCH_STATUS,
  UPDATE_LEAVE_GROUP_FETCH_STATUS,
} from './groups.actionTypes';
import { REQUEST_STATUSES } from '../../constants/requestStatuses.constants';

const INITIAL_STATE = {
  list: [],
  detail: {},
  coordinators: {
    list: {
      items: [],
      fetchStatus: REQUEST_STATUSES.NULL,
    },
    detail: {
      item: {},
      fetchStatus: REQUEST_STATUSES.NULL,
    },
  },
  belongTo: {
    list: {
      items: [],
      fetchStatus: REQUEST_STATUSES.NULL,
      leaveFetchStatus: REQUEST_STATUSES.NULL,
    },
  },
  inviteMoreFetchStatus: null,
  updateGroupFetchStatus: null,
  processCSVFetchStatus: null,
  newGroupWithContacts: null,
  newGroupWithContactsFetchStatus: null,
};

export default function groups(state = INITIAL_STATE, action: Action) {
  switch (action.type) {
    case UPDATE_GROUPS_LIST:
      return {
        ...state,
        list: action.payload,
      };
    case UPDATE_GROUPS_DETAIL:
      return {
        ...state,
        detail: action.payload,
      };
    case UPDATE_COORDINATORS_GROUPS_LIST:
      return {
        ...state,
        coordinators: {
          ...state.coordinators,
          list: { ...state.coordinators.list, items: action.payload },
        },
      };
    case UPDATE_I_BELONG_TO_GROUPS_LIST:
      return {
        ...state,
        belongTo: {
          ...state.belongTo,
          list: { ...state.belongTo.list, items: action.payload },
        },
      };
    case UPDATE_COORDINATORS_GROUPS_DETAIL:
      return {
        ...state,
        coordinators: {
          ...state.coordinators,
          detail: { ...state.coordinators.detail, item: action.payload },
        },
      };
    case UPDATE_COORDINATORS_GROUPS_LIST_FETCH_STATUS:
      return {
        ...state,
        coordinators: {
          ...state.coordinators,
          list: { ...state.coordinators.list, fetchStatus: action.payload },
        },
      };
    case UPDATE_COORDINATORS_GROUPS_DETAIL_FETCH_STATUS:
      return {
        ...state,
        coordinators: {
          ...state.coordinators,
          detail: { ...state.coordinators.detail, fetchStatus: action.payload },
        },
      };
    case UPDATE_I_BELONG_TO_GROUPS_LIST_FETCH_STATUS:
      return {
        ...state,
        belongTo: {
          ...state.belongTo,
          list: { ...state.belongTo.list, fetchStatus: action.payload },
        },
      };
    case UPDATE_LEAVE_GROUP_FETCH_STATUS:
      return {
        ...state,
        belongTo: {
          ...state.belongTo,
          list: { ...state.belongTo.list, leaveFetchStatus: action.payload },
        },
      };
    case INVITE_MORE_FETCH_STATUS:
      return {
        ...state,
        inviteMoreFetchStatus: action.payload,
      };
    case UPDATE_GROUP_FETCH_STATUS:
      return {
        ...state,
        updateGroupFetchStatus: action.payload,
      };
    case PROCESS_CSV_FETCH_STATUS:
      return {
        ...state,
        processCSVFetchStatus: action.payload,
      };
    case SET_NEW_GROUP_WITH_CONTACTS:
      return {
        ...state,
        newGroupWithContacts: action.payload,
      };
    case SET_NEW_GROUP_WITH_CONTACTS_FETCH_STATUS:
      return {
        ...state,
        newGroupWithContactsFetchStatus: action.payload,
      };
    default:
      return state;
  }
}
