import {connect} from 'react-redux';
import Nickname from "./Nickname";
import {withRouter} from 'react-router-dom';
import {RouteComponentProps} from "react-router";

function mapStateToProps(state: any, otherProps: RouteComponentProps) {
  return {};
}

export default withRouter(connect(
  mapStateToProps,
  {}
)(Nickname));
