import { connect } from 'react-redux';
import BaseTemplate from './BaseTemplate';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { setIsQrCodeModalOpen, toggleMenu } from '../../reducers/Utils/utils.actions';
import { getAuthInfo, syncTokenWithStore } from '../../reducers/Auth/auth.actions';
import {
  getNotifications,
  getNotificationsSummary,
  postMarkAllNotificationsAsRead,
} from '../../reducers/Notifications/notifications.actions';

function mapStateToProps(state: any, otherProps: RouteComponentProps) {
  return {
    showMenu: state.utils.showMenu,
    user: state.auth.user,
    token: state.auth.token,
    notificationsCount: state.notifications.summary.unread_count,
    notificationsItems: state.notifications.list.results,
    notificationsListData: state.notifications.list,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    toggleMenu,
    syncTokenWithStore,
    getAuthInfo,
    setIsQrCodeModalOpen,
    getNotificationsSummary,
    getNotifications,
    postMarkAllNotificationsAsRead,
  })(BaseTemplate)
);
