import React, { useEffect } from 'react';
import { FormLabel } from '../../../../components/Forms/forms';
import Checkbox from '../../../../components/Forms/Checkbox';
import { useWatch } from 'react-hook-form';
import InputText from '../../../../components/Forms/InputText';
import InputFile from '../../../../components/Forms/InputFile';
import { UseFormReturn } from 'react-hook-form/dist/types/form';
import styled from 'styled-components';
import { colors } from '../../../../theme';

interface Props {
  formMethods: UseFormReturn;
}

const PrivacyPolicy: React.FC<Props> = (props) => {
  const { formMethods } = props;
  const { register, formState, control, setValue } = formMethods;
  const { errors } = formState;

  const watchPrivacyPolicy = useWatch({ name: 'use_custom_privacy_policy', control });

  return (
    <>
      <FormLabel formType={'survey'}>Polityka prywatności</FormLabel>
      <div style={{ width: '90%' }}>
        <Checkbox
          label={'Dodaj własną politykę prywatności'}
          name={'use_custom_privacy_policy'}
          formRef={register}
          formState={formState}
          popoverText={"Przed oddaniem głosu, użytkownik będzie musiał zaakceptować treść polityki prywatności."}
        />
        {watchPrivacyPolicy && <div>
            <InputFile
              label={'Załącz własny plik'}
              name={'privacy_policy'}
              formRef={register}
              formState={formState}
              control={control}
              setValue={setValue}
            />
        </div>}
      </div>
    </>
  );
};

export default PrivacyPolicy;
