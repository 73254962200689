import React, { useEffect, useState } from 'react';
import { MainHeader } from '../../utils/uiComponents';
import { useForm, useWatch } from 'react-hook-form';
import { ResetPasswordData, UserData, UserDataInputs } from '../../types/auth.types';
import InputText from '../../components/Forms/InputText';
import CheckboxSwitch from '../../components/Forms/CheckboxSwitch';
import { colors } from '../../theme';
import { Trans } from 'react-i18next';
import InputReadOnly from '../../components/Forms/InputReadOnly';
import InputEmail from '../../components/Forms/InputEmail';
import InputImage from '../../components/Forms/InputImage';
import { postMedia } from '../../reducers/Media/media.actions';
import { MyLocationState } from '../../utils/history';
import { Center, FlexContainer, Form } from '../../components/Containers/containers';
import { PrimarySmallButton } from '../../components/Buttons/buttons';
import InputPhone from '../../components/Forms/InputPhone';
import DeleteAccountButton from '../../components/DeleteAccountButton';
import AuthorizeLink from './AuthorizeLink';
import BlockchainButton from './BlockchainButton';
import ExtendedProfileSwitch from './ExtendedProfileSwitch';
import ExtendedProfilePart from './ExtendedProfilePart';
import { REQUEST_STATUSES } from '../../constants/requestStatuses.constants';
import { getMyProfilePath, getSurveyPath } from '../../constants/paths.constants';
import { deletePublicSurveyUid, getPublicSurveyUid } from '../../utils/localStorage';
import { useHistory, useLocation } from 'react-router-dom';
import VerifyPhoneCodeModal from '../VerifyPhoneCodeModal';
import DownloadAppButton from '../../components/DownloadAppButton';
import SimpleModal, { ModalProps } from '../../components/Modal/SimpleModal';
import { HandTitleIcon } from '../../components/Modal/ModalTitleIcons';
import { yupResolver } from '@hookform/resolvers/yup';
import { getMyProfileSchema } from './profileValidation';
import {
  extendedProfileModalContent,
  extendedProfileModalTitle,
  extendedWhenParams,
  formColor,
  noStreets,
} from './profile.config';
import {
  Attention,
  AuthenticateNotification,
  PhotosContainer,
  ProfileTypeHeader,
} from './MyProfilePartials';
import {
  addCountryIfExtended,
  cropDataForBlockchain,
  filterAddressBeforeSave,
} from './profileHelpers';

interface Props {
  user: UserData;
  getAuthInfo(state?: MyLocationState | undefined): void;
  updateUser(data: UserDataInputs, user: UserData): void;
  updateUserPhone(data: { phone: string }): void;
  sendEmailConfirmation(data: { email: string }): void;
  postMedia(media: any): Promise<any>;
  resetPassword(data: ResetPasswordData): void;
  setIsQrCodeModalOpen(flag: boolean): void;
  deleteMyAccount(): void;
  resetUserFetchStatus(): void;
  generateBlockchainKeys: any;
  setBlockchainFetchStatus: any;
  blockchainKeys: { public_key: string | null; private_key: string | null };
  blockchainFetchStatus: string | null;
  setUpdateUserFetchStatus: any;
  updateUserFetchStatus: string | null;
  generateQRCode: any;
  getBlockchainStatement: any;
  getSurveyDetail: any;
  setPhoneModalOpen: any;
  phoneModalOpen: any;
  countriesOptions?: any;
  streets?: any;
  userPatchFetchError?: any;
  setUpdateUserFetchError?: any;
  deleteMyAccountFetchStatus?: string | null;
  logout?: any;
  FCMDeviceInfo?: any;
}

const MyProfile: React.FC<Props> = ({
  user,
  updateUser,
  updateUserPhone,
  sendEmailConfirmation,
  resetPassword,
  generateBlockchainKeys,
  blockchainKeys,
  blockchainFetchStatus,
  setBlockchainFetchStatus,
  setIsQrCodeModalOpen,
  setUpdateUserFetchStatus,
  updateUserFetchStatus,
  generateQRCode,
  getBlockchainStatement,
  deleteMyAccount,
  getSurveyDetail,
  setPhoneModalOpen,
  phoneModalOpen,
  countriesOptions,
  streets,
  userPatchFetchError,
  setUpdateUserFetchError,
  deleteMyAccountFetchStatus,
  logout,
  getAuthInfo,
  resetUserFetchStatus,
  FCMDeviceInfo,
}) => {
  const [extendedChecked, setExtendedChecked] = useState<any>(user.extended_profile);
  const [blockchainDataToSave, setBlockchainDataToSave] = useState<any>(null);
  const [isSimpleModalOpen, setIsSimpleModalOpen] = useState<boolean>(false);
  const [simpleModalConfig, setSimpleModalConfig] = useState<ModalProps>();
  const [dynamicRequired, setDynamicRequired] = useState<{
    all: Array<string> | null | undefined;
    basic: Array<string> | null | undefined;
    extended: Array<string> | null | undefined;
  }>({
    all: null,
    basic: null,
    extended: null,
  });
  const history = useHistory();
  const { search, pathname } = useLocation();

  const getRequiredAsObject = () => {
    let req: any = {};
    dynamicRequired?.all?.forEach((reqKey: string) => (req[reqKey] = true));
    return req;
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    formState,
    setError,
    setFocus,
    clearErrors,
    control,
    setValue,
    getValues,
  } = useForm<UserDataInputs>({
    defaultValues: {
      email: user.email,
      phone: user.phone,
      enable_email_notifications: user.enable_email_notifications,
      enable_push_notifications: user.enable_push_notifications,
      enable_sms_notifications: user.enable_sms_notifications,
      extended_profile: user.extended_profile,
    },
    resolver: (data, context: any, opt) => {
      return yupResolver(getMyProfileSchema(context))(data, context, opt);
    },
    context: {
      ...getRequiredAsObject(),
      isExtendedChecked: extendedChecked,
      // this values wii be present in profile is it was already saved
      wasExtendedSaved: user.first_name && user.last_name && user?.extended_profile?.nationality,
    },
  });

  const watchPhone = useWatch({
    name: 'phone',
    defaultValue: user.phone,
    control,
  });
  const extendedProfileAgreement = useWatch({
    name: 'extended_profile.agreement',
    defaultValue: false,
    control,
  });
  const watchCountry: any = useWatch({
    name: 'extended_profile.country',
    defaultValue: user.extended_profile?.country || 'PL',
    control,
  });
  const isPolishAddress = watchCountry?.toUpperCase() === 'PL';

  const getPhoneWithPlus = (phone: string): string => {
    return phone && !phone.includes('+') ? `+${phone}` : phone;
  };

  const canAuthorizePhone =
    !!user.phone && getPhoneWithPlus(watchPhone) === getPhoneWithPlus(user.phone);

  useEffect(() => {
    if (extendedProfileAgreement) {
      setSimpleModalConfig({
        iconComponent: HandTitleIcon,
        title: extendedProfileModalTitle,
        text: extendedProfileModalContent,
        buttonConfirmText: 'Akceptuj',
        buttonCancelText: 'Anuluj',
        onButtonConfirmClick: () => {
          setIsSimpleModalOpen(false);
        },
        handleClose: () => {
          setValue('extended_profile.agreement', false);
          setIsSimpleModalOpen(false);
        },
      });
      setIsSimpleModalOpen(true);
    }
  }, [extendedProfileAgreement]);

  useEffect(() => {
    if (!userPatchFetchError) return;

    const setAllErrors = (objFrom: any, prefix?: string) => {
      for (let [errorKey, errorValue] of Object.entries(objFrom)) {
        if (errorKey === 'extended_profile') {
          setAllErrors(errorValue, 'extended_profile');
        } else {
          const [error] = errorValue as Array<string>;
          // @ts-ignore
          setError(`${prefix ? prefix + '.' : ''}${errorKey}`, {
            type: 'manual',
            message: error,
          });
        }
      }
    };
    setAllErrors(userPatchFetchError);

    const setFocusToElement = () => {
      const errorKeys = Object.keys(userPatchFetchError)[0];
      for (let errorKey of errorKeys) {
        const firstKey = Object.keys(userPatchFetchError)[0];
        if (firstKey !== 'non_field_errors' && firstKey !== 'extended_profile') {
          try {
            // @ts-ignore
            setFocus(firstKey);
          } catch (e) {
            console.error(e);
          }
          break;
        }
      }
    };
    setFocusToElement();
  }, [userPatchFetchError]);

  useEffect(() => {
    if (user && user.email) setValue('email', user.email);
  }, [user && user.email]);

  useEffect(() => {
    if (blockchainDataToSave && updateUserFetchStatus === REQUEST_STATUSES.DONE) {
      generateBlockchainKeys(blockchainDataToSave, user);
      setUpdateUserFetchStatus(null);
      setBlockchainDataToSave(null);
    } else if (blockchainDataToSave && updateUserFetchStatus === REQUEST_STATUSES.ERROR) {
      setTimeout(() => {
        setUpdateUserFetchStatus(null);
        setBlockchainDataToSave(null);
      }, 200);
    }
    if (updateUserFetchStatus === REQUEST_STATUSES.DONE) {
      redirectToPublicSurveyIfPossible();
    }
  }, [updateUserFetchStatus, blockchainDataToSave]);

  useEffect(() => {
    if (updateUserFetchStatus !== REQUEST_STATUSES.DONE) return;
    const searchParams = new URLSearchParams(search);
    let requiredValidations: any = searchParams.get('required');
    if (requiredValidations) requiredValidations = requiredValidations.split(',');
    const surveyToGo = getPublicSurveyUid();
    if (
      updateUserFetchStatus === REQUEST_STATUSES.DONE &&
      requiredValidations?.length &&
      surveyToGo
    ) {
      history.push(`${getSurveyPath()}/${surveyToGo}/vote`);
    }
  }, [updateUserFetchStatus]);

  useEffect(() => {
    if (blockchainFetchStatus === REQUEST_STATUSES.DONE) {
      setBlockchainFetchStatus(REQUEST_STATUSES.NULL);
      generateQRCode({ key: blockchainKeys.public_key });
    }
  }, [blockchainFetchStatus]);

  useEffect(() => {
    getAuthInfo();
    return () => {
      setUpdateUserFetchError(null);
    };
  }, []);

  // this is real unmount, because if redirect from unauthpath profile comp is remounting
  // problem probably because not Layout component above that fetch user date before entering here
  useEffect(() => {
    return () => {
      if (history.location.pathname !== getMyProfilePath()) {
        resetUserFetchStatus();
        deletePublicSurveyUid();
      }
    };
  }, [pathname]);

  // when user registered after invitation to survey
  const redirectToPublicSurveyIfPossible = () => {
    let publicSurveyUid = getPublicSurveyUid();
    if (publicSurveyUid) {
      deletePublicSurveyUid();
      history.push(`${getSurveyPath()}/${publicSurveyUid}/vote`);
    }
  };

  // temporary here to assign survey to new registered user
  const [wasPublicSurveyReq, setWasPublicSurveyReq] = useState<any>(false);
  useEffect(() => {
    !wasPublicSurveyReq && requestPublicSurveyIfPossible();
  }, [user]);

  const requestPublicSurveyIfPossible = () => {
    let publicSurveyUid = getPublicSurveyUid();
    if (publicSurveyUid) {
      setWasPublicSurveyReq(true);
      getSurveyDetail(publicSurveyUid, !!user);
    }
  };

  const onSubmit = async (data: UserDataInputs) => {
    data.phone = getPhoneWithPlus(data.phone);
    if (!isExtendedSaved && !extendedChecked) delete data.extended_profile;

    if (user.qr) delete data.extended_profile; // required to change notifications after extended profile is readonly

    if (data?.extended_profile?.no_street === null) data.extended_profile.no_street = false;
    if (data?.extended_profile?.street_id === noStreets) delete data.extended_profile.street_id;
    if (data?.extended_profile?.street === noStreets) delete data.extended_profile.street;
    if (data.extended_profile?.agreement13YO) delete data.extended_profile?.agreement13YO;

    if (data?.picture && data.picture[0]) {
      const response = await postMedia(data.picture[0]);
      data.picture = response.id;
    } else {
      delete data.picture;
    }
    if (data?.avatar && data.avatar[0]) {
      const response = await postMedia(data.avatar[0]);
      data.avatar = response.id;
    } else {
      delete data.avatar;
    }
    updateUser(filterAddressBeforeSave(data), user);
  };

  const authenticate = (key: 'phone' | 'email') => {
    if (key === 'phone') {
      if (!watchPhone) {
        setError('phone', { type: 'required' });
      } else {
        setPhoneModalOpen({ triggerRequest: true, open: false, codeModalOpen: true });
      }
    }
    if (key === 'email') {
      sendEmailConfirmation({ email: user.email });
    }
  };

  const parseTestFCMInfo = (data: any) => {
    try {
      return JSON.stringify({
        registration_id: FCMDeviceInfo.registration_id,
        device_id: FCMDeviceInfo.device_id,
        name: FCMDeviceInfo.name,
      });
    } catch (err) {
      console.error(err);
      return '';
    }
  };

  const verifyPhoneCallback = () => {
    setPhoneModalOpen({ triggerRequest: false, open: false, codeModalOpen: false });
    getAuthInfo();
  };

  const handleBlockchainButtonClick = () => {
    // save data, if ok save on blockchain, then generate qr
    let data: any = getValues();
    setUpdateUserFetchStatus(null);
    onSubmit({ ...data, extended_profile: { ...data.extendedProfile, agreement: true } });
    setBlockchainDataToSave(cropDataForBlockchain(data));
  };

  const handleAuthorizePhoneClick = (e: Event) => {
    e.preventDefault();
    if (canAuthorizePhone) {
      authenticate('phone');
    }
    if (!canAuthorizePhone) {
      setSimpleModalConfig({
        iconComponent: HandTitleIcon,
        title: 'Zapisz dane',
        text: `Przed dokonaniem autoryzacji telefonu sprawdź poprawność danych wprowadzonych w Mój profil i kliknij przycisk Zapisz u dołu strony.`,
        buttonText: 'Zamknij',
      });
      setIsSimpleModalOpen(true);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(search);
    const requiredFromParams = params.get('required')?.split(',');
    let forceExtended = false;
    for (let extendedWhenParam of extendedWhenParams) {
      if (requiredFromParams?.includes(extendedWhenParam)) {
        forceExtended = true;
        break;
      }
    }
    setExtendedChecked(forceExtended);
    setDynamicRequired({
      all: requiredFromParams,
      basic: requiredFromParams?.includes('name') ? ['name'] : null,
      extended: requiredFromParams?.filter((item) => extendedWhenParams.includes(item)),
    });
  }, [search]);

  const isExtendedSaved = user.extended_profile && Object.values(user.extended_profile).length;

  useEffect(() => {
    if (isExtendedSaved) setExtendedChecked(isExtendedSaved);
  }, [isExtendedSaved]);

  useEffect(() => {
    console.warn(errors);
  }, [errors]);

  const areFieldsFinal = !!(blockchainKeys?.private_key || user.qr);

  return (
    <FlexContainer bgDark={true}>
      {isSimpleModalOpen && (
        <SimpleModal
          open={isSimpleModalOpen}
          setOpen={setIsSimpleModalOpen}
          {...simpleModalConfig}
        />
      )}
      <VerifyPhoneCodeModal
        verifyCallback={verifyPhoneCallback}
        open={phoneModalOpen.open}
        codeModalOpen={phoneModalOpen.codeModalOpen}
        triggerRequest={phoneModalOpen.triggerRequest}
        handleCodeClose={() => {
          setPhoneModalOpen({ triggerRequest: false, open: false, codeModalOpen: false });
        }}
      />
      <Center>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <MainHeader>
            <Trans>MyProfile</Trans>
          </MainHeader>
          {(!user.is_email_verified || !user.is_phone_verified) && (
            <AuthenticateNotification>
              <Attention>
                <Trans>Attention</Trans>!
              </Attention>{' '}
              <Trans>AuthenticateNotificationToAuthenticate</Trans>
            </AuthenticateNotification>
          )}
          {user.is_email_verified && user.is_phone_verified && (
            <AuthenticateNotification>
              <Trans>Authenticated account</Trans>
            </AuthenticateNotification>
          )}

          <PhotosContainer>
            <div>
              <InputImage
                label={'Photo'}
                name={'picture'}
                formRef={register}
                formState={formState}
                defaultValue={user.picture && user.picture.image}
                borderColor={'#ffffff'}
                color={'#ffffff'}
                control={control}
              />
            </div>

            <div>
              <InputImage
                label={'Avatar'}
                name={'avatar'}
                formRef={register}
                formState={formState}
                defaultValue={user.avatar && user.avatar.image}
                borderColor={'#ffffff'}
                color={'#ffffff'}
                control={control}
              />
            </div>
          </PhotosContainer>

          <ProfileTypeHeader>
            <Trans>BasicProfileData</Trans>
          </ProfileTypeHeader>

          <InputText
            label={'VotingNick'}
            showRequiredStar={true}
            name={'voting_nick'}
            formRef={register}
            fieldError={errors?.voting_nick}
            formState={formState}
            defaultValue={user.voting_nick}
            borderColor={formColor}
            color={formColor}
            caretColor={formColor}
            final={areFieldsFinal}
            disabled={!!user.voting_nick}
            setValue={setValue}
            disabledValue={(!!user.voting_nick && user?.voting_nick) || null}
          />

          <InputPhone
            control={control}
            label={'Phone'}
            showRequiredStar
            name={'phone'}
            formRef={register}
            fieldError={errors?.phone}
            formState={formState}
            validate={{ required: true }}
            borderColor={'#ffffff'}
            color={'#ffffff'}
            caretColor={'#ffffff'}
            disabled={user.is_phone_verified}
            defaultValue={user.phone}
            final={areFieldsFinal}
            setValue={setValue}
          />

          {!user.is_phone_verified && (
            <AuthorizeLink onClick={handleAuthorizePhoneClick} isDimmed={!canAuthorizePhone}>
              <Trans>AuthorizePhone</Trans>
            </AuthorizeLink>
          )}

          {!user.is_email_verified &&
          (!user.email || user.email?.length === 0) &&
          !areFieldsFinal ? (
            <InputEmail
              label={'Email'}
              showRequiredStar
              name={'email'}
              formRef={register}
              fieldError={errors.email}
              formState={formState}
              validate={{ required: true }}
              borderColor={'#ffffff'}
              color={'#ffffff'}
              caretColor={'#ffffff'}
              final={areFieldsFinal}
            />
          ) : (
            user.email?.length > 0 && (
              <InputReadOnly
                showRequiredStar
                formRef={register}
                defaultValue={user.email}
                color={'#ffffff'}
                final={areFieldsFinal}
              />
            )
          )}

          {user.email && !user.is_email_verified && (
            <>
              <AuthorizeLink
                onClick={(e: Event) => {
                  e.preventDefault();
                  authenticate('email');
                }}
              >
                <Trans>Authorize</Trans>
              </AuthorizeLink>
            </>
          )}

          <InputText
            label={'Organization'}
            name={'organization'}
            formRef={register}
            fieldError={errors?.organization}
            formState={formState}
            defaultValue={user.organization}
            borderColor={'#ffffff'}
            color={'#ffffff'}
            caretColor={'#ffffff'}
            final={areFieldsFinal}
            disabled={!!user.organization}
          />

          {!extendedChecked && (
            <>
              <InputText
                label={'FirstName'}
                name={'first_name'}
                formRef={register}
                fieldError={
                  errors?.first_name?.type !== 'required' || dynamicRequired.basic?.includes('name')
                    ? errors?.first_name
                    : undefined
                }
                formState={formState}
                defaultValue={user.first_name}
                borderColor={'#ffffff'}
                color={'#ffffff'}
                caretColor={'#ffffff'}
                final={areFieldsFinal}
                disabled={!!user.first_name}
                setValue={setValue}
                disabledValue={user.first_name}
                showRequiredStar={dynamicRequired?.basic?.includes('name')}
              />
              <InputText
                label={'LastName'}
                name={'last_name'}
                formRef={register}
                fieldError={
                  errors?.first_name?.type !== 'required' || dynamicRequired.basic?.includes('name')
                    ? errors?.first_name
                    : undefined
                }
                formState={formState}
                defaultValue={user.last_name}
                borderColor={'#ffffff'}
                color={'#ffffff'}
                caretColor={'#ffffff'}
                final={areFieldsFinal}
                disabled={!!user.last_name}
                setValue={setValue}
                disabledValue={user.last_name}
                showRequiredStar={dynamicRequired?.basic?.includes('name')}
              />
            </>
          )}

          <ExtendedProfileSwitch
            checked={extendedChecked}
            setChecked={dynamicRequired?.extended?.length ? () => {} : setExtendedChecked}
            disabled={isExtendedSaved}
          />

          {user && extendedChecked && (
            <ExtendedProfilePart
              register={register}
              formState={formState}
              user={user}
              control={control}
              getValues={getValues}
              setValue={setValue}
              areFieldsFinal={areFieldsFinal}
              errors={errors}
              isPolishAddress={isPolishAddress}
              countriesOptions={countriesOptions}
              setError={setError}
              clearErrors={clearErrors}
            />
          )}

          <BlockchainButton
            user={user}
            blockchainFetchStatus={blockchainFetchStatus}
            blockchainKeys={blockchainKeys}
            onClick={handleBlockchainButtonClick}
            forceCloseModals={
              updateUserFetchStatus === REQUEST_STATUSES.ERROR && blockchainDataToSave
            }
          />

          {user.is_blockchain_verified && (
            <PrimarySmallButton
              type="button"
              borderColor={colors.white}
              bgColor={colors.white}
              color={colors.lightBlue}
              onClick={(e: Event) => {
                e.preventDefault();
                getBlockchainStatement('user');
              }}
            >
              <Trans>DownloadStatement</Trans>
            </PrimarySmallButton>
          )}

          {user.is_phone_verified &&
            user.is_email_verified &&
            blockchainKeys.public_key &&
            !user.is_blockchain_verified && (
              <PrimarySmallButton
                type="button"
                disabled={!blockchainKeys.public_key}
                borderColor={colors.white}
                bgColor={colors.lightBlue}
                color={colors.white}
                onClick={(e: Event) => {
                  e.preventDefault();
                  setIsQrCodeModalOpen(true);
                }}
              >
                <Trans>GenerateQR</Trans>
              </PrimarySmallButton>
            )}

          <MainHeader>
            <Trans>Settings</Trans>
          </MainHeader>

          <CheckboxSwitch
            label={'EmailNotifications'}
            name={'enable_email_notifications'}
            formRef={register}
            control={control}
            formState={formState}
          />

          <CheckboxSwitch
            label={'PushNotifications'}
            name={'enable_push_notifications'}
            formRef={register}
            control={control}
            formState={formState}
          />
          <CheckboxSwitch
            label={'SmsNotifications'}
            name={'enable_sms_notifications'}
            formRef={register}
            control={control}
            formState={formState}
          />
          {!user.is_blockchain_verified && (
            <PrimarySmallButton
              type="submit"
              borderColor={colors.white}
              bgColor={colors.white}
              color={colors.lightBlue}
            >
              <Trans>Save</Trans>
            </PrimarySmallButton>
          )}

          <DownloadAppButton
            borderColor={colors.white}
            bgColor={colors.white}
            color={colors.lightBlue}
            style={{ marginTop: 30, marginBottom: 30 }}
          />

          {user.email && (
            <PrimarySmallButton
              type="button"
              borderColor={colors.white}
              bgColor={colors.white}
              color={colors.lightBlue}
              onClick={(e: Event) => {
                e.preventDefault();
                resetPassword({ email: user.email });
              }}
            >
              <Trans>ResetPassword</Trans>
            </PrimarySmallButton>
          )}
        </Form>
        <DeleteAccountButton
          deleteMyAccount={deleteMyAccount}
          deleteMyAccountFetchStatus={deleteMyAccountFetchStatus}
          logout={logout}
        />
      </Center>
    </FlexContainer>
  );
};

export default MyProfile;
