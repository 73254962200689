import React, { useEffect, useState } from 'react';
import { ExcelTitleIcon } from './Modal/ModalTitleIcons';
import SimpleModal from './Modal/SimpleModal';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { useForm, Controller, useWatch } from 'react-hook-form';
import Select from 'react-select';
import { Trans } from 'react-i18next';
import { ModalButton } from './Modal/ModalPartials';
import {
  processCSV,
  setProcessCSVFetchStatus,
  setUpdateGroupFetchStatus,
} from '../reducers/Groups/groups.actions';
import { REQUEST_STATUSES } from '../constants/requestStatuses.constants';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Table = styled.div`
  display: flex;
  overflow-x: scroll;
  max-width: 100%;
  width: fit-content;
  justify-content: flex-start;
  align-items: stretch;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #555555;
  border-radius: 10px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 1px solid #555555;

  &:first-child {
    border-left: none;
  }
`;

const TableHeader = styled.div`
  padding: 0.5rem 0.25rem;
  background-color: #eeeeee;
`;

const TableRow = styled.div`
  padding: 0.5rem 0.5rem;
`;

const SelectsContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  align-items: center;
  justify-content: center;
`;

const SelectContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const SelectLabel = styled.span`
  display: inline-block;
  width: 5rem;
`;

const StyledSelect = styled(Select)`
  & .select__control {
    width: 20rem;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: center;
`;

const RelativeContainer = styled.div`
  position: relative;
`;

const ErrorText = styled.span`
  position: absolute;
  bottom: -1rem;
  color: red;
  left: 0;
  font-size: 0.75rem;
`;

interface Props {
  isOpen: boolean;
  setIsOpen(flag: any): void;
  CSVData: {
    available_fields: {
      [key: string]: Array<string>;
    };
    id: number;
  };
  processCSV(data: { [key: string]: any }): void;
  uuid: string;
  updateGroupFetchStatus: string;
  processCSVFetchStatus: string;
  setUpdateGroupFetchStatus(status: string | null): void;
  setProcessCSVFetchStatus(status: string | null): void;
}

interface Inputs {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  nickname: string;
}

const SelectWithLabel = (props: any) => {
  const { label, name, validate, availableSelectOptions, control, error, watchAll } = props;
  const [options, setOptions] = useState<any>(availableSelectOptions);

  useEffect(() => {
    const selectedOptions: Array<string | undefined> =
      (watchAll &&
        Object.entries(watchAll)
          .map(([key, value]: any): string | undefined => {
            if (key !== name) return value;
          })
          .filter((value) => value)) ||
      [];
    const newOptions = availableSelectOptions.filter(
      (option: { label: string; value: string }) =>
        !selectedOptions.includes(option.value) && option.value
    );
    setOptions(newOptions);
  }, [watchAll]);

  const getErrorText = (type: string) => {
    let text = '';
    switch (type) {
      case 'required':
        {
          text = 'To pole jest wymagane';
        }
        break;
      case 'duplicate':
        {
          text = 'Kolumna o tej nazwie została już przypisana';
        }
        break;
    }
    return text;
  };

  return (
    <SelectContainer>
      <SelectLabel>
        {label}
        {validate && validate.required ? '*' : ''}
      </SelectLabel>
      <Controller
        defaultValue={null}
        control={control}
        name={name}
        render={({ field }) => (
          <RelativeContainer>
            <StyledSelect
              inputRef={field.ref}
              classNamePrefix={'select'}
              options={options}
              isClearable={true}
              isSearchable={true}
              value={options.find((option: any) => option.value === field.value)}
              onChange={(val: any) => {
                field.onChange(val && val.value);
              }}
              placeholder={'Wybierz nazwę kolumny'}
              noOptionsMessage={() => 'Brak dostępnych kolumn'}
            />
            {error && <ErrorText>{getErrorText(error.type)}</ErrorText>}
          </RelativeContainer>
        )}
      />
    </SelectContainer>
  );
};

const CSVPreviewModal: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  CSVData,
  processCSV,
  uuid,
  updateGroupFetchStatus,
  processCSVFetchStatus,
  setUpdateGroupFetchStatus,
  setProcessCSVFetchStatus,
}) => {
  const availableSelectOptions = Object.keys(CSVData.available_fields).map((key) => ({
    value: key,
    label: key,
  }));

  const {
    handleSubmit,
    setError,
    formState: { errors },
    control,
  }: any = useForm<Inputs>();

  const watchAll = useWatch({
    control,
  });

  const closeModal = () => {
    setIsOpen(null);
    setUpdateGroupFetchStatus(REQUEST_STATUSES.NULL);
    setProcessCSVFetchStatus(REQUEST_STATUSES.NULL);
  };

  useEffect(() => {
    if (updateGroupFetchStatus === REQUEST_STATUSES.DONE) closeModal();
  }, [updateGroupFetchStatus]);

  const findDuplicates = (arr: any) => {
    const filtered = arr.filter((item: any, index: any) => arr.indexOf(item) !== index);
    return [...new Set(filtered)];
  };

  const onSubmit = (data: Inputs) => {
    const { email, first_name, last_name, phone, nickname } = data;
    let isValid = true;
    if (!email) {
      isValid = false;
      setError('email', { type: 'required' });
    }
    const duplicates = findDuplicates(Object.values(data)).filter((value) => value);
    if (duplicates.length > 0) {
      duplicates.forEach((duplicate) => {
        Object.entries(data).forEach(([key, value]) => {
          if (value && value === duplicate) {
            setError(key, { type: 'duplicate' });
          }
        });
      });
      isValid = false;
    }
    if (isValid) {
      const payload = {
        uuid: uuid,
        csv_request: CSVData.id,
        name: [first_name, last_name].filter((value) => value),
        email: email,
        phone: phone,
        nickname: nickname,
      };
      processCSV(payload);
    }
  };

  return (
    <SimpleModal
      title={'Import kontaktów z CSV'}
      text={
        'Przypisz nazwy kolumn do odpowiednich danych. Poniżej znajduje się podgląd części zaimportowanego przez Ciebie pliku CSV.'
      }
      customButtonComponent={<></>}
      open={isOpen}
      iconComponent={ExcelTitleIcon}
      modalSize={'xl'}
      children={
        <Form onSubmit={handleSubmit(onSubmit)}>
          <SelectsContainer>
            <SelectWithLabel
              label={'Imię'}
              name={'first_name'}
              availableSelectOptions={availableSelectOptions}
              control={control}
              error={errors.first_name}
              watchAll={watchAll}
            />
            <SelectWithLabel
              label={'Nazwisko'}
              name={'last_name'}
              availableSelectOptions={availableSelectOptions}
              control={control}
              error={errors.last_name}
              watchAll={watchAll}
            />
            <SelectWithLabel
              label={'Email'}
              name={'email'}
              availableSelectOptions={availableSelectOptions}
              control={control}
              validate={{ required: true }}
              error={errors.email}
              watchAll={watchAll}
            />
            <SelectWithLabel
              label={'Telefon'}
              name={'phone'}
              availableSelectOptions={availableSelectOptions}
              control={control}
              error={errors.phone}
              watchAll={watchAll}
            />
            {/*<SelectWithLabel*/}
            {/*  label={'Pseudonim'}*/}
            {/*  name={'nickname'}*/}
            {/*  availableSelectOptions={availableSelectOptions}*/}
            {/*  control={control}*/}
            {/*  error={errors.nickname}*/}
            {/*  watchAll={watchAll}*/}
            {/*/>*/}
          </SelectsContainer>
          <Table>
            {Object.entries(CSVData.available_fields).map(([key, values]) => {
              return (
                <Column key={key}>
                  <TableHeader>{key ? key : `-`}</TableHeader>
                  {values.map((value, index) => {
                    return <TableRow key={index}>{value ? value : '-'}</TableRow>;
                  })}
                </Column>
              );
            })}
          </Table>

          <ButtonsWrapper>
            <ModalButton
              type={'submit'}
              disableMargin
              disabled={
                processCSVFetchStatus === REQUEST_STATUSES.FETCHING ||
                updateGroupFetchStatus === REQUEST_STATUSES.FETCHING
              }
            >
              <Trans>Importuj</Trans>
            </ModalButton>
            <ModalButton
              onClick={() => {
                closeModal();
              }}
              cancel
              disableMargin
            >
              Anuluj
            </ModalButton>
          </ButtonsWrapper>
        </Form>
      }
    />
  );
};

function mapStateToProps(state: any) {
  return {
    updateGroupFetchStatus: state.groups.updateGroupFetchStatus,
    processCSVFetchStatus: state.groups.processCSVFetchStatus,
  };
}

export default connect(mapStateToProps, {
  processCSV,
  setUpdateGroupFetchStatus,
  setProcessCSVFetchStatus,
})(CSVPreviewModal);
