import React, { useEffect } from 'react';
import Counter from '../../containers/Surveys/NewSurveyExtended/Counter';
import { useWatch } from 'react-hook-form';

interface Props {
  setValue: any;
  register: any;
  control: any;
  name: string;
  fieldsLength: number;
  className?: string;
}

const RequiredAnswersCounters: React.FC<Props> = (props) => {
  const { setValue, register, control, name, fieldsLength, className } = props;

  const nameOfMinRequiredAnswersField = `${name}.min_required_answers`;
  const nameOfMaxRequiredAnswersField = `${name}.max_required_answers`;

  const watchMinRequiredAnswers = useWatch({
    name: nameOfMinRequiredAnswersField,
    control,
    defaultValue: 1,
  });
  const watchMaxRequiredAnswers = useWatch({
    name: nameOfMaxRequiredAnswersField,
    control,
    defaultValue: 1,
  });

  useEffect(() => {
    if (watchMinRequiredAnswers > fieldsLength) {
      setValue(nameOfMinRequiredAnswersField, fieldsLength || 1);
    }
    if (watchMaxRequiredAnswers > fieldsLength) {
      setValue(nameOfMaxRequiredAnswersField, fieldsLength || 1);
    }
  }, [fieldsLength]);

  return (
    <div className={className}>
      <Counter
        setValue={(value) => setValue(nameOfMinRequiredAnswersField, value)}
        minCount={1}
        maxCount={watchMaxRequiredAnswers}
        text={'Minimalna liczba wymaganych odpowiedzi'}
        register={register}
        name={nameOfMinRequiredAnswersField}
        value={watchMinRequiredAnswers}
      />
      <Counter
        setValue={(value) => setValue(nameOfMaxRequiredAnswersField, value)}
        minCount={watchMinRequiredAnswers}
        maxCount={fieldsLength}
        text={'Maksymalna liczba wymaganych odpowiedzi'}
        register={register}
        name={nameOfMaxRequiredAnswersField}
        value={watchMaxRequiredAnswers}
      />
    </div>
  );
};

export default RequiredAnswersCounters;
