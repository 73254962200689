import React, { useEffect } from 'react';
import { FormLabel } from '../../../../components/Forms/forms';
import Checkbox from '../../../../components/Forms/Checkbox';
import { useWatch } from 'react-hook-form';
import InputText from '../../../../components/Forms/InputText';
import { UseFormReturn } from 'react-hook-form/dist/types/form';

interface Props {
  formMethods: UseFormReturn;
}

const AdditionalSettings: React.FC<Props> = (props) => {
  const { formMethods } = props;
  const { register, formState, control, setValue } = formMethods;
  const { errors } = formState;

  const watchInstructionVideoCheckbox = useWatch({ name: 'instruction_video_checkbox', control });

  useEffect(() => {
    if (!watchInstructionVideoCheckbox) {
      setValue('instruction_link', 'https://www.youtube.com/watch?v=An50vLxW9JM');
    }
  }, [watchInstructionVideoCheckbox]);

  return (
    <>
      <FormLabel formType={'survey'}>Ustawienia dodatkowe</FormLabel>
      <div style={{ width: '90%' }}>
        <Checkbox
          label={'Protokół tylko dla Organizatora'}
          name={'is_results_only_for_owner'}
          formRef={register}
          formState={formState}
        />
        <Checkbox
          label={'Głosuj z użyciem karty do głosowania'}
          name={'is_voting_card_vote'}
          formRef={register}
          formState={formState}
        />
        <Checkbox
          label={'showOnPage'}
          name={'show_on_page'}
          formRef={register}
          formState={formState}
          popoverText={'showOnPageDescription'}
        />
        <Checkbox
          label={'ShowResultsAfterVotedDescription'}
          name={'show_results_after_voted'}
          formRef={register}
          formState={formState}
        />
        <Checkbox
          label={'SendVoteConfirmation'}
          name={'send_vote_confirmations'}
          formRef={register}
          formState={formState}
        />
        <Checkbox
          label={'Dodaj własny link do filmu instruktażowego'}
          name={'instruction_video_checkbox'}
          formRef={register}
          formState={formState}
        />
        {watchInstructionVideoCheckbox && (
          <InputText
            label={'Link do filmu instruktażowego'}
            name={'instruction_link'}
            formRef={register}
            formState={formState}
            formType={'survey'}
            fieldError={errors.instruction_link}
          />
        )}
      </div>
    </>
  );
};

export default AdditionalSettings;
