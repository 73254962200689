import React, { FC, useEffect } from 'react';
import InputText from '../../components/Forms/InputText';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  getGovernmentProvinces,
  getGovernmentCounties,
  getGovernmentStreets,
  getGovernmentLocalities,
  getGovernmentMunicipalities,
} from '../../reducers/GovernmentLocation/governmentLocation.actions';
import SearchSelect from './SearchSelect';
import { useWatch } from 'react-hook-form';
import useFirstRender from '../../hooks/useFirstRender';
import { noLocalitiesErrorMessage, noStreets, noStreetsErrorMessage } from './profile.config';
import NoStreetsSwitch from './NoStreetsSwitch';
import { REQUEST_STATUSES } from '../../constants/requestStatuses.constants';

interface ExtendedProfilePlAddressProps {
  register?: any;
  formState?: any;
  user?: any;
  formColor?: any;
  areFieldsFinal?: any;
  errors?: any;
  control?: any;
  setValue: any;

  getGovernmentProvinces: any;
  getGovernmentCounties: any;
  getGovernmentMunicipalities: any;
  getGovernmentStreets: any;
  getGovernmentLocalities: any;
  provinces: any;
  municipalities: any;
  counties: any;
  localities: any;
  streets: any;
  municipalityKinds: any;
  noStreetsChecked?: any;
  setError: any;
  localitiesFetchStatus: string | null;
  streetsFetchStatus: string | null;
  clearErrors: any;
}

const getNoOptionsMessage = (type = 'województwo') => `Wybierz ${type}, aby pobrać dostępne opcje`;

const mapToChoices = (
  dataSet?: Array<any>,
  options?: { kind?: boolean; localityCount?: boolean; kinds?: any; streetCount?: any }
) => {
  return (
    dataSet &&
    dataSet.map(
      ({
        id,
        name,
        symbol,
        locality_count,
        street_count,
        kind,
      }: {
        id: number;
        name: string;
        symbol: string;
        locality_count: number;
        street_count: number;
        kind: string;
      }) => {
        const sublabel =
          `${options?.kind && kind ? ' ' + getKind({ kind, kinds: options?.kinds }) : ''}` +
          `${options?.localityCount && locality_count ? ' (' + locality_count + ')' : ''}` +
          `${options?.streetCount && street_count ? ' (' + street_count + ')' : ''}`;
        return {
          value: id,
          label: name,
          sublabel,
        };
      }
    )
  );
};

const getKind = ({ kind, kinds }: { kind: any; kinds: any }) => {
  return (kinds && kind && kinds[kind]) || '';
};

const ExtendedProfilePlAddress: FC<ExtendedProfilePlAddressProps> = (props) => {
  const {
    register,
    formState,
    user,
    formColor,
    areFieldsFinal,
    errors,
    getGovernmentProvinces,
    getGovernmentCounties,
    getGovernmentMunicipalities,
    getGovernmentStreets,
    getGovernmentLocalities,
    provinces,
    municipalities,
    counties,
    localities,
    streets,
    control,
    setValue,
    municipalityKinds,
    noStreetsChecked,
    setError,
    localitiesFetchStatus,
    streetsFetchStatus,
    clearErrors,
  } = props;

  const isFirstRender = useFirstRender();

  const selectedProvince = useWatch({
    name: 'extended_profile.province_id',
    control,
    defaultValue: user.extended_profile?.province_id,
  });

  const selectedCounty = useWatch({
    name: 'extended_profile.county_id',
    control,
    defaultValue: user.extended_profile?.county_id,
  });

  const selectedMunicipality = useWatch({
    name: 'extended_profile.municipality_id',
    control,
    defaultValue: user.extended_profile?.municipality_id,
  });

  const selectedLocality = useWatch({
    name: 'extended_profile.locality_id',
    control,
    defaultValue: user.extended_profile?.locality_id,
  });

  const selectedStreet = useWatch({
    name: 'extended_profile.street_id',
    control,
    defaultValue: user.extended_profile?.street_id,
  });

  useEffect(() => {
    if (!isFirstRender) setValue('extended_profile.no_street', false);
  }, [selectedLocality]);

  useEffect(() => {
    if (!localities.length && localitiesFetchStatus === REQUEST_STATUSES.DONE) {
      setError('extended_profile.municipality_id', {
        type: 'manual',
        message: noLocalitiesErrorMessage,
      });
    } else if (localities.length && selectedMunicipality) {
      clearErrors('extended_profile.municipality_id');
    }
  }, [localities, selectedMunicipality]);

  useEffect(() => {
    if (!noStreetsChecked && !streets.length && streetsFetchStatus === REQUEST_STATUSES.DONE) {
      setError('extended_profile.locality_id', {
        type: 'manual',
        message: noStreetsErrorMessage,
      });
    } else if ((noStreetsChecked || streets.length) && selectedLocality) {
      clearErrors('extended_profile.locality_id');
    }
  }, [streets, selectedLocality, noStreetsChecked]);

  useEffect(() => {
    getGovernmentProvinces();
  }, []);

  useEffect(() => {
    const provinceId = selectedProvince;
    if (provinces.length > 0 && selectedProvince) {
      getGovernmentCounties({ province: provinceId });
    }
  }, [provinces, selectedProvince]);

  useEffect(() => {
    const countyId = selectedCounty;
    if (counties.length > 0 && countyId) {
      getGovernmentMunicipalities({ county: countyId });
    }
  }, [counties, selectedCounty]);

  useEffect(() => {
    const municipalityId = selectedMunicipality;
    if (municipalities.length > 0 && municipalityId) {
      getGovernmentLocalities({ municipality: municipalityId });
    }
  }, [municipalities, selectedMunicipality]);

  useEffect(() => {
    const localityId = selectedLocality;
    if (localities.length > 0 && localityId) {
      getGovernmentStreets({ locality: localityId });
    }
  }, [localities, selectedLocality]);

  useEffect(() => {
    if (selectedProvince && !isFirstRender) {
      setValue('extended_profile.county_id', null);
      setValue('extended_profile.municipality_id', null);
      setValue('extended_profile.locality_id', null);
      setValue('extended_profile.street_id', null);
    }
  }, [selectedProvince]);

  useEffect(() => {
    if (selectedCounty && !isFirstRender) {
      setValue('extended_profile.municipality_id', null);
      setValue('extended_profile.locality_id', null);
      setValue('extended_profile.street_id', null);
    }
  }, [selectedCounty]);

  useEffect(() => {
    if (selectedMunicipality && !isFirstRender) {
      setValue('extended_profile.locality_id', null);
      setValue('extended_profile.street_id', null);
    }
  }, [selectedMunicipality]);

  useEffect(() => {
    if (selectedLocality && !isFirstRender) {
      setValue('extended_profile.street_id', null);
    }
  }, [selectedLocality]);

  const noAvailableStreets =
    localities?.find((locality: any) => locality.id === selectedLocality)?.street_count === 0;

  return (
    <>
      <SearchSelect
        label={'Province'}
        showRequiredStar
        name={'extended_profile.province_id'}
        defaultValue={user.extended_profile?.province_id}
        borderColor={formColor}
        color={formColor}
        caretColor={formColor}
        final={areFieldsFinal}
        fieldError={errors?.extended_profile?.province_id}
        options={mapToChoices(provinces)}
        control={control}
        formRef={register}
      />

      <SearchSelect
        label={'District'}
        showRequiredStar
        name={'extended_profile.county_id'}
        defaultValue={user.extended_profile?.county_id}
        borderColor={formColor}
        color={formColor}
        caretColor={formColor}
        final={areFieldsFinal}
        fieldError={errors?.extended_profile?.county_id}
        options={mapToChoices(counties)}
        control={control}
        noOptionsMessage={getNoOptionsMessage('województwo')}
        formRef={register}
      />

      <SearchSelect
        label={'Community'}
        showRequiredStar
        name={'extended_profile.municipality_id'}
        defaultValue={user.extended_profile?.municipality_id}
        borderColor={formColor}
        color={formColor}
        caretColor={formColor}
        final={areFieldsFinal}
        fieldError={errors?.extended_profile?.municipality_id}
        options={mapToChoices(municipalities, {
          localityCount: true,
          kind: true,
          kinds: municipalityKinds,
        })}
        control={control}
        noOptionsMessage={getNoOptionsMessage('powiat')}
        formRef={register}
      />

      <SearchSelect
        label={'Locality'}
        showRequiredStar
        name={'extended_profile.locality_id'}
        defaultValue={user.extended_profile?.locality_id}
        borderColor={formColor}
        color={formColor}
        caretColor={formColor}
        final={areFieldsFinal}
        fieldError={errors?.extended_profile?.locality_id}
        options={mapToChoices(localities, { streetCount: true })}
        control={control}
        noOptionsMessage={getNoOptionsMessage('gminę')}
        formRef={register}
      />

      {(noAvailableStreets || errors?.extended_profile?.no_street) && selectedLocality && (
        <NoStreetsSwitch
          label={noStreets}
          name={'extended_profile.no_street'}
          formRef={register}
          formState={formState}
          defaultValue={errors?.extended_profile?.no_street}
          final={!!areFieldsFinal}
          disabled={areFieldsFinal}
          fieldError={errors?.extended_profile?.no_street}
          control={control}
        />
      )}

      <SearchSelect
        label={'Street'}
        showRequiredStar
        name={'extended_profile.street_id'}
        defaultValue={user.extended_profile?.street_id}
        borderColor={formColor}
        color={formColor}
        caretColor={formColor}
        final={areFieldsFinal}
        fieldError={errors?.extended_profile?.street_id}
        options={mapToChoices(streets)}
        control={control}
        noOptionsMessage={getNoOptionsMessage('miejscowość')}
        formRef={register}
        disabled={noStreetsChecked}
        disabledValue={noStreetsChecked && noStreets}
        setValue={setValue}
      />
    </>
  );
};

function mapStateToProps(state: any, otherProps: RouteComponentProps) {
  return {
    provinces: state.governmentLocation.province.list,
    counties: state.governmentLocation.county.list,
    municipalities: state.governmentLocation.municipality.list,
    localities: state.governmentLocation.locality.list,
    localitiesFetchStatus: state.governmentLocation.locality.fetchStatus,
    streets: state.governmentLocation.street.list,
    streetsFetchStatus: state.governmentLocation.street.fetchStatus,
    municipalityKinds: state.constants.details?.municipality_types_pl,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getGovernmentProvinces,
    getGovernmentCounties,
    getGovernmentMunicipalities,
    getGovernmentStreets,
    getGovernmentLocalities,
  })(ExtendedProfilePlAddress)
);
