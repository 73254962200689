import styled from "styled-components";
import {device} from "../../theme";

export const SurveyListH1 = styled.h1`
  color: #000;
  font-weight: bold;
  font-size: 40px;
  text-transform: uppercase;
  text-align: center;
  
  margin-top: 0;
  margin-bottom: 26px;
  
  @media ${device.laptop} { 
    font-size: 50px;
  }
`

export const GroupsListH1 = styled(SurveyListH1)`
  margin-top: 0px;
  margin-bottom: 0px;
`;

export const SurveyTypeDescription = styled.span`
  font-size: 15px;
  color: #BBBBBB;
  margin-top: 4px;
  text-align: center;
`

export const SurveyInputLabel = styled.label`
  
`

export const TextEllipsis = styled.div`
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`
