import React from 'react';
import styled from 'styled-components';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useHistory } from 'react-router-dom';
import { colors, sizes } from '../theme';

const AbsoluteContainer = styled.div`
  position: absolute;
  z-index: 9999;
  top: 0px;
  left: 0px;
  width: ${sizes.navbarHeightDesktopPxVal}px;
  height: ${sizes.navbarHeightDesktopPxVal}px;
  cursor: pointer;

  svg {
    color: ${colors.gray};
    width: 60px;
    height: 60px;
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const DrawerBackButton: any = (props: any) => {
  const { backPath, onClick } = props;
  const history = useHistory();

  const handleClick = () => {
    if (onClick) onClick();
    else backPath ? history.push(backPath) : history.goBack();
  };

  return (
    <Container onClick={handleClick}>
      <ChevronLeftIcon />
    </Container>
  );
};

const AbsoluteDrawerBackButton: any = (props: any) => {
  const { isBackVisible = false } = props;

  return (
    <>
      {isBackVisible ? (
        <AbsoluteContainer>{<DrawerBackButton {...props} />}</AbsoluteContainer>
      ) : (
        <></>
      )}
    </>
  );
};

export { AbsoluteDrawerBackButton };
export default DrawerBackButton;
