import React, { FC } from 'react';
import styled from 'styled-components';
import InputText from '../../components/Forms/InputText';
import ProfileDatePicker from '../../components/Forms/ProfileDatePicker';
import moment from 'moment';
import InputReadOnly from '../../components/Forms/InputReadOnly';
import InputPostalCode from '../../components/Forms/InputPostalCode';
import ExtendedProfileSwitch from './ExtendedProfileSwitch';
import { extendedProfileAgreement } from './profile.config';
import ExtendedProfilePlAddress from './ExtendedProfilePlAddress';
import { useWatch } from 'react-hook-form';
import ExtendedProfileForeignAddress from './ExtendedProfileForeignAddress';
import SearchSelect from './SearchSelect';

const formColor = '#fff';

export const extendedProfileFields = {
  government_id: {
    label: 'PESEL',
  },
  birth_date: {
    label: 'BirthDate',
  },
  street: {
    label: 'Street',
  },
  house_number: {
    label: 'HouseNumber',
  },
  apartment_number: {
    label: 'ApartmentNumber',
  },
  postal_code: {
    label: 'PostalCode',
  },
  locality: {
    label: 'Locality',
  },
  country: {
    label: 'Country',
  },
};

const ExtendedProfilePart = (props) => {
  const {
    register,
    formState,
    user,
    control,
    getValues,
    areFieldsFinal,
    errors,
    isPolishAddress = true,
    countriesOptions,
    setValue,
    ...otherProps
  } = props;

  const noStreetsChecked = useWatch({
    name: 'extended_profile.no_street',
    control,
    defaultValue: user.extended_profile?.no_street,
  });

  return (
    <>
      <InputText
        label={'FirstName'}
        showRequiredStar
        name={'first_name'}
        formRef={register}
        fieldError={errors?.first_name}
        formState={formState}
        defaultValue={user.first_name}
        borderColor={'#ffffff'}
        color={'#ffffff'}
        caretColor={'#ffffff'}
        final={areFieldsFinal}
        disabled={user.first_name}
        setValue={setValue}
        disabledValue={user.first_name}
      />
      <InputText
        label={'LastName'}
        showRequiredStar
        name={'last_name'}
        formRef={register}
        fieldError={errors?.last_name}
        formState={formState}
        defaultValue={user.last_name}
        borderColor={'#ffffff'}
        color={'#ffffff'}
        caretColor={'#ffffff'}
        final={areFieldsFinal}
        disabled={user.last_name}
        setValue={setValue}
        disabledValue={user.last_name}
      />

      <SearchSelect
        label={'Nationality'}
        showRequiredStar
        name={'extended_profile.nationality'}
        defaultValue={user?.extended_profile?.nationality}
        borderColor={formColor}
        color={formColor}
        caretColor={formColor}
        final={areFieldsFinal}
        fieldError={errors?.extended_profile?.nationality}
        options={countriesOptions}
        control={control}
        formRef={register}
        disabled={user.extended_profile?.nationality}
      />

      <ProfileDatePicker
        label="BirthDate"
        showRequiredStar
        name="extended_profile.birth_date"
        register={register}
        control={control}
        getValues={getValues}
        initialFocusedDate={moment(new Date()).subtract(18, 'years').format('YYYY-MM-DD')}
        borderColor={formColor}
        color={formColor}
        caretColor={formColor}
        defaultValue={user.extended_profile?.birth_date}
        final={areFieldsFinal}
        formRef={register}
        fieldError={errors?.extended_profile?.birth_date}
        disabled={user.extended_profile?.birth_date}
        disabledValue={user.extended_profile?.birth_date}
        setValue={setValue}
      />

      <ExtendedProfileSwitch
        label={'Min13YOAgreement'}
        name={'extended_profile.agreement13YO'}
        formRef={register}
        formState={formState}
        defaultValue={!!user.extended_profile?.birth_date}
        final={!!areFieldsFinal}
        disabled={user.extended_profile?.birth_date}
        fieldError={errors?.extended_profile?.agreement13YO}
        control={control}
      />

      <SearchSelect
        label={'Country'}
        showRequiredStar
        name={'extended_profile.country'}
        defaultValue={user.extended_profile?.country}
        borderColor={formColor}
        color={formColor}
        caretColor={formColor}
        final={areFieldsFinal}
        fieldError={errors?.extended_profile?.country}
        options={countriesOptions}
        control={control}
        formRef={register}
      />

      {isPolishAddress ? (
        <ExtendedProfilePlAddress
          formState={formState}
          formColor={formColor}
          register={register}
          user={user}
          areFieldsFinal={areFieldsFinal}
          errors={errors}
          control={control}
          noStreetsChecked={noStreetsChecked}
          setValue={setValue}
          {...otherProps}
        />
      ) : (
        <ExtendedProfileForeignAddress
          formState={formState}
          formColor={formColor}
          register={register}
          user={user}
          areFieldsFinal={areFieldsFinal}
          errors={errors}
          control={control}
          noStreetsChecked={noStreetsChecked}
          setValue={setValue}
          {...otherProps}
        />
      )}

      <InputText
        label={'HouseNumber'}
        showRequiredStar
        name={'extended_profile.house_number'}
        formRef={register}
        formState={formState}
        defaultValue={user.extended_profile?.house_number}
        borderColor={formColor}
        color={formColor}
        caretColor={formColor}
        final={areFieldsFinal}
        fieldError={errors?.extended_profile?.house_number}
      />

      <InputText
        label={'ApartmentNumber'}
        name={'extended_profile.apartment_number'}
        formRef={register}
        formState={formState}
        defaultValue={user.extended_profile?.apartment_number}
        borderColor={formColor}
        color={formColor}
        caretColor={formColor}
        final={areFieldsFinal}
        fieldError={errors?.extended_profile?.apartment_number}
      />

      <InputPostalCode
        label={'PostalCode'}
        showRequiredStar
        name={'extended_profile.postal_code'}
        formRef={register}
        formState={formState}
        defaultValue={user.extended_profile?.postal_code}
        borderColor={formColor}
        color={formColor}
        caretColor={formColor}
        final={areFieldsFinal}
        control={control}
        fieldError={errors?.extended_profile?.postal_code}
      />

      {!areFieldsFinal && (
        <ExtendedProfileSwitch
          label={extendedProfileAgreement}
          name={'extended_profile.agreement'}
          formRef={register}
          formState={formState}
          defaultValue={false}
          final={!!areFieldsFinal}
          disabled={areFieldsFinal}
          fieldError={errors?.extended_profile?.agreement}
          control={control}
        />
      )}
    </>
  );
};

export default ExtendedProfilePart;
