import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import Authenticate from './Authenticate';
import { confirmPhoneSignup, sendPhoneSignup, setPostPhoneCodeFetchStatus } from '../../../reducers/Auth/auth.actions';

function mapStateToProps(state: any, otherProps: RouteComponentProps) {
  return {
    user: state.auth.user,
    smsCodeId: state.auth.smsCodeId,
    postPhoneCodeFetchStatus: state.auth.verifyPhoneCode.postPhoneCodeFetchStatus,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    sendPhoneSignup,
    confirmPhoneSignup,
    setPostPhoneCodeFetchStatus
  })(Authenticate)
);
