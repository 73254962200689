import React from 'react';
import { toast, ToastContainerProps } from 'react-toastify';
import { Trans } from 'react-i18next';
import { ToastOptions } from '../types/utils.types';

const autoCloseDefault: number | false = 3000;

export const toastContainerProps: ToastContainerProps = {
  position: 'top-right',
  hideProgressBar: true,
  newestOnTop: false,
  closeOnClick: true,
  rtl: false,
  pauseOnFocusLoss: false,
  draggable: false,
  pauseOnHover: true,
};

export const toastOptions: ToastOptions = {
  position: 'top-center',
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
};

const getTransKey = (key: any) => {
  if (!isNaN(key)) {
    return (
      <>
        <Trans>No.</Trans> {Number(key) + 1}
      </>
    );
  } else {
    return <Trans>{key}</Trans>;
  }
};

const generateMessageFromObj: any = (obj: {}, prevKeys: Array<JSX.Element> = []) => {
  let messages: Array<JSX.Element> = [];
  Object.entries(obj).forEach(([key, value]) => {
    let prevKeysMessage = prevKeys.map((prevKey, index) => <>{prevKey}: </>);
    if (Array.isArray(value)) {
      value.forEach((message, index) =>
        messages.push(
          <div>
            <strong>
              {prevKeysMessage} {getTransKey(key)}:
            </strong>{' '}
            <br />
            <Trans>{message}</Trans>
          </div>
        )
      );
    } else if (typeof value === 'string') {
      messages.push(
        <div>
          <strong>
            {prevKeysMessage} {getTransKey(key)}:
          </strong>{' '}
          <br />
          <Trans>{value}</Trans>
        </div>
      );
    } else {
      messages = messages.concat(generateMessageFromObj(value, [...prevKeys, getTransKey(key)]));
    }
  });
  return messages;
};

const prepareMessage = (message: string | undefined, data: { detail?: string }) => {
  if (!!message || !!data.detail) {
    return <Trans>{message ? message : data.detail}</Trans>;
  } else {
    const completeMessage = generateMessageFromObj(data);
    return completeMessage.map((msg: string, index: number) => <div key={index}>{msg}</div>);
  }
};

/**
 * EXAMPLES:
 * SUCCESS:
 * showNotification({
    message: 'String',
    type: 'success'
   });
 *
 * ERROR:
 * showNotification({
    response: error.response,
    type: 'error'
   });
 *
 * showNotification({
    message: 'String',
    type: 'error'
   });
 *
 *
 * @param response - OPTIONAL - whole response
 * @param message - OPTIONAL - string to translate - (add translation to src/translations/)
 * @param translatedMessage
 * @param type - REQUIRED - type of notification - (success, error, warning, info)
 * @param autoClose - OPTIONAL - display time - (DEFAULT: 3000)
 */

export const showNotification = ({
  response,
  message,
  translatedMessage,
  type,
  autoClose = autoCloseDefault,
}: {
  response?: { status: number; data: string | {} };
  message?: string;
  translatedMessage?: any;
  type: string;
  autoClose?: number | false;
}) => {
  const options = {
    ...toastOptions,
    autoClose: autoClose,
    className: `${type}-toast`,
  };
  if (response && response.status === 500) {
    toast.error(<Trans>ServerError</Trans>, options);
    return;
  }
  if ((response && response.status !== 500) || message || translatedMessage) {
    const msg = translatedMessage ? (
      translatedMessage
    ) : (
      <>{prepareMessage(message, response ? response.data : {})}</>
    );
    switch (type) {
      case 'warning':
        toast.warn(msg, options);
        break;
      case 'success':
        toast.success(msg, options);
        break;
      case 'error':
        toast.error(msg, options);
        break;
      case 'info':
      default:
        toast(msg, options);
        break;
    }
  }
};
