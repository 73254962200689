import React from 'react';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import { PrimarySmallButton } from './Buttons/buttons';

const SpinnerContainer: any = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PrimarySmallSpinnerButton = (props: any) => {
  const { children, onClick, isSpinning = true, style = {} } = props;

  return (
    <PrimarySmallButton disable={isSpinning} onClick={onClick} style={style} disabled={isSpinning}>
      {isSpinning ? (
        <SpinnerContainer>
          <CircularProgress color="inherit" size={28} />
        </SpinnerContainer>
      ) : (
        children
      )}
    </PrimarySmallButton>
  );
};
