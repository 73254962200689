import React, { useRef } from 'react';
import styled from 'styled-components';
import { FormTypes } from '../../types/form.types';
import './Checkbox.css';
import ErrorMessage from './ErrorMessage';
import { Trans } from 'react-i18next';
import MyPopover from '../MyPopover';

const InputContainer: any = styled.div`
  display: flex;
  align-items: ${(props: any) => (props.labelComponent ? 'start' : 'center')};

  ${(props: any) =>
    props.labelComponent
      ? `label {
    margin: 3px 0px 0px 0px;
    div {
      position: inherit;
      margin-right: 12px;
    }
  }`
      : ''}
`;

interface InputTypes extends FormTypes {
  label?: string;
  labelComponent?: any;
  name: string;
  defaultChecked?: boolean;
  value?: any;
  uppercase?: boolean;
  onChange?(): void;
  style?: any;
  popoverText?: string;
  extraButton?: any;
  showSquare?: boolean;
  className?: string;
  disabled?: boolean;
}

const Checkbox: React.FC<InputTypes> = ({
  label,
  labelComponent,
  name,
  type = 'checkbox',
  placeholder = '',
  formRef,
  fieldError,
  validate = {},
  defaultChecked = false,
  value,
  uppercase = true,
  onChange,
  style = {},
  popoverText,
  extraButton,
  showSquare = true,
  className,
  disabled,
}) => {
  const inputContainerRef = useRef(null);

  return (
    <div style={{ ...style }}>
      <InputContainer ref={inputContainerRef} labelComponent={labelComponent}>
        <label
          className={`control control-checkbox${disabled ? ' disabled' : ''}${
            uppercase ? ' uppercase' : ''
          }${!showSquare ? ' hide-square' : ''}`}
        >
          {label ? <Trans>{label}</Trans> : <> </>}
          {typeof onChange === 'function' ? (
            <input
              type={type}
              {...formRef(name, validate)}
              defaultChecked={defaultChecked}
              value={value}
              onChange={onChange}
              disabled={disabled}
            />
          ) : (
            <input
              type={type}
              {...formRef(name, validate)}
              defaultChecked={defaultChecked}
              value={value}
              disabled={disabled}
            />
          )}

          {showSquare && (
            <div
              className={`control_indicator${label ? ` with_label` : ''}${
                className ? ` ${className}` : ''
              }`}
            />
          )}
        </label>
        {labelComponent ? labelComponent : <></>}
        {popoverText && <MyPopover text={popoverText} containerRef={inputContainerRef} />}
        {extraButton ? extraButton : <></>}
      </InputContainer>
      <ErrorMessage fieldError={fieldError} validate={validate} />
    </div>
  );
};

export default Checkbox;
