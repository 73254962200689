import { SurveyAnswer, SurveyData } from '../../../types/surveys.types';

export const getAnswers = (answers: Array<SurveyAnswer>): any => {
  if (answers && answers.length > 0) {
    return answers.map((answer) => ({
      text: answer.text,
      image: answer.image,
    }));
  } else {
    return [
      {
        text: '',
        image: '',
      },
    ];
  }
};

export const getDefaultValues = (newSurvey: SurveyData): any => {
  const isEditMode =
    newSurvey.questions &&
    newSurvey.questions[0]?.answers &&
    newSurvey.questions[0]?.answers.length > 0;
  return {
    name: newSurvey.name ?? '',
    question:
      newSurvey.questions && newSurvey.questions.length > 0 ? newSurvey.questions[0].question : '',
    answers: getAnswers(isEditMode ? newSurvey.questions[0].answers : []),
  };
};
