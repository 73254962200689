export const UPDATE_GOVERNMENT_PROVINCES = 'GET_GOVERNMENT_PROVINCE';
export const GET_GOVERNMENT_PROVINCE_FETCH_STATUS = 'GET_GOVERNMENT_PROVINCE_FETCH_STATUS';
export const UPDATE_GOVERNMENT_COUNTIES = 'GET_GOVERNMENT_COUNTY';
export const GET_GOVERNMENT_COUNTY_FETCH_STATUS = 'GET_GOVERNMENT_COUNTY_FETCH_STATUS';
export const UPDATE_GOVERNMENT_LOCALITIES = 'GET_GOVERNMENT_LOCALITY';
export const GET_GOVERNMENT_LOCALITY_FETCH_STATUS = 'GET_GOVERNMENT_LOCALITY_FETCH_STATUS';
export const UPDATE_GOVERNMENT_STREETS = 'GET_GOVERNMENT_STREET';
export const GET_GOVERNMENT_STREET_FETCH_STATUS = 'GET_GOVERNMENT_STREET_FETCH_STATUS';
export const UPDATE_GOVERNMENT_MUNICIPALITIES = 'UPDATE_GOVERNMENT_MUNICIPALITIES';
export const GET_GOVERNMENT_MUNICIPALITY_FETCH_STATUS = 'GET_GOVERNMENT_MUNICIPALITY_FETCH_STATUS';
