import styled from 'styled-components';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { colors, device } from '../theme';

export const Input: any = styled.input`
  margin-top: 0.625rem;
  height: 3.75rem;
  border: 2px solid
    ${(props: any) =>
      props.borderColor
        ? props.borderColor
        : props.disabled
        ? '#0A5057'
        : props.isDirty || props.defaultValue
        ? '#373737'
        : '#CACACA'};
  border-radius: 30px;
  color: ${(props: any) =>
    props.color
      ? props.color
      : props.disabled
      ? colors.darkBlue
      : props.isDirty || props.defaultValue
      ? '#373737'
      : '#95989A'};
  background-color: ${(props: any) => (props.disabled ? '#0A5057' : 'transparent')};
  font-size: 1.5rem;
  padding: 1rem 1.25rem;
  caret-color: ${(props: any) => (props.caretColor ? props.caretColor : colors.lightBlue)};
  &::placeholder {
    color: #95989a;
  }
  &:hover {
    border-color: ${(props: any) =>
      props.borderColor ? props.borderColor : props.disabled ? '#0A5057' : '#979797'};
  }
  &:focus {
    color: ${(props: any) => (props.color ? props.color : '#646464')};
    border-color: ${(props: any) =>
      props.borderColor ? props.borderColor : props.disabled ? '#0A5057' : colors.lightBlue};
  }

  width: 90%;

  @media ${device.laptop} {
    max-width: 600px;
  }
`;

export const InvisibleInput: any = styled.input`
  display: none;
`;

export const Textarea: any = styled.textarea`
  margin-top: 0.625rem;
  min-height: 3.75rem;
  border: 2px solid
    ${(props: any) =>
      props.disabled ? '#0A5057' : props.isDirty || props.defaultValue ? '#373737' : '#CACACA'};
  border-radius: 30px;
  color: ${(props: any) =>
    props.disabled ? colors.darkBlue : props.isDirty || props.defaultValue ? '#373737' : '#95989A'};
  background-color: ${(props: any) => (props.disabled ? '#0A5057' : 'transparent')};
  font-size: 1.5rem;
  padding: 1rem 1.25rem;
  caret-color: ${colors.lightBlue};
  resize: vertical;
  &::placeholder {
    color: #95989a;
  }
  &:hover {
    color: ${(props: any) => (props.isDirty || props.defaultValue ? '#373737' : '#95989A')};
    border-color: ${(props) => (props.disabled ? '#0A5057' : '#979797')};
  }
  &:focus {
    color: #646464;
    border-color: ${(props) => (props.disabled ? '#0A5057' : colors.lightBlue)};
  }

  width: 90%;

  @media ${device.laptop} {
    max-width: 600px;
  }
`;

export const Button: any = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 3.75rem;
  width: 100%;
  padding: 1rem 1.25rem;
  margin-top: 1rem;
  background-color: ${(props: any) =>
    props.bgColor ? props.bgColor : props.bgDark ? colors.white : colors.darkBlue};
  border: 2px solid
    ${(props: any) =>
      props.borderColor
        ? props.borderColor
        : props.bgColor
        ? props.bgColor
        : props.bgDark
        ? colors.white
        : colors.darkBlue};
  border-radius: 50px;
  font-size: 1.5rem;
  text-transform: uppercase;
  color: ${(props: any) =>
    props.color ? props.color : props.bgDark ? colors.darkBlue : colors.white};
  &:hover {
    cursor: pointer;
    color: ${colors.white};
    background-color: ${(props: any) => (props.bgColor ? props.bgColor : colors.lightBlue)};
    border: 2px solid
      ${(props: any) =>
        props.borderColor ? props.borderColor : props.bgColor ? props.bgColor : colors.lightBlue};
  }
  &:focus {
    // background-color: ${colors.red};
    // border: 2px solid ${colors.red};
  }
  &:disabled {
    cursor: not-allowed;
    color: ${(props: any) => (props.bgDark ? colors.lightBlue : colors.black)};
    background-color: ${(props: any) => (props.bgDark ? colors.black : colors.white)};
    border: 2px solid
      ${(props: any) =>
        props.borderColor ? props.borderColor : props.bgDark ? colors.lightBlue : colors.black};
  }

  width: 90%;

  @media ${device.laptop} {
    max-width: 600px;
  }
`;

export const LogoSvg = styled.img`
  height: 225px;
`;

export const LogoSvgMedium = styled.img`
  height: 140px;
`;

export const PrimaryButton: any = styled.button`
  border: 2px solid ${(props: any) => props.borderColor};
  border-radius: 50px;
  background-color: ${(props: any) => props.bgColor};
  font-size: 20px;
  color: ${(props: any) => props.color};
  text-transform: uppercase;
  width: 100%;
  max-width: 250px;
  height: 55px;
  margin-bottom: 28px;
  padding: 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:first-of-type {
    margin-top: 28px;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const PrimarySmallButton = styled.button`
  border: ${(props) => (props.disabled ? 'none' : `2px solid ${props.theme.secondaryColor}`)};
  background-color: ${(props) => (props.disabled ? 'darkgray' : props.theme.secondaryColor)};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  border-radius: 50px;
  height: 40px;
  color: white;
  justify-content: center;
  align-items: center;
  display: grid;
  font-size: 20px;
  margin: 25px 0;
  width: 150px;
  user-select: none;
`;

export const PrimaryFlexButton = styled.button`
  border: ${(props) => (props.disabled ? 'none' : `2px solid ${props.theme.secondaryColor}`)};
  background-color: ${(props) => (props.disabled ? 'darkgray' : props.theme.secondaryColor)};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  border-radius: 50px;
  height: 40px;
  color: white;
  justify-content: center;
  align-items: center;
  display: grid;
  font-size: 20px;
  margin: 25px 0;
  user-select: none;
`;

export const PrimaryInput: any = styled.input<any>`
  border: 2px solid
    ${(props: any) => (props.disabled ? props.theme.disableColor : props.borderColor)};
  border-radius: 50px;
  //@ts-ignore
  color: ${(props: any) => (props.disabled ? props.theme.disableColor : props.color)};
  height: 45px;
  background-color: transparent;
  font-size: 13px;
  padding: 0 25px;
  margin-top: 5px;
  width: 90%;
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px rgb(203, 203, 203) inset !important;
  }
  &:focus {
    outline: none;
  }
  &::placeholder {
    //@ts-ignore
    color: ${(props) => (props.whiteBg ? 'rgba(0,0,0,0.3)' : 'rgba(255,255,255,0.4)')};
  }

  @media ${device.laptop} {
    max-width: 600px;
  }
`;

export const ReadOnlyInput: any = styled.input<any>`
  border: 2px solid #005159;
  border-radius: 50px;
  //@ts-ignore
  color: #1bd0e2;
  height: 45px;
  background-color: #005159;
  font-size: 13px;
  padding: 0 25px;
  margin-top: 5px;
  width: 235px;
  max-width: 80%;
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px rgb(203, 203, 203) inset !important;
  }
  &:focus {
    outline: none;
  }
  &::placeholder {
    //@ts-ignore
    color: ${(props) => (props.whiteBg ? 'rgba(0,0,0,0.3)' : 'rgba(255,255,255,0.4)')};
  }
`;

export const SmallText = styled.div`
  font-size: 10px;
  color: ${(props) => props.theme.errorColor};
  margin-top: 3px;
  margin-bottom: 15px;
`;

export const PrimaryTextarea = styled.textarea`
  border: 4px solid ${(props) => props.theme.secondaryColor};
  border-radius: 20px;
  height: 80px;
  background-color: transparent;
  font-size: 20px;
  padding: 10px 25px;
  margin-top: 10px;
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px rgb(203, 203, 203) inset !important;
  }
  &::placeholder {
    color: rgba(0, 0, 0, 0.3);
  }
`;

export const PrimaryLabel: React.FC<any> = styled.div`
  //@ts-ignore
  color: ${(props: any) => props.color};
  margin-top: 23px;
  font-size: 16px;
  text-align: center;
  position: relative;
`;

export const CloseIconStyle = styled(CloseIcon)`
  position: absolute;
  top: calc(10px + env(safe-area-inset-top));
  right: 20px;
  cursor: pointer;
  color: #ffffff;
  z-index: 10;
`;

export const MainHeader = styled.div`
  color: ${({ color }) => color || 'white'};
  font-size: 30px;
  text-align: center;
  margin-bottom: 30px;
  text-transform: uppercase;
  margin-top: 2rem;
`;

export const MainHeader2 = styled.div`
  color: ${colors.black};
  font-size: 20px;
  text-align: center;
  margin-bottom: 30px;
  text-transform: uppercase;
  margin-top: 2rem;
  font-weight: bold;
`;
export const MainText2 = styled.div`
  color: ${colors.grayDescription};
  font-size: 16px;
  text-align: center;
  margin-bottom: 30px;
  margin-top: 10px;
`;

export const BigCircleButton: any = styled.button`
  border: 2px solid ${(props: any) => (props.borderColor ? props.borderColor : colors.lightBlue)};
  border-radius: 50%;
  background-color: ${(props: any) => (props.bgColor ? props.bgColor : colors.lightBlue)};
  font-size: 30px;
  width: 41px;
  height: 41px;
  line-height: 20px;
  margin: 1.5rem 0;
  padding: 0;
  color: ${(props: any) => (props.color ? props.color : colors.white)};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;
