import useEventListener from './useEventListener';

const useClickCircleAnimation = () => {
  useEventListener('click', createParticle);

  function createParticle(e: MouseEvent) {
    const particle = document.createElement('particle');
    document.body.appendChild(particle);
    const x = e.clientX;
    const y = e.clientY;
    const borderWidth = 2;
    const startWidth = 25;
    const endWidth = 60;
    const width = startWidth;
    particle.style.position = 'absolute';
    particle.style.zIndex = '3000';
    particle.style.top = '0';
    particle.style.left = '0';
    particle.style.pointerEvents = 'none';
    particle.style.border = `${borderWidth}px solid #1BD0E2`;
    particle.style.borderRadius = '100%';
    particle.style.width = `${width}px`;
    particle.style.height = `${width}px`;
    particle.style.transform = `translate(-50%, -50%) translate(${x}px, ${y}px)`;
    particle.style.marginTop = `${window.scrollY}px`;
    particle.style.marginLeft = `${window.scrollX}px`;
    const animation = particle.animate(
      [
        {
          opacity: 1,
        },
        {
          width: `${endWidth}px`,
          height: `${endWidth}px`,
          opacity: 0,
        },
      ],
      {
        duration: 300,
      }
    );
    animation.onfinish = removeParticle;
  }

  function removeParticle(e: any) {
    e.currentTarget.effect.target.remove();
  }
};

export default useClickCircleAnimation;
