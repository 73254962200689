import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import ContactPersons from './ContactPersons'
import {RouteComponentProps} from "react-router";
import {getContactPersons} from "../../reducers/ContactPersons/contactPersons.actions";

function mapStateToProps(state: any, otherProps: RouteComponentProps) {
  return {
    contactPersons: state.contactPersons.list
  };
}

export default withRouter(connect(
  mapStateToProps,
  {
    getContactPersons
  }
)(ContactPersons));
