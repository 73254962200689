import React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import { colors } from '../../theme';

const Description = styled.div`
  font-size: 14px;
  color: ${colors.lightGrayRBG};
`;

interface DescTypes {
  text: string;
}

const InputDescription: React.FC<DescTypes> = ({ text }) => {
  return (
    <Description>
      <Trans>{text}</Trans>
    </Description>
  );
};

export default InputDescription;
