export function dynamicSort(property: string) {
  let sortOrder = 1;
  if(property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a: any,b: any) {
    let result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
    return result * sortOrder;
  }
}

export function dynamicSortMultiple(args: Array<string>) {
  return function (obj1: any, obj2: any) {
    let i = 0, result = 0, numberOfProperties = args.length;
    while(result === 0 && i < numberOfProperties) {
      result = dynamicSort(args[i])(obj1, obj2);
      i++;
    }
    return result;
  }
}
