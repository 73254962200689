import MobileMessagingHOC from './MobileMessagingHOC';
import { connect } from 'react-redux';
import {
  getNotifications,
  getNotificationsSummary,
  registerFCMDevice,
  updateFCMInfo,
} from '../../reducers/Notifications/notifications.actions';
import { logout } from '../../reducers/Auth/auth.actions';

const mapStateToProps = (state: any) => {
  return {
    user: state.auth.user,
    token: state.auth.token,
    loginFetchStatus: state.auth.loginFetchStatus,
    fcmDevice: state.notifications.fcmDevice?.info,
    fcmDeviceFetchStatus: state.notifications.fcmDevice?.fetchStatus,
  };
};

export default connect(mapStateToProps, {
  registerFCMDevice,
  updateFCMInfo,
  getNotifications,
  getNotificationsSummary,
  logout,
})(MobileMessagingHOC);
