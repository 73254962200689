import React, { useEffect } from 'react';
import { Button, ButtonsContainer } from './SelectGroup';
import { connect } from 'react-redux';
import { getGroups } from '../reducers/Groups/groups.actions';
import { Group } from '../types/groups.types';

interface Props {
  groups: Array<Group>;
  coordinatorGroups: Array<Group>;
  selectedGroups: Array<number>;
  getGroups(): void;
  getCoordinatorGroups(): void;
}

const SelectedGroups: React.FC<Props> = ({
  selectedGroups,
  groups,
  coordinatorGroups,
  getGroups,
  getCoordinatorGroups,
}) => {
  useEffect(() => {
    getGroups();
    getCoordinatorGroups && getCoordinatorGroups();
  }, [getGroups]);

  const allGroups = [...groups, ...coordinatorGroups];

  return (
    <ButtonsContainer>
      {allGroups &&
        allGroups
          .filter((group) => selectedGroups.includes(+group.id))
          .map((group, key) => {
            return (
              <Button key={key} type={'button'} selected={true} hover={false}>
                {group.name}
              </Button>
            );
          })}
    </ButtonsContainer>
  );
};

export default SelectedGroups;

// function mapStateToProps(state: any) {
//   return {
//     groups: state.groups.list
//   };
// }
//
// export default connect(mapStateToProps, {
//   // getGroups
// })(SelectedGroups);
