import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import ContactPersonsDetail from './ContactPersonsDetail'
import {RouteComponentProps} from "react-router";
import {
  deleteContactPerson,
  getContactPerson,
  postContactPerson, resetContactPerson,
  updateContactPerson
} from "../../../reducers/ContactPersons/contactPersons.actions";
import {getGroups} from "../../../reducers/Groups/groups.actions";

type OtherProps = RouteComponentProps<{
  id: string;
}>

function mapStateToProps(state: any, otherProps: OtherProps) {
  return {
    contactPerson: state.contactPersons.detail,
    groups: state.groups.list,
    id: otherProps.match.params.id
  };
}

export default withRouter(connect(
  mapStateToProps,
  {
    getContactPerson,
    updateContactPerson,
    postContactPerson,
    resetContactPerson,
    deleteContactPerson,
    getGroups
  }
)(ContactPersonsDetail));
