import React, { useEffect, useState } from 'react';
import { FormInput } from '../../../components/Forms/forms';
import Checkbox from '../../../components/Forms/Checkbox';
import AddAnswer from '../../../components/Forms/AddAnswer';
import ErrorMessage from '../../../components/Forms/ErrorMessage';
import DeleteOutlineIcon from '@material-ui/icons/Close';
import { useFieldArray, useWatch } from 'react-hook-form';
import { colors } from '../../../theme';
import { ActionButton, IconContainer } from '../../../components/ActionButton';
import Counter from '../NewSurveyExtended/Counter';
import RequiredAnswersCounters from '../../../components/Forms/RequiredAnswersCounters';

interface Props {
  item?: any;
  questionIndex?: any;
  register?: any;
  formState?: any;
  handleAppend?: any;
  errors?: any;
  nestIndex?: number;
  control?: any;
  name?: string;
  nameExtraField?: string;
  manualErrors?: any;
  // add these values if will operate as radio button
  onlyOneChoice?: boolean;
  defaultAnswer?: boolean;
  setValue?: any;
  getValues?: any;
  surveyType?: string;
}

const AnswersFieldArray: React.FC<Props> = (props) => {
  const {
    item,
    register,
    formState,
    handleAppend,
    errors,
    nestIndex,
    nameExtraField,
    manualErrors,
    control,
    name,
    onlyOneChoice,
    setValue,
    getValues,
    defaultAnswer,
    surveyType,
    questionIndex,
  } = props;

  const nameOfAnswersField = name || `questions[${nestIndex}].answers`;
  const nameOfQuestionField = name || `questions[${nestIndex}]`;
  const nameOfExtraField = nameExtraField || `questions[${nestIndex}].extraAnswer`;

  const getExtendedSurveyErrors = () =>
    typeof nestIndex !== 'undefined' ? errors.questions[nestIndex].answers : manualErrors;
  const getSimpleSurveyErrors = () => {
    return surveyType === 'simple' && name ? errors?.[name]?.[0]?.value : manualErrors;
  };
  const errorFieldName = manualErrors || getExtendedSurveyErrors() || getSimpleSurveyErrors();

  const { fields, remove, append }: any = useFieldArray({
    control,
    name: nameOfAnswersField,
  });

  const removeAnswer = (answerIndex: number) => {
    remove(answerIndex);
  };

  const deselectOtherCheckboxes = (index: number) => {
    if (!onlyOneChoice) return;
    const answersToSet =
      typeof nestIndex === 'number'
        ? getValues()?.questions[nestIndex]?.answers?.map((answer: any, answerIndex: number) => ({
            ...answer,
            value: +index === +answerIndex,
          }))
        : getValues().answers?.map((answer: any, answerIndex: number) => ({
            ...answer,
            value: +index === +answerIndex,
          }));
    if (!name) {
      // extended survey
      // error: question text set to '' after setting only new answers on setValue('questions[${nestIndex}].answers', answersToSet)
      setValue(nameOfQuestionField, { ...getValues(nameOfQuestionField), answers: answersToSet });
    } else {
      // simple survey
      setValue(nameOfAnswersField, answersToSet);
    }
  };

  return (
    <div style={{ minWidth: '15rem' }}>
      {surveyType === 'extended' && (
        <RequiredAnswersCounters
          control={control}
          register={register}
          setValue={setValue}
          name={`questions[${questionIndex}]`}
          fieldsLength={fields.length}
        />
      )}
      {fields.map((answer: any, answerIndex: number) => {
        return (
          <React.Fragment key={answer.id}>
            <FormInput
              style={{ display: 'none' }}
              formType={'survey'}
              {...register(`${nameOfAnswersField}[${answerIndex}].text`)}
              defaultValue={fields[answerIndex].text}
            />
            <Checkbox
              className={'checked'}
              label={answer.text}
              name={`${nameOfAnswersField}[${answerIndex}].value`}
              formRef={register}
              formState={formState}
              defaultChecked={fields[answerIndex].value}
              onChange={() => {
                deselectOtherCheckboxes(answerIndex);
              }}
              extraButton={
                <IconContainer onClick={() => removeAnswer(answerIndex)}>
                  <ActionButton
                    type={'button'}
                    bgColor={'transparent'}
                    color={colors.red}
                    border={`2px solid ${colors.red}`}
                  >
                    <DeleteOutlineIcon />
                  </ActionButton>
                </IconContainer>
              }
              showSquare={defaultAnswer}
            />
          </React.Fragment>
        );
      })}
      {(surveyType === 'extended' || (surveyType === 'simple' && fields.length < 3)) && (
        <AddAnswer
          handleAppend={(event, value) => {
            if (!value || !value.length) return;
            append({
              value: false,
              text: value,
            });
          }}
          name={nameOfExtraField}
          formState={formState}
        />
      )}
      {defaultAnswer && <ErrorMessage fieldError={errorFieldName} />}
    </div>
  );
};

export default AnswersFieldArray;
