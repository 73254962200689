import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';
import styled from 'styled-components';
import { colors, device } from '../../../theme';
import SelectGroup from '../../../components/SelectGroup';
import {
  getCoordinatorGroups as _getCoordinatorGroups,
  getGroups as _getGroups,
  postNewGroupWithContacts as _postNewGroupWithContacts,
  setNewGroupWithContactsFetchStatus as _setNewGroupWithContactsFetchStatus,
} from '../../../reducers/Groups/groups.actions';
import ErrorMessage from '../../../components/Forms/ErrorMessage';
import { updateNewSurveyData as _updateNewSurveyData } from '../../../reducers/Surveys/surveys.actions';
import { SurveyData } from '../../../types/surveys.types';
import SurveyTypeHeader from '../../../components/SurveyTypeHeader';
import { FormLabel } from '../../../components/Forms/forms';
import { CircleButton, PrimarySmallButton } from '../../../components/Buttons/buttons';
import { Form } from '../../../components/Containers/containers';
import SimpleModal from '../../../components/Modal/SimpleModal';
import CreateGroupModalContent from './CreateGroupModalContent';
import { Group, NewGroupWithContacts } from '../../../types/groups.types';
import { UsersTitleIcon } from '../../../components/Modal/ModalTitleIcons';
import { REQUEST_STATUSES } from '../../../constants/requestStatuses.constants';
import _ from 'lodash';

export const EmailsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  border: 2px solid #000000;
  border-radius: 30px;
  width: 90%;
  padding: 0.5rem;
  min-height: 100px;
  align-items: flex-start;
  align-content: flex-start;

  color: ${colors.gray};

  @media ${device.laptop} {
    max-width: 600px;
  }

  margin-top: 1rem;
`;

export const EmailButton = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.black};
  background: ${colors.gray};
  padding: 0.125rem 0.625rem;
  border-radius: 20px;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;

  &:last-child {
    margin-right: 0;
  }
`;

const CoordinatorsFormLabel = styled(FormLabel)`
  margin-top: 45px;
`;

interface Props {
  newSurvey: SurveyData;
  newGroupWithContacts: Group | null;
  newGroupWithContactsFetchStatus: string | null;
  groups?: Array<any>;
  groupsFetchStatus?: string;
  coordinatorGroups?: Array<any>;
  coordinatorGroupsFetchStatus?: string;

  getGroups(): void;
  setStep(stepNumber: number): void;
  updateNewSurveyData(data: SurveyData): void;
  postNewGroupWithContacts(data: NewGroupWithContacts): void;
  setNewGroupWithContactsFetchStatus(status: string | null): void;
  getCoordinatorGroups(): void;
}

type Inputs = {
  tab: string;
  emails: string;
  group_name: string;
  groups: string;
  coordinator_groups: string;
  invite_existing_groups: boolean;
  invite_existing_coordinator_groups: boolean;
};

const Invite: React.FC<Props> = (props) => {
  const {
    getGroups,
    getCoordinatorGroups,
    setStep,
    newSurvey,
    postNewGroupWithContacts,
    updateNewSurveyData,
    newGroupWithContacts,
    newGroupWithContactsFetchStatus,
    setNewGroupWithContactsFetchStatus,
    groups,
    coordinatorGroups,
    coordinatorGroupsFetchStatus,
  } = props;

  const [selectedMergedGroups, setSelectedMergedGroups] = useState<Array<number>>(
    newSurvey.invited_groups || []
  );
  // const [selectedCoordinatorGroups, setSelectedCoordinatorGroups] = useState<Array<number>>(
  //   newSurvey.invited_groups || []
  // );
  const [createGroupModalOpen, setCreateGroupModalOpen] = useState<any>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    formState,
    setError,
    setValue,
    clearErrors,
  } = useForm<Inputs>({
    defaultValues: {
      tab: 'newContacts',
    },
  });

  useEffect(() => {
    getGroups();
  }, [getGroups]);

  useEffect(() => {
    getCoordinatorGroups();
  }, [getCoordinatorGroups]);

  useEffect(() => {
    if (!!errors.groups) clearErrors('groups');
    if (!!errors.coordinator_groups) clearErrors('coordinator_groups');
  }, [selectedMergedGroups]);

  const onSubmit = (data: Inputs) => {
    let isValid = true;
    const uniqMergedGroups = _.uniq(selectedMergedGroups);
    if (!uniqMergedGroups || uniqMergedGroups.length === 0) {
      isValid = false;
      setError('groups', { type: 'required' });
      setError('coordinator_groups', { type: 'required' });
    }
    if (isValid) {
      updateNewSurveyData({
        ...newSurvey,
        invited_groups: uniqMergedGroups,
      });
      setStep(4);
    }
  };

  const handleAddClick = (e: Event) => {
    e.preventDefault();
    setNewGroupWithContactsFetchStatus(null);
    setCreateGroupModalOpen(true);
  };

  useEffect(() => {
    if (newGroupWithContactsFetchStatus === REQUEST_STATUSES.DONE && newGroupWithContacts) {
      getGroups();
      getCoordinatorGroups();
      setSelectedMergedGroups((prev: any) => [...prev, +newGroupWithContacts.id]);
      // setSelectedGroups((prev: any) => [...prev, +newGroupWithContacts.id]);
      // setSelectedCoordinatorGroups((prev: any) => [...prev, +newGroupWithContacts.id]);
    }
  }, [newGroupWithContactsFetchStatus]);

  return (
    <>
      <SurveyTypeHeader type={newSurvey.survey_type} />
      {createGroupModalOpen && (
        <SimpleModal
          open={createGroupModalOpen}
          setOpen={setCreateGroupModalOpen}
          title={'Utwórz nową grupę'}
          customButtonComponent={<></>}
          iconComponent={UsersTitleIcon}
        >
          <CreateGroupModalContent
            setOpen={setCreateGroupModalOpen}
            postNewGroupWithContacts={postNewGroupWithContacts}
            newGroupWithContactsFetchStatus={newGroupWithContactsFetchStatus}
          />
        </SimpleModal>
      )}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <CoordinatorsFormLabel formType={'survey'}>
          <Trans>InviteCoordinatorGroup</Trans>
        </CoordinatorsFormLabel>
        <SelectGroup
          selectedGroups={selectedMergedGroups}
          setSelectedGroups={setSelectedMergedGroups}
          text={false}
          getGroups={getCoordinatorGroups}
          groups={coordinatorGroups}
          fetchStatus={coordinatorGroupsFetchStatus}
        />
        <ErrorMessage fieldError={errors.coordinator_groups} />

        <FormLabel formType={'survey'}>
          <Trans>InviteExistingGroup</Trans>
        </FormLabel>
        <PrimarySmallButton
          style={{ margin: '0 0 30px 0' }}
          type={'button'}
          onClick={handleAddClick}
        >
          Utwórz nową grupę
        </PrimarySmallButton>
        <SelectGroup
          selectedGroups={selectedMergedGroups}
          setSelectedGroups={setSelectedMergedGroups}
          text={false}
          getGroups={getGroups}
          groups={groups}
        />
        <ErrorMessage fieldError={errors.groups} />

        <PrimarySmallButton type={'submit'}>
          <Trans>Further</Trans>
        </PrimarySmallButton>
      </Form>
    </>
  );
};

function mapStateToProps(state: any, otherProps: RouteComponentProps) {
  return {
    newSurvey: state.surveys.newSurvey,
    newGroupWithContacts: state.groups.newGroupWithContacts,
    newGroupWithContactsFetchStatus: state.groups.newGroupWithContactsFetchStatus,
    groups: state.groups.list,
    coordinatorGroups: state.groups.coordinators.list.items,
    coordinatorGroupsFetchStatus: state.groups.coordinators.list.fetchStatus,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getGroups: _getGroups,
    getCoordinatorGroups: _getCoordinatorGroups,
    updateNewSurveyData: _updateNewSurveyData,
    postNewGroupWithContacts: _postNewGroupWithContacts,
    setNewGroupWithContactsFetchStatus: _setNewGroupWithContactsFetchStatus,
  })(Invite)
);
