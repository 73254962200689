import React, { ChangeEvent, useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { Group, GroupData } from '../../../types/groups.types';
import InputText from '../../../components/Forms/InputText';
import { Trans } from 'react-i18next';
import { colors, device } from '../../../theme';
import { ContactPerson } from '../../../types/contactPersons.types';
import ContactPersonButton from '../../../components/ContactPersonButton';
import styled from 'styled-components';
import { dynamicSort } from '../../../utils/sort';
import { history } from '../../../utils/history';
import { v1 } from 'uuid';
import { getContactPersonsPath, getGroupsPath } from '../../../constants/paths.constants';
import { Center, FlexContainer, Form } from '../../../components/Containers/containers';
import { SurveyListH1 } from '../../../components/Typography/typography';
import { FormInput, FormLabel } from '../../../components/Forms/forms';
import { CircleButton, PrimarySmallButton } from '../../../components/Buttons/buttons';
import { postCVS } from '../../../reducers/Media/media.actions';
import CSVPreviewModal from '../../../components/CSVPreviewModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { REQUEST_STATUSES } from '../../../constants/requestStatuses.constants';
import { ButtonsContainer } from '../../../components/SelectGroup';
import MultiselectEmail from '../../../components/MultiselectEmail';
import CheckboxSwitch from '../../../components/Forms/CheckboxSwitch';

const ContactPersonsButtonsContainer = styled.div`
  max-height: 200px;
  overflow-y: scroll;
  width: 90%;
  margin-top: 1rem;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const CSVButton = styled.img`
  width: 41px;
  height: 41px;
  margin: 20px 0;
  cursor: pointer;
`;

interface Props {
  uuid: string;
  groups: Array<Group>;
  group: Group;
  contactPersons: Array<ContactPerson>;
  user: any;

  getGroup(data: { uuid: string }): void;
  postGroup(data: GroupData): void;
  updateGroup(uuid: string, data: GroupData, redirect?: string): void;
  getContactPersons(): void;
  resetGroup(): void;
  deleteGroup(uuid: string): void;
  postGroupWithContacts(data: GroupData, redirect?: string): void;
  setUpdateGroupFetchStatus(status: any): void;
}

interface Inputs extends Group {
  groupContactsSearch: string;
  contactsSearch: string;
  coordinators: any;
}

const GroupsDetail: React.FC<Props> = ({
  uuid,
  getGroup,
  postGroup,
  updateGroup,
  group,
  groups,
  getContactPersons,
  contactPersons,
  resetGroup,
  deleteGroup,
  postGroupWithContacts,
  setUpdateGroupFetchStatus,
  user,
}) => {
  const [isNewGroup, setIsNewGroup] = useState<boolean>(true);
  const [groupContactPersons, setGroupContactPersons] = useState<Array<number>>([]);
  const [selectedContactPersons, setSelectedContactPersons] = useState<Array<ContactPerson>>([]);
  const [isCSVPreviewModalOpen, setIsCSVPreviewModalOpen] = useState<boolean>(false);
  const [coordinatorEmails, setCoordinatorEmails] = useState<Array<string>>([]);
  const [CSVData, setCSVData] = useState<any>();
  const hiddenFileInput = React.useRef<any>(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    formState,
    setError,
    clearErrors,
    control,
    setValue,
  } = useForm<Inputs>({
    defaultValues: group,
  });

  const watchGroupContactsSearch = useWatch({
    name: 'groupContactsSearch',
    defaultValue: '',
    control,
  });
  const watchContactsSearch = useWatch({
    name: 'contactsSearch',
    defaultValue: '',
    control,
  });
  const watchCoordinators = useWatch({
    name: 'coordinators',
    defaultValue: '',
    control,
  });

  useEffect(() => {
    if (!isCSVPreviewModalOpen) {
      if (hiddenFileInput.current) {
        hiddenFileInput.current.value = null;
      }
      setCSVData(undefined);
    }
  }, [isCSVPreviewModalOpen]);

  useEffect(() => {
    if (!isNewGroup) {
      getGroup({ uuid: group.uuid });
    }
    getContactPersons();
  }, [groups]);

  useEffect(() => {
    if (group && group.contacts) {
      if (group.contacts) {
        setGroupContactPersons(group.contacts.map((contact) => contact.id));
      }
      setValue('name', group.name);
      setValue('email_after_contact_delete', group.email_after_contact_delete);
    }
  }, [group]);

  useEffect(() => {
    if (uuid === 'new-group') {
      setIsNewGroup(true);
    } else {
      setIsNewGroup(false);
      getGroup({ uuid: uuid });
    }
    return () => {
      resetGroup();
    };
  }, [history.location]);

  const onSubmit = (data: GroupData) => {
    if (coordinatorEmails.length && user.email && coordinatorEmails.includes(user.email)) {
      setError('coordinators', {
        type: 'manual',
        message: 'Nie możesz dodać swojego adresu email',
      });
    } else if (isNewGroup) {
      postGroupWithContacts(
        {
          name: data.name,
          emails: selectedContactPersons.map((contact) => contact.email),
          manager_emails_list_only: coordinatorEmails,
          email_after_contact_delete: data.email_after_contact_delete,
        },
        getGroupsPath()
      );
    } else {
      updateGroup(
        uuid,
        {
          name: data.name,
          manager_emails_list_only: coordinatorEmails,
          email_after_contact_delete: data.email_after_contact_delete,
        },
        getGroupsPath()
      );
    }
  };

  const searchFilter = (contact: ContactPerson, search: string) => {
    if (search) {
      const contactValue = (contact.email + contact.name).toUpperCase() + contact.phone;
      return contactValue.indexOf(search.toUpperCase()) > -1;
    } else {
      return true;
    }
  };

  const handleSelect = (contact: ContactPerson) => {
    if (selectedContactPersons.includes(contact))
      setSelectedContactPersons((prev: any) => prev.filter((id: any) => id !== contact));
    else setSelectedContactPersons((prev: any) => [...prev, contact]);
  };

  const handleAddFromCSV = () => {
    hiddenFileInput.current && hiddenFileInput.current.click();
  };

  const handleFileInputChange = (event: ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    if (file) {
      postCVS(file)
        .then((response) => {
          setUpdateGroupFetchStatus(REQUEST_STATUSES.NULL);
          setIsCSVPreviewModalOpen(true);
          setCSVData(response);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const onCoordinatorsChange = () => {
    clearErrors('coordinators');
  };

  useEffect(() => {
    if (group?.manager_emails_list_only?.length)
      setCoordinatorEmails(group?.manager_emails_list_only);
  }, [group?.manager_emails_list_only]);

  return (
    <FlexContainer>
      <Center>
        {isCSVPreviewModalOpen && CSVData && (
          <CSVPreviewModal
            isOpen={CSVData && isCSVPreviewModalOpen}
            setIsOpen={setIsCSVPreviewModalOpen}
            CSVData={CSVData}
            uuid={uuid}
          />
        )}
        {(isNewGroup || (!isNewGroup && group.uuid)) && (
          <>
            <SurveyListH1>
              <Trans>{isNewGroup ? 'CreateGroup' : 'EditGroup'}</Trans>
            </SurveyListH1>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <InputText
                label={'Name'}
                name={'name'}
                formRef={register}
                formState={formState}
                fieldError={errors.name}
                // defaultValue={group.name}
                formType={'survey'}
              />

              <FormLabel formType={'survey'}>
                <Trans>Coordinators</Trans>
              </FormLabel>
              <MultiselectEmail
                setEmails={(value) => {
                  setCoordinatorEmails(value);
                  onCoordinatorsChange();
                }}
                errorKey={'coordinators'}
                emails={coordinatorEmails}
                errors={errors}
              />
              {contactPersons && contactPersons.length > 0 && (
                <>
                  {groupContactPersons && groupContactPersons.length > 0 && (
                    <>
                      <FormLabel formType={'survey'}>
                        <Trans>ContactPersons</Trans>
                      </FormLabel>
                      <FormInput
                        {...register('groupContactsSearch')}
                        isDirty={formState.dirtyFields['groupContactsSearch']}
                        placeholder={'Wyszukaj...'}
                        formType={'survey'}
                      />
                      <ContactPersonsButtonsContainer>
                        {contactPersons
                          .filter((contact) => groupContactPersons.includes(contact.id))
                          .filter((contact) => searchFilter(contact, watchGroupContactsSearch))
                          .sort(dynamicSort('email'))
                          .map((contact) => {
                            return (
                              <ContactPersonButton
                                key={v1()}
                                contact={contact}
                                group={group}
                                showEditButton={true}
                              />
                            );
                          })}
                      </ContactPersonsButtonsContainer>
                    </>
                  )}
                  {contactPersons?.filter((contact) => !groupContactPersons.includes(contact.id))
                    .length > 0 && (
                    <>
                      <FormLabel formType={'survey'}>
                        <Trans>AddExistingContactPersons</Trans>
                      </FormLabel>
                      <FormInput
                        {...register('contactsSearch')}
                        isDirty={formState.dirtyFields['contactsSearch']}
                        placeholder={'Wyszukaj...'}
                        formType={'survey'}
                      />
                      <ContactPersonsButtonsContainer>
                        {contactPersons
                          .filter((contact) => !groupContactPersons.includes(contact.id))
                          .filter((contact) => searchFilter(contact, watchContactsSearch))
                          .sort(dynamicSort('email'))
                          .map((contact) => {
                            return (
                              <ContactPersonButton
                                key={v1()}
                                contact={contact}
                                group={group}
                                showEditButton={true}
                                handleSelect={isNewGroup ? handleSelect : undefined}
                                isSelected={selectedContactPersons.includes(contact)}
                              />
                            );
                          })}
                      </ContactPersonsButtonsContainer>
                    </>
                  )}
                </>
              )}

              {!isNewGroup && (
                <>
                  <FormLabel formType={'survey'}>
                    <Trans>AddNewContactPersons</Trans>
                  </FormLabel>
                  <ButtonsContainer>
                    <CircleButton
                      type={'button'}
                      borderColor={colors.darkGreen}
                      bgColor={colors.darkGreen}
                      color={'#ffffff'}
                      onClick={() => {
                        history.push(`${getContactPersonsPath()}/new-contact?group=${group.id}`);
                      }}
                    >
                      <Trans>+</Trans>
                    </CircleButton>
                    <input
                      type="file"
                      accept="text/csv”"
                      ref={hiddenFileInput}
                      onChange={handleFileInputChange}
                      style={{ display: 'none' }}
                    />
                    <CSVButton src={'/images/csv-button.svg'} onClick={handleAddFromCSV} />
                  </ButtonsContainer>
                </>
              )}
              <FormLabel formType={'survey'}>
                <Trans>Powiadomienia</Trans>
              </FormLabel>
              <CheckboxSwitch
                label={'Powiadamiaj o usunięciu konta przez użytkownika należącego do tej grupy'}
                name={'email_after_contact_delete'}
                formRef={register}
                control={control}
                formState={formState}
                theme={'dark'}
              />
              <PrimarySmallButton type={'submit'}>
                <Trans>{isNewGroup ? 'Add' : 'Save'}</Trans>
              </PrimarySmallButton>
              {!isNewGroup && (
                <PrimarySmallButton
                  type={'button'}
                  borderColor={colors.red}
                  bgColor={colors.red}
                  color={'#ffffff'}
                  onClick={() => {
                    deleteGroup(group.uuid);
                  }}
                  style={{ marginTop: '24px' }}
                >
                  <Trans>Delete</Trans>
                </PrimarySmallButton>
              )}
            </Form>
          </>
        )}
      </Center>
    </FlexContainer>
  );
};

export default GroupsDetail;
