import React, { useEffect, useState } from 'react';
import { history } from '../../utils/history';
import { Group } from '../../types/groups.types';
import { Trans } from 'react-i18next';
import { colors } from '../../theme';
import GroupButton from '../../components/GroupButton';
import { getGroupsPath } from '../../constants/paths.constants';
import { Center, FlexContainer } from '../../components/Containers/containers';
import { CircleButton } from '../../components/Buttons/buttons';
import { GroupsListH1 } from '../../components/Typography/typography';
import styled from 'styled-components';
import SimpleModal, { ModalProps } from '../../components/Modal/SimpleModal';
import { AlertTitleIcon } from '../../components/Modal/ModalTitleIcons';

const Separator = styled.div`
  height: 30px;
`;

const NoAvailableGroups = () => (
  <div>
    <Trans>NoGroups</Trans>
  </div>
);

interface Props {
  groups: Array<Group>;
  coordinatorGroups: Array<Group>;
  groupsIBelongTo: Array<Group>;
  getGroups(): void;
  getCoordinatorGroups(): void;
  updateContactPerson?: any;
  deleteContactPerson?: any;
  getGroupsIBelongTo?: any;
  leaveGroup?: any;
  leaveFetchStatus?: null | string;
}

const Groups: React.FC<Props> = ({
  groups,
  coordinatorGroups,
  getGroups,
  getCoordinatorGroups,
  updateContactPerson,
  deleteContactPerson,
  getGroupsIBelongTo,
  groupsIBelongTo,
  leaveGroup,
  leaveFetchStatus,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [modalConfig, setModalConfig] = useState<ModalProps>();

  const openConfirmationModal = ({ uuid }: { uuid: string }) => {
    setIsOpen(true);
    setTimeout(() => {
      setModalConfig({
        iconComponent: AlertTitleIcon,
        title: <Trans>DoYouWantToLeaveGroup</Trans>,
        buttonConfirmText: 'Tak',
        buttonCancelText: 'Nie',
        onButtonConfirmClick: () => {
          leaveGroup({ uuid });
          setIsOpen(false);
        },
      });
    }, 300);
  };

  useEffect(() => {
    getGroups?.();
    getCoordinatorGroups?.();
    getGroupsIBelongTo?.();
  }, []);

  useEffect(() => {
    getGroupsIBelongTo?.();
  }, [leaveFetchStatus]);

  const handleAddClick = () => {
    history.push(`${getGroupsPath()}/new-group`);
  };

  return (
    <>
      <FlexContainer>
        <SimpleModal open={isOpen} setOpen={setIsOpen} {...modalConfig} />
        <Center>
          <GroupsListH1>
            <Trans>InviteCoordinatorGroup</Trans>
          </GroupsListH1>
          <Separator />
          {coordinatorGroups?.length ? (
            coordinatorGroups?.sort().map((group, key) => {
              return (
                <GroupButton
                  key={key}
                  group={group}
                  readOnly={true}
                  updateContactPerson={() => {}}
                  deleteContactPerson={deleteContactPerson}
                />
              );
            })
          ) : (
            <NoAvailableGroups />
          )}
        </Center>

        <Separator />

        <Center>
          <GroupsListH1>
            <Trans>InviteExistingGroup</Trans>
          </GroupsListH1>
          <CircleButton
            borderColor={colors.darkGreen}
            bgColor={colors.darkGreen}
            color={'#ffffff'}
            onClick={handleAddClick}
          >
            +
          </CircleButton>
          {groups?.length ? (
            groups.sort().map((group, key) => {
              return (
                <GroupButton
                  key={key}
                  group={group}
                  updateContactPerson={updateContactPerson}
                  deleteContactPerson={deleteContactPerson}
                />
              );
            })
          ) : (
            <></>
          )}
        </Center>

        <Separator />

        <Center>
          <GroupsListH1>
            <Trans>GroupsIBelongTo</Trans>
          </GroupsListH1>
          <Separator />
          {groupsIBelongTo?.length ? (
            groupsIBelongTo.sort().map((group, key) => {
              return <GroupButton key={key} group={group} leaveGroup={openConfirmationModal} />;
            })
          ) : (
            <NoAvailableGroups />
          )}
        </Center>
      </FlexContainer>
    </>
  );
};

export default Groups;
