import { useWatch } from 'react-hook-form';
import { useEffect } from 'react';
import useDebounce from './useDebounce';

const useSaveFormOnChangeToStore = ({ control, fun }: any) => {
  const watchAll = useWatch<any>({ control });
  const debouncedValue = useDebounce<any>(watchAll, 500);

  useEffect(() => {
    fun(watchAll);
  }, [debouncedValue]);
};

export default useSaveFormOnChangeToStore;
