import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import styled from 'styled-components';
import { colors } from '../../../theme';

export const ActionButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 5%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: ${colors.darkBlue};
  border: none;
  color: white;
  height: 1.5rem;
  width: 1.5rem;
  font-size: 0.875rem;
  margin-left: 0.375rem;
  &:hover {
    cursor: pointer;
  }
`;

interface EndAdornment {
  onClick(): void;
}

const EndAdornment: React.FC<EndAdornment> = ({ onClick }) => {
  return (
    <ActionButton
      type={'button'}
      onClick={(event) => {
        event.stopPropagation();
        onClick();
      }}
    >
      <CloseIcon />
    </ActionButton>
  );
};

export default EndAdornment;
