import React, { useMemo, useEffect, SyntheticEvent } from 'react';
import { FormTypes } from '../../types/form.types';
import styled from 'styled-components';
import { colors } from '../../theme';
import { PrimaryLabel } from '../../utils/uiComponents';
import { Trans } from 'react-i18next';
import ErrorMessage from './ErrorMessage';
import { CircleButton } from '../Buttons/buttons';
import { useWatch } from 'react-hook-form';
import { postMedia } from '../../reducers/Media/media.actions';

const AddPhotoContainer: any = styled.label`
  position: relative;
  margin-top: 0.625rem;
  height: 145px;
  width: 145px;
  border: 3px solid ${(props: any) => (props.borderColor ? props.borderColor : '#000000')};
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 4rem;
  color: ${(props: any) =>
    props.color
      ? props.color
      : props.disabled
      ? colors.darkBlue
      : props.isDirty || props.defaultValue
      ? '#373737'
      : '#95989A'};
  background: ${(props: any) => (props.bgImage ? `url(${props.bgImage})` : 'transparent')};
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin: 19px 0;
`;

const AddButton: any = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 41px;
  height: 41px;
  margin: 20px 0;

  font-size: 24px;
  text-transform: uppercase;
  color: ${(props: any) => (props.color ? props.color : '#fff')};
  font-weight: 200;

  background-color: ${(props: any) => (props.bgColor ? props.bgColor : colors.darkBlue)};
  border: 3px solid ${(props: any) => (props.borderColor ? props.borderColor : colors.darkBlue)};
  border-radius: 100%;

  cursor: pointer;
`;

const ResetButton: any = styled.div`
  position: absolute;
  left: 0.5rem;
  top: 0.5rem;
  width: 20px;
  height: 20px;
  background-color: ${colors.red};
  border-radius: 50%;
  cursor: pointer;
  font-size: 13px;
  color: ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AddPhotoInput = styled.input`
  display: none;
`;

const HelperText = styled.div`
  color: ${colors.gray};
  font-size: 15px;
}
`;

interface Props extends FormTypes {
  control: any;
  name: string;
  helperText?: string;
  setValue?: any;
  defaultImage?: string;
}

const InputImage: React.FC<Props> = ({
  label,
  name,
  formRef,
  fieldError,
  validate = {},
  defaultValue,
  borderColor,
  color,
  control,
  helperText,
  setValue,
  defaultImage,
}) => {
  const watchValue = useWatch({ name: name, control });
  const file = watchValue && watchValue[0];
  const fileUrl = useMemo(
    () => (file && URL.createObjectURL(file)) || defaultValue,
    [file, defaultValue]
  );

  const fileIdInputName = `${name}_id`;

  const handleResetClick = (event: MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    if (!!setValue) {
      setValue(name, null);
      setValue(fileIdInputName, null);
    }
  };

  return (
    <>
      {label && (
        <PrimaryLabel color={color}>
          <Trans>{label}</Trans>
        </PrimaryLabel>
      )}
      <AddPhotoContainer bgImage={fileUrl || defaultImage} borderColor={borderColor}>
        {fileUrl && setValue && <ResetButton onClick={handleResetClick}>X</ResetButton>}
        <AddPhotoInput
          type="file"
          accept="image/*"
          {...formRef(name, {
            ...validate,
            ...(!!setValue
              ? {
                  onChange: async (event: Event) => {
                    const target = event.target as HTMLInputElement;
                    const fileList = target.files;
                    const file = fileList && fileList[0];
                    let fileId = undefined;
                    if (file) {
                      await postMedia(file)
                        .then((response) => {
                          fileId = response.id;
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                    }
                    setValue(fileIdInputName, fileId);
                  },
                }
              : {}),
          })}
        />
        {!fileUrl && (
          <AddButton borderColor={colors.darkGreen} bgColor={colors.darkGreen}>
            +
          </AddButton>
        )}
      </AddPhotoContainer>
      {helperText && (
        <HelperText>
          <Trans>{helperText}</Trans>
        </HelperText>
      )}
      <input type="hidden" {...formRef(fileIdInputName)} />
      <ErrorMessage fieldError={fieldError} validate={validate} />
    </>
  );
};

export default InputImage;
