import { connect } from 'react-redux';
import AuthorizedVotingLogin from './AuthorizedVotingLogin';
import { RouteComponentProps } from 'react-router';
import { loginUser } from '../../reducers/Auth/auth.actions';
import { getSurveyDetail, resetSurveyState } from '../../reducers/Surveys/surveys.actions';
import { setEmailModalOpen, setPhoneModalOpen } from '../../reducers/Utils/utils.actions';

function mapStateToProps(state: any, otherProps: RouteComponentProps) {
  return {
    loginFetchStatus: state.auth.loginFetchStatus,
    errorTypes: state.auth.errorTypes,
    accountForSurveyVotingData: state.auth.accountForSurveyVoting.data,
    user: state.auth.user,
    surveyDetails: state.surveys.detail,
    surveyDetailsFetchStatus: state.surveys.detailFetchStatus,
    emailModalOpen: state.utils.emailModal,
    phoneModalOpen: state.utils.phoneModal,
  };
}

export default connect(mapStateToProps, {
  loginUser,
  getSurveyDetail,
  resetSurveyState,
  setPhoneModalOpen,
  setEmailModalOpen,
})(AuthorizedVotingLogin);
