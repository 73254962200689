import React, { useEffect, useState } from 'react';
import { useForm, useWatch, useFormState } from 'react-hook-form';
import Checkbox from '../Forms/Checkbox';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { setUserNickname } from '../../reducers/Auth/auth.actions';
import { UserData } from '../../types/auth.types';
import ErrorMessage from '../Forms/ErrorMessage';
import SimpleModal from '../Modal/SimpleModal';
import NicknameModal from '../../containers/Surveys/Vote/NicknameModal';
import { UserTitleIcon } from '../Modal/ModalTitleIcons';
import { UseFormReturn } from 'react-hook-form/dist/types/form';
import styled from 'styled-components';

const AgreementsContainer = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

interface Props {
  formMethods: UseFormReturn<any>;
  survey: any;
  user: UserData;
  setUserNicknameFetchStatus: string | null;
  setUserNickname(data: any): void;
}

const ProjectVoteAgreements: React.FC<Props> = (props) => {
  const { survey, user, setUserNicknameFetchStatus, setUserNickname, formMethods } = props;
  const { register, formState, control, setValue, clearErrors, setError } = formMethods;
  const { errors, isSubmitted } = useFormState({
    control,
  });
  const [isNicknameModalOpen, setIsNicknameModalOpen] = useState<boolean>(false);

  const watchNicknameApproval = useWatch({ name: 'nickname_approval', control });
  const watchNameApproval = useWatch({ name: 'name_approval', control });
  const watchAnonymizedApproval = useWatch({ name: 'anonymized_approval', control });
  const watchPrivacyPolicyApproval = useWatch({ name: 'privacy_policy_approval', control });

  useEffect(() => {
    if (watchAnonymizedApproval || watchNicknameApproval || watchNameApproval) {
      clearErrors('approval');
    }
    if (!(watchAnonymizedApproval || watchNicknameApproval || watchNameApproval) && isSubmitted) {
      setError('approval', {
        type: 'manual',
        message: 'Musisz zaznaczyć minimum jedną zgodę',
      });
    }
  }, [watchAnonymizedApproval, watchNicknameApproval, watchNameApproval, isSubmitted]);

  useEffect(() => {
    if (!survey.privacy_policy) {
      clearErrors('privacyPolicyApproval');
    }
    else {
      if (!watchPrivacyPolicyApproval && isSubmitted) {
        setError('privacy_policy_approval', {
          type: 'manual',
          message: 'Akceptacja polityki prywatności jest wymagana'
        });
      } else {
        clearErrors('privacy_policy_approval');
      }
    }
  }, [watchPrivacyPolicyApproval, isSubmitted]);

  useEffect(() => {
    if (watchNicknameApproval && !user.voting_nick) {
      setIsNicknameModalOpen(true);
    }
  }, [watchNicknameApproval, user]);

  const obscureEmail = (email: string) => {
    const [name, domain] = email.split('@');
    const [part1, part2] = domain.split('.');
    return `${name[0]}${new Array(name.length).join('*')}@${new Array(part1.length + 1).join(
      '*'
    )}.${new Array(part2.length + 1).join('*')}`;
  };

  return (
    <AgreementsContainer>
      {isNicknameModalOpen && (
        <SimpleModal
          open={isNicknameModalOpen}
          setOpen={setIsNicknameModalOpen}
          title={'Ustaw nick'}
          text={'Ustaw swój nick, który zostanie przypisany do Twojego konta.'}
          children={
            <NicknameModal
              setOpen={setIsNicknameModalOpen}
              failCallback={() => setValue('nickname_approval', false)}
              setUserNickname={setUserNickname}
              setUserNicknameFetchStatus={setUserNicknameFetchStatus}
            />
          }
          customButtonComponent={<></>}
          iconComponent={UserTitleIcon}
          disableClose={true}
        />
      )}
      {user && survey && (
        <>
          {survey?.privacy_policy && (
            <>
              <Checkbox
                formState={formState}
                label={`Akceptuję politykę prywatności dodaną przez organizatora.`}
                name={`privacy_policy_approval`}
                formRef={register}
              />
              <a href={survey.privacy_policy} target={'_blank'} style={{color: "inherit", marginLeft: "35px"}}>Zobacz treść polityki prywatności</a>
              <ErrorMessage fieldError={errors.privacy_policy_approval} />
            </>
          )}
          {survey?.is_protocol_label_nick && !watchNameApproval && (
            <Checkbox
              formState={formState}
              label={`Wyrażam zgodę na publikację swojej odpowiedzi w formie pseudonimu${
                user?.voting_nick ? ` (${user?.voting_nick})` : ''
              }, na którą oddałem głos, w protokole, do którego inni użytkownicy głosujący w konkretnym głosowaniu będą mieli dostęp oraz inne osoby trzecie. `}
              name={`nickname_approval`}
              formRef={register}
            />
          )}
          {survey?.is_protocol_label_name && !watchNicknameApproval && (
            <Checkbox
              formState={formState}
              label={`Wyrażam zgodę na publikację swojej odpowiedzi w formie imienia i nazwiska${
                user?.first_name || user?.last_name
                  ? ` (${[user?.first_name, user?.last_name].filter((val) => !!val).join(' ')})`
                  : ''
              }, na którą oddałem głos, w protokole, do którego inni użytkownicy głosujący w konkretnym głosowaniu będą mieli dostęp oraz inne osoby trzecie. `}
              name={`name_approval`}
              formRef={register}
            />
          )}
          {((survey?.is_protocol_label_nick && !survey?.is_protocol_label_name) ||
            (!survey?.is_protocol_label_nick && !survey?.is_protocol_label_name)) && (
            <Checkbox
              formState={formState}
              label={`Wyrażam zgodę na publikację swojej odpowiedzi w formie zanonimizowanego maila${
                user?.public_email
                  ? ` (${user?.public_email})`
                  : user?.email
                  ? ` (${obscureEmail(user?.email)})`
                  : ''
              }, na którą oddałem głos, w protokole, do którego inni użytkownicy głosujący w konkretnym głosowaniu będą mieli dostęp oraz inne osoby trzecie. `}
              name={`anonymized_approval`}
              formRef={register}
            />
          )}
        </>
      )}
      <ErrorMessage fieldError={errors.approval} />
      <Checkbox
        formState={formState}
        label={'PermissionToAddToGroup'}
        name={`assign_to_group`}
        formRef={register}
      />
    </AgreementsContainer>
  );
};

function mapStateToProps(state: any, otherProps: RouteComponentProps) {
  return {
    user: state.auth.user,
    setUserNicknameFetchStatus: state.auth.setUserNicknameFetchStatus,
  };
}

export default withRouter(connect(mapStateToProps, { setUserNickname })(ProjectVoteAgreements));
