import React from 'react';
import { DateTimePicker as Picker } from '@material-ui/pickers';
import { createTheme, IconButton, InputAdornment } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersOverrides } from '@material-ui/pickers/typings/overrides';
import { colors } from '../../theme';
import moment from 'moment';
import { Trans } from 'react-i18next';
import ErrorMessage from './ErrorMessage';
import { FieldError, useWatch } from 'react-hook-form';
import { ValidationTypes } from '../../types/form.types';
import EventIcon from '@material-ui/icons/Event';

type overridesNameToClassKey = {
  [P in keyof MuiPickersOverrides]: keyof MuiPickersOverrides[P];
};

declare module '@material-ui/core/styles/overrides' {
  export interface ComponentNameToClassKey extends overridesNameToClassKey {}
}

const materialTheme = createTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: colors.lightBlue,
      },
    },
    MuiInput: {
      underline: {
        '&:hover': {
          '&:not(.Mui-disabled)': {
            '&:before': {
              borderBottom: `2px solid ${colors.lightBlue}`,
            },
          },
        },
        '&:after': {
          borderBottom: `2px solid ${colors.lightBlue}`,
        },
      },
    },
    MuiInputBase: {
      input: {
        textAlign: 'center',
        cursor: 'pointer',
      },
    },
    MuiTypography: {
      root: {
        color: colors.lightBlue,
      },
    },
    MuiPickersDay: {
      day: {
        color: colors.lightBlue,
      },
      daySelected: {
        backgroundColor: colors.darkBlue,
        '&:hover': {
          backgroundColor: colors.lightBlue,
        },
      },
      dayDisabled: {
        color: colors.gray,
      },
      current: {
        color: colors.black,
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: colors.lightBlue,
      },
    },
    MuiPickerDTTabs: {
      tabs: {
        backgroundColor: colors.lightBlue,
      },
    },
    MuiPickersClock: {
      squareMask: {
        cursor: 'pointer',
      },
      pin: {
        backgroundColor: colors.lightBlue,
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: colors.darkBlue,
      },
    },
    MuiPickersClockPointer: {
      noPoint: {
        backgroundColor: colors.lightBlue,
      },
      pointer: {
        backgroundColor: colors.lightBlue,
      },
      thumb: {
        borderColor: colors.lightBlue,
      },
    },
  },
});

const inputAdornmentTheme = createTheme({
  overrides: {
    MuiSvgIcon: {
      root: {
        fill: colors.lightBlue,
      },
    },
  },
});

interface Props {
  value?: any;
  onChange(e: any): void;
  minDate?: Date;
  maxDate?: Date;
  name: string;
  formRef: any;
  disabled?: boolean;
  disablePast?: boolean;
  minDateMessage?: string;
  maxDateMessage?: string;
  fieldError?: FieldError | undefined;
  validate?: ValidationTypes;
  control?: any;
}

const DateTimePicker: React.FC<Props> = ({
  value,
  onChange,
  minDate,
  maxDate,
  name,
  formRef,
  disabled,
  disablePast,
  minDateMessage,
  maxDateMessage,
  fieldError,
  validate,
  control,
}) => {
  const watchValue = useWatch({ name: name, control });

  return (
    <ThemeProvider theme={materialTheme}>
      <Picker
        variant="inline"
        ampm={false}
        value={value || watchValue ? moment(value || watchValue) : null}
        onChange={(e: any) => onChange(e)}
        format="DD/MM/YYYY HH:mm"
        minDate={minDate || new Date('1900-01-01')}
        minDateMessage={<Trans>{minDateMessage}</Trans>}
        maxDate={maxDate || new Date('2100-01-01')}
        maxDateMessage={<Trans>{maxDateMessage}</Trans>}
        autoOk={true}
        disabled={disabled}
        disablePast={disablePast}
        InputProps={{
          endAdornment: (
            <ThemeProvider theme={inputAdornmentTheme}>
              <InputAdornment position="end">
                <IconButton>
                  <EventIcon fill={colors.lightBlue} />
                </IconButton>
              </InputAdornment>
            </ThemeProvider>
          ),
        }}
      />
      <ErrorMessage fieldError={fieldError} validate={validate} />
      <input style={{ display: 'none' }} type={'string'} {...formRef(name, validate)} />
    </ThemeProvider>
  );
};

export default DateTimePicker;
