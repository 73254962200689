import * as yup from 'yup';
import { AnyObject, Maybe } from 'yup/lib/types';
import { yupLocale } from './yupHelpers';

yupLocale();

// https://github.com/jquense/yup/issues/312#issuecomment-745034006

const URL =
  /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;

export const mixedTests = {
  fileRequired: {
    name: 'fileRequired',
    message: 'To pole jest wymagane',
    fun: (value: any) => value?.length !== 0,
  },
  fileExtension: {
    name: 'fileExtension',
    message: 'Nieprawidłowe rozszerzenie',
    fun: (extensions: Array<string>) => (value: any) =>
      !value ||
      value?.length === 0 ||
      (value && value[0] && extensions.includes(value[0]?.name.split('.').pop())),
  },
  fileSize: {
    name: 'fileSize',
    message: (sizeMB: number) =>
      `Zbyt duży rozmiar pliku. Maksymalny rozmiar załącznika to ${sizeMB}MB.`,
    fun: (sizeMB: number) => (value: any) =>
      value?.length === 0 || !(value && value[0].size > sizeMB * 1024 * 1024),
  },
};

yup.addMethod<yup.NumberSchema>(yup.number, 'maxDecimalDigits', function (digitsNumber: number) {
  return this.test(
    'maxDecimalDigits',
    `Wartośc może posiadać tylko ${digitsNumber} miejsc po przecinku`,
    function (value) {
      const regexp = new RegExp(`^\\d+(\\.\\d{1,${digitsNumber}})?$`);
      return !!value && regexp.test(value.toString());
    }
  );
});

yup.addMethod<yup.StringSchema>(yup.string, 'urlExtended', function () {
  return this.test('urlExtended', `To nie jest link`, function (value) {
    const regexp = new RegExp(URL);
    return !value || regexp.test(value);
  });
});

declare module 'yup' {
  interface NumberSchema<
    TType extends Maybe<number> = number | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends yup.BaseSchema<TType, TContext, TOut> {
    maxDecimalDigits(digitsNumber: number): NumberSchema<TType, TContext>;
  }
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends yup.BaseSchema<TType, TContext, TOut> {
    urlExtended(): StringSchema<TType, TContext>;
  }
}

export default yup;
