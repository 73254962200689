
export enum DeviceTypeEnum {
  android = 'android',
  ios = 'ios',
}

export interface FCMRegisterPayload {
  registration_id: string;
  device_id: string;
  name: string;
  type: DeviceTypeEnum;
  app_version?: string;
  // for event type message
  deep_link: string;
}
