import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import MyProfile from './MyProfile';
import {
  getAuthInfo,
  resetPassword,
  sendEmailConfirmation,
  updateUser,
  updateUserPhone,
  generateBlockchainKeys,
  generateQRCode,
  setBlockchainFetchStatus,
  setUserFetchStatus,
  setUpdateUserFetchStatus,
  getBlockchainStatement,
  deleteMyAccount,
  setUpdateUserFetchError,
  logout,
  resetUserFetchStatus,
} from '../../reducers/Auth/auth.actions';
import {
  setEmailModalOpen,
  setIsQrCodeModalOpen,
  setPhoneModalOpen,
} from '../../reducers/Utils/utils.actions';
import { getSurveyDetail } from '../../reducers/Surveys/surveys.actions';

function mapStateToProps(state: any, otherProps: RouteComponentProps) {
  return {
    user: state.auth.user,
    userFetchStatus: state.auth.user.userFetchStatus,
    blockchainKeys: state.auth.blockchainKeys,
    blockchainFetchStatus: state.auth.blockchainFetchStatus,
    updateUserFetchStatus: state.auth.updateUserFetchStatus,
    userPatchFetchError: state.auth.updateUserFetchError,
    emailModalOpen: state.utils.emailModal,
    phoneModalOpen: state.utils.phoneModal,
    countriesOptions: state.constants.dropdowns?.countries,
    streets: state.governmentLocation.street.list,
    deleteMyAccountFetchStatus: state.auth.deleteMyAccountFetchStatus,
    FCMDeviceInfo: state.notifications.fcmDevice?.info,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getAuthInfo,
    updateUser,
    updateUserPhone,
    sendEmailConfirmation,
    resetPassword,
    generateBlockchainKeys,
    setBlockchainFetchStatus,
    setIsQrCodeModalOpen,
    setUpdateUserFetchStatus,
    generateQRCode,
    getBlockchainStatement,
    deleteMyAccount,
    //todo: remove, tmp when user was invited and just registered
    getSurveyDetail,
    setPhoneModalOpen,
    setEmailModalOpen,
    setUpdateUserFetchError,
    logout,
    resetUserFetchStatus,
  })(MyProfile)
);
