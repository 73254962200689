import React, { useEffect, useRef } from 'react';
import moment from 'moment';
import { getSurveysListForEndToast } from '../reducers/Surveys/surveys.actions';
import { showNotification } from '../utils/taost.config';
import { Trans } from 'react-i18next';
import { useInterval } from '../utils/useInterval';

const delay = 60 * 1000;

const useEndSurveyToast = ({ user, token }: { user: any; token: string | null }): void => {
  const queryDate: any = useRef(localStorage.getItem('notificationQueryDate'));
  const requestTimeoutRef: any = useRef();

  const endSurveyNotification = async () => {
    if (user && token) {
      const date = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss');
      if (queryDate.current) {
        let queryData: any = {
          date_after: queryDate.current,
          date_before: date,
        };
        const searchParams = `?${new URLSearchParams(queryData).toString()}`;
        // todo: move to redux
        await getSurveysListForEndToast(searchParams).then((data) => {
          data &&
            data.forEach((survey: { name: string; id: string; uuid: string }) => {
              showNotification({
                translatedMessage: (
                  <>
                    <Trans>EndedSurveyNotificationBefore</Trans> <strong>{survey.name}</strong>{' '}
                    <Trans>EndedSurveyNotificationAfter</Trans>
                  </>
                ),
                type: 'warning',
                autoClose: 15000,
              });
            });
        });
      }
      queryDate.current = date;
      localStorage.setItem('notificationQueryDate', date);
    }
  };

  useEffect(() => {
    endSurveyNotification();
    requestTimeoutRef.current = setTimeout(() => {
      endSurveyNotification();
    }, delay);

    return () => {
      clearTimeout(requestTimeoutRef.current);
    };
  }, []);
};

export default useEndSurveyToast;
