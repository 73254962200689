import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import SurveyDetail from './SurveyDetail';
import { getSurveyDetail, resetSurveyDetail } from '../../../reducers/Surveys/surveys.actions';
import { inviteMore, setInviteMoreFetchStatus } from '../../../reducers/Groups/groups.actions';

type OtherProps = RouteComponentProps<{
  uuid: string;
}>;

function mapStateToProps(state: any, otherProps: OtherProps) {
  return {
    uuid: otherProps.match.params.uuid,
    survey: state.surveys.detail,
    inviteMoreFetchStatus: state.groups.inviteMoreFetchStatus,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getSurveyDetail,
    resetSurveyDetail,
    inviteMore,
    setInviteMoreFetchStatus,
  })(SurveyDetail)
);
