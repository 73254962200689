import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';
import ErrorMessage from './ErrorMessage';
import { FormTypes } from '../../types/form.types';
import i18next from 'i18next';
import { EndAdornmentContainer, FormInput, FormLabel } from './forms';
import InputReadOnly from './InputReadOnly';
import InputDescription from './InputDescription';

interface InputTypes extends FormTypes {
  label?: string;
  name: string;
  description?: string;
  tooltipText?: string;
  disabled?: boolean;
  final?: boolean;
  EndAdornment?: JSX.Element;
  disabledFormInput?: boolean;
  disabledValue?: string | null;
  setValue?: any;
}

const InputText: React.FC<InputTypes> = ({
  label,
  description,
  name,
  type = 'text',
  placeholder = '',
  formRef,
  fieldError,
  validate = {},
  formState,
  defaultValue = '',
  borderColor,
  color,
  caretColor,
  formType,
  showRequiredStar,
  disabled = false,
  final,
  tooltipText,
  onFocus,
  onBlur,
  onInputClick,
  EndAdornment,
  autocomplete = true,
  disabledFormInput = false,
  disabledValue,
  setValue,
}) => {
  useEffect(() => {
    if (setValue && disabledValue && disabled) setValue(name, disabledValue);
  }, [disabledValue, disabled]);

  return disabled || final ? (
    <InputReadOnly
      label={label}
      showRequiredStar={showRequiredStar}
      name={name}
      formRef={formRef}
      formState={formState}
      defaultValue={disabledValue ? disabledValue : defaultValue}
      color={color}
      final={final}
      description={description}
      tooltipText={tooltipText}
    />
  ) : (
    <div style={{ width: '100%', textAlign: 'center' }}>
      {label && (
        <FormLabel color={color} formType={formType}>
          <Trans>{label}</Trans>
          {(validate.required || showRequiredStar) && '*'}
        </FormLabel>
      )}
      {description && <InputDescription text={description} />}

      <div style={{ position: 'relative' }}>
        <FormInput
          type={type}
          {...formRef(name, validate)}
          placeholder={i18next.t(placeholder)}
          isDirty={formState.dirtyFields[name]}
          defaultValue={defaultValue}
          borderColor={borderColor}
          color={color}
          caretColor={caretColor}
          formType={formType}
          onFocus={onFocus}
          onBlur={onBlur}
          autocomplete={autocomplete}
          autoComplete={autocomplete ? 'on' : 'off'}
          onClick={onInputClick}
          pointerEvents={disabledFormInput ? 'none' : 'auto'}
        />
        <EndAdornmentContainer>{EndAdornment}</EndAdornmentContainer>
      </div>
      <ErrorMessage fieldError={fieldError} validate={validate} />
    </div>
  );
};

export default InputText;
