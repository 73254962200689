import { connect } from 'react-redux';
import NewSurvey from './NewSurvey';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { resetNewSurveyData, updateNewSurveyData } from '../../../reducers/Surveys/surveys.actions';

type OtherProps = RouteComponentProps<{
  type: 'simple' | 'extended' | 'image' | undefined;
}>;

function mapStateToProps(state: any, otherProps: OtherProps) {
  return {
    user: state.auth.user,
    type: otherProps.match.params.type,
    newSurvey: state.surveys.newSurvey,
    // newConfirmedSurvey: state.surveys.newConfirmedSurvey,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    resetNewSurveyData,
    updateNewSurveyData,
  })(NewSurvey)
);
