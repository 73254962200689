import styled from 'styled-components';
import { colors } from '../theme';

export const ActionButton: any = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${(props: any) => (props.bgColor ? props.bgColor : colors.darkBlue)};
  border: ${(props: any) => (props.border ? props.border : 'none')};
  color: ${(props: any) => (props.color ? props.color : colors.white)};
  height: 20px;
  width: 20px;
  font-size: 0.875rem;
  margin-left: 0.375rem;
  &:hover {
    cursor: pointer;
  }

  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg.MuiSvgIcon-root {
    height: 70%;
  }
`;

export const IconContainer = styled.span`
  margin-left: auto;
  cursor: pointer;
  display: ${(props: any) => (props.display ? props.display : 'flex')};
  color: white;
`;
