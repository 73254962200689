import { Dispatch } from 'redux';
import {
  INVITE_MORE_FETCH_STATUS,
  PROCESS_CSV_FETCH_STATUS,
  SET_NEW_GROUP_WITH_CONTACTS,
  SET_NEW_GROUP_WITH_CONTACTS_FETCH_STATUS,
  UPDATE_COORDINATORS_GROUPS_DETAIL_FETCH_STATUS,
  UPDATE_COORDINATORS_GROUPS_LIST,
  UPDATE_COORDINATORS_GROUPS_LIST_FETCH_STATUS,
  UPDATE_GROUP_FETCH_STATUS,
  UPDATE_GROUPS_DETAIL,
  UPDATE_GROUPS_LIST,
  UPDATE_I_BELONG_TO_GROUPS_LIST,
  UPDATE_I_BELONG_TO_GROUPS_LIST_FETCH_STATUS,
  UPDATE_LEAVE_GROUP_FETCH_STATUS,
} from './groups.actionTypes';
import axios from 'axios';
import { showNotification } from '../../utils/taost.config';
import { GroupData } from '../../types/groups.types';
import { history } from '../../utils/history';
import { getGroupsPath } from '../../constants/paths.constants';
import { REQUEST_STATUSES } from '../../constants/requestStatuses.constants';

export const getGroups = () => {
  let url = process.env.REACT_APP_API_URL + `/user/contact_groups/`;
  return function (dispatch: Dispatch) {
    axios
      .get(url)
      .then((response) => {
        dispatch(setGroups(response.data));
      })
      .catch((error) => {
        showNotification({
          response: error.response,
          type: 'error',
        });
      });
  };
};

export const getCoordinatorGroups = () => {
  let url = process.env.REACT_APP_API_URL + `/user/contact_foreign_groups/`;

  return function (dispatch: Dispatch) {
    dispatch(setCoordinatorsGroupsListFetchStatus(REQUEST_STATUSES.FETCHING));
    axios
      .get(url)
      .then((response) => {
        dispatch(setCoordinatorsGroups(response.data));
        dispatch(setCoordinatorsGroupsListFetchStatus(REQUEST_STATUSES.DONE));
      })
      .catch((error) => {
        showNotification({
          response: error.response,
          type: 'error',
        });
        dispatch(setCoordinatorsGroupsListFetchStatus(REQUEST_STATUSES.ERROR));
      });
  };
};

export const leaveGroup = ({ uuid }: { uuid: string }) => {
  let url = process.env.REACT_APP_API_URL + `/user/contact_connected_groups/${uuid}/leave_group/`;

  return function (dispatch: Dispatch) {
    dispatch(setLeaveGroupFetchStatus(REQUEST_STATUSES.FETCHING));
    axios
      .post(url)
      .then((response) => {
        dispatch(setLeaveGroupFetchStatus(REQUEST_STATUSES.DONE));
      })
      .catch((error) => {
        showNotification({
          response: error.response,
          type: 'error',
        });
        dispatch(setLeaveGroupFetchStatus(REQUEST_STATUSES.ERROR));
      });
  };
};

export const getGroupsIBelongTo = () => {
  let url = process.env.REACT_APP_API_URL + `/user/contact_connected_groups/`;

  return function (dispatch: Dispatch) {
    dispatch(setIBelongToGroupsListFetchStatus(REQUEST_STATUSES.FETCHING));
    axios
      .get(url)
      .then((response) => {
        dispatch(setIBelongToGroups(response.data));
        dispatch(setIBelongToGroupsListFetchStatus(REQUEST_STATUSES.DONE));
      })
      .catch((error) => {
        showNotification({
          response: error.response,
          type: 'error',
        });
        dispatch(setIBelongToGroupsListFetchStatus(REQUEST_STATUSES.ERROR));
      });
  };
};

export const getGroup = (data: { uuid: string }) => {
  let url = process.env.REACT_APP_API_URL + `/user/contact_groups/${data.uuid}/`;
  return function (dispatch: Dispatch) {
    axios
      .get(url)
      .then((response) => {
        dispatch(setGroup(response.data));
      })
      .catch((error) => {
        history.push(getGroupsPath());
        showNotification({
          response: error.response,
          type: 'error',
        });
      });
  };
};

export const postGroup = (data: GroupData) => {
  let url = process.env.REACT_APP_API_URL + `/user/contact_groups/`;
  return function (dispatch: Dispatch) {
    axios
      .post(url, data)
      .then((response) => {
        dispatch(setGroup(response.data));
        history.push(`${getGroupsPath()}/${response.data.uuid}`);
        showNotification({
          message: 'Dodano grupę',
          type: 'success',
        });
      })
      .catch((error) => {
        showNotification({
          response: error.response,
          type: 'error',
        });
      });
  };
};

export const postGroupWithContacts = (data: GroupData, redirect?: string) => {
  let url = process.env.REACT_APP_API_URL + `/user/contact_groups/create_with_contacts/`;
  return function (dispatch: Dispatch) {
    axios
      .post(url, data)
      .then((response) => {
        dispatch(setGroup(response.data));
        showNotification({
          message: 'Dodano grupę',
          type: 'success',
        });
        if (!!redirect) history.push(redirect);
      })
      .catch((error) => {
        showNotification({
          response: error.response,
          type: 'error',
        });
      });
  };
};

export async function postNewGroup(data: GroupData) {
  let url = process.env.REACT_APP_API_URL + `/user/contact_groups/`;
  return axios
    .post(url, data)
    .then((response) => {
      showNotification({
        message: 'Dodano grupę',
        type: 'success',
      });
      return response.data;
    })
    .catch((error) => {
      showNotification({
        response: error.response,
        type: 'error',
      });
    });
}

export function postNewGroupWithContacts(data: GroupData) {
  let url = process.env.REACT_APP_API_URL + `/user/contact_groups/create_with_contacts/`;
  return function (dispatch: Dispatch) {
    dispatch(setNewGroupWithContactsFetchStatus(REQUEST_STATUSES.FETCHING));
    axios
      .post(url, data)
      .then((response) => {
        dispatch(setNewGroupWithContacts(response.data));
        dispatch(setNewGroupWithContactsFetchStatus(REQUEST_STATUSES.DONE));
        showNotification({
          message: 'Dodano grupę',
          type: 'success',
        });
      })
      .catch((error) => {
        dispatch(setNewGroupWithContactsFetchStatus(REQUEST_STATUSES.ERROR));
        showNotification({
          response: error.response,
          type: 'error',
        });
      });
  };
}

export const updateGroup = (uuid: string, data: GroupData, redirect?: string) => {
  let url = process.env.REACT_APP_API_URL + `/user/contact_groups/${uuid}/`;
  return function (dispatch: Dispatch) {
    dispatch(setUpdateGroupFetchStatus(REQUEST_STATUSES.FETCHING));
    axios
      .patch(url, data)
      .then((response) => {
        dispatch(setGroup(response.data));
        dispatch(setUpdateGroupFetchStatus(REQUEST_STATUSES.DONE));
        showNotification({
          message: 'Edytowano grupę',
          type: 'success',
        });
        if (!!redirect) history.push(redirect);
      })
      .catch((error) => {
        dispatch(setUpdateGroupFetchStatus(REQUEST_STATUSES.ERROR));
        showNotification({
          response: error.response,
          type: 'error',
        });
      });
  };
};

export const deleteGroup = (uuid: string) => {
  let url = process.env.REACT_APP_API_URL + `/user/contact_groups/${uuid}/`;
  return function (dispatch: Dispatch) {
    axios
      .delete(url)
      .then((response) => {
        // @ts-ignore
        dispatch(getGroups());
        showNotification({
          message: 'Usunięto grupę',
          type: 'success',
        });
        history.push(getGroupsPath());
      })
      .catch((error) => {
        showNotification({
          response: error.response,
          type: 'error',
        });
      });
  };
};

export const inviteGroups = (data: { survey: number; groups: Array<number> }) => {
  let url = process.env.REACT_APP_API_URL + `/user/contact_groups/invite_groups/`;
  return function (dispatch: Dispatch) {
    axios
      .post(url, data)
      .then((response) => {
        showNotification({
          message: 'Zaproszono grupy',
          type: 'success',
        });
      })
      .catch((error) => {
        showNotification({
          response: error.response,
          type: 'error',
        });
      });
  };
};

export const resetGroup = () => {
  return function (dispatch: Dispatch) {
    dispatch(setGroup({}));
  };
};

export const inviteMore = (data: { emails: Array<string>; group_ids: Array<number> }) => {
  let url = `${process.env.REACT_APP_API_URL}/user/contact_groups/update_contacts/`;

  return function (dispatch: Dispatch) {
    dispatch(setInviteMoreFetchStatus(REQUEST_STATUSES.FETCHING));
    axios
      .post(url, data)
      .then((response) => {
        // @ts-ignore
        dispatch(getGroups());
        dispatch(setInviteMoreFetchStatus(REQUEST_STATUSES.DONE));
      })
      .catch((error) => {
        showNotification({
          response: error.response,
          type: 'error',
        });
        dispatch(setInviteMoreFetchStatus(REQUEST_STATUSES.ERROR));
      });
  };
};

export const processCSV = (data: { [key: string]: any }) => {
  let url = process.env.REACT_APP_API_URL + `/user/process_csv/`;
  return function (dispatch: Dispatch) {
    dispatch(setProcessCSVFetchStatus(REQUEST_STATUSES.FETCHING));
    axios
      .post(url, data)
      .then((response) => {
        const payload = {
          contacts: response.data.contacts,
        };
        dispatch(setProcessCSVFetchStatus(REQUEST_STATUSES.DONE));
        // @ts-ignore
        dispatch(updateGroup(data.uuid, payload));
      })
      .catch((error) => {
        dispatch(setProcessCSVFetchStatus(REQUEST_STATUSES.ERROR));
        showNotification({
          response: error.response,
          type: 'error',
        });
      });
  };
};

export const setInviteMoreFetchStatus = (data: any) => {
  return {
    type: INVITE_MORE_FETCH_STATUS,
    payload: data,
  };
};

export const setUpdateGroupFetchStatus = (data: any) => {
  return {
    type: UPDATE_GROUP_FETCH_STATUS,
    payload: data,
  };
};

export const setProcessCSVFetchStatus = (data: any) => {
  return {
    type: PROCESS_CSV_FETCH_STATUS,
    payload: data,
  };
};

export const setGroups = (data: any) => {
  return {
    type: UPDATE_GROUPS_LIST,
    payload: data,
  };
};

export const setCoordinatorsGroups = (data: any) => {
  return {
    type: UPDATE_COORDINATORS_GROUPS_LIST,
    payload: data,
  };
};

export const setCoordinatorsGroupsListFetchStatus = (data: any) => {
  return {
    type: UPDATE_COORDINATORS_GROUPS_LIST_FETCH_STATUS,
    payload: data,
  };
};

export const setIBelongToGroups = (data: any) => {
  return {
    type: UPDATE_I_BELONG_TO_GROUPS_LIST,
    payload: data,
  };
};

export const setIBelongToGroupsListFetchStatus = (data: any) => {
  return {
    type: UPDATE_I_BELONG_TO_GROUPS_LIST_FETCH_STATUS,
    payload: data,
  };
};

export const setLeaveGroupFetchStatus = (data: any) => {
  return {
    type: UPDATE_LEAVE_GROUP_FETCH_STATUS,
    payload: data,
  };
};

export const setCoordinatorsGroupsDetailsFetchStatus = (data: any) => {
  return {
    type: UPDATE_COORDINATORS_GROUPS_DETAIL_FETCH_STATUS,
    payload: data,
  };
};

export const setGroup = (data: any) => {
  return {
    type: UPDATE_GROUPS_DETAIL,
    payload: data,
  };
};

export const setNewGroupWithContacts = (data: any) => {
  return {
    type: SET_NEW_GROUP_WITH_CONTACTS,
    payload: data,
  };
};

export const setNewGroupWithContactsFetchStatus = (data: any) => {
  return {
    type: SET_NEW_GROUP_WITH_CONTACTS_FETCH_STATUS,
    payload: data,
  };
};
