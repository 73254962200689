import { MenuLink } from '../../types/layout.types';
import { logout } from '../../reducers/Auth/auth.actions';
import {
  getContactPersonsPath,
  getCreateSurveyPath,
  getGroupsPath,
  getMyProfilePath,
  getSurveysPath,
  getTermsAndConditionsPath,
} from '../../constants/paths.constants';

export const menuLinks: Array<MenuLink> = [
  {
    text: 'Vote',
    path: `${getSurveysPath()}?active=true`,
  },
  {
    text: 'CreateVoting',
    path: getCreateSurveyPath(),
  },
  {
    text: 'VotingsList',
    path: getSurveysPath(),
  },
  {
    text: 'MyProfile',
    path: getMyProfilePath(),
  },
  {
    text: 'ContactPersons',
    path: getContactPersonsPath(),
  },
  {
    text: 'Groups',
    path: getGroupsPath(),
  },
  {
    text: 'TermsAndConditions',
    path: getTermsAndConditionsPath(),
  },
  {
    text: 'Logout',
    action: 'logout',
  },
];
