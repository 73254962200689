import React, { useEffect, useState } from 'react';
import { LogoSvgMedium, MainHeader2, MainText2 } from '../../../utils/uiComponents';
import { history } from '../../../utils/history';
import { Trans } from 'react-i18next';
import { useForm } from 'react-hook-form';
import InputText from '../../../components/Forms/InputText';
import { UserData } from '../../../types/auth.types';
import {
  Center,
  Form,
  ContainerBlock,
  AbsoluteButtonContainer,
} from '../../../components/Containers/containers';
import { PrimarySmallButton } from '../../../components/Buttons/buttons';
import { colors } from '../../../theme';
import { REQUEST_STATUSES } from '../../../constants/requestStatuses.constants';
import { EmailTitleIcon } from '../../../components/Modal/ModalTitleIcons';
import SimpleModal from '../../../components/Modal/SimpleModal';
import { getMyProfilePath } from '../../../constants/paths.constants';

interface Props {
  smsCodeId: string;
  user: UserData;
  postPhoneCodeFetchStatus: string;

  sendPhoneSignup(phone: string): void;
  confirmPhoneSignup(data: { id: string; raw_code: string }): void;
  setPostPhoneCodeFetchStatus(status: string | null): void;
}

const modalConfig = {
  title: 'modalVerifyPhoneTitle',
  text: 'modalVerifyPhoneText',
};

const Authenticate: React.FC<Props> = ({
  sendPhoneSignup,
  user,
  smsCodeId,
  confirmPhoneSignup,
  postPhoneCodeFetchStatus,
  setPostPhoneCodeFetchStatus,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    formState,
  } = useForm<{ code: string }>();
  const [isOpen, setIsOpen] = useState<any>(true);

  useEffect(() => {
    if (!user.is_phone_verified && history.location.state?.phone) {
      sendPhoneSignup(history.location.state.phone);
    } else {
      history.push(getMyProfilePath());
    }
  }, []);
  const onSubmit = (data: { code: string }) => {
    confirmPhoneSignup({
      id: smsCodeId,
      raw_code: data.code,
    });
  };

  const handleModalClose = () => {
    setPostPhoneCodeFetchStatus(REQUEST_STATUSES.NULL);
    setIsOpen(false);
    history.push(getMyProfilePath());
  };

  return (
    <ContainerBlock>
      {postPhoneCodeFetchStatus === REQUEST_STATUSES.DONE && (
        <SimpleModal
          {...modalConfig}
          open={isOpen}
          setOpen={setIsOpen}
          iconComponent={EmailTitleIcon}
          onButtonClick={handleModalClose}
        />
      )}
      <Center style={{ paddingTop: '5rem' }}>
        <MainHeader2>
          <Trans>SmsVerificationHeader</Trans>
        </MainHeader2>
        <MainText2>
          {user?.phone ? (
            <>
              <Trans>SmsVerificationTextPart1</Trans>
              {user.phone}
              <div>
                <Trans>SmsVerificationTextPart2</Trans>
              </div>
            </>
          ) : (
            <Trans>SmsVerificationText</Trans>
          )}
        </MainText2>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <InputText
            label={'Kod'}
            name={'code'}
            formRef={register}
            fieldError={errors.code}
            formState={formState}
            borderColor={colors.grayBorder}
          />
          <AbsoluteButtonContainer>
            <PrimarySmallButton
              type="submit"
              borderColor={colors.darkBlue}
              bgColor={colors.darkBlue}
              color={colors.white}
            >
              <Trans>Dalej</Trans>
            </PrimarySmallButton>
          </AbsoluteButtonContainer>
        </Form>
      </Center>
    </ContainerBlock>
  );
};

export default Authenticate;
