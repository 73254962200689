import React, { useEffect, useState } from 'react';
import { Form } from '../../../components/Containers/containers';
import { useForm, useWatch } from 'react-hook-form';
import { ButtonsWrapper, ModalButton } from '../../../components/Modal/ModalPartials';
import { REQUEST_STATUSES } from '../../../constants/requestStatuses.constants';
import { Trans } from 'react-i18next';
import InputText from '../../../components/Forms/InputText';
import ErrorMessage from '../../../components/Forms/ErrorMessage';
import MultiselectEmail from '../../../components/MultiselectEmail';
import { NewGroupWithContacts } from '../../../types/groups.types';

interface Props {
  newGroupWithContactsFetchStatus: string | null;

  setOpen(isOpen: boolean): void;
  postNewGroupWithContacts(data: NewGroupWithContacts): void;
}

type Inputs = {
  emails: string;
  group_name: string;
};

const CreateGroupModalContent: React.FC<Props> = (props) => {
  const { setOpen, postNewGroupWithContacts, newGroupWithContactsFetchStatus } = props;
  const [newEmails, setNewEmails] = useState<Array<string>>([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    formState,
    control,
    setError,
    clearErrors,
  } = useForm<Inputs>();

  const watchGroupName = useWatch({
    name: 'group_name',
    defaultValue: '',
    control,
  });

  useEffect(() => {
    if (!!errors.emails) clearErrors('emails');
  }, [newEmails]);

  useEffect(() => {
    if (watchGroupName) {
      if (!!errors.group_name) {
        clearErrors('group_name');
      }
    } else {
      if (!!errors.emails) {
        clearErrors('emails');
      }
    }
  }, [watchGroupName]);

  useEffect(() => {
    if (newGroupWithContactsFetchStatus === REQUEST_STATUSES.DONE) handleClose();
  }, [newGroupWithContactsFetchStatus]);

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = (data: Inputs) => {
    let isValid = true;
    if (!data.group_name) {
      isValid = false;
      setError('group_name', { type: 'required' });
    }
    if (newEmails.length < 1) {
      isValid = false;
      setError('emails', { type: 'required' });
    }
    if (isValid) {
      postNewGroupWithContacts({ name: watchGroupName, emails: newEmails });
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <InputText
        label={''}
        name={'group_name'}
        formRef={register}
        formState={formState}
        formType={'survey'}
        placeholder={'Nazwa grupy'}
      />
      <ErrorMessage fieldError={errors.group_name} />
      <MultiselectEmail setEmails={setNewEmails} emails={newEmails} errors={errors} />
      <ButtonsWrapper>
        <ModalButton
          type={'submit'}
          full
          disabled={newGroupWithContactsFetchStatus === REQUEST_STATUSES.FETCHING}
        >
          <Trans>Utwórz</Trans>
        </ModalButton>
        <ModalButton type={'button'} full cancel onClick={handleClose}>
          <Trans>Anuluj</Trans>
        </ModalButton>
      </ButtonsWrapper>
    </Form>
  );
};

export default CreateGroupModalContent;
