import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { AbsoluteDrawerBackButton } from '../../../components/DrawerBackButton';
import { history } from '../../../utils/history';
import { getCreateProjectPath } from '../../../constants/paths.constants';
import { Center, FlexContainer, Form } from '../../../components/Containers/containers';
import SurveyTypeHeader from '../../../components/SurveyTypeHeader';
import useProjectDataValidation from './utils/useProjectDataValidation';
import { ProjectStep1Schema, ProjectStep2Schema } from './utils/projectValidation';
import { FieldError, useForm } from 'react-hook-form';
import _ from 'lodash';
import { REQUEST_STATUSES } from '../../../constants/requestStatuses.constants';
import SimpleModal from '../../../components/Modal/SimpleModal';
import { UsersTitleIcon } from '../../../components/Modal/ModalTitleIcons';
import CreateGroupModalContent from '../NewSurvey/CreateGroupModalContent';
import { Trans } from 'react-i18next';
import SelectGroup from '../../../components/SelectGroup';
import ErrorMessage from '../../../components/Forms/ErrorMessage';
import { FormLabel } from '../../../components/Forms/forms';
import { PrimarySmallButton } from '../../../components/Buttons/buttons';
import { Group, NewGroupWithContacts } from '../../../types/groups.types';
import styled from 'styled-components';
import {
  getCoordinatorGroups,
  getGroups,
  postNewGroupWithContacts,
  setNewGroupWithContactsFetchStatus,
} from '../../../reducers/Groups/groups.actions';
import { updateNewSurveyData } from '../../../reducers/Surveys/surveys.actions';

const CoordinatorsFormLabel = styled(FormLabel)`
  margin-top: 45px;
`;

interface Props {
  newSurvey: any;
  newGroupWithContacts: Group | null;
  newGroupWithContactsFetchStatus: string | null;
  groups?: Array<any>;
  groupsFetchStatus?: string;
  coordinatorGroups?: Array<any>;
  coordinatorGroupsFetchStatus?: string;

  getGroups(): void;
  setStep(stepNumber: number): void;
  updateNewSurveyData(data: any): void;
  postNewGroupWithContacts(data: NewGroupWithContacts): void;
  setNewGroupWithContactsFetchStatus(status: string | null): void;
  getCoordinatorGroups(): void;
}

const ProjectStep3: React.FC<Props> = (props) => {
  const {
    getGroups,
    getCoordinatorGroups,
    newSurvey,
    postNewGroupWithContacts,
    updateNewSurveyData,
    newGroupWithContacts,
    newGroupWithContactsFetchStatus,
    setNewGroupWithContactsFetchStatus,
    groups,
    coordinatorGroups,
    coordinatorGroupsFetchStatus,
  } = props;

  const [errors, setErrors] = useState<{ groups?: FieldError; coordinator_groups?: FieldError }>(
    {}
  );
  const [selectedMergedGroups, setSelectedMergedGroups] = useState<Array<number>>(
    newSurvey?.invited_groups || []
  );
  const [createGroupModalOpen, setCreateGroupModalOpen] = useState<any>(false);

  useProjectDataValidation({
    data: newSurvey,
    schemas: [ProjectStep1Schema, ProjectStep2Schema],
    notValidCallback: () => {
      history.replace(getCreateProjectPath({ step: 1 }));
    },
  });

  useEffect(() => {
    getGroups();
  }, [getGroups]);

  useEffect(() => {
    getCoordinatorGroups();
  }, [getCoordinatorGroups]);

  useEffect(() => {
    setErrors({});
  }, [selectedMergedGroups]);

  const onSubmit = () => {
    let isValid = true;
    const uniqMergedGroups = _.uniq(selectedMergedGroups);
    if (!uniqMergedGroups || uniqMergedGroups.length === 0) {
      isValid = false;
      setErrors((prev) => ({ ...prev, groups: { type: 'required' } }));
      setErrors((prev) => ({ ...prev, coordinator_groups: { type: 'required' } }));
    }
    if (isValid) {
      updateNewSurveyData({
        invited_groups: uniqMergedGroups,
      });
      history.push(getCreateProjectPath({ step: 4 }));
    }
  };

  const handleAddClick = (e: Event) => {
    e.preventDefault();
    setNewGroupWithContactsFetchStatus(null);
    setCreateGroupModalOpen(true);
  };

  useEffect(() => {
    if (newGroupWithContactsFetchStatus === REQUEST_STATUSES.DONE && newGroupWithContacts) {
      getGroups();
      getCoordinatorGroups();
      setSelectedMergedGroups((prev: any) => [...prev, +newGroupWithContacts.id]);
    }
  }, [newGroupWithContactsFetchStatus]);

  return (
    <>
      <AbsoluteDrawerBackButton
        isBackVisible={true}
        onClick={() => {
          history.push(getCreateProjectPath({ step: 2 }));
        }}
      />
      <FlexContainer>
        <Center>
          <SurveyTypeHeader type={'project'} />
          {createGroupModalOpen && (
            <SimpleModal
              open={createGroupModalOpen}
              setOpen={setCreateGroupModalOpen}
              title={'Utwórz nową grupę'}
              customButtonComponent={<></>}
              iconComponent={UsersTitleIcon}
            >
              <CreateGroupModalContent
                setOpen={setCreateGroupModalOpen}
                postNewGroupWithContacts={postNewGroupWithContacts}
                newGroupWithContactsFetchStatus={newGroupWithContactsFetchStatus}
              />
            </SimpleModal>
          )}
          <CoordinatorsFormLabel formType={'survey'}>
            <Trans>InviteCoordinatorGroup</Trans>
          </CoordinatorsFormLabel>
          <SelectGroup
            selectedGroups={selectedMergedGroups}
            setSelectedGroups={setSelectedMergedGroups}
            text={false}
            getGroups={getCoordinatorGroups}
            groups={coordinatorGroups}
            fetchStatus={coordinatorGroupsFetchStatus}
          />
          <ErrorMessage fieldError={errors.coordinator_groups} />

          <FormLabel formType={'survey'}>
            <Trans>InviteExistingGroup</Trans>
          </FormLabel>
          <PrimarySmallButton
            style={{ margin: '0 0 30px 0' }}
            type={'button'}
            onClick={handleAddClick}
          >
            Utwórz nową grupę
          </PrimarySmallButton>
          <SelectGroup
            selectedGroups={selectedMergedGroups}
            setSelectedGroups={setSelectedMergedGroups}
            text={false}
            getGroups={getGroups}
            groups={groups}
          />
          <ErrorMessage fieldError={errors.groups} />

          <PrimarySmallButton type={'submit'} onClick={onSubmit}>
            <Trans>Further</Trans>
          </PrimarySmallButton>
        </Center>
      </FlexContainer>
    </>
  );
};

function mapStateToProps(state: any, otherProps: RouteComponentProps) {
  return {
    newSurvey: state.surveys.newSurvey,
    newGroupWithContacts: state.groups.newGroupWithContacts,
    newGroupWithContactsFetchStatus: state.groups.newGroupWithContactsFetchStatus,
    groups: state.groups.list,
    coordinatorGroups: state.groups.coordinators.list.items,
    coordinatorGroupsFetchStatus: state.groups.coordinators.list.fetchStatus,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getGroups,
    getCoordinatorGroups,
    updateNewSurveyData,
    postNewGroupWithContacts,
    setNewGroupWithContactsFetchStatus,
  })(ProjectStep3)
);
