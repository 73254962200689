import styled from 'styled-components';
import { colors, device, fontSizes } from '../../theme';

export const FormLabelProject: any = styled.label`
  text-align: center;
  width: 100%;
  display: block;
  font-size: 40px;
  font-weight: bold;
  margin-top: 35px;
  margin-bottom: 12px;
`;

export const FormLabelProjectContent: any = styled.div`
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  display: block;
  font-size: 30px;
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 12px;
`;

export const FormLabel: any = styled.label`
  text-align: center;
  width: 100%;
  display: block;

  ${(props: any) => {
    switch (props.formType) {
      case 'survey':
        return `
          font-size: 32px;
          font-weight: bold;
          margin-top: 35px;
          margin-bottom: 12px;
        `;
      case 'projects':
        return `
          font-size: 32px;
          font-weight: bold;
          margin-top: 35px;
          margin-bottom: 12px;
          text-align: left;
        `;
      case 'survey-own-time':
        return `
          font-weight: 400;
          font-size: 15px;
          text-transform: uppercase;
        `;
      default:
        return `
          color: ${props.color};
          margin-top: 23px;
          font-size: 16px;
        `;
    }
  }}
`;

export const FormSublabel = styled.div`
  text-align: center;
  width: 100%;
  display: block;
  font-size: 18px;
  font-weight: normal;
  margin-top: 0px;
  margin-bottom: 25px;
  color: ${colors.grayDescription};
`;

export const alignInputParentStyles = `
  width: 90%;
    
  @media ${device.laptop} { 
    max-width: 600px;
  }
`;

export const generalInputStyles = `
  border-radius: 30px;
  ${alignInputParentStyles}
`;

export const getInputStyles = (props: any) => {
  switch (props.formType) {
    case 'survey':
      return `
          height: 41px;
          border: 3px solid #000;
          background-color: transparent;
          font-size: 24px;
          padding-left: 26px;
          color: ${props.color ? props.color : '#000'};
          overflow-x: scroll;
          padding-right: 15px;
        `;
    case 'survey-own-time':
      return `
        height: 34px;
        border: 3px solid #1BD0E2;
        border-radius: 8px;
      `;
    default:
      return `
          margin-top: 0.625rem;
          height: 3.75rem;
          border: 2px solid ${
            props.borderColor
              ? props.borderColor
              : props.disabled
              ? '#0A5057'
              : props.isDirty || props.defaultValue
              ? '#373737'
              : '#CACACA'
          };
          background-color: ${props.disabled ? '#0A5057' : 'transparent'};
          font-size: 1.5rem;
          padding: 1rem 1.25rem;
          caret-color: ${props.caretColor ? props.caretColor : colors.lightBlue};
          color: ${
            props.color
              ? props.color
              : props.disabled
              ? colors.darkBlue
              : props.isDirty || props.defaultValue
              ? '#373737'
              : '#95989A'
          };
          &::placeholder {
            color: #95989A;
          };
          &:hover {
            border-color: ${
              props.borderColor ? props.borderColor : props.disabled ? '#0A5057' : '#979797'
            };
          }
          &:focus {
            color: ${props.color ? props.color : '#646464'};
            border-color: ${
              props.borderColor ? props.borderColor : props.disabled ? '#0A5057' : colors.lightBlue
            };
          }
        `;
  }
};

export const FormInput: any = styled.input`
  border-radius: 30px;
  width: 90%;
  position: relative;
  pointer-events: ${({ pointerEvents = 'auto' }: any) => pointerEvents};

  @media ${device.laptop} {
    max-width: 600px;
  }

  ${(props) => getInputStyles(props)}
`;

export const EndAdornmentContainer = styled.div`
  position: absolute;
  right: calc(5% + 1rem);
  top: 50%;
  transform: translateY(-50%);
`;

export const FormTextarea: any = styled.textarea`
  ${(props: any) => {
    switch (props.formType) {
      case 'survey':
        return `
        min-height: 112px;
        border: 3px solid #000;
        background-color: transparent;
        font-size: 24px;
        padding-left: 26px;
        color: ${props.color ? props.color : '#000'};
      `;
      default:
        return `
        margin-top: 0.625rem;
        min-height: 3.75rem;
        border: 2px solid ${
          props.disabled ? '#0A5057' : props.isDirty || props.defaultValue ? '#373737' : '#CACACA'
        };
        color: ${
          props.disabled
            ? colors.darkBlue
            : props.isDirty || props.defaultValue
            ? '#373737'
            : '#95989A'
        };
        background-color: ${props.disabled ? '#0A5057' : 'transparent'};
        font-size: 1.5rem;
        padding: 1rem 1.25rem;
        caret-color: ${colors.lightBlue};
        &::placeholder {
          color: #95989A;
        };
        &:hover {
          color: ${props.isDirty || props.defaultValue ? '#373737' : '#95989A'};
          border-color: ${props.disabled ? '#0A5057' : '#979797'};
        }
        &:focus {
          color: #646464;
          border-color: ${props.disabled ? '#0A5057' : colors.lightBlue};
        }
        
      `;
    }
  }}

  border-radius: 30px;
  resize: vertical;
  width: 90%;

  @media ${device.laptop} {
    max-width: 600px;
  }
`;

export const FormInputErrorText = styled.div`
  font-size: ${fontSizes.mobile.h4};
  color: ${colors.red};
  margin-top: 3px;
`;
