import React from 'react';
import styled from 'styled-components';
import { colors } from '../../theme';

const Link = styled.a<any>`
  margin-top: 10px;
  color: ${(props) => (props.isDimmed ? colors.gray : colors.white)};
  font-weight: bold;
  text-decoration: underline;
  font-size: 16px;
  cursor: pointer;
`;

interface Props {
  children: any;
  onClick: (event: any) => any;
  isDimmed?: boolean;
}

const AuthorizeLink: React.FC<Props> = ({ children, onClick, isDimmed = false }) => {
  return (
    <Link isDimmed={isDimmed} onClick={onClick}>
      {children}
    </Link>
  );
};

export default AuthorizeLink;
