import { SurveyAnswer, SurveyData, SurveyQuestion } from '../../../types/surveys.types';

export const getErrors = (questions: Array<SurveyQuestion>): any => {
  return {
    name: undefined,
    questions:
      questions && questions.length > 0
        ? questions.map(() => ({
            question: undefined,
            answers: undefined,
          }))
        : [
            {
              question: undefined,
              answers: undefined,
            },
          ],
  };
};

export const getAnswers = (answers: Array<SurveyAnswer>) => {
  if (answers && answers.length > 0) {
    return answers.map((answer) => ({
      text: answer.text,
      value: answer.is_default,
    }));
  } else {
    return [];
  }
};

export const getQuestions = (questions: Array<SurveyQuestion>): any => {
  if (questions && questions.length > 0) {
    return questions.map((question) => ({
      question: question.question,
      answers: getAnswers(question.answers),
      is_free_answers: question.is_free_answers,
      min_required_answers: question.min_required_answers,
      max_required_answers: question.max_required_answers,
    }));
  } else {
    return [
      {
        question: '',
        answers: getAnswers([]) || [],
        extraAnswer: '',
        is_free_answers: false,
        min_required_answers: 1,
        max_required_answers: 1,
      },
    ];
  }
};

export const getDefaultValues = (newSurvey: SurveyData): any => {
  return {
    name: newSurvey.name ?? '',
    questions: getQuestions(newSurvey.questions),
    default_answer: newSurvey.default_answer ?? '',
  };
};
