import { connect } from 'react-redux';
import CustomDrawer from './CustomDrawer';
import { logout } from '../../reducers/Auth/auth.actions';
import { setIsQrCodeModalOpen, toggleMenu } from '../../reducers/Utils/utils.actions';

function mapStateToProps(state: any) {
  return {
    user: state.auth.user,
    // changePasswordPostStatus: state.auth.changePasswordPostStatus,
    // userPatchStatus: state.auth.userPatchStatus
  };
}

export default connect(mapStateToProps, { toggleMenu, logout, setIsQrCodeModalOpen })(CustomDrawer);
