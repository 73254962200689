import { useEffect } from 'react';
import { AnySchema } from 'yup/lib/schema';

const useProjectDataValidation = ({
  data,
  schemas,
  notValidCallback,
}: {
  data: any;
  schemas: Array<AnySchema>;
  notValidCallback?: any;
}) => {
  const mergeSchemas = (...schemas: Array<AnySchema>) => {
    const [first, ...rest] = schemas;
    return rest.reduce((mergedSchemas, schema) => mergedSchemas.concat(schema), first);
  };

  useEffect(() => {
    mergeSchemas(...schemas)
      .isValid(data)
      .then((valid) => {
        if (!valid && !!notValidCallback) notValidCallback();
      })
      .catch((error) => {
        console.log('error', error);
      });
  }, [data, schemas]);
};

export default useProjectDataValidation;
