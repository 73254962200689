import React, { useEffect, useState } from 'react';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { Trans } from 'react-i18next';
import QuestionAnswerOutlinedIcon from '@material-ui/icons/QuestionAnswerOutlined';
import { BottomNavigation } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { bottomNavigationActions, bottomNavigationPaths } from './baseTemplate.config';
import { history } from '../../utils/history';
import { BottomNavigationActionType } from '../../types/layout.types';
import { UserData } from '../../types/auth.types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQrcode } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import * as theme from '../../theme';
import { showNotification } from '../../utils/taost.config';
import i18next from 'i18next';
import { colors } from '../../theme';

const BottomNavigationSafeArea = styled.div`
  position: fixed;
  bottom: 0;
  height: env(safe-area-inset-bottom);
  background-color: ${colors.bottomNavigationBg};
  z-index: 1;
  width: 100%;
`;

const Circle = styled.div`
  border-radius: 50%;
  background-color: ${theme.colors.darkBlue};
  padding: 10px;
  width: 56px;
  height: 56px;
  color: ${theme.colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -20px;
  box-shadow: 0 3px 6px #00000029;
`;

const Icon = styled(FontAwesomeIcon)<any>`
  min-height: 20px;
  min-width: 20px;
  path {
    fill: ${theme.colors.white} !important;
  }
`;

const IconLabel = styled.span`
  font-size: 9px;
  font-weight: bold;
`;

const useStyles = makeStyles({
  root: {
    background: '#F7F7F7',
    padding: '6px 0px 6px',
    minWidth: 'initial',
    color: '#B3B2B3',
    '& svg': {
      '& path ': {
        fill: '#B3B2B3',
      },
    },
    '&.Mui-selected': {
      color: theme.colors.darkBlue,
      '& svg': {
        '& path ': {
          fill: theme.colors.darkBlue,
        },
      },
    },
  },
  label: {
    fontSize: '0.6rem',
    color: '#B3B2B3',
    textTransform: 'uppercase',
    '&.Mui-selected': {
      fontSize: '0.6rem !important',
      color: theme.colors.darkBlue,
    },
  },
});

interface Props {
  user: UserData;
  setIsQrCodeModalOpen(flag: boolean): void;
}

const CustomBottomNavigation: React.FC<Props> = ({ user, setIsQrCodeModalOpen }) => {
  const classes = useStyles({});

  const getIndex = () => {
    let result = '';
    bottomNavigationPaths.forEach((key) => {
      if (key === history.location.pathname + history.location.search) {
        result = key;
      }
    });
    return result;
  };

  const [index, setIndex] = useState<any>(getIndex());

  useEffect(() => {
    setIndex(getIndex());
  }, [history.location]);

  const getBottomNavigationActionIcon = (icon: string) => {
    switch (icon) {
      case 'create':
        return (
          <svg
            id="create-icon"
            data-name="create-icon"
            xmlns="http://www.w3.org/2000/svg"
            width="27.285"
            height="21.596"
            viewBox="0 0 27.285 21.596"
          >
            <g id="Group_1392" data-name="Group 1392" transform="translate(-1346.73 -267.717)">
              <g id="Group_1391" data-name="Group 1391">
                <path
                  id="Path_1176"
                  data-name="Path 1176"
                  d="M1368.747,267.717H1352a5.28,5.28,0,0,0-5.266,5.268v11.061a5.28,5.28,0,0,0,5.267,5.267h16.751a5.282,5.282,0,0,0,5.267-5.268V272.984A5.282,5.282,0,0,0,1368.747,267.717Zm2.943,8.007v8.322a2.952,2.952,0,0,1-2.944,2.945H1352a2.952,2.952,0,0,1-2.944-2.945v-2.738h0v-8.323A2.951,2.951,0,0,1,1352,270.04h16.75a2.951,2.951,0,0,1,2.944,2.944v2.739Z"
                  fill="#373737"
                />
              </g>
            </g>
            <path
              id="Path_1177"
              data-name="Path 1177"
              d="M1365.742,277.353h-4.208v-4.208h-2.322v2.489h0v1.72H1355v2.322h2.489v0h1.719v4.208h2.324v-4.208h4.208Z"
              transform="translate(-1346.73 -267.717)"
              fill="#373737"
            />
          </svg>
        );
      case 'active':
        return (
          <svg
            id="active-icon"
            data-name="active-icon"
            xmlns="http://www.w3.org/2000/svg"
            width="27.285"
            height="21.596"
            viewBox="0 0 27.285 21.596"
          >
            <path
              id="Path_1028"
              data-name="Path 1028"
              d="M1462.353,285.49l-5.144-5.144a1.5,1.5,0,0,1,2.121-2.121l2.806,2.806,6.909-8.4a1.5,1.5,0,0,1,2.318,1.9Z"
              transform="translate(-1450.594 -267.717)"
              fill="#373737"
            />
            <path
              id="Path_1029"
              data-name="Path 1029"
              d="M1472.611,267.717H1455.86a5.281,5.281,0,0,0-5.266,5.268v11.061a5.281,5.281,0,0,0,5.267,5.267h16.751a5.281,5.281,0,0,0,5.267-5.268V272.984A5.281,5.281,0,0,0,1472.611,267.717Zm2.944,8.007v8.322a2.953,2.953,0,0,1-2.945,2.945h-16.75a2.952,2.952,0,0,1-2.944-2.945v-2.738h0v-8.323a2.951,2.951,0,0,1,2.944-2.944h16.75a2.951,2.951,0,0,1,2.945,2.944v2.739Z"
              transform="translate(-1450.594 -267.717)"
              fill="#373737"
            />
          </svg>
        );
      case 'all':
        return (
          <svg
            id="all-icon"
            data-name="all-icon"
            xmlns="http://www.w3.org/2000/svg"
            width="27.285"
            height="21.597"
            viewBox="0 0 27.285 21.597"
          >
            <path
              id="Path_1038"
              data-name="Path 1038"
              d="M1569.221,278.254h24.961v-2.487H1566.9v2.487h2.324Z"
              transform="translate(-1566.897 -266.186)"
              fill="#373737"
            />
            <path
              id="Path_1039"
              data-name="Path 1039"
              d="M1593.928,283.075h.2a5.176,5.176,0,0,0,.057-.56v-1.876H1566.9v1.877a5.157,5.157,0,0,0,.057.559h26.974Z"
              transform="translate(-1566.897 -266.186)"
              fill="#373737"
            />
            <path
              id="Path_1040"
              data-name="Path 1040"
              d="M1572.164,287.782h16.751a5.267,5.267,0,0,0,4.36-2.322H1567.8A5.27,5.27,0,0,0,1572.164,287.782Z"
              transform="translate(-1566.897 -266.186)"
              fill="#373737"
            />
            <path
              id="Path_1041"
              data-name="Path 1041"
              d="M1572.165,268.509h21.111a5.275,5.275,0,0,0-4.362-2.323h-16.751a5.273,5.273,0,0,0-4.36,2.323h4.362Z"
              transform="translate(-1566.897 -266.186)"
              fill="#373737"
            />
            <path
              id="Path_1042"
              data-name="Path 1042"
              d="M1593.247,273.382h.935v-1.929a5.156,5.156,0,0,0-.057-.559h-27.171a5.149,5.149,0,0,0-.057.56v1.928h26.35Z"
              transform="translate(-1566.897 -266.186)"
              fill="#373737"
            />
          </svg>
        );
      case 'mine':
        return (
          <svg
            id="mine-icon"
            data-name="mine-icon"
            xmlns="http://www.w3.org/2000/svg"
            width="27.285"
            height="25.306"
            viewBox="0 0 27.285 25.306"
          >
            <path
              id="Path_1016"
              data-name="Path 1016"
              d="M1706.192,267.153h-16.75a5.282,5.282,0,0,0-5.267,5.268v10.558a5.282,5.282,0,0,0,5.267,5.267h8.5l5.571,3.994,0,0a1.178,1.178,0,0,0,.676.217,1.166,1.166,0,0,0,1.161-1.162v-3.05h.837a5.282,5.282,0,0,0,5.267-5.268V272.42A5.282,5.282,0,0,0,1706.192,267.153Zm2.946,5.268v10.555a2.952,2.952,0,0,1-2.945,2.944h-2a1.167,1.167,0,0,0-1.161,1.162v1.952L1699,286.143a1.164,1.164,0,0,0-.68-.22h-8.877a2.951,2.951,0,0,1-2.944-2.944V272.42a2.951,2.951,0,0,1,2.944-2.944h16.75A2.951,2.951,0,0,1,1709.138,272.421Z"
              transform="translate(-1684.175 -267.153)"
              fill="#373737"
            />
            <g id="Group_440" data-name="Group 440" transform="translate(6.787 5.846)">
              <path
                id="Path_694"
                data-name="Path 694"
                d="M1691.953,283.239a3.636,3.636,0,0,1-.991-.119v-1.4a3.328,3.328,0,0,0,.789.109.89.89,0,0,0,.789-.344,1.921,1.921,0,0,0,.238-1.073V273h1.677v7.4a3.13,3.13,0,0,1-.633,2.111A2.342,2.342,0,0,1,1691.953,283.239Z"
                transform="translate(-1690.962 -272.999)"
                fill="#373737"
              />
              <path
                id="Path_695"
                data-name="Path 695"
                d="M1700.158,280.913l-.321-.821h-.042a2.461,2.461,0,0,1-.858.728,2.793,2.793,0,0,1-1.15.2,1.863,1.863,0,0,1-1.372-.5,1.906,1.906,0,0,1-.5-1.417,1.6,1.6,0,0,1,.673-1.42,3.894,3.894,0,0,1,2.032-.506l1.049-.032v-.265a.824.824,0,0,0-.711-.922.8.8,0,0,0-.229,0,4.287,4.287,0,0,0-1.706.437l-.546-1.115a4.9,4.9,0,0,1,2.315-.546,2.9,2.9,0,0,1,1.868.53,1.965,1.965,0,0,1,.649,1.613v4.032h-1.151Zm-.487-2.8-.638.021a2.019,2.019,0,0,0-1.07.26.816.816,0,0,0-.352.726c0,.466.268.7.8.7a1.272,1.272,0,0,0,.917-.331,1.156,1.156,0,0,0,.344-.877Z"
                transform="translate(-1690.962 -272.999)"
                fill="#373737"
              />
              <path
                id="Path_696"
                data-name="Path 696"
                d="M1702.78,280.139a.9.9,0,0,1,.244-.686.98.98,0,0,1,.71-.233.962.962,0,0,1,.7.238,1.049,1.049,0,0,1,0,1.354.948.948,0,0,1-.694.246.974.974,0,0,1-.7-.241A.9.9,0,0,1,1702.78,280.139Zm1.619-1.852h-1.319L1702.8,273h1.873Z"
                transform="translate(-1690.962 -272.999)"
                fill="#373737"
              />
            </g>
          </svg>
        );
      case 'qr':
        return (
          <Circle>
            <Icon icon={faQrcode} />
            <IconLabel>
              <Trans>QrCode</Trans>
            </IconLabel>
          </Circle>
        );
      default:
        return <QuestionAnswerOutlinedIcon />;
    }
  };

  const checkAuthentication = (action: BottomNavigationActionType) => {
    switch (action.action) {
      case 'create':
        if (user.is_phone_verified && user.is_email_verified) history.push(action.path);
        else {
          showNotification({
            message: i18next.t('MissingEmailAndPhoneVerification'),
            type: 'error',
            autoClose: 5000,
          });
          setTimeout(() => history.push(action!.notAuthenticatedPath as string), 300);
        }
        break;
      case 'qr':
        setIsQrCodeModalOpen(true);
        break;
      default:
        history.push(action.path);
        break;
    }
  };

  return (
    <>
      <BottomNavigation
        value={index}
        showLabels
        style={{
          width: '100%',
          position: 'fixed',
          bottom: 0,
          marginBottom: 'env(safe-area-inset-bottom)',
          zIndex: 1,
          left: 0,
          background: colors.bottomNavigationBg,
        }}
        onChange={(event, value) => {
          setIndex(value);
          window.scrollTo(0, 0);
        }}
      >
        {bottomNavigationActions
          .filter((action) => !(user.is_blockchain_verified && action.icon === 'qr'))
          .map((action, key) => (
            <BottomNavigationAction
              key={key}
              label={<Trans>{action.label}</Trans>}
              value={action.value}
              icon={getBottomNavigationActionIcon(action.icon)}
              classes={{
                root: classes.root,
                label: classes.label,
              }}
              onClick={() => {
                checkAuthentication(action);
              }}
              disabled={!!action.disabled}
            />
          ))}
      </BottomNavigation>
      <BottomNavigationSafeArea />
    </>
  );
};

export default CustomBottomNavigation;
