import React, { useState } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import PrivateRoute from './containers/PrivateRoute';
import Layout from './containers/Layout';
import store from './store';
import { Provider } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toastContainerProps } from './utils/taost.config';
import { publicRoutes } from './routes';
import SyncApp from './containers/SyncApp';
import { colors } from './theme';
import { history } from './utils/history';
import styled, { ThemeProvider } from 'styled-components';
import { AbsoluteDrawerBackButton } from './components/DrawerBackButton';
import { useScript } from './utils/customHooks';
import { initFBScripts } from './utils/initThirdScripts';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'moment/locale/pl';
import moment from 'moment';
import { initAppInstall } from './utils/pwaInstallHelper';
import * as Sentry from '@sentry/browser';
import SWModal from './components/SWModal';
import useClickCircleAnimation from './utils/useClickCircleAnimation';
import MobileMessagingHOC from './containers/MobileMessagingHOC';

const AppVersion = styled.div`
  position: fixed;
  bottom: 0;
  left: 0.5rem;
  color: ${colors.gray};
  font-size: 10px;
  z-index: 10;
`;

moment.locale('pl');

const initSentry = () => {
  console.warn('Sentry', process.env?.REACT_APP_SENTRY_DSN);
  if (process.env.NODE_ENV === 'development') return;
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    tracesSampleRate: 1.0,
  });
};
initSentry();

const App = () => {
  useClickCircleAnimation();
  useScript('https://connect.facebook.net/en_US/sdk.js', initFBScripts);
  initAppInstall();
  const [language, setLanguage] = useState<string | undefined>();
  const LayoutWithProps = () => {
    return <Layout setLanguage={setLanguage} />;
  };

  toast.configure(toastContainerProps);

  return (
    <ThemeProvider theme={colors}>
      <MuiPickersUtilsProvider utils={MomentUtils} locale={'pl'}>
        <Provider store={store}>
          <SWModal />
          <AppVersion>{process.env.REACT_APP_VERSION}</AppVersion>
          <Router history={history}>
            <MobileMessagingHOC>
              <Switch>
                {publicRoutes.map((route, key) => {
                  const { component: Component, ...rest } = route;
                  // @ts-ignore
                  return (
                    <Route
                      key={key}
                      {...rest}
                      render={(props) => (
                        <>
                          <AbsoluteDrawerBackButton {...rest} />
                          {/*//@ts-ignore*/}
                          <Component {...props} />
                        </>
                      )}
                    />
                  );
                })}
                <SyncApp>
                  <PrivateRoute path="/" component={LayoutWithProps} />
                </SyncApp>
              </Switch>
            </MobileMessagingHOC>
          </Router>
        </Provider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default App;
