import React, { useRef } from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';
import { colors, device } from '../theme';
import { SurveyTypeEnum } from '../types/surveys.types';
import MyPopover from './MyPopover';

const Header = styled.h1`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 40px;
  line-height: 40px;
  margin-bottom: 0;
  text-align: center;
  display: flex;

  @media ${device.laptop} {
    font-size: 50px;
    line-height: 50px;
  }
`;

export const Description = styled.span`
  margin-top: 7px;
  color: ${colors.gray};
  font-size: 15px;
  margin-bottom: 40px;
`;

interface Props {
  type: SurveyTypeEnum | 'simple' | 'extended' | 'image' | 'projects' | string;
  headerPopover?: any;
}

const getTextFromType = (type: string): any => {
  switch (type) {
    case 'simple':
      return 'Poll';
    case 'extended':
      return 'ExtendedSurvey';
    case 'image':
      return 'PictureSurvey';
    case 'project':
      return 'Projects';
  }
};

const SurveyTypeHeader: React.FC<Props> = ({ type, headerPopover }) => {
  const containerRef = useRef();

  return (
    <>
      <Header ref={containerRef.current}>
        <Trans>{getTextFromType(type)}</Trans>
        {headerPopover && (
          <MyPopover text={headerPopover} containerRef={containerRef} buttonType="info" />
        )}
      </Header>
      <Description>
        <Trans>{getTextFromType(type) + 'Description'}</Trans>
      </Description>
    </>
  );
};

export default SurveyTypeHeader;
