import React, { useEffect, useState } from 'react';
import { QrCodeTitleIcon } from './Modal/ModalTitleIcons';
import SimpleModal from './Modal/SimpleModal';
import { connect } from 'react-redux';
import { setIsQrCodeModalOpen } from '../reducers/Utils/utils.actions';
import { generateQRCode } from '../reducers/Auth/auth.actions';
import styled from 'styled-components';
import { history } from '../utils/history';
import { getMyProfilePath } from '../constants/paths.constants';

const QrCodeImage = styled.img`
  width: 200px;
  height: 200px;
`;

const steps = [
  `dokonaj autoryzacji adresu e-mail i numeru telefonu klikając „Autoryzuj” pod każdym polem`,
  `po autoryzacji w/w danych pod wskazanymi polami pojawi się napis „Zapisz dane na blockchain”, który należy kliknąć`,
  `potwierdź wysyłane dane`,
  `w Moim profilu kliknij „Wygeneruj kod QR”`,
  `kod QR pojawi się w Moim profilu`,
  'zweryfikuj się u organizatora głosowania (urzędnika)',
];

interface Props {
  isOpen: boolean;
  setIsOpen(flag: boolean): void;
  blockchainKeys: { public_key: string | null; private_key: string | null };
  generateQRCode(data: { key: string }): void;
  qr: any;
}

const QrCodeModal: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  qr,
  blockchainKeys,
  generateQRCode,
}) => {
  const [modalConfig, setModalConfig] = useState<any>();

  const modalConfigShow = {
    title: 'QrCode',
    text:
      'Poniżej znajduje się kod QR pod którym zapisany jest Twój klucz publiczny. Pokaż go organizatorowi głosowania (urzednikowi) w celu zweryfikowania.',
    buttonText: 'Close',
  };

  const modalConfigGenerate = {
    title: 'QrCode',
    text: '',
    buttonText: 'Close',
  };

  useEffect(() => {
    if (isOpen) {
      if (qr) {
        setModalConfig(modalConfigShow);
      } else {
        if (blockchainKeys?.public_key) {
          generateQRCode({ key: blockchainKeys.public_key });
        } else {
          setModalConfig(modalConfigGenerate);
        }
      }
    }
  }, [isOpen, qr]);

  const onButtonClick = () => {
    !qr && history.push(getMyProfilePath());
    setIsOpen(false);
  };

  return (
    <>
      <SimpleModal
        {...modalConfig}
        open={isOpen}
        iconComponent={QrCodeTitleIcon}
        onButtonClick={onButtonClick}
        children={
          qr ? (
            <QrCodeImage src={qr} alt={'QrCode'} />
          ) : (
            <div style={{ textAlign: 'left' }}>
              Obecnie nie posiadasz zweryfikowanego kodu QR. W celu wygenerowania go, wykonaj
              następujące kroki:{' '}
              {steps.map((step, index) => (
                <p key={index}>
                  <strong>Krok {index + 1}</strong> - {step}
                </p>
              ))}
            </div>
          )
        }
      />
    </>
  );
};

function mapStateToProps(state: any) {
  return {
    isOpen: state.utils.isQrCodeModalOpen,
    blockchainKeys: state.auth.blockchainKeys,
    qr: state.auth.user && state.auth.user.qr,
  };
}

export default connect(mapStateToProps, {
  setIsOpen: setIsQrCodeModalOpen,
  generateQRCode,
})(QrCodeModal);
