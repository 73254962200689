import { connect } from 'react-redux';
import TermsAndConditions from './TermsAndConditions';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { getConstants } from '../../reducers/Constants/constants.actions';

function mapStateToProps(state: any, otherProps: RouteComponentProps) {
  return {
    fileUrl: state.constants.details?.settings?.current_regulation?.file,
    fileLastUpdate: state.constants.details?.settings?.current_regulation?.binding_date,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getConstants,
  })(TermsAndConditions)
);
