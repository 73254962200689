import yup, { mixedTests } from '../../../utils/yup/yupExtended';
import { TestContext } from 'yup';

export const SimpleSurveyStep1Schema = yup.object().shape({
  title: yup.string().required(),
  question: yup.string().required(),
  // default_answer
  answers: yup.array().of(
    yup.object().shape({
      text: yup.string(),
      value: yup
        .string()
        .nullable()
        .test(
          'default_answer',
          'Zaznacz domyślną odpowiedź',
          function (this: any, value: any, context: TestContext) {
            // https://github.com/jquense/yup/issues/289#issuecomment-861867193
            const contextGeneric = context as unknown as {
              from: { value: Record<string, unknown> }[];
            };
            const isDefaultRequired = !!contextGeneric.from[1].value?.default_answer;
            // @ts-ignore
            const isAnySelected = !!contextGeneric.from[1].value?.answers?.find(
              (answer: { text: string; value: boolean }) => answer.value
            );
            return !isDefaultRequired || isAnySelected;
          }
        ),
    })
  ),
});
