import yup from '../../utils/yup/yupExtended';
import moment from 'moment';

const dropdownTypeError = 'To pole jest wymagane';
const ageError = 'Wymagany minimalny wiek 13 lat';
const agreementTypeError = 'Wymagana akceptacja';

const basicValidationShape = {
  voting_nick: yup.string().test({
    message: dropdownTypeError,
    test: (value: any, context: any) => {
      return context.originalValue || (!context.originalValue && value);
    },
  }),
  phone: yup.string().typeError(dropdownTypeError).required(),
  email: yup.string().typeError(dropdownTypeError).required(),
  organizations: yup.string().nullable(),
};

const basicExtendedValidationShape = {
  first_name: yup.string().required(),
  last_name: yup.string().required(),
};

const extendedProfileValidationShape = {
  extended_profile: yup.object().shape({
    birth_date: yup
      .string()
      .typeError(dropdownTypeError)
      .test({
        message: dropdownTypeError,
        test: (value: any, context: any) => {
          return (
            context.options?.context?.wasExtendedSaved ||
            (!context.options?.context?.wasExtendedSaved && value)
          );
        },
      })
      .test({
        message: ageError,
        test: (value: any, context: any) => {
          return moment().diff(value, 'years') >= 13;
        },
      }),
    agreement13YO: yup.boolean().oneOf([true], agreementTypeError).nullable(),
    nationality: yup.string().typeError(dropdownTypeError).required(),
    country: yup.string().typeError(dropdownTypeError).required(),
    house_number: yup.string().typeError(dropdownTypeError).required(),
    postal_code: yup.string().typeError(dropdownTypeError).required(),
    agreement: yup.boolean().oneOf([true], agreementTypeError).nullable(),
    // pl address only
    province_id: yup
      .string()
      .nullable()
      .when('country', {
        is: (country: any) => country === 'PL' || !country,
        then: yup.string().typeError(dropdownTypeError).required(),
      }),
    county_id: yup
      .string()
      .nullable()
      .when('country', {
        is: (country: any) => country === 'PL' || !country,
        then: yup.string().typeError(dropdownTypeError).required(),
      }),
    municipality_id: yup
      .string()
      .nullable()
      .when('country', {
        is: (country: any) => country === 'PL' || !country,
        then: yup.string().typeError(dropdownTypeError).required(),
      }),
    locality_id: yup
      .string()
      .nullable()
      .when('country', {
        is: (country: any) => country === 'PL' || !country,
        then: yup.string().typeError(dropdownTypeError).required(),
      }),
    street_id: yup
      .string()
      .nullable()
      .when(['country', 'no_street'], {
        is: (country: any, no_street: boolean) => (country === 'PL' || !country) && !no_street,
        then: yup.string().typeError(dropdownTypeError).required(),
      }),
    //  other addresses
    // province: yup
    //   .string()
    //   .nullable()
    //   .when('country', {
    //     is: (country: any) => country !== 'PL',
    //     then: yup.string().typeError(dropdownTypeError).required(),
    //   }),
    // county: yup
    //   .string()
    //   .nullable()
    //   .when('country', {
    //     is: (country: any) => country !== 'PL',
    //     then: yup.string().required(),
    //   }),
    // municipality: yup
    //   .string()
    //   .nullable()
    //   .typeError(dropdownTypeError)
    //   .when('country', {
    //     is: (country: any) => country !== 'PL',
    //     then: yup.string().required(),
    //   }),
    locality: yup
      .string()
      .nullable()
      .when('country', {
        is: (country: any) => country !== 'PL',
        then: yup.string().typeError(dropdownTypeError).required(),
      }),
  }),
};

export const getMyProfileSchema = ({
  age,
  name,
  address,
  isExtendedChecked,
  wasExtendedSaved,
}: {
  age?: any;
  name?: any;
  address?: any;
  isExtendedChecked?: any;
  wasExtendedSaved?: boolean;
}) => {
  let validationShape: any = basicValidationShape;
  if (name) {
    validationShape = wasExtendedSaved
      ? validationShape
      : { ...validationShape, ...basicExtendedValidationShape };
  }
  if (age || address || isExtendedChecked) {
    validationShape =
      wasExtendedSaved || name
        ? validationShape
        : { ...validationShape, ...basicExtendedValidationShape };
    validationShape = { ...validationShape, ...extendedProfileValidationShape };
  }
  return yup.object().shape(validationShape);
};
