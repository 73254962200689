import React, {useEffect} from "react";
import {history} from "../../utils/history";
import {ContactPerson} from "../../types/contactPersons.types";
import {Trans} from "react-i18next";
import {colors} from "../../theme";
import {dynamicSort} from "../../utils/sort";
import {getContactPersonsPath} from "../../constants/paths.constants";
import {Center, FlexContainer} from "../../components/Containers/containers";
import {CircleButton, ContactButton, PrimarySmallButton} from "../../components/Buttons/buttons";
import {SurveyListH1} from "../../components/Typography/typography";
import styled from "styled-components";

const ContactButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 90%;
`

interface Props {
  contactPersons: Array<ContactPerson>;

  getContactPersons(): void;
}

const ContactPersons: React.FC<Props> = ({contactPersons, getContactPersons}) => {
  useEffect(() => {
    getContactPersons();
  }, [getContactPersons])

  const handleAddClick = () => {
    history.push(`${getContactPersonsPath()}/new-contact`);
  }

  return (
    <FlexContainer>
      <Center>
        <SurveyListH1><Trans>ContactPersons</Trans></SurveyListH1>
        <CircleButton
          borderColor={colors.darkGreen}
          bgColor={colors.darkGreen}
          color={"#ffffff"}
          onClick={handleAddClick}
        >+</CircleButton>
        <ContactButtonsContainer>
          {contactPersons && (
            contactPersons.sort(dynamicSort('email')).map((person, key) => {
              return (
                <ContactButton
                  key={key}
                  onClick={() => history.push(`${getContactPersonsPath()}/${person.id}`)}
                  color={colors.lightBlue}
                >
                  {person.email}
                </ContactButton>
              )
            })
          )}
        </ContactButtonsContainer>
      </Center>
    </FlexContainer>
  )
}

export default ContactPersons;
