import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { useForm, useWatch } from 'react-hook-form';
import InputEmail from '../../components/Forms/InputEmail';
import { emailPattern } from '../../constants/patterns.constants';
import InputPassword from '../../components/Forms/InputPassword';
import { RegisterData } from '../../types/auth.types';
import { LogoSvg } from '../../utils/uiComponents';
import Checkbox from '../../components/Forms/Checkbox';
import { history, MyLocationState } from '../../utils/history';
import { Center, LoginBgContainer, Form } from '../../components/Containers/containers';
import { PrimarySmallButton } from '../../components/Buttons/buttons';
import LinkToTermsAndConditions from '../../components/LinkToTermsAndConditions';
import { REQUEST_STATUSES } from '../../constants/requestStatuses.constants';
import { getLoginPath } from '../../constants/paths.constants';
import { showNotification } from '../../utils/taost.config';

interface Props {
  registerUser(data: RegisterData, state: MyLocationState): any;
  setRegistrationFetchStatus(registerFetchStatus: null | string): void;
  registerFetchStatus: null | string;
}

type Inputs = {
  email: string;
  password1: string;
  password2: string;
  regulation: boolean;
};

const Register: React.FC<Props> = ({
  registerUser,
  registerFetchStatus,
  setRegistrationFetchStatus,
}) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    formState,
    setError,
    clearErrors,
  } = useForm<Inputs>();

  const password1 = useWatch({
    name: 'password1',
    defaultValue: '',
    control,
  });
  const password2 = useWatch({
    name: 'password1',
    defaultValue: '',
    control,
  });

  const onSubmit = (data: Inputs) => {
    let isValid = true;
    if (data.password1 !== data.password2) {
      isValid = false;
      setError('password2', { type: 'differentPasswords' });
    }
    if (isValid) {
      registerUser(data, history.location.state);
    }
  };

  useEffect(() => {
    clearErrors('password2');
  }, [password1, password2]);

  useEffect(() => {
    if (registerFetchStatus === REQUEST_STATUSES.DONE) {
      if (history.location?.state && history.location.state?.redirectToSurvey)
        history.push(history.location.state?.redirectToSurvey);
      else history.push(getLoginPath(), history.location.state);
      setRegistrationFetchStatus(REQUEST_STATUSES.NULL);
      showNotification({
        message: 'Zarejstrowano pomyślnie. Sprawdź swojego maila i kliknij w link weryfikacyjny.',
        type: 'success',
      });
    }
  }, [registerFetchStatus]);

  return (
    <LoginBgContainer>
      <Center>
        <LogoSvg src={'/images/ivoting-logo.svg'} />
        <Form onSubmit={handleSubmit(onSubmit)}>
          <InputEmail
            formRef={register}
            fieldError={errors.email}
            validate={{
              required: true,
              pattern: emailPattern,
            }}
            formState={formState}
          />
          <InputPassword
            label={'Password'}
            name={'password1'}
            formRef={register}
            fieldError={errors.password1}
            validate={{ required: true }}
            formState={formState}
          />
          <InputPassword
            label={'RepeatPassword'}
            name={'password2'}
            formRef={register}
            fieldError={errors.password2}
            validate={{ required: true }}
            formState={formState}
          />
          <Checkbox
            labelComponent={<LinkToTermsAndConditions />}
            name={'regulation'}
            formRef={register}
            formState={formState}
            validate={{ required: true }}
            uppercase={false}
            fieldError={errors.regulation}
            style={{ marginTop: '1rem' }}
          />
          <PrimarySmallButton type="submit">
            <Trans>Further</Trans>
          </PrimarySmallButton>
        </Form>
      </Center>
    </LoginBgContainer>
  );
};

export default Register;
