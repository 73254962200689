import React, { FC, useEffect, useRef, useState } from 'react';
import SimpleModal from '../../components/Modal/SimpleModal';
import { PhoneTitleIcon } from '../../components/Modal/ModalTitleIcons';
import PinInput from '../../components/Forms/PinInput';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { REQUEST_STATUSES } from '../../constants/requestStatuses.constants';
import { Trans } from 'react-i18next';
import InputPhone from '../../components/Forms/InputPhone';
import * as theme from '../../theme';

const PinInputContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const phoneFormId = 'phone-number';
const codeFormId = 'phone-code';

const errorMessage = 'Niepoprawny kod';

/**
 * Similar to /MyProfile/Authenticate/Authenticate.tsx
 * Flow: save phone nr in profile, request code for the phone from profile,
 * send confirmation code, trigger callback
 */
const VerifyPhoneCodeModal: FC<any> = (props) => {
  const {
    open,
    triggerRequest,
    user,
    sendPhoneSignup,
    codeFetchStatus,
    verificationsDirectFromBE,
    checkVerifications,
    verifyCallback,
    confirmPhoneSignup,
    phoneErrors,
    smsCodeId,
    updateUser,
    userFetchStatus,
    accountForSurveyVotingData,
    phoneModalOpen,
    codeModalOpen,
    handlePhoneClose,
    handleCodeClose,
    resetPostPhoneCodeFetchStatus,
    setPhoneModalOpen,
    setSmsCodeId,
  } = props;

  const {
    register: registerPhone,
    handleSubmit: handleSubmitPhone,
    setValue: setValuePhone,
    getValues: getValuesPhone,
    control: controlPhone,
    formState: formStatePhone,
  } = useForm({
    defaultValues: {},
  });
  const {
    register: registerCode,
    handleSubmit: handleSubmitCode,
    setValue: setValueCode,
    getValues: getValuesCode,
    control: controlCode,
  } = useForm({
    defaultValues: {},
  });

  const [openPhoneModal, setOpenPhoneModal] = useState<any>(open || phoneModalOpen.open);
  const [openCodeModal, setOpenCodeModal] = useState<any>(
    codeModalOpen || phoneModalOpen?.codeModalOpen
  );

  const checkPhoneVerificationTimeoutRef: any = useRef();
  const phoneValuesRef: any = useRef();

  const isPhoneVerified = () => {
    return (
      user?.is_phone_verified ||
      codeFetchStatus === REQUEST_STATUSES.DONE ||
      verificationsDirectFromBE?.isPhoneVerified
    );
  };

  const onPhoneNumberSubmit = (event?: any) => {
    event.preventDefault();
    const phoneValues = getValuesPhone();
    phoneValuesRef.current = phoneValues;
    // @ts-ignore
    phoneValues.phone &&
      updateUser({
        // @ts-ignore
        phone: phoneValues.phone?.includes('+') ? phoneValues.phone : '+' + phoneValues.phone,
      });
    // @ts-ignore
    phoneValues.phone && sendPhoneSignup(phoneValues.phone);
  };

  const onResendClick = (event: any) => {
    event.preventDefault();
    sendPhoneSignup(
      phoneValuesRef.current ||
        (user?.phone && { phone: user.phone }) ||
        (accountForSurveyVotingData?.phone && { phone: accountForSurveyVotingData?.phone })
    );
  };

  useEffect(() => {
    resetPostPhoneCodeFetchStatus();
  }, [open, codeModalOpen, phoneModalOpen]);

  useEffect(
    function requestPhoneCodeAfterUserUpdate() {
      if (userFetchStatus === REQUEST_STATUSES.DONE && user?.phone && triggerRequest) {
        if (user?.phone) {
          sendPhoneSignup({ phone: user?.phone });
        } else if (phoneValuesRef.current?.phone) {
          sendPhoneSignup(phoneValuesRef.current);
          phoneValuesRef.current = null;
        }
      }
    },
    [userFetchStatus, triggerRequest]
  );

  useEffect(() => {
    setOpenCodeModal(phoneModalOpen?.codeModalOpen);
  }, [phoneModalOpen?.codeModalOpen]);

  const onCodeSubmit = (event: any) => {
    event.preventDefault();
    const values = getValuesCode();
    confirmPhoneSignup({
      id: smsCodeId,
      // @ts-ignore
      raw_code: values.code,
    });
    setSmsCodeId(null);
  };

  const checkIfPhoneVerifiedInTimeout = () => {
    if (checkPhoneVerificationTimeoutRef.current) return;
    checkPhoneVerificationTimeoutRef.current = setTimeout(() => {
      if (!checkPhoneVerificationTimeoutRef.current) return;
      checkVerifications();
      checkPhoneVerificationTimeoutRef.current = null;
      checkIfPhoneVerifiedInTimeout();
    }, 5 * 1000);
  };

  useEffect(() => {
    return () => {
      checkPhoneVerificationTimeoutRef.current &&
        clearTimeout(checkPhoneVerificationTimeoutRef.current);
    };
  }, []);

  useEffect(() => {
    //  checking if email was verified on other device
    if (triggerRequest || phoneModalOpen.triggerRequest) {
      checkIfPhoneVerifiedInTimeout();
    } else {
      checkPhoneVerificationTimeoutRef.current &&
        clearTimeout(checkPhoneVerificationTimeoutRef.current);
    }
  }, [triggerRequest, phoneModalOpen.triggerRequest]);

  useEffect(() => {
    if (isPhoneVerified()) {
      checkPhoneVerificationTimeoutRef.current &&
        clearTimeout(checkPhoneVerificationTimeoutRef.current);
      setPhoneModalOpen({ open: false, codeModalOpen: false, triggerRequest: false });
      setOpenPhoneModal(false);
      setOpenCodeModal(false);
      verifyCallback();
    }
  }, [verificationsDirectFromBE, codeFetchStatus]);

  useEffect(() => {
    if (smsCodeId && codeFetchStatus === REQUEST_STATUSES.DONE) return;
    if (open || phoneModalOpen.open) setOpenPhoneModal(open || phoneModalOpen.open);
  }, [open, phoneModalOpen?.open]);

  useEffect(
    function afterPhoneSubmitSuccess() {
      if (!smsCodeId || codeFetchStatus === REQUEST_STATUSES.DONE) return;
      setOpenPhoneModal(false);
      setOpenCodeModal(true);
    },
    [smsCodeId]
  );

  useEffect(() => {
    if (user?.phone) {
      // @ts-ignore
      setValuePhone('phone', user?.phone);
    }
  }, [user?.phone]);

  return (
    <>
      <SimpleModal
        title={'SurveyRequiresPhoneVerification'}
        text={'SendCodeToNumber'}
        open={openPhoneModal}
        iconComponent={PhoneTitleIcon}
        mainButtonForm={phoneFormId}
        buttonText={'Send'}
        handleClose={handlePhoneClose}
      >
        <PinInputContainer>
          <form id={phoneFormId} onSubmit={onPhoneNumberSubmit}>
            <InputPhone
              control={controlPhone}
              label={'Phone'}
              showRequiredStar
              name={'phone'}
              formRef={registerPhone}
              fieldError={phoneErrors?.phone}
              formState={formStatePhone}
              validate={{ required: true }}
              borderColor={theme.colors.grayBorder}
              caretColor={theme.colors.grayBorder}
              disabled={user?.is_phone_verified}
              defaultValue={user?.phone}
              labelColor={theme.colors.grayBorder}
              setValue={setValuePhone}
            />
          </form>
        </PinInputContainer>
      </SimpleModal>

      <SimpleModal
        title={<Trans>SmsVerificationHeader</Trans>}
        textChildren={
          user?.phone ? (
            <>
              <Trans>SmsVerificationTextPart1</Trans>
              {user.phone}
              <div>
                <Trans>SmsVerificationTextPart2</Trans>
              </div>
            </>
          ) : (
            <Trans>SmsVerificationText</Trans>
          )
        }
        open={openCodeModal}
        iconComponent={PhoneTitleIcon}
        mainButtonForm={codeFormId}
        onLinkButtonClick={onResendClick}
        handleClose={handleCodeClose}
      >
        <PinInputContainer>
          <form id={codeFormId} onSubmit={onCodeSubmit}>
            <PinInput
              type="pin"
              name="code"
              control={controlCode}
              defaultValue={null}
              customErrorMessage={codeFetchStatus === REQUEST_STATUSES.ERROR && errorMessage}
            />
          </form>
        </PinInputContainer>
      </SimpleModal>
    </>
  );
};
export default VerifyPhoneCodeModal;
