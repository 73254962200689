import { useEffect, useReducer } from 'react';
import * as serviceWorker from '../serviceWorker';

export const isSWAvailable = () =>
  process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator;

const ACTION_TYPES = {
  ON_SERVICE_WORKER_UPDATE: 'ON_SERVICE_WORKER_UPDATE',
  SET_NEW_VERSION_AVAILABLE: 'SET_NEW_VERSION_AVAILABLE',
};

const initialState = { waitingWorker: {}, newVersionAvailable: false };

function init(initialState) {
  return { ...initialState };
}

function serviceWorkerReducer(state, action) {
  const { registration, newVersionAvailable } = action.payload;
  switch (action.type) {
    case ACTION_TYPES.ON_SERVICE_WORKER_UPDATE:
      return {
        ...state,
        waitingWorker: registration && registration.waiting,
        newVersionAvailable: true,
      };
    case ACTION_TYPES.SET_NEW_VERSION_AVAILABLE:
      return {
        ...state,
        newVersionAvailable,
      };
    default:
      return { ...state };
  }
}

export const useServiceWorker = () => {
  const [state, dispatch] = useReducer(serviceWorkerReducer, initialState, init);

  const onUpdateClick = () => {
    const { waitingWorker } = state;
    waitingWorker && waitingWorker.postMessage({ type: 'SKIP_WAITING' });
    dispatch({
      type: ACTION_TYPES.SET_NEW_VERSION_AVAILABLE,
      payload: { newVersionAvailable: false },
    });
    window?.location?.reload && window.location.reload();
  };

  const onServiceWorkerUpdate = (registration) => {
    console.log('SW should be updated!');
    dispatch({
      type: ACTION_TYPES.ON_SERVICE_WORKER_UPDATE,
      payload: { registration },
    });
  };

  useEffect(() => {
    if (isSWAvailable()) {
      serviceWorker.register({ onUpdate: onServiceWorkerUpdate });
    }
  }, []);

  if (isSWAvailable()) {
    return [state.newVersionAvailable, onUpdateClick];
  } else {
    return [false, () => {}];
  }
};
