import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Groups from './Groups';
import { RouteComponentProps } from 'react-router';
import {
  getGroups,
  getCoordinatorGroups,
  getGroupsIBelongTo,
  leaveGroup,
} from '../../reducers/Groups/groups.actions';
import {
  deleteContactPerson,
  updateContactPerson,
} from '../../reducers/ContactPersons/contactPersons.actions';

function mapStateToProps(state: any, otherProps: RouteComponentProps) {
  return {
    groups: state.groups.list,
    coordinatorGroups: state.groups.coordinators.list.items,
    coordinatorGroupsFetchStatus: state.groups.coordinators.list.fetchStatus,
    groupsIBelongTo: state.groups.belongTo.list.items,
    groupsIBelongToFetchStatus: state.groups.belongTo.list.fetchStatus,
    leaveFetchStatus: state.groups.belongTo.list.leaveFetchStatus,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getGroups,
    getCoordinatorGroups,
    updateContactPerson,
    deleteContactPerson,
    getGroupsIBelongTo,
    leaveGroup,
  })(Groups)
);
