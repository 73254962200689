import React, { useEffect, useState } from 'react';
import { SurveyAnswer, SurveyQuestion } from '../../types/surveys.types';
import { Trans } from 'react-i18next';
import styled from 'styled-components';
import ErrorMessage from '../Forms/ErrorMessage';
import { FormLabel } from '../Forms/forms';
import { colors, device } from '../../theme';
import QuestionSeparator from './QuestionSeparator';
import Checkbox from '../Forms/Checkbox';
import { UseFormReturn } from 'react-hook-form/dist/types/form';
import getFieldError from '../../utils/getFieldError';
import { useWatch } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { defaultAnswerImage } from '../../containers/Surveys/NewProject/utils/projectDefaultValues';

const bigFontSize = '28px';

const fontSize = '22px';

const InfoText = styled.span`
  color: ${colors.gray};
  font-size: 15px;
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AnswerContainer: any = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  width: 100%;
  gap: 0.5rem;

  ${(props: any) =>
    props.vote &&
    `&:hover {
      cursor: pointer;
    }`}
`;

const AnswerContentContainer = styled.div`
  display: block;

  @media ${device.tablet} {
    display: flex;
    gap: 0.5rem;
  }
`;

const AnswerNr: any = styled.div`
  min-width: 45px;
  min-height: 45px;
  font-size: ${fontSize};
  line-height: 45px;
  background-color: ${colors.gray};
  border-radius: 50%;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Image: any = styled.div<{ hasLink: boolean }>`
  min-width: 120px;
  min-height: 120px;
  border: none;
  border-radius: 2px;
  background: ${(props: any) => (props.bgImage ? `url(${props.bgImage})` : 'transparent')};
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  cursor: ${(props) => (props.hasLink ? 'pointer' : 'auto')};
`;

const ImageLinkIcon = styled(FontAwesomeIcon)<{ icon: any }>`
  content: '';
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  color: ${colors.darkBlue};
`;

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
`;

const TaskTypeLabel = styled(FormLabel)`
  margin-top: 10px;
  text-align: left;
  color: ${colors.darkBlue};
  font-size: ${bigFontSize};
`;

const TaskType = styled(FormLabel)`
  margin-top: 10px;
  text-align: left;
`;

const TextContainer = styled.div`
  font-size: ${fontSize};
  flex-grow: 1;
  min-width: 25%;
`;

const TextTitle = styled.div`
  font-weight: bold;
`;

const TextDescription = styled.div`
  font-style: italic;
  font-weight: 300;
`;

const TextAmount = styled.div`
  text-transform: uppercase;
`;

const CheckboxContainer = styled.div`
  min-width: 40px;
  min-height: 40px;

  label {
    padding: 0px;
    margin-left: auto;
    margin-right: auto;
  }
`;

interface Props {
  question: SurveyQuestion;
  formMethods: UseFormReturn<any>;
  questionIndex: number;
  placeholderImage?: string | null;
}

const ProjectQuestionPreview: React.FC<Props> = (props) => {
  const { question, formMethods, questionIndex, placeholderImage } = props;
  const questionData = (() => {
    if (question.hasOwnProperty('is_free_answers') && question.is_free_answers) {
      question.answers.push({
        is_free_answer: true,
        question_id: question.id,
        text: '',
        is_default: false,
        image: null,
        id: `${question.id}-${question.answers.length}`,
      });
    }
    return question;
  })();

  const { register, formState, setValue, getValues, control } = formMethods;
  const { errors } = formState;

  const selectedAnswersCount = useWatch({
    control,
    name: `questions.${questionIndex}.answers`,
  })?.filter((answer: any) => !!answer.is_checked).length;

  const toggleCheckbox = (checkboxName: string) => {
    // setValue(checkboxName, !getValues(checkboxName));
  };

  const handleImageClick = (event: React.MouseEvent, link?: string) => {
    event.stopPropagation();
    if (link) {
      const win: any = window.open(link, '_blank');
      win.focus();
    }
  };

  const watchCheckboxes = useWatch({
    name: `questions.${questionIndex}.answers`,
    // @ts-ignore
    defaultValue: question && question[questionIndex] && question[questionIndex]?.answers,
    control,
  });

  const checkIfIsMaxChecked = () => {
    let checkedLength = watchCheckboxes.filter((checkbox: any) => !!checkbox?.is_checked).length;
    return checkedLength === question?.max_required_answers;
  };

  const isCheckboxDisabled = (checkboxName: string) => {
    const areUncheckedDisabled = checkIfIsMaxChecked();
    const currentValue = formMethods.getValues(checkboxName);
    return areUncheckedDisabled && !currentValue;
  };

  return (
    <>
      <QuestionSeparator />

      <TitleContainer>
        <TaskTypeLabel formType={'projects'} style={{ flex: 1, marginRight: '20px' }}>
          <Trans>TaskTypeLabel</Trans>
        </TaskTypeLabel>
        <TaskType formType={'projects'} style={{ flex: 5 }}>
          {typeof questionData.question === 'string' && questionData.question}
        </TaskType>
      </TitleContainer>

      <div style={{ marginTop: '2rem' }}>
        {questionData &&
          questionData.answers &&
          questionData.answers.map((answer: any, answerIndex) => {
            const checkboxName = `questions.${questionIndex}.answers.${answerIndex}.is_checked`;

            return (
              <AnswerContainer key={answer.id} onClick={() => toggleCheckbox(checkboxName)}>
                <AnswerNr>{answerIndex + 1}</AnswerNr>
                <AnswerContentContainer>
                  <ImageContainer>
                    <Image
                      bgImage={answer?.image?.image || placeholderImage}
                      onClick={(event: React.MouseEvent) => handleImageClick(event, answer.link)}
                      hasLink={!!answer.link}
                    >
                      {' '}
                      {answer.link && <ImageLinkIcon icon={faLink} />}
                    </Image>
                  </ImageContainer>
                  <TextContainer>
                    <TextTitle>{answer?.text}</TextTitle>
                    {answer?.project_data?.short_description && (
                      <TextDescription>{answer.project_data.short_description}</TextDescription>
                    )}
                    {typeof answer?.project_data?.value === 'number' && (
                      <TextAmount>
                        <Trans>TaskAmountLabel</Trans>:
                        {` ${answer?.project_data?.value} ${answer?.project_data?.currency}`}
                      </TextAmount>
                    )}
                  </TextContainer>
                </AnswerContentContainer>

                <CheckboxContainer>
                  <Checkbox
                    name={checkboxName}
                    formRef={register}
                    formState={formState}
                    disabled={isCheckboxDisabled(checkboxName)}
                  />
                </CheckboxContainer>
              </AnswerContainer>
            );
          })}
      </div>
      <InfoText>
        Minimalna liczba wymaganych odpowiedzi {question.min_required_answers}
        <br />
        Maksymalna liczba wymaganych odpowiedzi {question.max_required_answers}
        <br />
        Liczba udzielonych odpowiedzi {selectedAnswersCount}
      </InfoText>
      <ErrorMessage fieldError={getFieldError(errors, `questions.${questionIndex}`)} />
    </>
  );
};

export default ProjectQuestionPreview;
