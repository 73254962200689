import { actionTypes } from './notifications.actionTypes';
import { REQUEST_STATUSES } from '../../constants/requestStatuses.constants';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';

type FCMRegistration = {
  name: string;
  device_id: string;
  registration_id: string;
  app_version: string;
  type: string;
};

export function getNotificationsSummary() {
  return function (dispatch: Dispatch) {
    dispatch({
      type: actionTypes.SET_SUMMARY_FETCH_STATUS,
      payload: REQUEST_STATUSES.FETCHING,
    });
    const url = process.env.REACT_APP_API_URL + `/notification/latest/summary/`;
    axios
      .get(url, {})
      .then((response) => {
        dispatch({
          type: actionTypes.SET_SUMMARY_FETCH_STATUS,
          payload: REQUEST_STATUSES.DONE,
        });
        dispatch({ type: actionTypes.SET_SUMMARY, payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.SET_SUMMARY_FETCH_STATUS,
          payload: REQUEST_STATUSES.ERROR,
        });
      });
  };
}

export function getNotifications({
  cursor,
  page_size,
}: {
  cursor?: string;
  page_size?: string | number;
}) {
  return function (dispatch: Dispatch) {
    dispatch({
      type: actionTypes.SET_NOTIFICATIONS_FETCH_STATUS,
      payload: REQUEST_STATUSES.FETCHING,
    });
    const url = process.env.REACT_APP_API_URL + `/notification/latest/`;
    axios
      .get(url, {
        params: {
          cursor,
          page_size,
        },
      })
      .then((response) => {
        dispatch({
          type: actionTypes.SET_NOTIFICATIONS_FETCH_STATUS,
          payload: REQUEST_STATUSES.DONE,
        });
        dispatch({
          type: actionTypes.SET_NOTIFICATIONS_LIST,
          payload: { ...response.data, cursor },
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.SET_NOTIFICATIONS_FETCH_STATUS,
          payload: REQUEST_STATUSES.ERROR,
        });
      });
  };
}

export function postMarkAllNotificationsAsRead() {
  return function (dispatch: Dispatch) {
    dispatch({
      type: actionTypes.SET_ALL_AS_READ_FETCH_STATUS,
      payload: REQUEST_STATUSES.FETCHING,
    });
    const url = process.env.REACT_APP_API_URL + `/notification/latest/mark_as_read/`;
    axios
      .post(url, {
        params: {},
      })
      .then((response) => {
        dispatch({
          type: actionTypes.SET_ALL_AS_READ_FETCH_STATUS,
          payload: REQUEST_STATUSES.DONE,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.SET_ALL_AS_READ_FETCH_STATUS,
          payload: REQUEST_STATUSES.ERROR,
        });
      });
  };
}

export function registerFCMDevice(data: any) {
  if (!data || typeof data !== 'object') return;

  let dataToPost = {
    ...data,
    app_version:
      data?.app_version && data?.app_version.match(/\./g).length === 1
        ? data.app_version + '.0'
        : data.app_version,
  };

  return function (dispatch: Dispatch) {
    dispatch({
      type: actionTypes.REGISTER_FCM_DEVICE_FETCH_STATUS,
      payload: REQUEST_STATUSES.FETCHING,
    });
    const url = process.env.REACT_APP_API_URL + '/devices/';
    axios
      .post(url, dataToPost)
      .then((response) => {
        const responsePayload: FCMRegistration = response.data;
        dispatch({
          type: actionTypes.REGISTER_FCM_DEVICE_FETCH_STATUS,
          payload: REQUEST_STATUSES.DONE,
        });
        dispatch({ type: actionTypes.REGISTER_FCM_DEVICE, payload: responsePayload });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.REGISTER_FCM_DEVICE_FETCH_STATUS,
          payload: REQUEST_STATUSES.ERROR,
        });

        if (error?.response?.data) {
          toast.error(
            `${JSON.stringify(error?.response?.status)} ${JSON.stringify(error?.response?.data)}`,
            { autoClose: 30000 }
          );
          console.error(error.response.data);
        }
      });
  };
}

export function updateFCMInfo(data: any) {
  return function (dispatch: Dispatch) {
    dispatch({
      type: actionTypes.UPDATE_FCM_INFO,
      payload: data,
    });
  };
}
