import React from 'react';
import { Center } from '../../../components/Containers/containers';
import { Trans } from 'react-i18next';
import moment from 'moment';
import i18next from 'i18next';
import styled from 'styled-components';
import { colors, device } from '../../../theme';

const InfoButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  padding: 0.5rem 0.875rem;
  background-color: ${colors.gray};
  border: 2px solid ${colors.gray};
  border-radius: 50px;
  font-size: 1rem;
  margin: 5px;
  color: ${colors.white};
  text-align: center;

  @media ${device.laptop} {
    max-width: 600px;
  }
`;

const SummarySettingsPreview = (props: any) => {
  const { survey, watchStartDate, watchEndDate, type } = props;

  const isProject = type === 'project';

  const protocolType = [
    survey.is_protocol_label_nick ? `${i18next.t('z pseudonimem')}` : '',
    survey.is_protocol_label_name ? `${i18next.t('z imieniem i nazwiskiem')}` : '',
    survey.is_protocol_anonymized ? `${i18next.t('z wygwiazdkowanym emailem')}` : '',
  ].filter((value) => !!value);

  const requiredAuthorization = [
    survey.require_to_vote_email ? `${i18next.t('email')}` : '',
    survey.require_to_vote_phone ? `${i18next.t('phone')}` : '',
    survey.require_to_vote_qr ? `${i18next.t('qrCode')}` : '',
    survey.require_to_vote_name ? `${i18next.t('imię i nazwisko')}` : '',
    survey.require_to_vote_address ? `${i18next.t('adres')}` : '',
    survey.require_to_vote_min_age_checkbox
      ? `${i18next.t('minimalny wiek')} - ${survey.require_to_vote_min_age}`
      : '',
  ].filter((value) => !!value);

  return (
    <Center>
      {survey.time?.value !== 'custom' && (
        <InfoButton>
          <Trans>DurationTime</Trans>:{' '}
          {survey.time?.value !== 'indefinitely' ? `${survey.time?.value} min` : 'Bezterminowo'}
        </InfoButton>
      )}
      {survey.time?.value === 'custom' && (
        <>
          <InfoButton>
            <Trans>StartDate</Trans>:{' '}
            {moment(watchStartDate || survey.time.start_date).format('DD-MM-YYYY HH:mm')}
          </InfoButton>
          <InfoButton>
            <Trans>EndDate</Trans>:{' '}
            {moment(watchEndDate || survey.time.end_date).format('DD-MM-YYYY HH:mm')}
          </InfoButton>
        </>
      )}
      {!isProject && (
        <InfoButton>
          <Trans>
            {survey.is_overt === true
              ? survey.survey_type === 'extended'
                ? 'PublicSurvey'
                : 'PublicPoll'
              : survey.survey_type === 'extended'
              ? 'SecretSurvey'
              : 'SecretPoll'}
          </Trans>
        </InfoButton>
      )}

      {isProject && <InfoButton>Projekt jawny</InfoButton>}

      {isProject && (
        <InfoButton>
          <Trans>Rodzaj protokołu</Trans>: {protocolType.join(', ')}
        </InfoButton>
      )}

      {isProject && (
        <InfoButton>
          {survey.is_results_only_for_owner
            ? 'Protokół tylko dla Organizatora'
            : 'Protokół nie tylko dla Organizatora'}
        </InfoButton>
      )}

      {isProject && (
        <InfoButton>
          {survey.is_voting_card_vote
            ? 'Głosuj z użyciem karty do głosowania'
            : 'Nie głosuj z użyciem karty do głosowania'}
        </InfoButton>
      )}

      {survey.is_closed_vote && (
        <InfoButton>
          <Trans>ClosedPoll</Trans>
        </InfoButton>
      )}

      <InfoButton>
        <Trans>{survey.show_on_page ? 'showOnPage' : 'doNotShowOnPage'}</Trans>
      </InfoButton>
      {!isProject && (
        <InfoButton>
          <Trans>
            {survey.close_after_submitted_votes
              ? 'End the vote if everyone has voted'
              : 'Do not end the vote if everyone has voted'}
          </Trans>
        </InfoButton>
      )}
      <InfoButton>
        <Trans>
          {survey.show_results_after_voted
            ? 'ShowResultsAfterVotedDescription'
            : 'DoNotShowResultsAfterVotedDescription'}
        </Trans>
      </InfoButton>

      {survey.send_vote_confirmations && (
        <InfoButton>
          <Trans>SendVoteConfirmation</Trans>
        </InfoButton>
      )}

      {isProject && (
        <InfoButton>Link do filmu instruktażowego: {survey.instruction_link}</InfoButton>
      )}

      <InfoButton>
        <Trans>RequiredAuthorization</Trans>: {requiredAuthorization.join(', ')}
        {requiredAuthorization.length === 0 && ` ${i18next.t('noAuthorization')}`}
      </InfoButton>
    </Center>
  );
};

export default SummarySettingsPreview;
