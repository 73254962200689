import { connect } from 'react-redux';
import Surveys from './Surveys';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import {
  getSurveysList,
  resetSurveysList,
  setUpdatedSurveysList,
} from '../../reducers/Surveys/surveys.actions';

function mapStateToProps(state: any, otherProps: RouteComponentProps) {
  return {
    surveys: state.surveys.list,
    location: otherProps.location,
    user: state.auth.user,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getSurveysList,
    setUpdatedSurveysList,
    resetSurveysList,
  })(Surveys)
);
