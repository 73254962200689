import React, { FC, useEffect } from 'react';
import InputText from '../../components/Forms/InputText';
import NoStreetsSwitch from './NoStreetsSwitch';
import { noStreets } from './profile.config';

interface ExtendedProfileForeignAddressProps {
  register?: any;
  formState?: any;
  user?: any;
  formColor?: any;
  areFieldsFinal?: any;
  errors?: any;
  control?: any;
  noStreetsChecked?: any;
  setValue?: any;
}

const ExtendedProfileForeignAddress: FC<ExtendedProfileForeignAddressProps> = (props) => {
  const {
    register,
    formState,
    user,
    formColor,
    areFieldsFinal,
    errors,
    control,
    noStreetsChecked,
    setValue,
  } = props;

  useEffect(() => {
    if (!noStreetsChecked) {
      setValue('extended_profile.street', '');
    }
  }, [noStreetsChecked]);

  return (
    <>
      <InputText
        label={'Region'}
        // showRequiredStar
        name={'extended_profile.province'}
        formRef={register}
        formState={formState}
        defaultValue={user.extended_profile?.province}
        borderColor={formColor}
        color={formColor}
        caretColor={formColor}
        final={areFieldsFinal}
        fieldError={errors?.extended_profile?.province}
      />

      {/*<InputText*/}
      {/*  label={'District'}*/}
      {/*  showRequiredStar*/}
      {/*  name={'extended_profile.county'}*/}
      {/*  formRef={register}*/}
      {/*  formState={formState}*/}
      {/*  defaultValue={user.extended_profile?.county}*/}
      {/*  borderColor={formColor}*/}
      {/*  color={formColor}*/}
      {/*  caretColor={formColor}*/}
      {/*  final={areFieldsFinal}*/}
      {/*  fieldError={errors?.extended_profile?.county}*/}
      {/*/>*/}

      {/*<InputText*/}
      {/*  label={'Community'}*/}
      {/*  showRequiredStar*/}
      {/*  name={'extended_profile.municipality'}*/}
      {/*  formRef={register}*/}
      {/*  formState={formState}*/}
      {/*  defaultValue={user.extended_profile?.municipality}*/}
      {/*  borderColor={formColor}*/}
      {/*  color={formColor}*/}
      {/*  caretColor={formColor}*/}
      {/*  final={areFieldsFinal}*/}
      {/*  fieldError={errors?.extended_profile?.municipality}*/}
      {/*/>*/}

      <InputText
        label={'Locality'}
        showRequiredStar
        name={'extended_profile.locality'}
        formRef={register}
        formState={formState}
        defaultValue={user.extended_profile?.locality}
        borderColor={formColor}
        color={formColor}
        caretColor={formColor}
        final={areFieldsFinal}
        fieldError={errors?.extended_profile?.locality}
      />

      <NoStreetsSwitch
        label={noStreets}
        name={'extended_profile.no_street'}
        formRef={register}
        formState={formState}
        defaultValue={errors?.extended_profile?.no_street}
        final={!!areFieldsFinal}
        disabled={areFieldsFinal}
        fieldError={errors?.extended_profile?.no_street}
        control={control}
      />

      <InputText
        label={'Street'}
        showRequiredStar
        name={'extended_profile.street'}
        formRef={register}
        formState={formState}
        defaultValue={user.extended_profile?.street}
        borderColor={formColor}
        color={formColor}
        caretColor={formColor}
        final={areFieldsFinal}
        fieldError={errors?.extended_profile?.street}
        disabled={noStreetsChecked}
        disabledValue={noStreetsChecked && noStreets}
        setValue={setValue}
      />
    </>
  );
};

export default ExtendedProfileForeignAddress;
