export const deviceTypes = {
  TABLET: 'TABLET',
  MOBILE: 'MOBILE',
  DESKTOP: 'DESKTOP',
}

export const getDeviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return deviceTypes.TABLET;
  }
  if (
    /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return deviceTypes.MOBILE;
  }
  return deviceTypes.DESKTOP;
};
