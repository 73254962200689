import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Center, LoginBgContainer } from '../../components/Containers/containers';
import { LogoSvg } from '../../utils/uiComponents';
import TitleHeader from '../../components/TitleHeader';
import { Trans } from 'react-i18next';
import { colors } from '../../theme';

const TitleSuccessContainer = styled.div`
  margin-top: 50px;
  margin-bottom: 80px;
  text-align: center;
  color: ${colors.darkGreen};
  line-height: 20px;
  font-size: 20px;
  max-width: 75vw;
`;

const ResetSuccess = () => {
  return (
    <LoginBgContainer>
      <Center>
        <LogoSvg src={'/images/ivoting-logo.svg'} />
        <TitleHeader size="medium">
          <TitleSuccessContainer>
            <Trans>ResetPasswordSuccess</Trans>
          </TitleSuccessContainer>
        </TitleHeader>
      </Center>
    </LoginBgContainer>
  );
};

export default ResetSuccess;
