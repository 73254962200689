import {Action} from "../../types/reducerAction.types";
import {UPDATE_CONTACT_PERSONS_DETAIL, UPDATE_CONTACT_PERSONS_LIST} from "./contactPersons.actionTypes";

const INITIAL_STATE = {
  list: [],
  detail: {}
};

export default function contactPersons(state = INITIAL_STATE, action: Action) {
  switch (action.type) {
    case UPDATE_CONTACT_PERSONS_LIST:
      return {
        ...state,
        list: action.payload
      }
    case UPDATE_CONTACT_PERSONS_DETAIL:
      return {
        ...state,
        detail: action.payload
      }
    default:
      return state;
  }
};
