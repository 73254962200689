import React, { useEffect, useState } from 'react';
import { ButtonsWrapper, ModalButton } from '../../../components/Modal/ModalPartials';
import { Trans } from 'react-i18next';
import { useForm, useWatch } from 'react-hook-form';
import { Form, Center } from '../../../components/Containers/containers';
import InputText from '../../../components/Forms/InputText';
import { REQUEST_STATUSES } from '../../../constants/requestStatuses.constants';
import { showNotification } from '../../../utils/taost.config';
import ErrorMessage from '../../../components/Forms/ErrorMessage';

interface Props {
  setOpen(flag: boolean): void;
  failCallback(): void;
  setUserNickname(data: { voting_nick: string }): void;
  setUserNicknameFetchStatus: any;
}

type Inputs = {
  voting_nick: string;
};

const NicknameModal: React.FC<Props> = ({
  setOpen,
  failCallback,
  setUserNickname,
  setUserNicknameFetchStatus,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    formState,
    setError,
    clearErrors,
    control,
  } = useForm<Inputs>();

  const nickname = useWatch({
    name: 'voting_nick',
    defaultValue: '',
    control,
  });

  useEffect(() => {
    clearErrors('voting_nick');
  }, [nickname]);

  useEffect(() => {
    if (setUserNicknameFetchStatus === REQUEST_STATUSES.DONE) {
      setOpen(false);
      showNotification({
        message: 'Pomyślnie ustawiono nick.',
        type: 'success',
      });
    }
    if (setUserNicknameFetchStatus === REQUEST_STATUSES.ERROR) {
      setError('voting_nick', { type: 'nicknameTaken' });
    }
  }, [setUserNicknameFetchStatus]);

  const handleClose = () => {
    failCallback();
    setOpen(false);
  };

  const onSubmit = (data: Inputs) => {
    let isValid = true;
    if (!data.voting_nick) {
      setError('voting_nick', { type: 'required' });
      isValid = false;
    }
    if (data.voting_nick && data.voting_nick.length > 80) {
      setError('voting_nick', { type: 'maxLength', message: '80' });
      isValid = false;
    }
    if (isValid) {
      setUserNickname(data);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <InputText label={'Nick'} name={'voting_nick'} formRef={register} formState={formState} />
      <ErrorMessage fieldError={errors.voting_nick} />
      <ButtonsWrapper>
        <ModalButton
          type={'submit'}
          full
          disabled={setUserNicknameFetchStatus === REQUEST_STATUSES.FETCHING}
        >
          <Trans>Ustaw</Trans>
        </ModalButton>
        <ModalButton type={'button'} full cancel onClick={handleClose}>
          <Trans>Anuluj</Trans>
        </ModalButton>
      </ButtonsWrapper>
    </Form>
  );
};

export default NicknameModal;
