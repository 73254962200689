import React, { useState } from 'react';
import ErrorMessage from './Forms/ErrorMessage';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import 'react-multi-email/style.css';
import styled from 'styled-components';
import { getInputStyles } from './Forms/forms';
import { colors } from '../theme';

const MultiselectContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 15px;
  width: 90%;

  > div.react-multi-email {
    ${(props) => getInputStyles(props)}
    font-size: 24px !important;
    border-color: ${(props: any) => (props.isError ? colors.red : colors.black)} !important;
    border-width: 3px !important;
    height: auto;
    border-radius: 2rem;
    padding: 3px 18px 3px 18px;

    span[data-placeholder] {
      top: 0;
      left: 0.75em;
      color: #757575;
    }

    input {
      font-size: 18px;
      max-width: 100%;
    }

    .chip-container {
      line-height: 0.95 !important;
      font-size: 14px !important;
      display: flex;
      .email-content {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        line-height: 18px;
      }
      .email-content:hover {
        text-overflow: unset !important;
      }
      .delete-button {
        min-width: 5px;
        min-height: 5px;
        color: ${colors.red};
        font-size: 21px;
        padding-left: 3px;
        padding-right: 3px;
        margin-left: 0 !important;
      }
    }
  }
`;

interface Props {
  emails?: Array<any>;
  setEmails(emails: Array<string>): any;
  errors?: any;
  errorKey?: string;
}

const MultiselectEmail: React.FC<Props> = ({ errors, emails, setEmails, errorKey = 'emails' }) => {
  return (
    // @ts-ignore
    <MultiselectContainer isError={errors && errors[errorKey]}>
      <ReactMultiEmail
        placeholder={'Adresy email'}
        emails={emails}
        onChange={(_emails: Array<string>) => {
          setEmails(_emails);
        }}
        className={'react-multi-email'}
        getLabel={(email: string, index: number, removeEmail: (index: number) => void) => {
          return (
            <div data-tag className="chip-container" key={index}>
              <span className="email-content">{email}</span>
              <span data-tag-handle className="delete-button" onClick={() => removeEmail(index)}>
                ×
              </span>
            </div>
          );
        }}
      />
      <ErrorMessage fieldError={errors && errors[errorKey]} />
    </MultiselectContainer>
  );
};

export default MultiselectEmail;
