import countryData from './country-data.json';
import _ from 'lodash';
import LocaleCurrency from 'locale-currency';

export interface SuggestionType {
  name: string;
  alpha2: string;
  alpha3: string;
  isoNumeric: string;
  countryCode: string;
}

export interface SuggestionTypeWithCurrency extends SuggestionType {
  currencyCode: string;
}

const countries = associateCurrencies(countriesWithCurrency(countryData));

function associateCurrencies(countries: any) {
  return _.map(
    countries,
    (country: SuggestionType): SuggestionTypeWithCurrency => ({
      ...country,
      currencyCode: LocaleCurrency.getCurrency(country.alpha2),
    })
  );
}

function countriesWithCurrency(countries: any) {
  return _.filter(
    countries,
    (country: SuggestionType) => !!LocaleCurrency.getCurrency(country.alpha2)
  );
}

function stringStartsWith(s: any, fragment: any) {
  return s.indexOf(fragment) === 0;
}

function stringContains(s: any, fragment: any) {
  return s.indexOf(fragment) !== -1;
}

export function getSuggestions(arg = '') {
  const value = arg.trim().toUpperCase();
  return value === ''
    ? countries
    : _.filter(countries, (country: SuggestionTypeWithCurrency) => {
        return (
          stringStartsWith(country.currencyCode, value) ||
          stringContains(country.name.toUpperCase(), value)
        );
      });
}

export function checkIfCurrencyExists(currency: string) {
  const value = currency.trim().toUpperCase();
  return !!_.find(
    countries,
    (country: SuggestionTypeWithCurrency) => country.currencyCode === value
  );
}
