import React, { FC, useCallback, useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import styled from 'styled-components';
import keycloakProvider from './Keycloak';
import { ReactKeycloakProvider } from '@react-keycloak/web';

const KeycloackTitle = styled.div``;

const CyberIDLoginParent = (props: any) => {
  return (
    <ReactKeycloakProvider
      authClient={keycloakProvider}
      onEvent={(event: unknown, error: unknown) => {
        console.log('onKeycloakEvent', event, error);
      }}
      onTokens={(tokens: unknown) => {
        console.log('onKeycloakTokens', tokens);
      }}
      LoadingComponent={<div>Loading...</div>}
    >
      <CyberIDLogin />
    </ReactKeycloakProvider>
  );
};

const CyberIDLogin = (props: any) => {
  const { initialized, keycloak } = useKeycloak();

  const login = useCallback(() => {
    if (keycloak?.login) keycloak?.login({
        // redirectUri: "https://nocache.free.dev.ivoting.pl/",
        scope: "ivotingfree_basic_scope",
    });
    else {
      console.error(`Can't log in `, keycloak);
    }
  }, [keycloak]);

  const logout = () => keycloak.logout();

  return (
    <div>
      <KeycloackTitle>Keycloack login</KeycloackTitle>
      <button onClick={login}>Login</button>
      {keycloak?.authenticated && <button onClick={logout}>Logout</button>}

      <div>Logs:</div>
      <div>Keycloack {initialized ? 'Initialized ' : 'Not initialized'} </div>
      <div>Is authenticated: {keycloak?.authenticated}</div>
    </div>
  );
};

export default CyberIDLoginParent;
