import React, { useEffect } from 'react';
import { Group } from '../types/groups.types';
import styled from 'styled-components';
import { colors, device } from '../theme';
import InputText from './Forms/InputText';
import { FormStateProxy } from 'react-hook-form/dist/types/form';
import { Trans, useTranslation } from 'react-i18next';
import { REQUEST_STATUSES } from '../constants/requestStatuses.constants';
import NumberBadge from './NumberBadge';
import Tooltip from '@material-ui/core/Tooltip';

export const ButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 90%;
  gap: 1rem;

  @media ${device.laptop} {
    max-width: 600px;
  }
`;

export const Button: any = styled.button`
  display: inline-block;
  text-align: center;
  width: 100%;
  padding: 0.5rem 0.875rem;
  background-color: ${(props: any) => (props.selected ? colors.darkBlue : colors.gray)};
  border: 2px solid ${(props: any) => (props.selected ? colors.darkBlue : colors.gray)};
  border-radius: 50px;
  font-size: 1rem;
  margin: 5px;
  color: ${colors.white};
  overflow-wrap: break-word;

  ${(props: any) =>
    props.hover !== false && !props.disabled
      ? `
  &:hover {
    cursor: pointer;
    background-color: ${colors.lightBlue};
    border: 2px solid ${colors.lightBlue};
  }
  `
      : ''}

  @media ${device.laptop} {
    max-width: 30%;
  }
`;

export const ButtonWithTooltip = (props: any) => {
  const { disabledInfo, disabled, ...otherProps } = props;

  return disabled ? (
    <Tooltip title={disabled ? disabledInfo : null}>
      <Button {...otherProps} hover={false} onClick={(e: any) => e.preventDefault()} />
    </Tooltip>
  ) : (
    <Button {...otherProps} />
  );
};

interface Props {
  register?: any;
  formState?: any;
  groups?: Array<Group>;
  selectedGroups: Array<number>;
  newGroup?: boolean;
  text?: boolean;
  fetchStatus?: string;

  getGroups?(): void;
  setSelectedGroups(p: (prev: any) => Array<number>): void;
}

// todo: change it all to work as a single input
const SelectGroup: React.FC<Props> = ({
  register,
  formState,
  selectedGroups,
  setSelectedGroups,
  getGroups,
  groups,
  newGroup = false,
  text = true,
  fetchStatus,
}) => {
  const handleClickGroup = (group: Group) => {
    if (selectedGroups.includes(+group.id)) {
      setSelectedGroups((prev: any) => prev.filter((id: number) => id !== +group.id));
    } else {
      setSelectedGroups((prev: any) => [...prev, +group.id]);
    }
  };
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (getGroups) getGroups();
  }, []);

  return (
    <>
      {newGroup && register && formState && (
        <InputText
          label={'AddToNewGroup'}
          name={'newGroup'}
          formRef={register}
          formState={formState}
          formType={'survey'}
        />
      )}
      {groups && groups.length > 0 && (
        <>
          {text && (
            <p style={{ margin: '20px 0' }}>
              <Trans>orAddToExistingGroup</Trans>
            </p>
          )}
          <ButtonsContainer>
            {groups.map((group, key) => {
              return (
                <ButtonWithTooltip
                  disabled={group?.read_only}
                  disabledInfo={t('NoInvitePermission')}
                  key={key}
                  type={'button'}
                  selected={selectedGroups && selectedGroups.includes(+group.id)}
                  onClick={() => handleClickGroup(group)}
                >
                  {group.name}
                  {typeof group.contacts_count === 'number' && (
                    <NumberBadge number={group.contacts_count} withBorder={true} />
                  )}
                </ButtonWithTooltip>
              );
            })}
          </ButtonsContainer>
        </>
      )}
      {(!groups || !groups.length) &&
        !newGroup &&
        fetchStatus === REQUEST_STATUSES.DONE &&
        'Brak istniejących grup'}
    </>
  );
};

export default SelectGroup;
