import React from 'react';
import styled from 'styled-components';

const Header: any = styled.h1`
  text-transform: uppercase;
  font-size: ${(props: any) => (props.size === 'medium' ? 30 : 50)}px;
  line-height: 50px;
  font-weight: bold;
  margin-bottom: 0;
`;

interface Props {
  title?: 'string';
  children?: any;
  size?: any;
}

const TitleHeader: React.FC<Props> = ({ title, children, size }) => {
  return <Header size={size}>{title || children}</Header>;
};

export default TitleHeader;
