import { createBrowserHistory, History } from 'history';

export interface MyLocationState {
  redirect?: string;
  phone?: string;
  isVoting?: boolean;
  uuid?: string;
  nickname?: string;
  canVoteWithoutLogin?: boolean;
  redirectToSurvey?: string;
  surveyType?: string;
}

export const history: History<MyLocationState> = createBrowserHistory();
