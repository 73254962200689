import { useMemo } from 'react';
import { setLocale } from 'yup';
import { LocaleObject } from 'yup/lib/locale';

// getting validation params

export const getValidationParam = ({
  schema,
  name,
  paramName,
}: {
  schema: any;
  name: string;
  paramName: string;
}) => {
  const field = name.split('.').reduce((previousValue, currentValue, index, array) => {
    if (!isNaN(+currentValue)) {
      return previousValue.innerType;
    }
    return previousValue.fields[currentValue];
  }, schema);
  return field?.tests.find((test: any) => test.OPTIONS.name === paramName)?.OPTIONS;
};

export const getValidationParamValue = ({
  schema,
  name,
  paramName,
  valueName,
}: {
  schema: any;
  name: string;
  paramName: string;
  valueName?: string;
}) => {
  return getValidationParam({
    schema,
    name,
    paramName,
  })?.params[valueName || paramName];
};

export const useValidationParam = (props: { schema: any; name: string; paramName: string }) => {
  return useMemo(() => props.schema && getValidationParam(props), [props.schema]);
};

export const useValidationParamValue = (props: {
  schema: any;
  name: string;
  paramName: string;
  valueName?: string;
}) => {
  return useMemo(() => props.schema && getValidationParamValue(props), [props.schema]);
};

// locale

export const yupLocale = (customConfig?: LocaleObject) => {
  setLocale({
    mixed: {
      required: 'To pole jest wymagane',
      notType: function notType(_ref) {
        switch (_ref.type) {
          case 'number':
            return 'Wartość musi być liczbą';
          case 'string':
            return 'To nie jest tekst';
          default:
            return 'Nieprawidłowy typ';
        }
      },
      ...customConfig?.mixed,
    },
    string: {
      max: (params) => `To pole nie może być dłuższe niż ${params.max} znaków`,
      url: () => `To nie jest link`,
      ...customConfig?.string,
    },
    number: {
      min: (params) => `Wartość musi być nie mniejsza niż ${params.min}`,
      positive: (params) => `Wartość musi być większa od ${params.more}`,
      ...customConfig?.number,
    },
    date: {
      ...customConfig?.date,
    },
    boolean: {
      ...customConfig?.boolean,
    },
    object: {
      ...customConfig?.object,
    },
    array: {
      ...customConfig?.array,
    },
  });
};
