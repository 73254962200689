import { connect } from 'react-redux';
import QrVerification from './QrVerification';
import { RouteComponentProps } from 'react-router';
import {clerkVerificationCheckKeys, clerkVerificationVerify} from '../../reducers/Auth/auth.actions';

type OtherProps = RouteComponentProps<{
  uuid: string;
  public_key: string;
}>;

function mapStateToProps(state: any, otherProps: OtherProps) {
  const searchParams = new URLSearchParams(otherProps.location.search);

  return {
    uuid: searchParams.get('uuid'),
    public_key: searchParams.get('public_key'),
    user: state.auth.user,
    keysCheckFetchStatus: state.auth.clerkKeysCheckFetchStatus,
    verifyFetchStatus: state.auth.clerkVerifyFetchStatus,
  };
}

export default connect(mapStateToProps, {
  clerkVerificationCheckKeys,
  clerkVerificationVerify
})(QrVerification);
