export const extendedProfileAgreement = 'Oświadczenie o poprawności podanych danych osobowych';
export const noLocalities = 'Brak ulic';
export const noStreets = 'Brak ulic';
export const extendedProfileModalTitle = 'Oświadczenie o poprawności podanych danych osobowych';
export const extendedProfileModalContent =
  'Oświadczam, że podane przeze mnie dane osobowe, w tym dane o moim aktualnym zamieszkaniu, obywatelstwie oraz dane dotyczące mojego aktualnego wieku, w tym data urodzenia są zgodne z prawdą na dzień podpisania tego oświadczenia.';
export const noLocalitiesErrorMessage =
  'Brak dostępnych miejscowości. Sprawdź czy wybrałeś poprawną Gminę.';
export const noStreetsErrorMessage =
  'Brak dostępnych ulic. Sprawdź czy wybrałeś poprawną Miejscowość. W przypadku braku ulic zakreśl checkbox: Brak ulicy widoczny poniżej.';

export const extendedWhenParams = ['age', 'address'];
export const formColor = '#fff';
