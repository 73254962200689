import React, { FC } from 'react';
import styled from 'styled-components';
import { colors } from '../../theme';

const QuestionSeparator = styled.div`
  margin-top: 30px;
  height: 5px;
  margin-left: -40px;
  width: 20%;
  background-color: ${colors.darkBlue};
  margin-right: auto;
  float: left;
`;

export default QuestionSeparator;
