import React, { useEffect } from 'react';
import { FormLabel } from '../../../../components/Forms/forms';
import { Trans } from 'react-i18next';
import Checkbox from '../../../../components/Forms/Checkbox';
import Counter from '../../NewSurveyExtended/Counter';
import { useWatch } from 'react-hook-form';
import styled from 'styled-components';
import { UseFormReturn } from 'react-hook-form/dist/types/form';

const StyledCounter = styled(Counter)`
  margin-left: 1rem;
`;

interface Props {
  formMethods: UseFormReturn;
}

const AuthSettings: React.FC<Props> = (props) => {
  const { register, formState, control, setValue } = props.formMethods;

  const watchMinAgeValue = useWatch({ control, name: 'require_to_vote_min_age' });
  const watchMinAgeCheckbox = useWatch({ control, name: 'require_to_vote_min_age_checkbox' });

  const watchIsProtocolLabelName = useWatch({ control, name: 'is_protocol_label_name' });
  const watchIsRequireToVoteName = useWatch({ control, name: 'require_to_vote_name' });

  const watchIsProtocolAnonymized = useWatch({ control, name: 'is_protocol_anonymized' });
  const watchIsRequireToVoteEmail = useWatch({ control, name: 'require_to_vote_email' });

  useEffect(() => {
    if (watchIsProtocolLabelName && !watchIsRequireToVoteName) {
      setValue('require_to_vote_name', true);
    }
    if (watchIsProtocolAnonymized && !watchIsRequireToVoteEmail) {
      setValue('require_to_vote_email', true);
    }
  }, [watchIsProtocolLabelName, watchIsProtocolAnonymized]);

  return (
    <>
      <FormLabel formType={'survey'}>
        <Trans>RequiredAuthorization</Trans>
      </FormLabel>
      <div style={{ width: '90%' }}>
        <Checkbox
          label={'Email'}
          name={'require_to_vote_email'}
          formRef={register}
          formState={formState}
          popoverText={'requireToVoteEmail'}
          disabled={watchIsProtocolAnonymized}
        />
        <Checkbox
          label={'Phone'}
          name={'require_to_vote_phone'}
          formRef={register}
          formState={formState}
          popoverText={'requireToVotePhone'}
        />
        <Checkbox
          label={'QrCode'}
          name={'require_to_vote_qr'}
          formRef={register}
          formState={formState}
          popoverText={'requireToVoteQr'}
        />
        <Checkbox
          label={'Imię i nazwisko'}
          name={'require_to_vote_name'}
          formRef={register}
          formState={formState}
          popoverText={'requireToVoteName'}
          disabled={watchIsProtocolLabelName}
        />
        <Checkbox
          label={'Adres'}
          name={'require_to_vote_address'}
          formRef={register}
          formState={formState}
          popoverText={'requireToVoteAddress'}
        />
        <Checkbox
          label={'Minimalny wiek'}
          name={'require_to_vote_min_age_checkbox'}
          formRef={register}
          formState={formState}
          popoverText={'requireToVoteMinAge'}
          extraButton={
            watchMinAgeCheckbox && (
              <StyledCounter
                setValue={(value) => setValue('require_to_vote_min_age', value)}
                minCount={1}
                maxCount={99}
                register={register}
                name={`min_age_value`}
                value={watchMinAgeValue}
              />
            )
          }
        />
      </div>
    </>
  );
};

export default AuthSettings;
