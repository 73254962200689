import styled from 'styled-components';
import ExtendedProfileSwitch from './ExtendedProfileSwitch';
import { noStreets } from './profile.config';
import React, { FC } from 'react';

const NoStreetsContainer = styled.div`
  width: 100%;
  > div {
    margin-bottom: -5px;
  }
`;

const NoStreetsSwitch: FC<any> = ({
  name,
  formRef,
  formState,
  defaultValue,
  final,
  disabled,
  fieldError,
  control,
}) => {
  return (
    <NoStreetsContainer>
      <ExtendedProfileSwitch
        label={noStreets}
        name={name}
        formRef={formRef}
        formState={formState}
        defaultValue={defaultValue}
        final={final}
        disabled={disabled}
        fieldError={fieldError}
        control={control}
      />
    </NoStreetsContainer>
  );
};

export default NoStreetsSwitch;
