import { SurveyData } from '../../../types/surveys.types';

export const getAnswers = (newSurvey: SurveyData) => {
  const isEditMode = !!(newSurvey.questions && newSurvey?.questions[0]?.answers);
  if (isEditMode) {
    return newSurvey.questions[0].answers.map((answer) => ({
      text: answer.text,
      value: answer.is_default,
    }));
  } else {
    return [
      {
        text: 'Tak',
        value: false,
      },
      {
        text: 'Nie',
        value: false,
      },
      {
        text: 'Nie wiem',
        value: false,
      },
    ];
  }
};

export const getDefaultValues = (newSurvey: SurveyData): any => {
  return {
    title: newSurvey.name ?? '',
    question: newSurvey.questions ? newSurvey.questions[0].question : '',
    answers: getAnswers(newSurvey),
    default_answer: newSurvey.default_answer ?? false,
  };
};
