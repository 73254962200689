import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { colors } from '../../../../theme';
import AnswerInput from './AnswerInput';
import { getProjectAnswerDefaultValue } from '../utils/projectDefaultValues';
import RequiredAnswersCounters from '../../../../components/Forms/RequiredAnswersCounters';
import { PrimarySmallButton } from '../../../../components/Buttons/buttons';
import { FormLabel } from '../../../../components/Forms/forms';
import ErrorMessage from '../../../../components/Forms/ErrorMessage';
import getFieldError from '../../../../utils/getFieldError';
import { UseFormReturn } from 'react-hook-form/dist/types';
import { StyledRequiredAnswersCounters } from '../StyledComponents';

interface Props {
  name: string;
  formMethods: UseFormReturn;
  schema?: any;
}

const AnswersFieldArray: React.FC<Props> = (props) => {
  const { name, schema, formMethods } = props;
  const { control, register, formState, setValue } = formMethods;
  const { errors } = formState;
  const { fields, append, remove } = useFieldArray({
    control,
    name,
    shouldUnregister: false,
  });

  return (
    <>
      <FormLabel formType={'survey'}>Nazwa zadania</FormLabel>

      {fields.map((field, index) => (
        <AnswerInput
          key={field.id}
          formMethods={formMethods}
          name={`${name}.${index}`}
          remove={() => remove(index)}
          fieldsLength={fields.length}
          schema={schema}
          answerIndex={index}
        />
      ))}

      <ErrorMessage
        fieldError={getFieldError(
          errors,
          `${name.split('.').slice(0, 2).join('.')}.selected_default_answer`
        )}
      />

      <PrimarySmallButton
        type={'button'}
        onClick={() => append(getProjectAnswerDefaultValue())}
        color={colors.darkGreen}
        bgColor={'transparent'}
        borderColor={colors.darkGreen}
        style={{ marginTop: '1rem' }}
        fullWidth={true}
      >
        Dodaj kolejne zadanie
      </PrimarySmallButton>

      <StyledRequiredAnswersCounters
        control={control}
        register={register}
        setValue={setValue}
        name={name.split('.').slice(0, 2).join('.')}
        fieldsLength={fields.length}
      />
    </>
  );
};

export default AnswersFieldArray;
