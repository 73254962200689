import {BottomNavigationActionType} from "../../types/layout.types";
import {getCreateSurveyPath, getMyProfilePath, getSurveysPath} from "../../constants/paths.constants";

export const bottomNavigationPaths = [
  `${getCreateSurveyPath()}`, `${getSurveysPath()}?active=true`, `${getSurveysPath()}`, `${getSurveysPath()}?mine=true`
]

export const bottomNavigationActions: Array<BottomNavigationActionType> = [
  {
    label: 'Create',
    value: bottomNavigationPaths[0],
    icon: 'create',
    path: bottomNavigationPaths[0],
    action: 'create',
    notAuthenticatedPath: getMyProfilePath()
  },
  {
    label: 'Active',
    value: bottomNavigationPaths[1],
    icon: 'active',
    path: bottomNavigationPaths[1]
  },
  {
    label: '',
    value: '',
    icon: 'qr',
    path: '',
    action: 'qr'
  },
  {
    label: 'All',
    value: bottomNavigationPaths[2],
    icon: 'all',
    path: bottomNavigationPaths[2]
  },
  {
    label: 'Mine',
    value: bottomNavigationPaths[3],
    icon: 'mine',
    path: bottomNavigationPaths[3]
  },
]
