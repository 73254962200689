import React from 'react';
import { ResultTypeWithSurveyData, SurveyType, SurveyTypeEnum } from '../../types/surveys.types';
import SimpleModal from '../Modal/SimpleModal';
import { Center } from '../Containers/containers';
// @ts-ignore
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { showNotification } from '../../utils/taost.config';
import styled from 'styled-components';
import { colors, device } from '../../theme';
import SurveyShareIcons from './SurveyShareIcons';
import { isInWebviewByDomain } from '../../utils/mobileHelper';
import SurveyShareNative from './SurveyShareNative';

const IconsContainer = styled.div`
  display: flex;
`;

const LinkSpan = styled.span`
  color: #333;
  padding: 0.5rem 1.5rem;
  margin: 2rem 0;
  border: 3px solid #000;
  border-radius: 100px;
  width: 90%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &:hover {
    color: #666;
    cursor: pointer;
  }

  @media ${device.laptop} {
    max-width: 600px;
  }
`;

const QrCode = styled.img`
  height: 10rem;
  border: 3px solid #000;
`;

interface Props {
  uuid: string;
  survey: SurveyType | ResultTypeWithSurveyData;
  isOpen: boolean;

  setIsOpen(flag: boolean): void;
}

const SurveyShareModal: React.FC<Props> = ({ isOpen, setIsOpen, uuid, survey }) => {
  const link =
    survey.survey_type === SurveyTypeEnum.project
      ? survey.share_url
      : `${process.env.REACT_APP_URL}/survey/${uuid}/${
          survey && survey.has_ended ? 'results' : 'vote'
        }`;

  const copyLinkToClipboard = () => {
    showNotification({
      message: 'Skopiowano link',
      type: 'success',
    });
  };

  return (
    <SimpleModal open={isOpen} setOpen={setIsOpen} title={'ShareVoting'}>
      <Center>
        {survey && (
          <>
            <IconsContainer>
              {isInWebviewByDomain() ? (
                <SurveyShareNative url={link} />
              ) : (
                <SurveyShareIcons link={link} copyLinkToClipboard={copyLinkToClipboard} />
              )}
            </IconsContainer>
            <CopyToClipboard text={link} onCopy={copyLinkToClipboard}>
              <LinkSpan>{link}</LinkSpan>
            </CopyToClipboard>
            {survey.qr && <QrCode src={survey.qr} />}
          </>
        )}
      </Center>
    </SimpleModal>
  );
};

export default SurveyShareModal;
