export function getSessionToken() {
  return localStorage.getItem('sessionToken');
}

export function getVoterId() {
  return localStorage.getItem('vid');
}

export function getPublicSurveyUid() {
  return localStorage.getItem('publicSurveyUid');
}

export function saveSessionToken(data: { token: string | undefined }) {
  if (data && data.token) localStorage.setItem('sessionToken', data.token);
  else console.error('Incorrect token format!');
}

export function saveVoterId(voterId: string | null) {
  if (voterId) localStorage.setItem('vid', voterId);
  else console.error('Incorrect voter id format!');
}

export function savePublicSurveyUid(publicSurveyId: string | null) {
  if (publicSurveyId) localStorage.setItem('publicSurveyUid', publicSurveyId);
  else console.error('Incorrect voter id format!');
}

export function deleteSessionToken() {
  localStorage.removeItem('sessionToken');
  localStorage.removeItem('user');
  // if sb used app before changes for multiple users
  localStorage.removeItem('public_key');
  localStorage.removeItem('private_key');
  // possibly also notificationQueryDate
}

export function deleteVoterId() {
  localStorage.removeItem('vid');
}

export function deletePublicSurveyUid() {
  localStorage.removeItem('publicSurveyUid');
}

export function getBlockchainKeysForUser(userId: string | number) {
  let allKeysFromStorage: object = getAllKeysFromStorage();
  for (let [storedUserId, keys] of Object.entries(allKeysFromStorage)) {
    if (String(storedUserId) === String(userId)) {
      return keys;
    }
  }
  return {
    private_key: null,
    public_key: null,
  };
}

export function saveBlockchainKeysForUser(
  keys: { private_key: string; public_key: string },
  userId: string | number
) {
  let allKeysFromStorage: object = getAllKeysFromStorage();
  // @ts-ignore
  allKeysFromStorage[String(userId)] = keys;
  localStorage.setItem(`blockchain_keys`, JSON.stringify(allKeysFromStorage));
}

const getAllKeysFromStorage = () => {
  let usersKeys = {};
  let keysFromStorage = localStorage.getItem(`blockchain_keys`);

  if (typeof keysFromStorage === 'string' && keysFromStorage.length) {
    try {
      usersKeys = JSON.parse(keysFromStorage);
    } catch (err) {
      console.error(`Keys parsing error: ${err}`);
    }
  }
  return usersKeys;
};
