import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useForm, useWatch } from 'react-hook-form';
import InputText from '../../../components/Forms/InputText';
import InputTextarea from '../../../components/Forms/InputTextarea';
import SurveyTypeHeader from '../../../components/SurveyTypeHeader';
import { getAnswers, getDefaultValues } from './newSurveyPicture.helpers';
import { SurveyAnswer, SurveyData } from '../../../types/surveys.types';
import { PrimarySmallButton } from '../../../components/Buttons/buttons';
import { FormLabel } from '../../../components/Forms/forms';
import { Form } from '../../../components/Containers/containers';
import PicturesFieldArray from './PicturesFieldArray';
import _ from 'lodash';

interface Props {
  newSurvey: SurveyData;
  updateNewSurveyData(data: any): void;
  setStep(stepNumber: number): void;
}

type Inputs = {
  name: string;
  question: string;
  answers: [
    {
      text: string;
      image: any;
    }
  ];
};

const CreatePictureSurvey: React.FC<Props> = ({ updateNewSurveyData, setStep, newSurvey }) => {
  const isEditMode =
    newSurvey.questions && newSurvey.questions.length > 0 && newSurvey.questions[0]?.answers;
  const [answerCount, setAnswerCount] = useState<any>(
    isEditMode ? newSurvey.questions[0].answers.length : 2
  );
  const [answersErrors, setAnswersErrors] = useState<any>({});
  const getNewSurveyWithDefaultQuestions = (changedSurvey: any) => {
    if (changedSurvey.answers && changedSurvey.answers.length < 2)
      changedSurvey.answers = [
        {
          text: null,
          image: null,
        },
        {
          text: null,
          image: null,
        },
      ];
    return changedSurvey;
  };
  const { register, control, handleSubmit, formState, setValue, getValues } = useForm<any>({
    defaultValues: getNewSurveyWithDefaultQuestions(getDefaultValues(newSurvey)),
  });

  const errors = formState.errors;

  const watchAnswers = useWatch({
    control,
    name: 'answers',
    defaultValue: getAnswers(isEditMode ? newSurvey.questions[0].answers : []),
  });

  const onSubmit = (data: Inputs) => {
    let answersError = false;
    data.answers.forEach((answer, index) => {
      let error = !((!!answer.image[0] || !!answer.image) && answer.image.length && !!answer.text);
      if (error) answersError = true;
      setAnswersErrors((prev: any) => ({
        ...prev,
        [index]: error,
      }));
    });
    if (!answersError) {
      setStep(2);
      updateNewSurveyData({
        name: data.name,
        questions: [
          {
            question: data.question,
            answers: data.answers,
          },
        ],
        survey_type: 'image',
      });
    }
  };

  useEffect(() => {
    // todo: refactor setting images errors, add first render hook
    if (
      !watchAnswers ||
      !watchAnswers.length ||
      (watchAnswers && watchAnswers[0]?.image === '') ||
      (watchAnswers && watchAnswers[0]?.text === '')
    )
      return;
    if (!_.isEmpty(answersErrors)) setAnswersErrors({});
  }, [watchAnswers]);

  const getImageDefaultValue = (isEditMode: boolean, answer: SurveyAnswer | undefined) => {
    if (isEditMode && answer) {
      if (answer.image[0]) {
        return URL.createObjectURL(answer.image[0]);
      } else {
        return answer.image;
      }
    } else {
      return undefined;
    }
  };

  return (
    <>
      <SurveyTypeHeader type={'image'} />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <InputText
          label={'PollTitle'}
          name={'name'}
          formRef={register}
          fieldError={errors.name}
          validate={{ required: true }}
          formState={formState}
          formType={'survey'}
          showRequiredStar={false}
        />
        <InputTextarea
          label={'PollQuestion'}
          name={'question'}
          formRef={register}
          formState={formState}
          formType={'survey'}
          fieldError={errors.question}
          control={control}
          showRequiredStar={false}
          validate={{ required: true }}
        />

        <>
          <FormLabel formType={'survey'}>
            <Trans>AddPictures</Trans>
          </FormLabel>
          <PicturesFieldArray
            register={register}
            formState={formState}
            control={control}
            answerCount={answerCount}
            getImageDefaultValue={getImageDefaultValue}
            isEditMode={isEditMode}
            newSurvey={newSurvey}
            answersErrors={answersErrors}
            setAnswerCount={setAnswerCount}
            setAnswersErrors={setAnswersErrors}
            setValue={setValue}
          />
        </>
        <PrimarySmallButton>
          <Trans>Further</Trans>
        </PrimarySmallButton>
      </Form>
    </>
  );
};

export default CreatePictureSurvey;
