import { connect } from 'react-redux';
import Register from './Register';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { registerUser, setRegistrationFetchStatus } from '../../reducers/Auth/auth.actions';

function mapStateToProps(state: any, otherProps: RouteComponentProps) {
  return {
    registerFetchStatus: state.auth.registerFetchStatus,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    registerUser,
    setRegistrationFetchStatus
  })(Register)
);
