import { connect } from 'react-redux';
import VerifyEmailCodeModal from './VerifyEmailCodeModal';
import { RouteComponentProps } from 'react-router';
import {
  checkVerifications,
  loginUser,
  sendEmailConfirmation,
  verifyEmailByCode,
} from '../../reducers/Auth/auth.actions';

function mapStateToProps(state: any, otherProps: any) {
  return {
    ...otherProps,
    loginFetchStatus: state.auth.loginFetchStatus,
    errorTypes: state.auth.errorTypes,
    accountForSurveyVotingData: state.auth.accountForSurveyVoting.data,
    user: state.auth.user,
    codeFetchStatus: state.auth.verifyEmailCode.fetchStatus,
    verificationsDirectFromBE: state.auth.verificationsDirectFromBE,
  };
}

export default connect(mapStateToProps, {
  verifyEmailByCode,
  checkVerifications,
  sendEmailConfirmation,
})(VerifyEmailCodeModal);
