import { useWatch } from 'react-hook-form';
import { useValidationParamValue } from './yup/yupHelpers';

const useLettersCount = ({
  name,
  control,
  schema,
}: any): [lettersCount: number | undefined, maxlength: number | undefined] => {
  const watchValue = useWatch({
    name,
    control,
  });
  const lettersCount = watchValue?.length ?? 0;
  const maxLengthFromSchema = useValidationParamValue({ schema, name, paramName: 'max' });

  return [lettersCount, maxLengthFromSchema];
};

export default useLettersCount;
