import { actionTypes } from './notifications.actionTypes';
import { Action } from '../../types/reducerAction.types';
import { REQUEST_STATUSES } from '../../constants/requestStatuses.constants';
import { FCMRegisterPayload } from '../../types/FCMRegisterPayload.types';

interface initialStateType {
  summary: {
    unread_count: null | number | string;
    fetchStatus: null | string;
  };
  list: {
    next: null | string;
    previous: null | string;
    results: null | Array<any>;
    fetchStatus: null | string;
  };
  markAsRead: {
    fetchStatus: null | string;
  };
  // notificationsList: [],
  notificationsFetchStatus: null | string;
  fcmDevice: {
    fetchStatus: null | string;
    info: null | FCMRegisterPayload;
    confirmedInfo: null | any;
  };
  notifications: any;
}

const INITIAL_STATE: initialStateType = {
  summary: {
    unread_count: null,
    fetchStatus: REQUEST_STATUSES.NULL,
  },
  list: {
    next: null,
    previous: null,
    results: null,
    fetchStatus: REQUEST_STATUSES.NULL,
  },
  markAsRead: {
    fetchStatus: REQUEST_STATUSES.NULL,
  },
  // notificationsList: [],
  notificationsFetchStatus: null,
  fcmDevice: {
    fetchStatus: null,
    info: null,
    confirmedInfo: null,
  },
  notifications,
};

export default function notifications(state = INITIAL_STATE, action: Action) {
  switch (action.type) {
    case actionTypes.SET_SUMMARY_FETCH_STATUS:
      return {
        ...state,
        summary: { ...state.summary, fetchStatus: action.payload },
      };
    case actionTypes.SET_SUMMARY:
      return {
        ...state,
        summary: { ...state.summary, unread_count: action.payload?.unread_count },
      };
    case actionTypes.SET_NOTIFICATIONS_FETCH_STATUS:
      return {
        ...state,
        list: { ...state.list, fetchStatus: action.payload },
      };
    case actionTypes.SET_ALL_AS_READ_FETCH_STATUS:
      return {
        ...state,
        markAsRead: { ...state.markAsRead, fetchStatus: action.payload },
      };
    case actionTypes.SET_NOTIFICATIONS_LIST: {
      const wasLoadMore = action.payload.previous;
      return {
        ...state,
        list: {
          ...state.list,
          results:
            wasLoadMore && state.list.results?.length
              ? [...state.list.results, ...action.payload.results]
              : action.payload.results,
          next: action.payload.next,
          previous: action.payload.previous,
        },
      };
    }
    case actionTypes.REGISTER_FCM_DEVICE_FETCH_STATUS:
      return {
        ...state,
        fcmDevice: { ...state.fcmDevice, fetchStatus: action.payload },
      };
    case actionTypes.UPDATE_FCM_INFO:
      return {
        ...state,
        fcmDevice: { ...state.fcmDevice, info: action.payload },
      };
    case actionTypes.REGISTER_FCM_DEVICE:
      return {
        ...state,
        fcmDevice: { ...state.fcmDevice, confirmedInfo: action.payload },
      };
    default:
      return state;
  }
}
