import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../theme';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 0.5rem;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const CounterButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 30px;
  line-height: 0;
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 50%;
  background-color: ${colors.lightBlue};
  padding: 0;
  color: ${colors.white};
  cursor: pointer;
`;

const TextValue = styled.span`
  font-size: 1rem;
`;

interface Props {
  text?: string;
  maxCount?: number;
  minCount?: number;
  register: any;
  name: string;
  value: number;
  className?: string;

  setValue(value: number): void;
}

const Counter: React.FC<Props> = (props) => {
  const { text, minCount = 1, maxCount = 99, setValue, register, name, value } = props;

  const decrement = () => {
    if (value - 1 >= +minCount) setValue(value - 1);
  };

  const increment = () => {
    if (value + 1 <= +maxCount) setValue(value + 1);
  };

  return (
    <Container className={props.className}>
      {text}
      <ButtonsContainer>
        <CounterButton onClick={decrement} type={'button'}>
          -
        </CounterButton>
        <input type={'number'} {...register(name)} defaultValue={value || ''} hidden />
        <TextValue>{value || ''}</TextValue>
        <CounterButton onClick={increment} type={'button'}>
          +
        </CounterButton>
      </ButtonsContainer>
    </Container>
  );
};

export default Counter;
