import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { colors, device } from '../../theme';
import i18next from 'i18next';
import { FormStateProxy } from 'react-hook-form/dist/types/form';
import { FieldError } from 'react-hook-form';
import ErrorMessage from './ErrorMessage';
import { useRefState } from '../../utils/customHooks';

const AddAnswerContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Button = styled.button`
  border: none;
  background-color: transparent;
  font-size: 36px;
  line-height: 20px;
  margin-right: 7px;
  padding: 0;
  color: ${colors.lightBlue};
  cursor: pointer;
`;

const Input: any = styled.input`
  border: 2px solid
    ${(props: any) =>
      props.borderColor
        ? props.borderColor
        : props.disabled
        ? '#0A5057'
        : props.isDirty || props.defaultValue
        ? '#373737'
        : '#CACACA'};
  border-radius: 30px;
  color: ${(props: any) =>
    props.color
      ? props.color
      : props.disabled
      ? colors.darkBlue
      : props.isDirty || props.defaultValue
      ? '#373737'
      : '#95989A'};
  background-color: ${(props: any) => (props.disabled ? '#0A5057' : 'transparent')};
  font-size: 1rem;
  padding: 0.25rem 0.4375rem;
  caret-color: ${(props: any) => (props.caretColor ? props.caretColor : colors.lightBlue)};
  &::placeholder {
    color: #95989a;
  }
  &:hover {
    border-color: ${(props: any) =>
      props.borderColor ? props.borderColor : props.disabled ? '#0A5057' : '#979797'};
  }
  &:focus {
    color: ${(props: any) => (props.color ? props.color : '#646464')};
    border-color: ${(props: any) =>
      props.borderColor ? props.borderColor : props.disabled ? '#0A5057' : colors.lightBlue};
  }

  width: 90%;

  @media ${device.laptop} {
    max-width: 600px;
  }
`;

/**
 * If used the old way: no 'formRef', change 'handleAppend' arguments, add 'onChange'
 */
interface Props {
  style?: any;
  name: string;
  formRef?: any;
  formState: FormStateProxy;
  type?: string;
  placeholder?: string;
  fieldError?: FieldError;
  onChange?: (event: any) => void;
  disabled?: boolean;

  handleAppend(
    event: React.KeyboardEvent<HTMLInputElement> | React.MouseEvent<HTMLButtonElement>,
    value: string | null
  ): void;
}

const AddAnswer: React.FC<Props> = ({
  handleAppend,
  name,
  formRef,
  formState,
  type = 'text',
  placeholder = '',
  fieldError,
  style,
  onChange,
  disabled,
}) => {
  const [value, valueRef, setValue] = useRefState('');

  const textInputRef = useRef(null);

  const _handleAppend = (event: any, value: any) => {
    handleAppend(event, valueRef.current);
    setValue('');
  };

  return (
    <>
      <AddAnswerContainer style={style}>
        <Button type={'button'} onClick={(e) => _handleAppend(e, value)}>
          +
        </Button>
        <Input
          autoComplete="off"
          type={type}
          {...(formRef ? formRef(name) : textInputRef)}
          onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter') {
              event.preventDefault();
              _handleAppend(event, value);
            }
          }}
          value={value}
          onChange={(event: any) => {
            onChange && onChange(event);
            setValue(event.target.value);
          }}
          placeholder={i18next.t(placeholder)}
          isDirty={formState.dirtyFields[name]}
          disabled={disabled}
        />
      </AddAnswerContainer>
      {fieldError && <ErrorMessage fieldError={fieldError} />}
    </>
  );
};

export default AddAnswer;
