export const initFBScripts = () => {
  window.fbAsyncInit = function () {
    window.FB.init({
      appId: process.env.REACT_APP_FACEBOOK,
      autoLogAppEvents: true,
      xfbml: true,
      version: 'v9.0',
    });
  };
};
