import { connect } from 'react-redux';
import SyncApp from './SyncApp';
import {
  getAuthInfo,
  setBlockchainKeys,
  syncTokenWithStore,
} from '../../reducers/Auth/auth.actions';
import { getConstants } from '../../reducers/Constants/constants.actions';

function mapStateToProps(state: any, otherProps: any) {
  return {
    token: state.auth.token,
    user: state.auth.user,
    blockchainKeys: state.auth.blockchainKeys,
    constantsFetchStatus: state.constants.fetchStatus,
  };
}

export default connect(mapStateToProps, {
  syncTokenWithStore,
  getAuthInfo,
  setBlockchainKeys,
  getConstants,
})(SyncApp);
