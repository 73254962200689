import { REQUEST_STATUSES } from '../../constants/requestStatuses.constants';
import { Action } from '../../types/reducerAction.types';
import { UPDATE_CONSTANTS, GET_CONSTANTS_FETCH_STATUS } from './constants.actionTypes';

const INITIAL_STATE = {
  details: {
    countries: null,
    municipality_types_pl: null,
    settings: {
      project_placeholder_1_1: null,
      project_placeholder_16_9: null,
    },
    // raw dropdowns data
    dropdowns: null,
  },
  dropdowns: {
    countries: [],
  },
  fetchStatus: REQUEST_STATUSES.NULL,
};

export default function location(state = INITIAL_STATE, action: Action) {
  switch (action.type) {
    case UPDATE_CONSTANTS: {
      const countriesDropdown = Object.entries(action.payload.countries).map(([value, label]) => ({
        value,
        label,
      }));

      return {
        ...state,
        details: action.payload,
        dropdowns: {
          countries: countriesDropdown,
        },
      };
    }
    case GET_CONSTANTS_FETCH_STATUS:
      return {
        ...state,
        fetchStatus: action.payload,
      };
    default:
      return state;
  }
}
