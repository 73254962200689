const SET_NOTIFICATIONS_FETCH_STATUS = 'SET_NOTIFICATIONS_FETCH_STATUS';
const SET_NOTIFICATIONS_LIST = 'SET_NOTIFICATIONS_LIST';
const REGISTER_FCM_DEVICE_FETCH_STATUS = 'REGISTER_FCM_DEVICE_FETCH_STATUS';
const REGISTER_FCM_DEVICE = 'REGISTER_FCM_DEVICE';
const UPDATE_FCM_INFO = 'UPDATE_FCM_INFO';
const SET_SUMMARY_FETCH_STATUS = 'SET_SUMMARY_FETCH_STATUS';
const SET_SUMMARY = 'SET_SUMMARY';
const SET_ALL_AS_READ_FETCH_STATUS = 'SET_ALL_AS_READ_FETCH_STATUS';

export const actionTypes = {
  SET_NOTIFICATIONS_FETCH_STATUS,
  SET_NOTIFICATIONS_LIST,
  REGISTER_FCM_DEVICE,
  REGISTER_FCM_DEVICE_FETCH_STATUS,
  UPDATE_FCM_INFO,
  SET_SUMMARY_FETCH_STATUS,
  SET_SUMMARY,
  SET_ALL_AS_READ_FETCH_STATUS,
};
