import { Dispatch } from 'redux';
import {
  SET_EMAIL_MODAL_OPEN,
  SET_IS_QR_CODE_MODAL_OPEN,
  SET_PHONE_MODAL_OPEN,
  TOGGLE_MENU,
} from './utils.actionTypes';

export function toggleMenu() {
  return function (dispatch: Dispatch) {
    dispatch({
      type: TOGGLE_MENU,
    });
  };
}

export function setIsQrCodeModalOpen(flag: boolean) {
  return function (dispatch: Dispatch) {
    dispatch({
      type: SET_IS_QR_CODE_MODAL_OPEN,
      payload: flag,
    });
  };
}

export function setPhoneModalOpen({
  open,
  codeModalOpen,
  triggerRequest,
}: {
  open: boolean;
  codeModalOpen: boolean;
  triggerRequest: boolean;
}) {
  return function (dispatch: Dispatch) {
    dispatch({
      type: SET_PHONE_MODAL_OPEN,
      payload: { open, codeModalOpen, triggerRequest },
    });
  };
}

export function setEmailModalOpen({
  open,
  triggerRequest,
}: {
  open: boolean;
  triggerRequest: boolean;
}) {
  return function (dispatch: Dispatch) {
    dispatch({
      type: SET_EMAIL_MODAL_OPEN,
      payload: { open, triggerRequest },
    });
  };
}
