import { UseFormReturn } from 'react-hook-form';
import yup from '../../../../utils/yup/yupExtended';
import getFieldError from '../../../../utils/getFieldError';
import { Inputs } from './ProjectVote';

export const getProjectVoteSchema = (formMethods: UseFormReturn<Inputs> | undefined) =>
  yup.object({
    // any approval move to manual validation
    anonymized_approval: yup.boolean().nullable(),
    nickname_approval: yup.boolean().nullable(),
    name_approval: yup.boolean().nullable(),
    assign_to_group: yup.boolean().nullable(),
    privacy_policy_approval: yup.boolean().nullable(),
    questions: yup.array(
      yup
        .object({
          min_required_answers: yup.number(),
          max_required_answers: yup.number(),
          answers: yup.array(
            yup.object({
              id: yup.number(),
              is_checked: yup.boolean().nullable(),
            })
          ),
        })
        .test(function (this: any, obj: any) {
          const path = this.path.replaceAll('[', '.').replaceAll(']', '.').slice(0, -1);
          const selectedAnswersCount = obj.answers.filter(
            (answer: any) => !!answer.is_checked
          ).length;
          let error: { path: string; type?: string; message?: string } = { path: path };
          if (selectedAnswersCount < obj.min_required_answers) {
            error.type = 'too_few_selected_answers';
            error.message = 'Zaznacz minimalną liczbę wymaganych odpowiedzi';
          }
          if (selectedAnswersCount > obj.max_required_answers) {
            error.type = 'too_many_selected_answers';
            error.message = 'Zaznaczyłeś za dużo odpowiedzi';
          }
          if (error.type && error.message) {
            formMethods?.setError(path, {
              type: error.type,
              message: error.message,
            });
            return new yup.ValidationError(error.message, error.type, error.path);
          }
          if (getFieldError(formMethods?.formState.errors, path)) {
            formMethods?.clearErrors(path);
          }
          return true;
        })
    ),
  });
// .test(function (obj: any) {
//   const error = {
//     path: 'approval',
//     type: 'accept_at_least_one_approval',
//     message: 'Musisz zaznaczyć minimum jedną zgodę',
//   };
//
//   if (!(obj.anonymized_approval || obj.nickname_approval || obj.name_approval)) {
//     formMethods?.setError('approval', {
//       type: error.type,
//       message: error.message,
//     });
//     return new yup.ValidationError(error.message, error.type, error.path);
//   }
//   if (getFieldError(formMethods?.formState.errors, error.path)) {
//     formMethods?.clearErrors('approval');
//   }
//   return true;
// });
