import AuthorizedVotingLanding from './AuthorizedVotingLanding';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { checkIfUserCanVote, registerUser } from '../../reducers/Auth/auth.actions';
import { clearUserRequirementsForSurvey } from '../../reducers/Surveys/surveys.actions';

function mapStateToProps(state: any, otherProps: RouteComponentProps) {
  return {
    accountForSurveyVotingData: state.auth.accountForSurveyVoting.data,
    accountForSurveyVotingFetchStatus: state.auth.accountForSurveyVoting.fetchStatus,
  };
}

export default connect(mapStateToProps, {
  checkIfUserCanVote,
  clearUserRequirementsForSurvey,
})(AuthorizedVotingLanding);
