import {connect} from 'react-redux';
import PrivateRoute from "./PrivateRoute";
import {getAuthInfo, syncTokenWithStore} from "../../reducers/Auth/auth.actions";

function mapStateToProps(state: any) {
  return {
    token: state.auth.token
  };
}

export default connect(
  mapStateToProps, {
    syncTokenWithStore,
    getAuthInfo
  }
)(PrivateRoute);
