import React, { useEffect, useRef, useState } from 'react';
import AppBar from '@material-ui/core/AppBar/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CustomDrawer from '../../containers/CustomDrawer';
import CustomBottomNavigation from './BottomNavigation';
import { history } from '../../utils/history';
import { UserData } from '../../types/auth.types';
import NetworkService from '../../utils/network.service';
import store from '../../store';
import { BaseTemplateContainer, HeaderContainer } from '../../components/Containers/containers';
import { getSessionToken } from '../../utils/localStorage';
import { updateToken } from '../../reducers/Auth/auth.actions';
import { showNotification } from '../../utils/taost.config';
import AppBarInfoSvg from './AppBarInfoSvg';
import Notifications from './Notifications/Notifications';
import { sizes } from '../../theme';
import useEndSurveyToast from '../../hooks/useEndSurveyToast';

interface Props {
  showMenu: boolean;
  user: UserData;
  token: string;
  notificationsCount?: number | null;
  notifications?: any;
  notificationsListData?: any;

  toggleMenu(): void;
  syncTokenWithStore(redirect: boolean): void;
  getAuthInfo(): void;
  setIsQrCodeModalOpen(flag: boolean): void;
  getNotificationsSummary: any;
  getNotifications: any;
  postMarkAllNotificationsAsRead: any;
}

const BaseTemplate: React.FC<Props> = ({
  showMenu,
  children,
  user,
  toggleMenu,
  token,
  syncTokenWithStore,
  getAuthInfo,
  setIsQrCodeModalOpen,
  notificationsCount,
  notificationsListData,
  getNotificationsSummary,
  getNotifications,
  postMarkAllNotificationsAsRead,
}) => {
  const [showDrawer, setShowDrawer] = useState<any>();
  const [showBottomNavigation, setShowBottomNavigation] = useState<any>();
  const emailWarningHasBeenShown = useRef(false);
  const notificationsSummaryTimeoutRef: any = useRef(null);
  useEndSurveyToast({ user, token });

  const getSummaryInTimeout = () => {
    getNotificationsSummary();
    notificationsSummaryTimeoutRef.current = setTimeout(() => {
      getSummaryInTimeout();
    }, 60 * 1000);
  };

  useEffect(() => {
    return () => {
      if (notificationsSummaryTimeoutRef.current)
        clearTimeout(notificationsSummaryTimeoutRef.current);
    };
  }, []);

  useEffect(() => {
    if (!token && !getSessionToken()) {
      syncTokenWithStore(false);
    }
    NetworkService.setupInterceptors(store);
  }, []);

  useEffect(() => {
    if (token || getSessionToken()) {
      updateToken({ token: token || getSessionToken() });
    }

    if (token || getSessionToken()) {
      getAuthInfo();
      getSummaryInTimeout();
    }
  }, [token]);

  useEffect(() => {
    switch (history.location.pathname.split('/')[1]) {
      case 'my-profile':
        setShowDrawer(true);
        setShowBottomNavigation(false);
        break;
      case 'surveys': {
        // if (history.location.pathname.split("/")[2] === 'create' && !!history.location.pathname.split("/")[3]) {
        //   setShowDrawer(true);
        //   setShowBottomNavigation(false);
        // } else {
        setShowDrawer(true);
        setShowBottomNavigation(true);
        // }
        break;
      }
      case 'terms-and-conditions':
        setShowDrawer(true);
        setShowBottomNavigation(false);
        break;
      case 'qr_verification':
        setShowDrawer(true);
        setShowBottomNavigation(false);
        break;
      default:
        setShowDrawer(true);
        setShowBottomNavigation(true);
        break;
    }
  }, [history.location.pathname]);

  useEffect(() => {
    if (user && !user.email && !emailWarningHasBeenShown.current) {
      emailWarningHasBeenShown.current = true;
      showNotification({
        message: 'EmailWarning',
        type: 'warning',
        autoClose: false,
      });
    }
  }, [user]);

  return (
    <BaseTemplateContainer>
      {showDrawer && (token || getSessionToken()) && user && (
        <>
          <CustomDrawer showMenu={showMenu} />
          <AppBar
            position="absolute"
            style={{
              zIndex: 1200,
              background: '#1A1818',
              top: 0,
              left: 0,
            }}
          >
            <Toolbar>
              <HeaderContainer>
                <AppBarInfoSvg />
              </HeaderContainer>

              <Notifications
                count={notificationsCount || 0}
                notificationsListData={notificationsListData}
                getNotificationsSummary={getNotificationsSummary}
                getNotifications={getNotifications}
                postMarkAllNotificationsAsRead={postMarkAllNotificationsAsRead}
              />
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleMenu}
                style={{ marginLeft: '15px' }}
              >
                <MenuIcon style={{ fontSize: sizes.navbarIcons }} />
              </IconButton>
            </Toolbar>
          </AppBar>
        </>
      )}
      {children}
      {showBottomNavigation && token && user && (
        <CustomBottomNavigation user={user} setIsQrCodeModalOpen={setIsQrCodeModalOpen} />
      )}
    </BaseTemplateContainer>
  );
};

export default BaseTemplate;
