import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import GroupsDetail from './GroupsDetail';
import { RouteComponentProps } from 'react-router';
import {
  getGroup,
  postGroup,
  updateGroup,
  resetGroup,
  deleteGroup,
  postGroupWithContacts,
  setUpdateGroupFetchStatus,
} from '../../../reducers/Groups/groups.actions';
import { getContactPersons } from '../../../reducers/ContactPersons/contactPersons.actions';

type OtherProps = RouteComponentProps<{
  uuid: string;
}>;

function mapStateToProps(state: any, otherProps: OtherProps) {
  return {
    group: state.groups.detail,
    contactPersons: state.contactPersons.list,
    uuid: otherProps.match.params.uuid,
    groups: state.groups.list,
    user: state.auth.user,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getGroup,
    updateGroup,
    postGroup,
    postGroupWithContacts,
    getContactPersons,
    resetGroup,
    deleteGroup,
    setUpdateGroupFetchStatus,
  })(GroupsDetail)
);
