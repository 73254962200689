import React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

const StyledRegisterMemberContent = styled.div`
  margin-top: 15px;
  margin-bottom: 8px;
  a {
    color: unset;
  }
`;

const LinkButton: any = (props: any) => {
  const { text = 'Resend', onClick, children } = props;

  return (
    <StyledRegisterMemberContent>
      <a href={''} onClick={onClick}>
        {text && !children ? <Trans>{text}</Trans> : <></>}
        {children && !text ? <Trans>children</Trans> : <></>}
      </a>
    </StyledRegisterMemberContent>
  );
};

export default LinkButton;
