import { connect } from 'react-redux';
import VerifyPhoneCodeModal from './VerifyPhoneCodeModal';
import {
  checkVerifications,
  confirmPhoneSignup,
  loginUser,
  resetPostPhoneCodeFetchStatus,
  sendPhoneSignup,
  setSmsCodeId,
  updateUser,
} from '../../reducers/Auth/auth.actions';
import { setPhoneModalOpen } from '../../reducers/Utils/utils.actions';

function mapStateToProps(state: any, otherProps: any) {
  return {
    ...otherProps,
    loginFetchStatus: state.auth.loginFetchStatus,
    errorTypes: state.auth.errorTypes,
    accountForSurveyVotingData: state.auth.accountForSurveyVoting.data,
    user: state.auth.user,
    verificationsDirectFromBE: state.auth.verificationsDirectFromBE,
    codeFetchStatus: state.auth.verifyPhoneCode.postPhoneCodeFetchStatus,
    smsCodeId: state.auth.smsCodeId,
    userFetchStatus: state.auth.userFetchStatus,
    phoneModalOpen: state.utils.phoneModal,
  };
}

export default connect(mapStateToProps, {
  checkVerifications,
  sendPhoneSignup,
  confirmPhoneSignup,
  updateUser,
  resetPostPhoneCodeFetchStatus,
  setPhoneModalOpen,
  setSmsCodeId,
})(VerifyPhoneCodeModal);
