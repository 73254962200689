import styled from 'styled-components';
import { colors, device } from '../../theme';
import { alignInputParentStyles } from '../../components/Forms/forms';

export const AuthenticateNotification = styled.div`
  background-color: #ffffff;
  font-size: 20px;
  padding: 10px;
  border-radius: 15px;
  color: ${colors.lightBlue};
  text-align: center;
  width: 90%;
  @media ${device.laptop} {
    max-width: 600px;
  }
`;

export const Attention = styled.strong`
  color: ${colors.red};
`;

export const ProfileTypeHeader = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: ${colors.white};
  margin: 15px;
  margin-top: 25px;
  text-align: left;
  ${alignInputParentStyles}
`;

export const ProfileTypeDescription = styled.div`
  margin-top: 20px;
  font-size: 16px;
  color: ${colors.white};
  width: 90%;

  @media ${device.laptop} {
    max-width: 600px;
  }
`;

export const PhotosContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  > div:first-child {
    margin-right: 5%;
  }
  > div:last-child {
    margin-left: 5%;
  }
`;
