import styled from 'styled-components';
import { colors, device } from '../../theme';

export const SurveyListButton: any = styled.button`
  align-items: center;
  ${(props: any) => (props.small ? 'justify-content: center;' : '')}

  position: relative;
  width: 90%;
  min-height: 41px;
  margin-top: 19px;
  ${(props: any) => (props.timer ? `padding-bottom: 14px;` : '')}

  font-size: 24px;
  font-weight: 400;
  color: #fff;

  background-color: ${(props: any) =>
    props.bgColor ? props.bgColor : props.bgDark ? colors.white : colors.darkBlue};
  border-radius: 50px;
  border: 2px solid
    ${(props: any) =>
      props.showInfo
        ? colors.lightGreen
        : props.borderColor
        ? props.borderColor
        : props.bgColor
        ? props.bgColor
        : props.bgDark
        ? colors.white
        : colors.darkBlue};

  &:hover {
    cursor: pointer;
  }
  @media ${device.laptop} {
    max-width: ${(props: any) => (props.small ? '126px' : '610px')};
    border-color: ${(props: any) =>
      props.borderColor
        ? props.borderColor
        : props.bgColor
        ? props.bgColor
        : props.bgDark
        ? colors.white
        : colors.darkBlue};
  }
`;

export const SurveyTypeButton: any = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
  width: 90%;
  min-height: 54px;
  margin-top: 27px;

  font-size: 30px;
  font-weight: bold;
  color: #000;
  text-transform: uppercase;

  background-color: transparent;
  border: 3px solid ${colors.darkBlue};
  border-radius: 50px;

  &:hover {
    cursor: pointer;
  }

  @media ${device.laptop} {
    max-width: 610px;
  }
`;

export const PrimarySmallButton: any = styled.button`
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  min-height: 41px;
  margin-top: 60px;

  //text-transform: uppercase;
  color: ${(props: any) =>
    props.disabled ? colors.grayBorder : props.color ? props.color : '#fff'};
  font-weight: 200;

  background-color: ${(props: any) =>
    props.disabled ? colors.gray : props.bgColor ? props.bgColor : colors.darkBlue};
  border: 3px solid
    ${(props: any) =>
      props.disabled ? colors.gray : props.borderColor ? props.borderColor : colors.darkBlue};
  border-radius: 50px;

  &:hover {
    cursor: ${({ disabled }) => (disabled ? '' : 'pointer')};
  }

  @media ${device.laptop} {
    max-width: ${(props: any) => (props.fullWidth ? '90%' : '200px')};
  }
`;

export const PrimarySmallLinkAsButton: any = styled.a`
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  min-height: 41px;
  margin-top: 60px;

  //text-transform: uppercase;
  color: ${(props: any) =>
    props.disabled ? colors.grayBorder : props.color ? props.color : '#fff'};
  font-weight: 200;

  background-color: ${(props: any) =>
    props.disabled ? colors.gray : props.bgColor ? props.bgColor : colors.darkBlue};
  border: 3px solid
    ${(props: any) =>
      props.disabled ? colors.gray : props.borderColor ? props.borderColor : colors.darkBlue};
  border-radius: 50px;
  text-decoration: none;

  &:hover {
  }

  @media ${device.laptop} {
    max-width: ${(props: any) => (props.fullWidth ? '90%' : '200px')};
  }
`;

export const ContactButton: any = styled.div`
  padding: 0.5rem 0.875rem;
  margin: 0.375rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props: any) => props.color};
  border-radius: 50px;
  border: ${(props: any) => (props.color === 'white' ? '1px solid #000000' : 'none')};
  color: ${(props: any) => (props.color === 'white' ? '#000000' : '#ffffff')};
  width: 100%;

  @media ${device.laptop} {
    max-width: 600px;
  }

  cursor: pointer;
`;

export const CircleButton: any = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 41px;
  height: 41px;
  margin: 20px 0;

  font-size: 24px;
  text-transform: uppercase;
  color: ${(props: any) => (props.color ? props.color : '#fff')};
  font-weight: 200;

  background-color: ${(props: any) => (props.bgColor ? props.bgColor : colors.darkBlue)};
  border: 3px solid ${(props: any) => (props.borderColor ? props.borderColor : colors.darkBlue)};
  border-radius: 100%;

  cursor: pointer;
`;
