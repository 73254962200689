import React, { FC, useEffect, useRef } from 'react';
import SimpleModal from '../../components/Modal/SimpleModal';
import { EmailTitleIcon } from '../../components/Modal/ModalTitleIcons';
import PinInput from '../../components/Forms/PinInput';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { REQUEST_STATUSES } from '../../constants/requestStatuses.constants';

const PinInputContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const formId = 'email-code';

const errorMessage = 'Niepoprawny kod';

const VerifyEmailCodeModal: FC<any> = (props) => {
  const {
    open,
    triggerRequest,
    user,
    verifyEmailByCode,
    codeFetchStatus,
    verificationsDirectFromBE,
    checkVerifications,
    verifyCallback,
    sendEmailConfirmation,
    accountForSurveyVotingData,
  } = props;

  const { register, handleSubmit, setValue, getValues, control } = useForm({
    defaultValues: {},
  });

  const checkEmailVerificationTimeoutRef: any = useRef();

  const isEmailIsVerified = () => {
    return (
      user?.is_email_verified ||
      codeFetchStatus === REQUEST_STATUSES.DONE ||
      verificationsDirectFromBE.isEmailVerified
    );
  };

  const onSubmit = (event: any) => {
    event.preventDefault();
    const values = getValues();
    // @ts-ignore
    verifyEmailByCode(values);
  };

  const checkIfEmailVerifiedInTimeout = () => {
    if (checkEmailVerificationTimeoutRef.current) return;
    checkEmailVerificationTimeoutRef.current = setTimeout(() => {
      if (!checkEmailVerificationTimeoutRef.current) return;
      checkVerifications();
      checkEmailVerificationTimeoutRef.current = null;
      checkIfEmailVerifiedInTimeout();
    }, 5 * 1000);
  };

  const onResendClick = (event: any) => {
    event.preventDefault();
    sendEmailConfirmation({ email: user?.email || accountForSurveyVotingData.email });
  };

  useEffect(() => {
    return () => {
      checkEmailVerificationTimeoutRef.current &&
        clearTimeout(checkEmailVerificationTimeoutRef.current);
    };
  }, []);

  useEffect(() => {
    //  checking if email was verified on other device
    if (triggerRequest) {
      checkIfEmailVerifiedInTimeout();
    } else {
      checkEmailVerificationTimeoutRef.current &&
        clearTimeout(checkEmailVerificationTimeoutRef.current);
    }
  }, [triggerRequest]);

  useEffect(() => {
    if (isEmailIsVerified()) {
      checkEmailVerificationTimeoutRef.current &&
        clearTimeout(checkEmailVerificationTimeoutRef.current);
      verifyCallback();
    }
  }, [verificationsDirectFromBE, codeFetchStatus]);

  return (
    <SimpleModal
      title={'Zweryfikuj email'}
      text={'Wpisz kod weryfikacyjny otrzymany w emailu'}
      open={open}
      iconComponent={EmailTitleIcon}
      mainButtonForm={formId}
      onLinkButtonClick={onResendClick}
    >
      <PinInputContainer>
        <form id={formId} onSubmit={onSubmit}>
          <PinInput
            type="pin"
            name="code"
            control={control}
            defaultValue={null}
            customErrorMessage={codeFetchStatus === REQUEST_STATUSES.ERROR && errorMessage}
          />
        </form>
      </PinInputContainer>
    </SimpleModal>
  );
};

export default VerifyEmailCodeModal;
