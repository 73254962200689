import React from 'react';
import { isSWAvailable, useServiceWorker } from '../utils/useServiceWorker';
import { AlertTitleIcon } from './Modal/ModalTitleIcons';
import SimpleModal from './Modal/SimpleModal';

export const newVersionAvailableModalConfig = {
  iconComponent: AlertTitleIcon,
  title: 'Nowa wersja aplikacji',
  text: `Dostepna jest nowa wersja aplikacji. Naciśnij przycisk "Aktualizuj" aby zaktualizować aplikację do najnowszej wersji.`,
  buttonText: 'Aktualizuj',
  disableClose: true,
};

const SWModal = () => {
  const [isOpen, onUpdateClick] = useServiceWorker();

  return (
    <>
      {isSWAvailable() && (
        <SimpleModal
          open={isOpen}
          handleClose={onUpdateClick}
          {...newVersionAvailableModalConfig}
        />
      )}
    </>
  );
};

export default SWModal;
