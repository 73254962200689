import React, { useEffect, useState } from 'react';
import { history } from '../../utils/history';
import { UserData } from '../../types/auth.types';
import { FlexContainer, Form, Center } from '../../components/Containers/containers';
import { CheckTitleIcon, LockTitleIcon } from '../../components/Modal/ModalTitleIcons';
import SimpleModal, { ModalProps } from '../../components/Modal/SimpleModal';
import { REQUEST_STATUSES } from '../../constants/requestStatuses.constants';
import { useForm } from 'react-hook-form';
import InputText from '../../components/Forms/InputText';
import InputEmail from '../../components/Forms/InputEmail';
import InputPhone from '../../components/Forms/InputPhone';
import { colors } from '../../theme';
import { Trans } from 'react-i18next';
import { PrimarySmallButton } from '../../components/Buttons/buttons';
import { MainHeader } from '../../utils/uiComponents';
import { clerkVerificationVerify, getBlockchainStatement } from '../../reducers/Auth/auth.actions';
import InputReadOnly from '../../components/Forms/InputReadOnly';
import moment from 'moment';
import ProfileDatePicker from '../../components/Forms/ProfileDatePicker';

type DataInputs = {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  government_id: string;
  birth_date: string;
  locality: string;
};

interface Props {
  public_key: string | null;
  uuid: string | null;
  user: UserData;
  keysCheckFetchStatus: string;
  verifyFetchStatus: string;

  clerkVerificationCheckKeys(keys: { key: string; uuid: string }): void;
  clerkVerificationVerify(data: any): void;
}

const QrVerification: React.FC<Props> = ({
  public_key,
  uuid,
  user,
  clerkVerificationCheckKeys,
  keysCheckFetchStatus,
  clerkVerificationVerify,
  verifyFetchStatus,
}) => {
  const [isOpen, setIsOpen] = useState<any>(false);
  const [modalConfig, setModalConfig] = useState<ModalProps>();
  const [userData, setUserData] = useState<DataInputs>();

  const {
    register,
    handleSubmit,
    formState: { errors },
    formState,
    control,
    getValues,
    setValue,
  } = useForm<DataInputs>();

  const checkKeys = () => {
    if (public_key && uuid)
      clerkVerificationCheckKeys({
        key: public_key,
        uuid,
      });
  };

  const downloadBlockchainStatement = () => {
    getBlockchainStatement('clerk', { key: public_key, uuid });
  };

  const modalConfigs = {
    isNotClerk: {
      title: 'Brak autoryzacji',
      text: 'Niestety nie posiadasz uprawnień do zweryfikowania kodu qr.',
      iconComponent: LockTitleIcon,
      onButtonClick: () => history.push('/'),
    },
    invalidKeys: {
      title: 'Niepoprawny kod qr',
      text: 'Niestety kod QR jest niepoprawny lub stracił ważność.',
      iconComponent: LockTitleIcon,
      onButtonClick: () => history.push('/'),
    },
    invalidData: {
      title: 'Niepoprawne dane',
      text: 'Niestety wprowadzone dane są niepoprawne.',
      iconComponent: LockTitleIcon,
      onButtonClick: () => {
        setIsOpen(false);
        checkKeys();
      },
    },
    successVerify: {
      title: 'Uwierzytelniono',
      text: 'Dane zostały uwierzytelnione. Możesz teraz pobrać oświadczenie. Znajdziesz je również w zakładce "Mój profil".',
      iconComponent: CheckTitleIcon,
      onButtonConfirmClick: downloadBlockchainStatement,
      buttonConfirmText: 'Download',
      buttonCancelText: 'Close',
    },
    // noPermissions: {
    //   title: 'Brak uprawnień',
    //   text: 'Niestety nie możesz uwierzytelnić samego siebie.',
    //   iconComponent: LockTitleIcon,
    //   onButtonClick: () => history.push('/'),
    // }
  };

  useEffect(() => {
    if (user && !user.is_clerk) {
      setModalConfig(modalConfigs.isNotClerk);
      setIsOpen(true);
    } else if (!public_key || !uuid) {
      setModalConfig(modalConfigs.invalidKeys);
      setIsOpen(true);
    } else {
      checkKeys();
    }
  }, []);

  useEffect(() => {
    if (keysCheckFetchStatus === REQUEST_STATUSES.ERROR) {
      setModalConfig(modalConfigs.invalidKeys);
      setIsOpen(true);
    }
  }, [keysCheckFetchStatus]);

  useEffect(() => {
    if (verifyFetchStatus === REQUEST_STATUSES.ERROR) {
      setModalConfig(modalConfigs.invalidData);
      setIsOpen(true);
    }
    if (verifyFetchStatus === REQUEST_STATUSES.DONE) {
      setModalConfig(modalConfigs.successVerify);
      setIsOpen(true);
    }
  }, [verifyFetchStatus]);

  const onSubmit = (data: DataInputs) => {
    console.log(data);
    setUserData(data);
    clerkVerificationVerify({ ...data, phone: '+' + data.phone, key: public_key, uuid });
  };

  return (
    <FlexContainer bgDark={true}>
      <SimpleModal {...modalConfig} open={isOpen} setOpen={setIsOpen} />
      <Center>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <MainHeader>
            <Trans>Authenticate</Trans>
          </MainHeader>
          {verifyFetchStatus !== REQUEST_STATUSES.DONE && (
            <>
              <InputText
                label={'FirstName'}
                showRequiredStar
                name={'first_name'}
                formRef={register}
                fieldError={errors.first_name}
                formState={formState}
                validate={{ required: true }}
                borderColor={'#ffffff'}
                color={'#ffffff'}
                caretColor={'#ffffff'}
              />
              <InputText
                label={'LastName'}
                showRequiredStar
                name={'last_name'}
                formRef={register}
                fieldError={errors.last_name}
                formState={formState}
                validate={{ required: true }}
                borderColor={'#ffffff'}
                color={'#ffffff'}
                caretColor={'#ffffff'}
              />
              <InputEmail
                label={'Email'}
                showRequiredStar
                name={'email'}
                formRef={register}
                fieldError={errors.email}
                formState={formState}
                validate={{ required: true }}
                borderColor={'#ffffff'}
                color={'#ffffff'}
                caretColor={'#ffffff'}
              />
              <InputPhone
                control={control}
                label={'Phone'}
                showRequiredStar
                name={'phone'}
                formRef={register}
                fieldError={errors.phone}
                formState={formState}
                validate={{ required: true }}
                borderColor={'#ffffff'}
                color={'#ffffff'}
                caretColor={'#ffffff'}
                setValue={setValue}
              />
              <InputText
                label={'PESEL'}
                showRequiredStar
                name={'government_id'}
                formRef={register}
                fieldError={errors.government_id}
                formState={formState}
                validate={{ required: true }}
                borderColor={'#ffffff'}
                color={'#ffffff'}
                caretColor={'#ffffff'}
              />
              <ProfileDatePicker
                label="BirthDate"
                showRequiredStar
                name="birth_date"
                register={register}
                control={control}
                getValues={getValues}
                borderColor={'#fff'}
                color={'#fff'}
                caretColor={'#fff'}
                formRef={register}
                final={false}
              />
              <InputText
                label={'Locality'}
                showRequiredStar
                name={'locality'}
                formRef={register}
                fieldError={errors.locality}
                formState={formState}
                validate={{ required: true }}
                borderColor={'#ffffff'}
                color={'#ffffff'}
                caretColor={'#ffffff'}
              />
            </>
          )}
          {verifyFetchStatus === REQUEST_STATUSES.DONE && userData && (
            <>
              <InputReadOnly
                formRef={register}
                label={'FirstName'}
                defaultValue={userData.first_name}
                color={'#ffffff'}
              />
              <InputReadOnly
                formRef={register}
                label={'LastName'}
                defaultValue={userData.last_name}
                color={'#ffffff'}
              />
              <InputReadOnly formRef={register} defaultValue={userData.email} color={'#ffffff'} />
              <InputReadOnly
                formRef={register}
                label={'Phone'}
                defaultValue={userData.phone}
                color={'#ffffff'}
              />
              <InputReadOnly
                formRef={register}
                label={'PESEL'}
                defaultValue={userData.government_id}
                color={'#ffffff'}
              />
              <InputReadOnly
                formRef={register}
                label={'BirthDate'}
                defaultValue={userData.birth_date}
                color={'#ffffff'}
              />
              <InputReadOnly
                formRef={register}
                label={'Locality'}
                defaultValue={userData.locality}
                color={'#ffffff'}
              />
            </>
          )}

          {verifyFetchStatus !== REQUEST_STATUSES.DONE && (
            <PrimarySmallButton
              type="submit"
              borderColor={colors.white}
              bgColor={colors.white}
              color={colors.lightBlue}
              disabled={verifyFetchStatus === REQUEST_STATUSES.FETCHING}
            >
              <Trans>Authenticate</Trans>
            </PrimarySmallButton>
          )}
          {verifyFetchStatus === REQUEST_STATUSES.DONE && (
            <PrimarySmallButton
              type={'button'}
              borderColor={colors.white}
              bgColor={colors.white}
              color={colors.lightBlue}
              onClick={downloadBlockchainStatement}
            >
              <Trans>DownloadStatement</Trans>
            </PrimarySmallButton>
          )}
        </Form>
      </Center>
    </FlexContainer>
  );
};

export default QrVerification;
