import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useForm, useWatch } from 'react-hook-form';
import { ConfirmResetPasswordData, ResetPasswordData } from '../../types/auth.types';
import InputPassword from '../../components/Forms/InputPassword';
import { LogoSvg } from '../../utils/uiComponents';
import { Trans } from 'react-i18next';
import { emailPattern } from '../../constants/patterns.constants';
import InputEmail from '../../components/Forms/InputEmail';
import { Center, LoginBgContainer, Form } from '../../components/Containers/containers';
import TitleHeader from '../../components/TitleHeader';
import { colors } from '../../theme';
import { PrimarySmallButton } from '../../components/Buttons/buttons';
import { REQUEST_STATUSES } from '../../constants/requestStatuses.constants';
import { Redirect } from 'react-router';
import { getResetPasswordSuccessPath } from '../../constants/paths.constants';
import InputPhone from '../../components/Forms/InputPhone';
import Checkbox from '../../components/Forms/Checkbox';
import { Description } from '../../components/SurveyTypeHeader';

const InputsContainer: any = styled.div`
  width: 100%;
  margin-bottom: 30px;

  label {
    margin-top: 15px;
  }
`;

const OrContainer = styled.div`
  font-size: 15px;
  margin-top: 20px;
  text-align: center;
  color: ${colors.gray};
`;

interface Props {
  uid: string;
  token: string;
  fetchStatus: string;
  location: any;
  resetPasswordErrorMessages: any;
  changePasswordFetchStatus: string | undefined | null;

  resetPassword(data: ResetPasswordData, config?: object): void;

  resetPasswordByPhone(data: ResetPasswordData, config?: object): void;

  confirmResetPassword(data: ConfirmResetPasswordData): void;
}

type Inputs = {
  resetOption: string;
  email: string;
  new_password1: string;
  new_password2: string;
  phone?: string;
};

const Reset: React.FC<Props> = ({
  resetPassword,
  resetPasswordByPhone,
  confirmResetPassword,
  uid,
  token,
  fetchStatus,
  location,
  changePasswordFetchStatus,
  resetPasswordErrorMessages,
}) => {
  const [shouldRedirect, setShouldRedirect] = useState<any>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    formState,
    setError,
    clearErrors,
    control,
    setValue,
  } = useForm<Inputs>({
    defaultValues: {
      resetOption: 'email',
    },
  });

  const new_password1 = useWatch({
    name: 'new_password1',
    defaultValue: '',
    control,
  });
  const new_password2 = useWatch({
    name: 'new_password2',
    defaultValue: '',
    control,
  });
  const watchResetOption = useWatch({
    name: 'resetOption',
    defaultValue: 'email',
    control,
  });

  const onSubmit = (data: Inputs) => {
    if (uid && token) {
      let isValid = true;
      if (data.new_password1 !== data.new_password2) {
        isValid = false;
        setError('new_password2', { type: 'differentPasswords' });
      }
      if (isValid) {
        confirmResetPassword({
          ...data,
          uid: uid,
          token: token,
        });
      }
    } else if (data.email) {
      resetPassword(data, { noToast: true });
      setShouldRedirect(true);
    } else if (data.phone) {
      data.phone = `+${data.phone}`;
      resetPasswordByPhone(data, { noToast: true });
      setShouldRedirect(true);
    }
  };

  const wasResetByEmail = () => {
    const searchParams = new URLSearchParams(location.search);
    return !searchParams.get('by_email');
  };

  useEffect(() => {
    clearErrors('new_password2');
  }, [new_password1, new_password2]);

  return (
    <LoginBgContainer>
      <Center>
        {fetchStatus === REQUEST_STATUSES.DONE && shouldRedirect ? (
          <Redirect to={getResetPasswordSuccessPath()} />
        ) : (
          <></>
        )}
        <LogoSvg src={'/images/ivoting-logo.svg'} />
        <TitleHeader size="medium">
          <Trans>ResetPassword</Trans>
        </TitleHeader>
        {!uid && !token && (
          <Description>
            <Trans>ChooseOption</Trans>
          </Description>
        )}

        <Form onSubmit={handleSubmit(onSubmit)}>
          {!uid && !token && (
            <>
              <div style={{ width: '60%', marginTop: '2rem' }}>
                <Checkbox
                  label={'Email'}
                  type={'radio'}
                  name={`resetOption`}
                  formRef={register}
                  formState={formState}
                  value={'email'}
                />
                <Checkbox
                  label={'Phone'}
                  type={'radio'}
                  name={`resetOption`}
                  formRef={register}
                  formState={formState}
                  value={'phone'}
                />
              </div>

              {watchResetOption && watchResetOption === 'email' && (
                <InputEmail
                  formRef={register}
                  fieldError={errors.email}
                  validate={{
                    pattern: emailPattern,
                  }}
                  formState={formState}
                />
              )}

              {watchResetOption && watchResetOption === 'phone' && (
                <InputPhone
                  control={control}
                  label={'Phone'}
                  name={'phone'}
                  formRef={register}
                  fieldError={errors.phone}
                  formState={formState}
                  setValue={setValue}
                />
              )}
            </>
          )}
          {uid && token && (
            <>
              {wasResetByEmail() && (
                <InputEmail
                  formRef={register}
                  fieldError={errors.email}
                  customErrorMessage={
                    changePasswordFetchStatus === REQUEST_STATUSES.ERROR &&
                    resetPasswordErrorMessages?.email
                  }
                  validate={{
                    pattern: emailPattern,
                    required: true,
                  }}
                  formState={formState}
                />
              )}
              <InputPassword
                label={'Password'}
                name={'new_password1'}
                formRef={register}
                fieldError={errors.new_password1}
                customErrorMessage={
                  changePasswordFetchStatus === REQUEST_STATUSES.ERROR &&
                  resetPasswordErrorMessages?.new_password1
                }
                validate={{ required: true }}
                formState={formState}
              />
              <InputPassword
                label={'RepeatPassword'}
                name={'new_password2'}
                formRef={register}
                fieldError={errors.new_password2}
                customErrorMessage={
                  changePasswordFetchStatus === REQUEST_STATUSES.ERROR &&
                  resetPasswordErrorMessages?.new_password2
                }
                validate={{ required: true }}
                formState={formState}
              />
            </>
          )}
          <PrimarySmallButton type="submit">
            <Trans>Further</Trans>
          </PrimarySmallButton>
        </Form>
      </Center>
    </LoginBgContainer>
  );
};

export default Reset;
