import React, { useEffect } from 'react';
import store from '../../store';
import NetworkService from '../../utils/network.service';
import { UserData } from '../../types/auth.types';
import { history, MyLocationState } from '../../utils/history';
import { getBlockchainKeysForUser, getVoterId } from '../../utils/localStorage';
import { REQUEST_STATUSES } from '../../constants/requestStatuses.constants';

interface Props {
  token: string | null;
  voterId: string | null;
  user: UserData;
  syncTokenWithStore(redirect: boolean): void;
  getAuthInfo(state?: MyLocationState | undefined): void;
  redirect?: boolean;
  blockchainKeys?: any;
  setBlockchainKeys: any;
  getConstants: any;
  constantsFetchStatus: any;
}

const SyncApp: React.FC<Props> = ({
  children,
  token,
  voterId,
  user,
  syncTokenWithStore,
  getAuthInfo,
  redirect = true,
  blockchainKeys,
  setBlockchainKeys,
  getConstants,
  constantsFetchStatus,
}) => {
  useEffect(() => {
    if (!token) {
      syncTokenWithStore(redirect);
    }
    NetworkService.setupDefaultHeaders({ voterId: getVoterId() });
    NetworkService.setupInterceptors(store);
  }, []);

  useEffect(() => {
    if (token) {
      getAuthInfo();
      getConstants();
    }
  }, [token]);

  useEffect(() => {
    if (user?.pk) {
      let storageBlockchainKeys = getBlockchainKeysForUser(user.pk);
      if (storageBlockchainKeys && storageBlockchainKeys.public_key && !blockchainKeys.public_key) {
        setBlockchainKeys(storageBlockchainKeys);
      }
    }
  }, [user]);

  const areConstantsFetched = constantsFetchStatus === REQUEST_STATUSES.DONE;

  return <>{token && user && areConstantsFetched ? children : <div></div>}</>;
};

export default SyncApp;
