export const getLoginPath = () => '/login';
export const getCyberIDLoginPath = () => '/login-cyber-id';
export const getRegisterPath = () => '/register';
export const getResetPasswordPath = () => '/reset-password';
export const getResetPasswordSuccessPath = () => '/reset-password-success';
export const getTermsAndConditionsPath = () => '/terms-and-conditions';
export const getInfoPath = () => '/info';
export const getVerifyEmailPath = () => '/verify-email';
export const getMyProfilePath = () => '/my-profile';
export const getMyProfileAuthenticatePath = () => '/my-profile/authenticate';
export const getSurveysPath = () => '/surveys';
export const getSurveyPath = () => '/survey';
export const getSurveyThanksPath = ({ uuid }: { uuid: null | string | number } = { uuid: null }) =>
  `${getSurveyPath()}/${uuid ? uuid : ':uuid'}/thanks`;
export const getCreateSurveyPath = () => '/surveys/create';
export const getCreateSimpleSurveyPath = () => '/surveys/create/simple';
export const getCreateExtendedSurveyPath = () => '/surveys/create/extended';
export const getCreateImageSurveyPath = () => '/surveys/create/image';
export const getGroupsPath = () => '/groups';
export const getContactPersonsPath = () => '/contact-persons';
export const invitePath = () => '/invite';
export const qrVerificationPath = () => '/qr_verification';
export const getAuthorizedVotingLandingPath = () => '/authorized-voting';
export const getAuthorizedVotingLoginPath = () => '/authorized-voting-login';
export const getAuthorizedVotingRegisterPath = () => '/authorized-voting-register';
export const getCreateProjectPath = ({ step }: { step: number }) => `/create/project/step-${step}`;

// created because of initialization routes issues
export const pathsExcludedFromInterceptor = [
  `${getSurveyPath()}/:uuid/vote`,
  getAuthorizedVotingLandingPath(),
  getAuthorizedVotingLoginPath(),
  getAuthorizedVotingRegisterPath(),
];
