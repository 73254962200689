import React, { useEffect, useRef, useState } from 'react';
import { FieldError, useWatch } from 'react-hook-form';
import { FieldName } from 'react-hook-form/dist/types/fields';
import { FormStateProxy, SetFieldValue, SetValueConfig } from 'react-hook-form/dist/types/form';
import InputText from '../InputText';
import styled from 'styled-components';
import useOnClickOutside from '../../../utils/useOnClickOutside';
import EndAdornment from './EndAdornment';
import { checkIfCurrencyExists, getSuggestions, SuggestionTypeWithCurrency } from './helpers';
import SuggestionsContainer from './SuggestionsContainer';

const Select = styled.div`
  width: 100%;
  position: relative;
`;

interface Props {
  formRef: any;
  formState: any;
  label?: string;
  name: string;
  control: any;
  type?: string;
  placeholder?: string;
  fieldError?: FieldError | undefined;
  defaultValue?: string | boolean | number | null | undefined;
  formType?: string;
  showRequiredStar?: boolean;

  setValue(name: FieldName<any>, value: SetFieldValue<string>, config?: SetValueConfig): void;
}

const CurrencyFlagSelect: React.FC<Props> = (props) => {
  const {
    formRef,
    formState,
    label,
    name,
    placeholder,
    fieldError,
    formType,
    showRequiredStar,
    setValue,
    control,
  } = props;

  const value = useWatch({
    control,
    name,
    defaultValue: 'PLN',
  });
  const [suggestions, setSuggestions] = useState<Array<SuggestionTypeWithCurrency>>([]); // select dropdown data
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false); // show select dropdown
  const [isSelected, setIsSelected] = useState<boolean>(false); // if currency is selected disable input

  const toggleSuggestions = () => setShowSuggestions((prev: any) => !prev);
  const closeSuggestions = () => setShowSuggestions(false);

  // close select dropdown on click outside and reset value is not selected
  const dropdownRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(
    dropdownRef,
    () => {
      if (!isSelected) resetValue();
      closeSuggestions();
    },
    name
  );

  // get suggestions when value changes
  useEffect(() => {
    setSuggestions(getSuggestions(value));
    if (value) {
      setIsSelected(checkIfCurrencyExists(value));
    }
  }, [value]);

  // set value when suggestion click and make input disabled
  const onSuggestionClick = (value: string) => {
    setValue(name, value || '');
    setIsSelected(true);
    closeSuggestions();
  };

  // reset value and make input not disabled
  const resetValue = () => {
    setValue(name, '');
    setIsSelected(false);
  };

  return (
    <Select>
      <InputText
        label={label}
        name={name}
        formRef={formRef}
        type={'text'}
        fieldError={fieldError}
        formState={formState}
        formType={formType}
        showRequiredStar={showRequiredStar}
        placeholder={placeholder}
        onInputClick={toggleSuggestions}
        disabledFormInput={isSelected}
        EndAdornment={isSelected ? <EndAdornment onClick={resetValue} /> : undefined}
        autocomplete={false}
      />
      {showSuggestions && (
        <SuggestionsContainer
          suggestions={suggestions}
          dropdownRef={dropdownRef}
          onSuggestionClick={onSuggestionClick}
        />
      )}
    </Select>
  );
};

export default CurrencyFlagSelect;
