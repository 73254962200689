import { connect } from 'react-redux';
import Reset from './Reset';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import {
  confirmResetPassword,
  resetPassword,
  resetPasswordByPhone,
} from '../../reducers/Auth/auth.actions';

type OtherProps = RouteComponentProps<{
  uid: string;
  token: string;
  fetchStatus: string;
}>;

function mapStateToProps(state: any, otherProps: OtherProps) {
  return {
    uid: otherProps.match.params.uid,
    token: otherProps.match.params.token,
    fetchStatus: state.auth.resetPasswordFetchStatus,
    changePasswordFetchStatus: state.auth.changePasswordFetchStatus,
    resetPasswordErrorMessages: state.auth.resetPasswordFetchErrorMessages
  };
}

export default withRouter(
  connect(mapStateToProps, { resetPassword, resetPasswordByPhone, confirmResetPassword })(Reset)
);
