import React, { Ref } from 'react';
import styled from 'styled-components';
import { colors } from '../../../theme';
import { SuggestionTypeWithCurrency } from './helpers';
import 'flag-icon-css/css/flag-icons.css';

const Container = styled.div`
  margin-top: 0.5rem;
  position: absolute;
  left: 5%;
  right: 5%;
  max-height: 8rem;
  overflow-y: scroll;
  background-color: ${colors.white};
  z-index: 300;
  border: 3px solid #000;
  border-radius: 20px;
`;

const Suggestion = styled.div`
  cursor: pointer;
  padding: 0.25rem 0.5rem;

  &:hover {
    background-color: ${colors.gray};
  }
`;

const NoData = styled.div`
  padding: 0.25rem 0.5rem;
`;

interface Props {
  dropdownRef: Ref<HTMLDivElement>;
  onSuggestionClick(currencyCode: string): void;
  suggestions: Array<SuggestionTypeWithCurrency>;
}

const SuggestionsContainer: React.FC<Props> = (props) => {
  const { dropdownRef, onSuggestionClick, suggestions } = props;

  return (
    <Container ref={dropdownRef}>
      {suggestions.map(({ name, currencyCode, alpha2: countryCode }) => (
        <Suggestion key={`${name}-${currencyCode}`} onClick={() => onSuggestionClick(currencyCode)}>
          <span className={`flag-icon flag-icon-${countryCode.toLowerCase()}`} />
          &nbsp;
          <span className="country-name">
            {name} ({currencyCode})
          </span>
        </Suggestion>
      ))}
      {suggestions.length === 0 && <NoData>Nie znaleziono</NoData>}
    </Container>
  );
};

export default SuggestionsContainer;
