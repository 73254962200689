import React, { FC } from 'react';
import styled from 'styled-components';
import { colors } from '../../theme';
import AndroidShareIcon from '@material-ui/icons/Share';
import { isNativeIphone } from '../../utils/mobileHelper';

const LinkButton = styled.button`
  background-color: ${colors.gray};
  width: 54px;
  height: 54px;
  border: 0px solid ${colors.gray};
  border-radius: 50%;
  margin: 5px;
  padding: 0;
  padding-top: 3px;
  padding-right: 3px;

  &:hover {
    cursor: pointer;
  }
`;

interface SurveyShareNativeProps {
  url: string;
}

const SurveyShareNative: FC<SurveyShareNativeProps> = (props) => {
  const { url } = props;

  const handleShareClick = () => {
    // @ts-ignore
    window.bridge?.nativeShare(url);
  };

  return (
    <LinkButton type={'button'} onClick={handleShareClick}>
      <AndroidShareIcon />
    </LinkButton>
  );
};

export default SurveyShareNative;
