import {Dispatch} from "redux";
import {UPDATE_CONTACT_PERSONS_DETAIL, UPDATE_CONTACT_PERSONS_LIST} from "./contactPersons.actionTypes";
import axios from "axios";
import {showNotification} from "../../utils/taost.config";
import {PostUpdateContactPersonType} from "../../types/contactPersons.types";
import {history} from "../../utils/history";
import {getContactPersonsPath} from "../../constants/paths.constants";
import {getGroups} from "../Groups/groups.actions";

export const getContactPersons = () => {
  let url = process.env.REACT_APP_API_URL + `/user/contacts/`;
  return function (dispatch: Dispatch) {
    axios
      .get(url)
      .then((response) => {
        dispatch(setContactPersons(response.data))
      })
      .catch(error => {
        showNotification({
          response: error.response,
          type: "error"
        })
      });
  };
}

export const getContactPerson = (data: { id: number }) => {
  let url = process.env.REACT_APP_API_URL + `/user/contacts/${data.id}/`;
  return function (dispatch: Dispatch) {
    axios
      .get(url)
      .then((response) => {
        dispatch(setContactPerson(response.data))
      })
      .catch(error => {
        showNotification({
          response: error.response,
          type: "error"
        })
      });
  };
}

export const postContactPerson = (data: PostUpdateContactPersonType, redirect?: { path: string, add?: string }) => {
  let url = process.env.REACT_APP_API_URL + `/user/contacts/`;
  return function (dispatch: Dispatch) {
    axios
      .post(url, data)
      .then((response) => {
        dispatch(setContactPerson(response.data))
        if (redirect)
          history.push(`${redirect.path}${redirect.add ? `/${response.data[redirect.add]}` : ""}`)
        showNotification({
          message: "Dodano kontakt",
          type: "success"
        })
      })
      .catch(error => {
        showNotification({
          response: error.response,
          type: "error"
        })
      });
  };
}

export const updateContactPerson = (id: number, data: PostUpdateContactPersonType, redirect?: string, successMessage?: string) => {
  let url = process.env.REACT_APP_API_URL + `/user/contacts/${id}/`;
  return function (dispatch: Dispatch) {
    axios
      .patch(url, data)
      .then((response) => {
        dispatch(setContactPerson(response.data));
        //@ts-ignore
        dispatch(getGroups());
        showNotification({
          message: successMessage ? successMessage : "Edytowano kontakt",
          type: "success"
        })
        if (!!redirect) history.push(redirect);
      })
      .catch(error => {
        showNotification({
          response: error.response,
          type: "error"
        })
      });
  };
}

export const deleteContactPerson = (id: number, redirect: boolean = true) => {
  let url = process.env.REACT_APP_API_URL + `/user/contacts/${id}/`;
  return function (dispatch: Dispatch) {
    axios
      .delete(url)
      .then((response) => {
        // @ts-ignore
        dispatch(getContactPersons())
        showNotification({
          message: "Usunięto kontakt",
          type: "success"
        })
        if (redirect) history.push(getContactPersonsPath());
      })
      .catch(error => {
        showNotification({
          response: error.response,
          type: "error"
        })
      });
  };
}

export const resetContactPerson = () => {
  return function (dispatch: Dispatch) {
    dispatch(setContactPerson({}))
  }
}

export const setContactPersons = (data: any) => {
  return {
    type: UPDATE_CONTACT_PERSONS_LIST,
    payload: data
  }
}

export const setContactPerson = (data: any) => {
  return {
    type: UPDATE_CONTACT_PERSONS_DETAIL,
    payload: data
  }
}
