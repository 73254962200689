import React, { FC, useEffect } from 'react';
// @ts-ignore
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import styled from 'styled-components';
import { colors } from '../../theme';
import { deviceTypes, getDeviceType } from '../../utils/deviceType';

const LinkButton = styled.button`
  background-color: ${colors.gray};
  width: 54px;
  height: 54px;
  border: 0px solid ${colors.gray};
  border-radius: 50%;
  margin: 5px;
  padding: 0;

  &:hover {
    cursor: pointer;
  }
`;

const iconStyle = {
  borderRadius: '50%',
  padding: '5px',
};

interface SurveyShareIconsProps {
  link: string;
  copyLinkToClipboard: any;
}

const SurveyShareIcons: FC<SurveyShareIconsProps> = (props) => {
  const { link, copyLinkToClipboard } = props;

  const messengerOnClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const deviceType = getDeviceType();
    if (deviceType !== deviceTypes.DESKTOP) {
      window.open(
        'fb-messenger://share?link=' +
          encodeURIComponent(link) +
          '&app_id=' +
          encodeURIComponent(process.env.REACT_APP_FACEBOOK as string)
      );
    } else {
      window.open(
        'https://www.facebook.com/dialog/send?link=' +
          encodeURIComponent(link) +
          '&app_id=' +
          encodeURIComponent(process.env.REACT_APP_FACEBOOK as string) +
          '&redirect_uri=' +
          encodeURIComponent(link)
      );
    }
  };

  return (
    <>
      <CopyToClipboard text={link} onCopy={copyLinkToClipboard}>
        <LinkButton type={'button'}>C</LinkButton>
      </CopyToClipboard>
      <FacebookShareButton type={'button'} url={link}>
        <FacebookIcon style={iconStyle} />
      </FacebookShareButton>
      <span onClick={messengerOnClick} style={{ cursor: 'pointer' }}>
        <FacebookMessengerIcon style={iconStyle} />
      </span>
      <WhatsappShareButton type={'button'} url={link}>
        <WhatsappIcon style={iconStyle} />
      </WhatsappShareButton>

      <TwitterShareButton type={'button'} url={link}>
        <TwitterIcon style={iconStyle} />
      </TwitterShareButton>
      <EmailShareButton type={'button'} url={link}>
        <EmailIcon style={iconStyle} />
      </EmailShareButton>
    </>
  );
};

export default SurveyShareIcons;
