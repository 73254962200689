import { Action } from '../../types/reducerAction.types';
import {
  SET_CHANGE_PASSWORD_FETCH_ERROR,
  SET_CHANGE_PASSWORD_FETCH_STATUS,
  SET_IS_LOGGED,
  SET_LOGIN_FETCH_STATUS,
  SET_REGISTER_FETCH_STATUS,
  SET_RESET_PASSWORD_FETCH_STATUS,
  SET_SMS_CODE_ID,
  SET_USER_DATA,
  SET_USER_FETCH_STATUS,
  SET_VERIFY_EMAIL_FETCH_STATUS,
  SET_POST_PHONE_CODE_FETCH_STATUS,
  TOKEN_UPDATE,
  UNAUTH_USER,
  SET_BLOCKCHAIN_FETCH_STATUS,
  SET_BLOCKCHAIN_KEYS,
  SET_BLOCKCHAIN_QR_CODE,
  SET_CLERK_VERIFICATION_KEYS_CHECK_FETCH_STATUS,
  SET_CLERK_VERIFICATION_VERIFY_FETCH_STATUS,
  SET_UPDATE_USER_FETCH_STATUS,
  SET_USER_NICKNAME_FETCH_STATUS,
  SET_ERROR_TYPES,
  SET_DELETE_MY_ACCOUNT_FETCH_STATUS,
  CHECK_IF_USER_CAN_VOTE,
  CHECK_IF_USER_CAN_VOTE_FETCH_STATUS,
  VERIFY_EMAIL_BY_CODE_FETCH_STATUS,
  SET_UPDATE_USER_FETCH_ERROR,
  RESET_USER_PATCH_FETCH_STATUS,
  SET_LOGOUT_FETCH_STATUS,
} from './auth.actionTypes';
import { REQUEST_STATUSES } from '../../constants/requestStatuses.constants';

const INITIAL_STATE = {
  registerFetchStatus: null,
  loginFetchStatus: null,
  logoutFetchStatus: null,
  resetPasswordFetchStatus: null,
  resetPasswordFetchErrorMessages: null,
  changePasswordFetchStatus: null,
  verifyEmailFetchStatus: null,
  verifyPasswordFetchStatus: null,
  // phoneVerificationFetchStatus: null,
  blockchainFetchStatus: null,
  userFetchStatus: null,
  clerkKeysCheckFetchStatus: null,
  clerkVerifyFetchStatus: null,
  updateUserFetchStatus: null,
  updateUserFetchError: null,
  deleteMyAccountFetchStatus: null,
  token: null,
  user: null,
  blockchainKeys: {
    private_key: null,
    public_key: null,
  },
  smsCodeId: null,
  setUserNicknameFetchStatus: null,
  errorTypes: null,
  accountForSurveyVoting: {
    data: {
      vid: null,
      survey_uuid: null,
      email: null,
      phone: null,
      is_registered: null,
      require_to_vote_email: null,
      require_to_vote_phone: null,
    },
    fetchStatus: REQUEST_STATUSES.NULL,
  },
  verifyEmailCode: {
    fetchStatus: REQUEST_STATUSES.NULL,
  },
  verifyPhoneCode: {
    fetchStatus: REQUEST_STATUSES.NULL,
    postPhoneNrFetchStatus: REQUEST_STATUSES.NULL,
    postPhoneCodeFetchStatus: REQUEST_STATUSES.NULL,
  },
  verificationsDirectFromBE: {
    isEmailVerified: null,
    isPhoneVerified: null,
    fetchStatus: REQUEST_STATUSES.NULL,
  },
};

export default function auth(state = INITIAL_STATE, action: Action) {
  switch (action.type) {
    case SET_REGISTER_FETCH_STATUS:
      return {
        ...state,
        registerFetchStatus: action.payload,
      };
    case SET_LOGIN_FETCH_STATUS:
      return {
        ...state,
        loginFetchStatus: action.payload,
      };
    case SET_LOGOUT_FETCH_STATUS:
      return {
        ...state,
        logoutFetchStatus: action.payload,
      };
    case SET_RESET_PASSWORD_FETCH_STATUS:
      return {
        ...state,
        resetPasswordFetchStatus: action.payload,
        resetPasswordFetchErrorMessages: null,
      };
    case SET_CHANGE_PASSWORD_FETCH_STATUS:
      return {
        ...state,
        changePasswordFetchStatus: action.payload,
        resetPasswordFetchErrorMessages: null,
      };
    case SET_VERIFY_EMAIL_FETCH_STATUS:
      return { ...state, verifyEmailFetchStatus: action.payload };
    case SET_CHANGE_PASSWORD_FETCH_ERROR:
      return {
        ...state,
        changePasswordFetchStatus: REQUEST_STATUSES.ERROR,
        resetPasswordFetchErrorMessages: action.payload,
      };
    case SET_USER_FETCH_STATUS:
      return {
        ...state,
        userFetchStatus: action.payload,
      };
    case RESET_USER_PATCH_FETCH_STATUS:
      return {
        ...state,
        updateUserFetchError: null,
        updateUserFetchStatus: null,
      };
    case SET_USER_DATA:
      return {
        ...state,
        user: action.payload,
      };
    case UNAUTH_USER:
      return {
        ...INITIAL_STATE,
      };
    case TOKEN_UPDATE:
      return {
        ...state,
        token: action.payload,
      };
    case SET_SMS_CODE_ID:
      return {
        ...state,
        smsCodeId: action.payload,
      };
    case SET_POST_PHONE_CODE_FETCH_STATUS:
      return {
        ...state,
        verifyPhoneCode: { ...state.verifyPhoneCode, postPhoneCodeFetchStatus: action.payload },
      };
    case SET_BLOCKCHAIN_FETCH_STATUS:
      return { ...state, blockchainFetchStatus: action.payload };
    case SET_UPDATE_USER_FETCH_STATUS:
      return { ...state, updateUserFetchStatus: action.payload };
    case SET_UPDATE_USER_FETCH_ERROR:
      return { ...state, updateUserFetchError: action.payload?.data };
    case SET_BLOCKCHAIN_KEYS: {
      return { ...state, blockchainKeys: action.payload };
    }
    case SET_CLERK_VERIFICATION_KEYS_CHECK_FETCH_STATUS: {
      return {
        ...state,
        clerkKeysCheckFetchStatus: action.payload,
      };
    }
    case SET_CLERK_VERIFICATION_VERIFY_FETCH_STATUS: {
      return {
        ...state,
        clerkVerifyFetchStatus: action.payload,
      };
    }
    case SET_USER_NICKNAME_FETCH_STATUS: {
      return {
        ...state,
        setUserNicknameFetchStatus: action.payload,
      };
    }
    case SET_ERROR_TYPES: {
      return {
        ...state,
        errorTypes: action.payload,
      };
    }
    case SET_DELETE_MY_ACCOUNT_FETCH_STATUS: {
      return {
        ...state,
        deleteMyAccountFetchStatus: action.payload,
      };
    }
    case CHECK_IF_USER_CAN_VOTE: {
      return {
        ...state,
        accountForSurveyVoting: { ...state.accountForSurveyVoting, data: action.payload },
      };
    }
    case CHECK_IF_USER_CAN_VOTE_FETCH_STATUS: {
      return {
        ...state,
        accountForSurveyVoting: { ...state.accountForSurveyVoting, fetchStatus: action.payload },
      };
    }
    case VERIFY_EMAIL_BY_CODE_FETCH_STATUS: {
      return {
        ...state,
        verifyEmailCode: { ...state.verifyEmailCode, fetchStatus: action.payload },
      };
    }
    default:
      return state;
  }
}
