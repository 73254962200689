import {
  RESET_NEW_SURVEY_DATA,
  RESET_REQ_FOR_SURVEY,
  RESET_SURVEY_DETAIL,
  RESET_SURVEYS_LIST,
  SET_NEW_CONFIRMED_SURVEY,
  SET_VOTE_FETCH_ERROR,
  SET_VOTE_FETCH_STATUS,
  SET_VOTE_FREE_ANSWERS_FETCH_ERROR,
  SET_VOTE_FREE_ANSWERS_FETCH_STATUS,
  UPDATE_NEW_SURVEY_DATA,
  UPDATE_REQ_FOR_SURVEY,
  UPDATE_REQ_FOR_SURVEY_FETCH_STATUS,
  UPDATE_SURVEY_DETAIL,
  UPDATE_SURVEY_DETAIL_FETCH_ERROR,
  UPDATE_SURVEY_DETAIL_FETCH_STATUS,
  UPDATE_SURVEYS_LIST,
  SET_POST_NEW_SURVEY_DATA,
  SET_POST_NEW_SURVEY_FETCH_STATUS,
  SET_POST_NEW_SURVEY_FETCH_ERROR,
  RESET_NEW_PROJECT_DATA,
  RESET_VOTE_STATUS,
  CLEAR_REQ_FOR_SURVEY,
  RESET_VOTE_STATE,
  UPDATE_SURVEY_VOTING_DETAIL_FETCH_STATUS,
  UPDATE_SURVEY_VOTING_DETAIL,
  UPDATE_SURVEY_VOTING_DETAIL_FETCH_ERROR,
  UPDATE_SURVEY_RESULT_FETCH_ERROR,
} from './surveys.actionTypes';
import { Dispatch, Store } from 'redux';
import axios from 'axios';
import { showNotification } from '../../utils/taost.config';
import { getLoginPath, getSurveysPath } from '../../constants/paths.constants';
import { REQUEST_STATUSES } from '../../constants/requestStatuses.constants';
import { inviteGroups } from '../Groups/groups.actions';

export const getSurveysList = (reqParams: string = '') => {
  let url = process.env.REACT_APP_API_URL + `/surveys/` + reqParams;
  return function (dispatch: Dispatch) {
    axios
      .get(url)
      .then((response) => {
        dispatch(setUpdatedSurveysList(response.data));
      })
      .catch((error) => {
        showNotification({
          response: error.response,
          type: 'error',
        });
      });
  };
};

export const getSurveysListForEndToast = (reqParams: string = '') => {
  let url = process.env.REACT_APP_API_URL + `/surveys/finished/` + reqParams;
  return axios
    .get(url)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        showNotification({
          message: 'Sesja wygasła. Zaloguj się ponownie.',
          type: 'error',
        });
      } else {
        showNotification({
          response: error.response,
          type: 'error',
        });
      }
    });
};

// todo: change it to redux action
export const createNewSurvey = (data: any): Promise<any> => {
  let url = process.env.REACT_APP_API_URL + `/surveys/`;
  const dataToPost = {
    ...data,
    survey_type: data.survey_type === 'image' ? 'extended' : data.survey_type,
  };
  return axios
    .post(url, dataToPost)
    .then((response) => {
      showNotification({
        message:
          data.survey_type === 'extended' ? 'Utworzono nową ankietę' : 'Utworzono nowy sondaż',
        type: 'success',
      });
      return response;
    })
    .catch((error) => {
      showNotification({
        response: error.response,
        type: 'error',
      });
    });
};

export const postNewSurvey = (data: any) => {
  let url = process.env.REACT_APP_API_URL + `/surveys/`;
  return function (dispatch: any) {
    dispatch(setSurveyDetailFetchStatus(REQUEST_STATUSES.NULL));
    axios
      .post(url, data)
      .then((response) => {
        dispatch(setPostNewSurveyData(response.data));
        dispatch(setPostNewSurveyFetchStatus(REQUEST_STATUSES.DONE));
        dispatch(inviteGroups({ survey: response.data.id, groups: data.invited_groups }));
        showNotification({
          message: 'Dodano nowy projekt',
          type: 'success',
        });
      })
      .catch((error) => {
        dispatch(setPostNewSurveyFetchError(error.response));
        dispatch(setPostNewSurveyFetchStatus(REQUEST_STATUSES.ERROR));
        showNotification({
          response: error.response,
          type: 'error',
        });
      });
  };
};

export const getSurveyVotingDetail = (uuid: string, isLogged?: boolean) => {
  let url = process.env.REACT_APP_API_URL + `/surveys/${uuid}/`;
  return function (dispatch: Dispatch) {
    dispatch(setSurveyVotingDetailFetchStatus(REQUEST_STATUSES.NULL));
    axios
      .get(url)
      .then((response) => {
        dispatch(setSurveyVotingDetail(response.data));
        dispatch(setSurveyVotingDetailFetchStatus(REQUEST_STATUSES.DONE));
      })
      .catch((error) => {
        dispatch(setSurveyVotingDetailFetchError(error.response));
        dispatch(setSurveyVotingDetailFetchStatus(REQUEST_STATUSES.ERROR));
      });
  };
};

export const getSurveyDetail = (uuid: string, isLogged?: boolean) => {
  let url = process.env.REACT_APP_API_URL + `/surveys/${uuid}/`;
  return function (dispatch: Dispatch) {
    dispatch(setSurveyDetailFetchStatus(REQUEST_STATUSES.NULL));
    axios
      .get(url)
      .then((response) => {
        dispatch(setSurveyDetail(response.data));
        dispatch(setSurveyDetailFetchStatus(REQUEST_STATUSES.DONE));
      })
      .catch((error) => {
        dispatch(setSurveyDetailFetchError(error.response));
        dispatch(setSurveyDetailFetchStatus(REQUEST_STATUSES.ERROR));
      });
  };
};

//todo: separate results survey details
export const getSurveyResults = (uuid: string) => {
  let url = process.env.REACT_APP_API_URL + `/surveys/${uuid}/results/`;
  return function (dispatch: Dispatch) {
    dispatch(setSurveyResultFetchError(null));
    dispatch(setSurveyDetailFetchStatus(REQUEST_STATUSES.NULL));
    axios
      .get(url)
      .then((response) => {
        dispatch(setSurveyResultFetchError(null));
        dispatch(setSurveyDetailFetchStatus(REQUEST_STATUSES.DONE));
        dispatch(setSurveyDetail(response.data));
      })
      .catch((error) => {
        dispatch(setSurveyResultFetchError(error.response));
        dispatch(setSurveyDetailFetchStatus(REQUEST_STATUSES.ERROR));
        showNotification({
          response: error.response,
          type: 'error',
        });
      });
  };
};

export const resetSurveyState = () => {
  return function (dispatch: Dispatch) {
    dispatch(setSurveyDetailFetchStatus(REQUEST_STATUSES.NULL));
    dispatch(setSurveyDetailFetchError(null));
  };
};

// old way, didn't work on ios
export const getSurveyProtocol = (uuid: string, surveyName: string) => {
  axios({
    url: process.env.REACT_APP_API_URL + `/surveys/${uuid}/result_protocol/`,
    method: 'GET',
    responseType: 'blob',
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${surveyName}.pdf`);
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const postVote = ({
  selectedAnswers,
  uuid,
  survey,
  revealNameApprovalChecked,
  is_anonymous,
  nickname,
}: any) => {
  return function (dispatch: Dispatch) {
    let promises: Array<any> = [];
    let preparedAnswers: Array<any> = selectedAnswers
      .filter((answer: any) => !answer.is_free_answer)
      .map((answer: any) => answer.id);

    selectedAnswers
      .filter(
        (answer: any) => answer && answer.hasOwnProperty('is_free_answer') && answer.is_free_answer
      )
      .forEach((answer: any) => {
        let url = process.env.REACT_APP_API_URL + `/surveys/${uuid}/create_answer/`;
        let request = axios.post(url, {
          survey: survey.uuid,
          question: answer.question_id,
          text: answer.text,
        });
        promises.push(request);
      });

    dispatch(setPostVoteFreeAnswersFetchError(null));
    dispatch(setVoteFreeAnswersFetchStatus(REQUEST_STATUSES.FETCHING));
    Promise.all(promises)
      .then((response) => {
        preparedAnswers = [...preparedAnswers, ...response.map((x) => x.data.id)];

        dispatch(setPostVoteFreeAnswersFetchError(null));
        dispatch(setPostVoteFetchError(null));
        dispatch(setVoteFreeAnswersFetchStatus(REQUEST_STATUSES.DONE));
        dispatch(setVoteFetchStatus(REQUEST_STATUSES.FETCHING));
        surveyVote({
          uuid,
          answers: preparedAnswers,
          revealNameApprovalChecked,
          nickname,
          is_anonymous,
        })
          .then((response: any) => {
            if (response.status === 200) {
              dispatch(setPostVoteFetchError(null));
              dispatch(setVoteFetchStatus(REQUEST_STATUSES.DONE));
            } else {
              dispatch(setPostVoteFetchError(response));
              dispatch(setVoteFetchStatus(REQUEST_STATUSES.ERROR));
            }
          })
          .catch((error) => {
            dispatch(setPostVoteFetchError(error.response));
            dispatch(setVoteFetchStatus(REQUEST_STATUSES.ERROR));
          });
      })
      .catch((error) => {
        if (error.response.status !== 403) {
          showNotification({
            response: error.response,
            type: 'error',
          });
        }
        dispatch(setPostVoteFreeAnswersFetchError(error.response));
        dispatch(setVoteFreeAnswersFetchStatus(REQUEST_STATUSES.ERROR));
      });
  };
};

// todo: move to state: vote: {
//     fetchStatus: REQUEST_STATUSES.NULL,
//     errorMessage: null
//   }
export const surveyVote = ({
  uuid,
  nickname,
  is_anonymous,
  answers,
  revealNameApprovalChecked,
}: {
  uuid: string;
  answers: Array<number | undefined>;
  revealNameApprovalChecked: string;
  is_anonymous: boolean;
  nickname?: string;
}): Promise<any> => {
  let url = process.env.REACT_APP_API_URL + `/surveys/${uuid}/vote/`;
  // dispatch(setVoteFetchStatus(REQUEST_STATUSES.FETCHING));
  return axios
    .post(url, { answers: answers, nickname: nickname || null, is_anonymous })
    .then((response) => {
      showNotification({
        message: 'Głos został oddany',
        type: 'success',
      });
      // dispatch(setVoteFetchStatus(REQUEST_STATUSES.DONE));
      return response;
    })
    .catch((error) => {
      if (error.response.status !== 403) {
        showNotification({
          response: error.response,
          type: 'error',
        });
      }
      // dispatch(setVoteFetchStatus(REQUEST_STATUSES.ERROR));
      // dispatch(setVoteFetchErrorMessages(response));
      return error.response;
    });
};

export const projectVote = (props: {
  uuid: string;
  answers: Array<number | undefined>;
  is_anonymous: boolean;
  assign_to_group: boolean;
}) => {
  const { uuid, answers, is_anonymous, assign_to_group } = props;
  let url = process.env.REACT_APP_API_URL + `/surveys/${uuid}/vote/`;
  return function (dispatch: Dispatch) {
    dispatch(setVoteFetchStatus(REQUEST_STATUSES.FETCHING));
    axios
      .post(url, { answers: answers, is_anonymous, assign_to_group })
      .then((response) => {
        dispatch(setVoteFetchStatus(REQUEST_STATUSES.DONE));
      })
      .catch((error) => {
        dispatch(setVoteFetchStatus(REQUEST_STATUSES.ERROR));
        dispatch(setVoteFetchErrorMessages(error.response));
        if (error.response.status !== 403) {
          showNotification({
            response: error.response,
            type: 'error',
          });
        }
      });
  };
};

export const closeVoting = (uuid: string): Promise<any> => {
  let url = process.env.REACT_APP_API_URL + `/surveys/${uuid}/close/`;
  return axios
    .post(url)
    .then((response) => {
      showNotification({
        message: 'Głosowanie zostało zakończone',
        type: 'success',
      });
      return response;
    })
    .catch((error) => {
      showNotification({
        response: error.response,
        type: 'error',
      });
    });
};

export const getUserRequirementsForSurvey = ({ surveyUuid }: { surveyUuid: string }) => {
  let url = process.env.REACT_APP_API_URL + `/surveys/check_vote_ability/`;
  return function (dispatch: Dispatch) {
    dispatch(updateUserRequirementsForSurveyFetchStatus(REQUEST_STATUSES.FETCHING));
    axios
      .post(url, { survey_uuid: surveyUuid })
      .then((response) => {
        dispatch(updateUserRequirementsForSurvey(response.data));
        dispatch(updateUserRequirementsForSurveyFetchStatus(REQUEST_STATUSES.DONE));
      })
      .catch((error) => {
        showNotification({
          response: error.response,
          type: 'error',
        });
        dispatch(updateUserRequirementsForSurveyFetchStatus(REQUEST_STATUSES.ERROR));
      });
  };
};

export const setUpdatedSurveysList = (data: any) => {
  return {
    type: UPDATE_SURVEYS_LIST,
    payload: data,
  };
};

export const updateNewSurveyData = (data: any) => {
  return {
    type: UPDATE_NEW_SURVEY_DATA,
    payload: data,
  };
};

export const resetNewSurveyData = () => {
  return {
    type: RESET_NEW_SURVEY_DATA,
  };
};

export const setSurveyVotingDetailFetchStatus = (data: any) => {
  return {
    type: UPDATE_SURVEY_VOTING_DETAIL_FETCH_STATUS,
    payload: data,
  };
};

export const setSurveyDetailFetchStatus = (data: any) => {
  return {
    type: UPDATE_SURVEY_DETAIL_FETCH_STATUS,
    payload: data,
  };
};

export const setSurveyResultFetchError = (data: any) => {
  return {
    type: UPDATE_SURVEY_RESULT_FETCH_ERROR,
    payload: data,
  };
};

export const setSurveyVotingDetailFetchError = (data: any) => {
  return {
    type: UPDATE_SURVEY_VOTING_DETAIL_FETCH_ERROR,
    payload: data,
  };
};

export const setSurveyDetailFetchError = (data: any) => {
  return {
    type: UPDATE_SURVEY_DETAIL_FETCH_ERROR,
    payload: data,
  };
};

export const setSurveyVotingDetail = (data: any) => {
  return {
    type: UPDATE_SURVEY_VOTING_DETAIL,
    payload: data,
  };
};

export const setSurveyDetail = (data: any) => {
  return {
    type: UPDATE_SURVEY_DETAIL,
    payload: data,
  };
};

export const setVoteFetchStatus = (data: any) => {
  return {
    type: SET_VOTE_FETCH_STATUS,
    payload: data,
  };
};

export const resetVoteState = () => {
  return {
    type: RESET_VOTE_STATE,
    payload: null,
  };
};

export const setVoteFreeAnswersFetchStatus = (data: any) => {
  return {
    type: SET_VOTE_FREE_ANSWERS_FETCH_STATUS,
    payload: data,
  };
};

export const setVoteFetchErrorMessages = (data: any) => {
  return {
    type: UPDATE_SURVEY_DETAIL,
    payload: data,
  };
};

export const resetSurveyDetail = () => {
  return {
    type: RESET_SURVEY_DETAIL,
  };
};

export const resetSurveysList = () => {
  return {
    type: RESET_SURVEYS_LIST,
  };
};

export const setNewConfirmedSurvey = () => {
  return {
    type: SET_NEW_CONFIRMED_SURVEY,
  };
};

export const setPostVoteFreeAnswersFetchError = (payload: any) => {
  return {
    type: SET_VOTE_FREE_ANSWERS_FETCH_ERROR,
    payload,
  };
};

export const setPostVoteFetchError = (payload: any) => {
  return {
    type: SET_VOTE_FETCH_ERROR,
    payload,
  };
};

export const updateUserRequirementsForSurvey = (payload: any) => {
  return {
    type: UPDATE_REQ_FOR_SURVEY,
    payload,
  };
};

export const clearUserRequirementsForSurvey = (payload: any) => {
  return {
    type: CLEAR_REQ_FOR_SURVEY,
  };
};

export const updateUserRequirementsForSurveyFetchStatus = (payload: any) => {
  return {
    type: UPDATE_REQ_FOR_SURVEY_FETCH_STATUS,
    payload,
  };
};

export const resetUserRequirementsForSurvey = (payload: any) => {
  return {
    type: RESET_REQ_FOR_SURVEY,
    payload,
  };
};

export const setPostNewSurveyData = (data: any) => {
  return {
    type: SET_POST_NEW_SURVEY_DATA,
    payload: data,
  };
};
export const setPostNewSurveyFetchStatus = (data: any) => {
  return {
    type: SET_POST_NEW_SURVEY_FETCH_STATUS,
    payload: data,
  };
};
export const setPostNewSurveyFetchError = (data: any) => {
  return {
    type: SET_POST_NEW_SURVEY_FETCH_ERROR,
    payload: data,
  };
};

export const resetNewProjectData = () => {
  return {
    type: RESET_NEW_PROJECT_DATA,
  };
};

export const resetVoteStatus = () => {
  return {
    type: RESET_VOTE_STATUS,
  };
};
